import React, {useState} from 'react'
import CollectionTable from "../components/CollectionTable";
import ModalCategories from "../components/modal/ModalCategories";
import moment from "moment";
import {Button, message, PageHeader, Space, Modal, Input} from "antd";
import Icon, {DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined} from "@ant-design/icons";
import {useApiContext} from "../providers/ApiProvider";
import {ReactComponent as DeleteIcon} from "../assets/icons/delete_icon.svg";

export default function TemplateCategories() {

    const [apiDispatch] = useApiContext();
    const {apiDeleteEntity} = apiDispatch;
    const [showForm, setShowForm] = useState(false)
    const [selectedElement, setSelectedElement] = useState(null)
    const [needUpdate, setNeedUpdate] = useState(0)
    const [filters, setFilters] = useState({name:"name", value: ""})

    const columns = [
        {
            title: 'Nom',
            dataIndex: "name",
            render: (name) => (
                <span>
                   {name}
                </span>)
        },
        {
            title: 'Date de mise en avant',
            dataIndex: "showAt",
            render: (showAt) => (
                <span>
                   {showAt ? moment(showAt).format('DD-MM-YY') : ''}
                </span>)
        },
        {
            title: 'Importance',
            dataIndex: "importance",
            render: (importance) => (
                <span>
                   {importance}
                </span>)
        },
        {
            title: 'Actions',
            dataIndex: "id",
            render: (id, record) => (
                <Space>
                    <EditTwoTone onClick={() => {
                        setSelectedElement(record);
                        setShowForm(true)
                    }}/>
                    <Icon component={DeleteIcon} style={{color:"#E64D3D"}} onClick={() => showDeleteConfirm(id)}/>
                </Space>
            )
        }
    ]

    function showDeleteConfirm(id) {
        Modal.confirm({
            title: 'Êtes-vous sur de vouloir supprimer cette catégorie ?',
            icon: <ExclamationCircleOutlined/>,
            okText: 'Supprimer',
            okType: 'danger',
            cancelText: 'Annuler',
            onOk() {
                handleRemove(id)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    async function handleRemove(id) {

        const response = await apiDeleteEntity("template_categories", id);

        message.success("Catégorie supprimée avec succes")
        setNeedUpdate(needUpdate + 1)
    }

    const [searchInterval, setSearchInterval] = useState(null)
    function handleSearch(e) {
        clearTimeout(searchInterval)

       setSearchInterval(setTimeout(function() {
           setFilters({name:'name', value:e.target.value.trim()})
       }, 200))

    }

    return (
        <div>
            <PageHeader
                title="Catégories"
                extra={[
                    <Input onChange={handleSearch} style={{width:'200px'}}
                           key="categorie_search"
                           placeholder="Rechercher par nom"/>,
                    <Button type="primary"
                            key="categorie_create"
                            onClick={() => setShowForm(true)}>Créer</Button>,
                ]}
            />
            {
                showForm ?
                    <ModalCategories setIsVisible={setShowForm} isVisible={showForm} category={selectedElement} setNeedUpdate={setNeedUpdate}/>
                    : null
            }

            <CollectionTable columns={columns} filters={[filters]} endpoint={"template_categories"} reload={needUpdate}/>

        </div>
    )

}