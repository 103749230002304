import React, { useEffect, useRef, useState } from "react";
import { Form, Input, InputNumber, Radio, Select, Space, Switch, Button, Col, Row, Checkbox } from "antd";
import Colors from "../../../Colors";
import { borderStyles, shadowArray } from "../../utils";
import { useEditorContext } from "../../../../providers/EditorProvider";
import { CheckOutlined } from "@ant-design/icons";


export default function DimensionPanel({ message, setMessage }) {

    const [editorState, editorDispatch] = useEditorContext()
    const [backgroundColor, setBackgroundColor] = useState(message.style.backgroundColor)
    const [borderColor, setBorderColor] = useState(message.style.borderColor)
    const [saveTimeout, setSaveTimeout] = useState()
    const [type, setType] = useState(message.options?.action?.type)
    const [heightTouched, setHTouched] = useState(false)
    const [widthTouched, setWTouched] = useState(false)

    const form = useRef()

    useEffect(() => {

        if (typeof message.options?.action?.link === 'string') {
            let msgAction = message.options?.action ? { ...message.options?.action } : {}

            let action = { ...msgAction, link: { fr: message.options?.action?.link } }
            setMessage({ ...message, options: { ...message.options, action: action } })
            form.current.setFieldsValue({ link: action?.link?.[editorState.lng] })
        } else {
            form.current.setFieldsValue({ link: message.options?.action?.link?.[editorState.lng] })
        }
    }, [editorState.lng])

    useEffect(() => {
        if (form.current) {
            form.current.setFieldsValue({
                closable: !!message.options.closable,
                size: message.options.size || 'free',
                height: parseFloat(message.style.height),
                width: parseFloat(message.style.width),
                radius: message.style.borderRadius || 0,
                shadow: message.style.boxShadow || '',
                backgroundColor: message.style.backgroundColor,
                borderColor: borderColor,
                borderWidth: parseFloat(message.style.borderWidth) || 0,
                borderStyle: message.style.borderStyle || 'none',
                borderRadius: parseFloat(message.style.borderRadius) || 0
            })
        }
    }, [message.style])


    const updateElements = (formValues) => {
        return message.children.map(child => {
            return updateElementPosition({ ...child }, formValues)
        })
    }

    const handleChange = (changedValues, allValues) => {
        console.log('changedValues', changedValues)
        if (changedValues.hasOwnProperty('height')) return setHTouched(true)
        if (changedValues.hasOwnProperty('width')) return setWTouched(true);
        if (changedValues.size && changedValues.size.match(/vertical|horizontal/)) {
            return updateSize(allValues)
        }
        if (changedValues.type) {
            setType(changedValues.type)
        }
        handleSave(allValues, { options: { ...message.options, size: allValues.size } })
    }

    const handleSizeChanges = () => {
        let allValues = form.current.getFieldsValue();
        handleSave(allValues, { options: { ...message.options, size: allValues.size } })

    }

    const updateSize = (allValues) => {

        let additionalParams = { type: 'notification' }
        let options = { ...message.options, horizontalOffset: 0, verticalOffset: 0, size: allValues.size }

        if (allValues.size.includes('horizontal')) {
            // put width to 1920px
            allValues.width = allValues.size === 'horizontal' ? 1920 : 500
            options.animation = message.options.verticalPos === 'top' ? 'slideDown' : 'slideUp'
        } else {
            //put height to 1080px
            allValues.height = 1080
            options.animation = message.options.horizontalPos === 'left' ? 'slideLeft' : 'slideRight'
        }

        handleSave(allValues, { ...additionalParams, options: options })
    }

    const updateElementPosition = (element, formValues) => {

        let wRatio = formValues.width / parseFloat(message.style.width)
        let hRatio = formValues.height / parseFloat(message.style.height)

        if (element.type === 'picture') {

            let newContent = {}
            Object.keys(element.content).forEach((key, index) => {
                let content = { ...element.content[key] }
                let { translate, rotate } = content

                translate = [((translate[0] * wRatio)).toFixed(2) * 1, (translate[1] * hRatio).toFixed(2) * 1]
                let newStyleValues = {
                    transform: `translate(${translate[0]}px, ${translate[1]}px) rotate(${rotate}deg)`
                }
                newContent[key] = { ...content, style: { ...content.style, ...newStyleValues }, translate: translate }
            });

            return { ...element, content: newContent }

        } else {

            let { translate, rotate } = element

            translate = [((translate[0] * wRatio)).toFixed(2) * 1, (translate[1] * hRatio).toFixed(2) * 1]
            let newStyleValues = {
                transform: `translate(${translate[0]}px, ${translate[1]}px) rotate(${rotate}deg)`
            }

            return { ...element, style: { ...element.style, ...newStyleValues }, translate: translate }
        }
    }

    const getStyle = (size) => {

        let style = { ...message.style }
        if (size !== 'free') {
            delete style.top
            delete style.left
            delete style.right
            delete style.bottom

            style[message.options.horizontalPos] = '0%'
            style[message.options.verticalPos] = '0%'
        }
        return style;
    }


    const handleColorChange = (property, color, setter) => {
        form.current.setFieldsValue({
            [property]: color
        })
        setter(color)
        handleSave(form.current.getFieldsValue())
    }

    const handleSave = (formValues, additionalParams = {}) => {

        clearTimeout(saveTimeout)

        setSaveTimeout(setTimeout(() => {
            console.log(formValues)

            formValues.height = formValues.height ? formValues.height : parseFloat(message.style.height)
            formValues.width = formValues.width ? formValues.width : parseFloat(message.style.width)

            let values = {
                backgroundColor: formValues.backgroundColor,
                height: formValues.height + 'px',
                width: formValues.width + 'px',
                boxShadow: formValues.shadow,
                borderRadius: formValues.borderRadius + 'px',
                borderColor: formValues.borderColor,
                borderWidth: formValues.borderWidth + 'px',
                borderStyle: formValues.borderStyle
            }

            console.log(values)

            let messageStyle = getStyle(formValues.size)
            let children = updateElements(formValues)

            let messageCloseButton = updateElementPosition({ ...message.options.closeButton }, formValues);
            let messageOptions = { ...message.options }

            let action = {
                type: formValues.type,
                link: parseLink(formValues.link),
                targetBlank: formValues?.targetBlank,

            }
            if (additionalParams.options) {
                messageOptions = { ...messageOptions, ...additionalParams.options }
            }
            messageOptions = {
                ...messageOptions,
                closeButton: messageCloseButton,
                closable: formValues.closable,
                action: action
            }

            setMessage({
                ...message,
                style: {
                    ...messageStyle,
                    ...values
                },
                children: children,
                ...additionalParams,
                options: messageOptions
            })

            setWTouched(false)
            setHTouched(false)

        }, 500))
    }

    function parseLink(value) {
        let messageLinks = message.options?.action?.link ? { ...message.options.action.link } : {}

        if (!value || !value.trim()) return ''
        let match = value.match(/^(http|https):\/\//)
        if (!match) {
            value = 'https://' + value
        }
        return { ...messageLinks, [editorState?.lng]: value }
    }

    return (
        <Form
            name="dimensionForm"
            ref={form}
            initialValues={{
                closable: !!message.options.closable,
                size: message.options.size || 'free',
                height: parseFloat(message.style.height),
                width: parseFloat(message.style.width),
                radius: message.style.borderRadius || '',
                shadow: message.style.boxShadow || '',
                backgroundColor: message.style.backgroundColor,
                borderColor: borderColor,
                borderWidth: parseFloat(message.style.borderWidth) || 0,
                borderStyle: message.style.borderStyle || 'none',
                borderRadius: parseFloat(message.style.borderRadius) || 0,
                link: message.options?.action?.link,
                targetBlank: message.options?.action?.targetBlank,
                type: type,
                value: message.options?.action?.link?.[editorState.lng]
            }}
            onValuesChange={handleChange}
        >
            <p><strong>Paramètres généraux</strong></p>
            <Form.Item name="closable" label="Bouton de fermeture" valuePropName="checked">
                <Switch size="small" />
            </Form.Item>
            <Form.Item name="size" label="Taille">
                <Radio.Group>
                    <Radio style={{ display: 'block' }} value={"free"}>Personnalisé</Radio>
                    <Radio style={{ display: 'block' }} value={"vertical"}>Bandeau vertical</Radio>
                    <Radio style={{ display: 'block' }} value={"horizontal"}>Bandeau horizontal</Radio>
                    <Radio style={{ display: 'block' }} value={"horizontal-mobile"}>Bandeau mobile ( horizontal )</Radio>
                </Radio.Group>
            </Form.Item>

            <Row>
                <Col span={18}>
                    <Form.Item name="width" label="Largeur" hidden={message.options?.size?.includes('horizontal')}>
                        <InputNumber min={50} />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    {widthTouched && <Button onClick={handleSizeChanges} icon={<CheckOutlined />} />}
                </Col>
            </Row>
            <Row>
                <Col span={18}>
                    <Form.Item name="height" label="Hauteur" hidden={message.options?.size === 'vertical'}>
                        <InputNumber min={50} />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    {heightTouched && <Button onClick={handleSizeChanges} icon={<CheckOutlined />} />}
                </Col>
            </Row>
            <Form.Item name="backgroundColor" label="Couleur du fond">
                <Colors currentColor={backgroundColor}
                    setColor={color => handleColorChange('backgroundColor', color, setBackgroundColor)} />
            </Form.Item>
            <Form.Item name="shadow" label="Ombre">
                <Select placeholder={"shadow"} style={{ width: '150px' }}>
                    {
                        shadowArray.map((item, index) =>
                            <Select.Option key={item} value={item}>shadow {index}</Select.Option>
                        )
                    }
                </Select>
            </Form.Item>
            <Form.Item name={'borderRadius'} label="Arrondi">
                <InputNumber max={100} min={0} style={{ width: '60px' }} />
            </Form.Item>
            <p>Bordure :</p>
            <Space>
                <Form.Item name="borderStyle">
                    <Select placeholder={"Solide"} style={{ width: '150px' }}>
                        {borderStyles.map(item =>
                            <Select.Option key={'border_' + item.value} value={item.value}>{item.name}</Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item name={'borderWidth'}>
                    <InputNumber max={10} min={0} style={{ width: '60px' }} />
                </Form.Item>
                <Form.Item name={'borderColor'}>
                    <Colors currentColor={borderColor}
                        setColor={color => handleColorChange('borderColor', color, setBorderColor)} />
                </Form.Item>
            </Space>
            <div>
                <p><strong>Action lors du clic sur le message</strong></p>
                <Form.Item name={'type'}>
                    <Radio.Group>
                        <Space direction="vertical">
                            <Radio value={undefined}>Ne rien faire</Radio>
                            <Radio value={"close"}>Fermer le message</Radio>
                            {/*<Radio value={"step"}>Ouvrir</Radio>*/}
                            <Radio value={"link"}>Envoyer sur un lien</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
                {
                    type === 'link' &&
                    <>
                        <p>Destination</p>
                        <Form.Item name={'link'}>
                            <Input placeholder="Lien vers la page de destination" />
                        </Form.Item>
                        <Form.Item name={'targetBlank'} valuePropName="checked">
                            <Checkbox >Ouvrir le lien dans un nouvel onglet</Checkbox>
                        </Form.Item>
                    </>
                }
            </div>
        </Form >
    )
}