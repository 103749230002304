import React, {useCallback, useEffect, useState} from 'react'
import {Button, message, Space, Spin, Switch} from "antd";
import {useEditorContext} from "../../../providers/EditorProvider";
import {useApiContext} from "../../../providers/ApiProvider";
import {useAccountContext} from "../../../providers/AccountProvider";
import ModalPixabay from "../../modal/ModalPixabay";
import Colors from "../../Colors";

let lng = 'fr';
let localTarget = null

export default function PictureTab({isOpen, setVisible, target = null, getLink = null}) {

    const [accountState, accountDispatch] = useAccountContext();
    const {setHapidamKey} = accountDispatch
    const [editorState, editorDispatch] = useEditorContext()
    const {addElement, selectElement, updateElement} = editorDispatch

    const [apiDispatch] = useApiContext();
    const {apiPostEntity, apiUpdateEntity} = apiDispatch;

    const [loading, setLoading] = useState(true);
    const [hapidamClient, setHapidamClient] = useState(null);
    const [pixabayVisible, setPixabayVisible] = useState(false);
    const [hapidamLoading, setHapidamLoading] = useState(false);
    const [isHapidamListener, setIsHapidamListener] = useState(false)

    const [hasOverlay, setHasOverlay] = useState(!!target?.content[editorState.lng]?.style?.backgroundColor)
    const [overlayColor, setOverlayColor] = useState(target?.content[editorState.lng]?.style?.backgroundColor || 'rgba(0,0,0,.3)')

    useEffect(() => {
        localTarget = target
    }, [target])
    useEffect(() => {
        if (target)
            updateOverlay()
    }, [hasOverlay, overlayColor])

    const updateOverlay = () => {
        let lngContent = target.content[editorState.lng] ? {...target.content[editorState.lng]} : {...target.content[Object.keys(target.content)[0]]}
        let style = {...lngContent.style}
        if (hasOverlay) {
            style.backgroundColor = overlayColor
            style.backgroundBlendMode = "overlay"
        } else {
            style.backgroundBlendMode = 'initial'
        }

        updateElement({...target, content: {...target.content, [editorState.lng]: {...lngContent, style: style}}})
    }

    useEffect(() => {
        setHapidamLoading(false)

        if (!hapidamClient) {
            setHapidamClient(new Hapidam())
        } else {
            let now = new Date().getTime()
            let isHapidamKeyWorking = accountState.hapidamKey.time ? accountState.hapidamKey.time + 3600000 > now : false

            if (accountState.hapidamKey.key && isHapidamKeyWorking) {
                hapidamClient.setToken(accountState.hapidamKey.key)
                setLoading(false);

            } else {
                fetchHapidamKey()
            }

            //clean up the listener to avoid conflict between different hapidam events 
            if (!isOpen) {
                hapidamClient.off('select', handleResponse);
                setIsHapidamListener(false)
            }
        }

    }, [hapidamClient, isOpen])

    async function fetchHapidamKey() {
        let data = {accountId: accountState.account.id}
        let response = await apiPostEntity('hapidam-connect', data)
        if (response.success) {
            setHapidamKey(response.publicKey)
            hapidamClient.setToken(response.publicKey)
        }

        setLoading(false);
    }

    async function getMeta(url) {
        let img = new Image()
        img.src = url
        try {
            await img.decode()
        } catch (e) {
            message.error('erreur lors de la récupération de votre image, vérifier votre lien')
            return {error: true}
        }
        return {width: img.width, height: img.height}
    }

    async function addImage(url, smartRequest = null) {
        let id = Date.now()
        let {width, height, error} = await getMeta(url);
        if (error) {
            return
        }

        if (target) {
            return updateTarget(url, width, height, smartRequest)
        }
        if (getLink) {
            return getLink(url)
        }

        const element = {
            id: id,
            name: `${editorState.message.children.length} image`,
            type: 'picture',
            content: {
                [editorState.lng]: {
                    style: {
                        width: `250px`,
                        height: `${Math.floor(height * 250 / width)}px`,
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        backgroundPosition: `center`,
                        backgroundSize: 'contain',
                        backgroundRepeat: ' no-repeat',
                        backgroundImage: `url(${url})`,
                        transform: 'translate(0, 0)'
                    },
                    translate: [0, 0],
                    rotate: 0,
                    ratio: true,
                    smartRequest: smartRequest,
                }
            }
        }

        selectElement(element)
        addElement(element)
        setVisible(false)
    }

    const updateTarget = (url, imageWidth, imageHeight, smartRequest = null) => {

        let lngContent = localTarget.content[lng] ? {...localTarget.content[lng]} : {...localTarget.content[Object.keys(localTarget.content)[0]]}
        let style = {...lngContent.style}
        let targetWidth = parseFloat(style.width)
        style.backgroundImage = `url(${url})`
        style.backgroundPosition = "center"
        style.backgroundRepeat = " no-repeat"
        style.backgroundSize = "contain"
        style.height = `${Math.floor(imageHeight * targetWidth / imageWidth)}px`

        delete style.background

        updateElement({
            ...localTarget,
            content: {...localTarget.content, [lng]: {...lngContent, style: style, smartRequest: smartRequest}}
        })
    }

    const handleHapidam = () => {
        lng = editorState.lng

        if (!isHapidamListener) {
            hapidamClient.on('select', handleResponse);
            setIsHapidamListener(true)
        }

        if (target && target.content[lng].smartRequest)
            hapidamClient.openBuilder(JSON.stringify(target.content[lng].smartRequest.query), {
                switched: 'on',
                limit: 1
            });
        else
            hapidamClient.open({switched: 'on', limit: 1});
    }

    const handleResponse = useCallback(async (response) => {

        hapidamClient.off('select', handleResponse);
        setIsHapidamListener(false)
        hapidamClient.close();

        if (response.display)
            addImage(response.display.native.sm);
        else if (typeof response === 'object' && response.length) {
            const query = {id: Date.now(), query: response};
            const body = {queries: [query]}

            const data = {
                token: accountState.hapidamKey.key,
                body: body
            }

            const responseDam = await apiPostEntity('hapidam-smart-request', data)

            if (responseDam.results.length)
                addImage(responseDam.results[0].results[0].display.native.sm, query)
        }
    }, [hapidamClient, accountState.hapidamKey])

    function connect() {
        if (!isHapidamListener) {
            hapidamClient.on('select', handleConnectResponse);
            setIsHapidamListener(true)
        }
        hapidamClient.connect()
    }

    function handleConnectResponse(response) {
        hapidamClient.off('select', handleResponse);
        setIsHapidamListener(false)
        setHapidamLoading(true)
        let params = {
            hapidamKey: response.token
        }
        updateAccount(params)
    }

    async function updateAccount(params) {
        let response = await apiUpdateEntity('accounts', accountState.account.id, params)
        await fetchHapidamKey()
        setHapidamLoading(false)
    }


    return (
        <Spin spinning={loading || hapidamLoading}>
            <ModalPixabay addImage={addImage} visible={pixabayVisible} setVisible={setPixabayVisible}/>
            {
                target &&
                <div style={{minWidth: '250px'}}>
                    <p style={{fontWeight: 'bold'}}>Ajouter un overlay sur l'image</p>
                    <Space direction="vertical">
                        <div><Switch checked={hasOverlay} size="small"
                                     onChange={() => setHasOverlay(prev => !prev)}/> Activer
                        </div>
                        <Space><Colors currentColor={overlayColor}
                                       setColor={(color) => setOverlayColor(color)}/> Couleur</Space>
                    </Space>
                </div>
            }

            {hapidamClient && hapidamClient.token ?
                <Space direction="vertical" style={{width: '100%'}}>
                    {
                        target && <div style={{fontWeight: 'bold'}}>Modifier l'image</div>
                    }
                    <div className="insert-btn" onClick={handleHapidam}>
                        <p>Ouvrir la phototèque Hapidam</p>
                    </div>
                    <div className="insert-btn" onClick={() => setPixabayVisible(true)}>
                        <p>Choisir dans Pixabay</p>
                    </div>
                </Space>
                :
                <div>
                    <p style={{fontWeight: 'bold'}}>Votre compte n'est pas encore lié à la phototèque Hapidam</p>
                    <Button onClick={connect}>Connexion à la phototèque Hapidam</Button>
                </div>
            }

        </Spin>
    )
}