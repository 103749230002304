import React, {useState} from 'react'
import {fas} from '@fortawesome/pro-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {fad} from '@fortawesome/pro-duotone-svg-icons'
import {fal} from '@fortawesome/pro-light-svg-icons'
import {far} from '@fortawesome/pro-regular-svg-icons'

import {useEditorContext} from "../../../providers/EditorProvider";
import {Input, Select, Space, Spin} from "antd";
import rowImg from "../../../assets/row.png"
import circleImg from "../../../assets/cercle.png"
import squareImg from "../../../assets/square.png"
import triangleImg from "../../../assets/triangle.png"
import {useApiContext} from "../../../providers/ApiProvider";

import {ReactComponent as FontAwesome} from "../../../assets/icons/fontawesome.svg";
import {ReactComponent as IconFinder} from "../../../assets/icons/iconfinder.svg";
import {ReactComponent as FlatIcon} from "../../../assets/icons/flaticon.svg";


const FaIcons = {solid: fas, brands: fab, duotone: fad, light: fal, regular: far}
const BaseIcon = {
    type: 'iconFinder',
    style: {
        width: `80px`,
        height: `80px`,
        position: 'absolute',
        top: '0',
        left: '0',
        transform: 'translate(0, 0)',
        color: 'rgba(0,0,0,1)',
    },
    translate: [0, 0],
    rotate: 0,
    ratio: true

}

export default function IconTab({setVisible, target = null}) {

    const [apiDispatch] = useApiContext();
    const {apiFetchCollection, apiPostEntity} = apiDispatch;
    const [editorState, editorDispatch] = useEditorContext();
    const {addElement, selectElement, updateElement} = editorDispatch;

    const [faResult, setFaResult] = useState([])
    const [iconFinderResult, setIconFinderResult] = useState([])
    const [loading, setLoading] = useState(false)
    const [iconLibrary, setIconLibrary] = useState('fa')
    const [noResult, setNoResult] = useState(false)
    const [flatResult, setFlatResult] = useState([])


    async function addIconFinder(icon) {

        let id = Date.now()
        const element = {
            id: id,
            name: `${editorState.message.children.length} icône`,
            content: icon.code,
            colors: extractColors(icon.code),
            useCurrent: useCurrent(icon.code),
            ...BaseIcon
        }

        if (target) return updateTarget(element)

        insertElement(element)
    }

    function insertElement(element) {
        setLoading(false)
        selectElement(element)
        addElement(element)
        setVisible(false)
    }

    function updateTarget(element) {
        updateElement(
            {
                ...target,
                content: element.content,
                colors: element.colors,
                useCurrent: element.useCurrent
            })

        setLoading(false)
        setVisible(false)
    }

    function useCurrent(code) {
        let tagRegex = /(<.+?\/>)|(<.+?\s.+?>)/gm
        const found = code.match(tagRegex)

        if (found) {
            return !!found.find(item => !item.match(/fill|class|svg|xml|DOCTYPE|def|style|stop|desc|<\/.+?>|<[a-z]>/))
        }
        return false
    }

    function extractColors(code) {
        console.log(code)
        let fillRegex = /(fill:.+?["|;])|(stroke:.+?["|;])|(fill=".+?")|(stroke=".+?")|(stop-color=".+?")/gm
        const found = code.match(fillRegex)
        console.log(found)
        if (found) {
            let tempColors = found.filter(text => !text.includes('url')).map(text => {
                return text.replace(/fill:|fill="|stroke:|stroke="|"|;|}|stop-color="/gm, '')
            });
            let colors = new Set([...tempColors])
            colors = Array.from(colors);

            return colors
        }
        return null
    }

    function addIcon(elem, name, isShape = false) {
        setLoading(true)
        let id = Date.now();

        const element = {
            id: id,
            name: `Icon ${name}`,
            content: getIconString(elem, isShape),
            colors: typeof elem.icon[4] !== 'string' ? ['black', 'lightgray'] : null,
            useCurrent: typeof elem.icon[4] === 'string',
            ...BaseIcon,
            ratio: !isShape
        }

        if (target) return updateTarget(element)
        insertElement(element)
    }

    function getIconString(icon, isShape = false) {
        let str = `<svg width="100%" height="100%" viewBox="${"0 0 " + icon.icon[0] + " " + icon.icon[1]}" ${isShape ? 'preserveAspectRatio="none"' : ''}>`

        if (typeof icon.icon[4] === 'string') {
            str += `<path d="${icon.icon[4]}" fill="currentColor"/>`
        } else {
            icon.icon[4].map((item, index) => {
                str += `<path d="${item}" fill="${index === 0 ? 'black' : 'lightgray'}"/>`
            })
        }
        str += "</svg>"
        console.log(str)
        return str;
    }

    async function iconSearch(e, provider) {

        let query = e.trim()
        if (query.length === 0) return;

        setLoading(true)
        setNoResult(false)

        let params = {
            filters:
                [
                    {name: 'query', value: query.toLowerCase()},
                    {name: 'provider', value: provider}
                ]
        }
        // icon finder ajax request only work on https
        let response = await apiFetchCollection('searchIcon', params)
        console.log(response)
        if (provider === 'if') {
            handleIconFinderResults(response)
        } else {
            handleResults(provider, response)
        }

    }

    function handleResults(provider, response) {

        let data = response.success ? response.data : null

        setNoResult(data.length === 0)
        if (provider === 'flatIcon') {
            setFlatResult(data)
        } else {
            setFaResult(data)
        }

        setLoading(false)
    }


    function handleIconFinderResults(response) {

        let data = response.success ? response.data : null
        let results = data && data.icons ? data.icons : []

        setNoResult(results.length === 0)
        setIconFinderResult(results)
        setLoading(false)
    }

    const getIcon = async (icon) => {
        setLoading(true)

        let response = await apiPostEntity('getIcon', {icon: icon})
        await addIconFinder(response)

    }

    const getFlatIcon = async (icon) => {
        setLoading(true)
        let response = await apiPostEntity('getFlatIcon', {icon: icon})
        await addIconFinder(response)

        setLoading(false)
    }

    return (
        <Spin spinning={loading}>
            <div className="mb">
                <p>Rechercher une icône sur :</p>
                <Space>
                    {/* <div onClick={() => setIconLibrary('flatIcon')}
                         title="Flat icon" className={`pointer icon-library ${iconLibrary === 'flatIcon' ? 'selected' : ''}`}>
                        <FlatIcon style={{width: '30px'}}/>
                    </div> */}
                    <div onClick={() => setIconLibrary('if')}
                         title="Icon finder" className={`pointer icon-library ${iconLibrary === 'if' ? 'selected' : ''}`}>
                        <IconFinder style={{width: '30px'}}/>
                    </div>
                    <div onClick={() => setIconLibrary('fa')}
                         title="Font awesome" className={`pointer icon-library ${iconLibrary === 'fa' ? 'selected' : ''}`}>
                        <FontAwesome style={{width: '30px'}}/>
                    </div>
                </Space>
                <Input.Search placeholder="Rechercher par mot clé"
                              className="mt-20"
                              loading={loading}
                              onSearch={(e) => iconSearch(e, iconLibrary)}/>
                {
                    noResult ?
                        <div>Aucun résultat pour votre recherche</div> : null
                }
                <Space className="mt-20" wrap>
                    {
                        iconLibrary === 'fa' &&
                        faResult.map(icon =>
                            icon.styles.map(style =>
                                <div className="icon pointer" key={`fa_${style}_${icon.name}`}
                                     onClick={() => addIcon(FaIcons[style][icon.name], icon.label)}>
                                    <svg width="70%" height="70%"
                                         viewBox={"0 0 " + FaIcons[style][icon.name].icon[0] + " " + FaIcons[style][icon.name].icon[1]}>
                                        {typeof FaIcons[style][icon.name].icon[4] === 'string' ?
                                            <path d={FaIcons[style][icon.name].icon[4]} fill="currentColor"/>
                                            :
                                            FaIcons[style][icon.name].icon[4].map((item, index) =>
                                                <path key={'path_' + index} d={item}
                                                      fill={index === 0 ? 'black' : 'lightgray'}/>
                                            )
                                        }
                                    </svg>
                                </div>
                            ))
                    }
                    {
                        iconLibrary === 'if' &&
                        iconFinderResult.map(icon => {
                            if (icon.type !== 'vector') return;
                            let iconPng = icon.raster_sizes.find(item => item.size === 128) || icon.raster_sizes.pop()

                            return (
                                <div className="icon pointer" onClick={() => getIcon(icon)} key={`if_${icon.id}`}>
                                    <img style={{padding: '5px', maxWidth: '100%', maxHeight: '100%'}}
                                         src={iconPng.formats[0].preview_url}
                                         alt=""/>
                                </div>
                            )
                        })
                    }

                    {
                        iconLibrary === 'flatIcon' && flatResult.map(icon =>
                            <div className="icon pointer" key={icon.id} onClick={() => getFlatIcon(icon)}>
                                <img style={{width: '70%'}} src={icon.images.png?.['128']}/>
                            </div>
                        )

                    }
                </Space>


            </div>

            <div className="mb">
                <p>Insérer une forme</p>

                <div className="flex">
                    <div onClick={
                        () => addIcon({
                            icon: [500, 500, null, null, "M250 0a250 250 0 1 0 0 500a250 250 0 1 0 0-500z"],
                            iconName: "circle"
                        }, 'circle', true)
                    }>
                        <img
                            className="editor-shape-img"
                            src={circleImg}
                            alt=""
                        />
                    </div>

                    <div onClick={
                        () => addIcon({
                            icon: [150.7, 150.7, null, null, "M0,0H150.7V150.7H0z"],
                            iconName: "square"
                        }, 'carre', true)
                    }>
                        <img
                            className="editor-shape-img"
                            src={squareImg}
                        />
                    </div>

                    <div onClick={
                        () => addIcon({
                            icon: [500, 433, null, null, "m0 433l250-433 250 433h-500z"],
                            iconName: "triangle"
                        }, 'triangle', true)
                    }>
                        <img
                            className="editor-shape-img"
                            src={triangleImg}
                        />
                    </div>

                </div>


                <div>
                    <p>Une ligne</p>

                    <div className="flex">
                        <div onClick={
                            () => addIcon({
                                icon: [327.08324821246265, 45, null, null, "M304.1832482124627,20.1H0V25.6H327.08324821246265V20.1z"],
                                iconName: "ligne"
                            }, 'ligne', true)
                        }>
                            <img
                                className="editor-shape-img"
                                src={rowImg}
                            />
                        </div>

                    </div>
                </div>

            </div>
        </Spin>
    )
}
