import React, {useState} from 'react'
import {Button, Input, message, Modal, PageHeader, Space, Switch, Tag} from "antd";
import Icon, { EditTwoTone, ExclamationCircleOutlined} from "@ant-design/icons";
import CollectionTable from "../CollectionTable";
import {useApiContext} from "../../providers/ApiProvider";
import {ReactComponent as DeleteIcon} from "../../assets/icons/delete_icon.svg";
import {UserForm} from "./UserForm";
import UserPermissions from "./UserPermissions";

export default function UserList() {

    const [needUpdate, setNeedUpdate] = useState(0)
    const [apiDispatch] = useApiContext();
    const {apiDeleteEntity, apiUpdateEntity} = apiDispatch;
    const [filters, setFilters] = useState({name: "email", value: ""})
    const [isCreateVisible, setIsCreateVisible] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)
    const [showPermission, setShowPermission] = useState(null)

    function editUser(user) {
        setSelectedUser(user)
        setIsCreateVisible(true)
    }

    function showDeleteConfirm(id) {
        Modal.confirm({
            title: 'Êtes-vous sur de vouloir supprimer cet utilisateur ?',
            icon: <ExclamationCircleOutlined/>,
            okText: 'Supprimer',
            okType: 'danger',
            cancelText: 'Annuler',
            onOk() {
                handleRemove(id)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    async function handleRemove(id) {

        const response = await apiDeleteEntity("users", id);

        message.success("Utilisateur supprimé avec succès")
        setNeedUpdate(needUpdate + 1)
    }

    async function handleChange(value, id) {
        console.log(value)
        let data = {
            roles: [value ? 'ROLE_ADMIN' : 'ROLE_USER']
        }
        let response = await apiUpdateEntity('users', id, data)
        console.log(response)

    }

    const columns = [
        {
            title: 'Nom',
            dataIndex: "username",
            render: (username) => (
                <span>
                   {username}
                </span>)
        },
        {
            title: 'Email',
            dataIndex: "email",
            render: (email) => (
                <span>
                   {email}
                </span>)
        },
        {
            title: 'Admin',
            dataIndex: "roles",
            render: (roles, record) => (
                <span>
                    <Switch size="small" defaultChecked={roles.includes('ROLE_ADMIN')}
                            onChange={(value) => handleChange(value, record.id)}/>
                </span>
            )
        },
        {
            title: 'Actions',
            dataIndex: "id",
            render: (id, record) => (
                <Space>
                    <Tag onClick={() => setShowPermission(record)} className="pointer">Permissions</Tag>
                    <EditTwoTone onClick={()=> editUser(record)}/>
                    <Icon component={DeleteIcon} style={{color:"#E64D3D"}} onClick={() => showDeleteConfirm(id)}/>
                </Space>
            )
        }
    ]

    const [searchInterval, setSearchInterval] = useState(null)

    function handleSearch(e) {
        clearTimeout(searchInterval)
        console.log(e.target.value)
        setSearchInterval(setTimeout(function () {
            setFilters({name: 'email', value: e.target.value.trim()})
        }, 200))

    }


    const handleCreationFormClose = () => {
        setIsCreateVisible(false);
        setSelectedUser(null)
        setNeedUpdate((new Date).getTime())
    }

    return (
        <>
            <PageHeader
                title="Utilisateurs"
                extra={[
                    <Input onChange={handleSearch} style={{width: '200px'}} placeholder="Rechercher par email" key="user_search"/>,
                    <Button type="primary"
                            key="user_create"
                            onClick={() => setIsCreateVisible(true)}>Créer</Button>,
                ]}
            />
            <CollectionTable columns={columns} filters={[filters]} endpoint={"users"} reload={needUpdate}/>
            {
                showPermission &&
                <UserPermissions user={showPermission} setUser={setShowPermission} modal/>
            }
            <UserForm onClose={handleCreationFormClose} visible={isCreateVisible} modal item={selectedUser}/>

        </>
    )

}