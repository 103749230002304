import React from 'react'
import { Button, Input, Popover, Select, Slider, Space } from "antd";
import {
    AlignCenterOutlined,
    AlignLeftOutlined,
    AlignRightOutlined,
    BoldOutlined,
    CloseCircleOutlined,
    ColumnWidthOutlined,
    FontSizeOutlined,
    ItalicOutlined,
    LinkOutlined,
    OrderedListOutlined,
    UnderlineOutlined,
    UnorderedListOutlined
} from "@ant-design/icons";
import Colors from "../Colors";
import FontSelect from "./FontSelect";

export default function QuillToolbar({ quill, format, setEditMode }) {

    const handleChange = (property, value) => {

        quill.focus()
        let range = quill.selection.lastRange

        if (range) {
            let start = !!range.length ? range.index : 0
            let length = range.length || quill.getLength()
            if (property === 'list') {
                quill.formatLine(range.index, range.length, property, value)
            } else {
                quill.formatText(start, length, property, value)
                quill.format(property, value)
            }
            quill.focus()
        }
    }

    const handleSearch = (value) => {
        handleChange('link', value)
    }

    const linkMenu = (
        <Input.Search defaultValue={format?.link} placeholder="entrer votre lien" onSearch={handleSearch}
            enterButton={'valider'} />
    )

    const spacingMenu = (
        <>
            <p>Espacement des lettres</p>
            <Slider tipFormatter={val => `${val}px`}
                style={{ width: '150px' }}
                marks={{ '-2': -2, '-1': 1, 0: 0, 1: 1, 2: 2 }}
                defaultValue={parseFloat(format.letterspacing)}
                min={-2} max={2} step={0.1}
                onAfterChange={value => handleChange('letterspacing', value + 'px')} />
        </>
    )
    const fontSizeMenu = (
        <>
            <p>Taille de la police</p>
            <Slider tipFormatter={val => `${val}px`}
                style={{ width: '150px' }}
                marks={{ 8: 8, 20: 20, 40: 40, 60: 60 }}
                defaultValue={parseFloat(format.size)}
                min={8} max={60} step={1}
                onAfterChange={value => handleChange('size', value + 'px')} />
        </>
    )

    const openLink = () => {
        quill.blur()
    }

    return (
        format && quill ?
            <Space>
                <FontSelect handleChange={(value) => handleChange('font', value)} font={format?.font} />
                <Popover content={fontSizeMenu} trigger="click" destroyTooltipOnHide>
                    <Button icon={<FontSizeOutlined />}
                        title="Taille du texte"
                        onClick={openLink}
                    >{format.size}</Button>
                </Popover>
                <Popover content={spacingMenu} trigger="click" destroyTooltipOnHide>
                    <Button icon={<ColumnWidthOutlined />}
                        title="Espacement des lettres"
                        onClick={openLink}
                    >{format.letterspacing}</Button>
                </Popover>
                <Select style={{ width: '60px', paddingRight: '0' }} value={format?.align || ''}
                    suffixIcon={false}
                    onChange={(value) => handleChange('align', value)}
                >
                    <Select.Option style={{ paddingRight: '0' }} key={'align-left'}
                        value={''}><AlignLeftOutlined /></Select.Option>
                    <Select.Option style={{ paddingRight: '0' }} key={'align-center'}
                        value={'center'}><AlignCenterOutlined /></Select.Option>
                    <Select.Option style={{ paddingRight: '0' }} key={'align-right'} value={'right'}><AlignRightOutlined /></Select.Option>
                </Select>
                <Button icon={<BoldOutlined />}
                    type={format?.bold ? 'primary' : ''}
                    onClick={() => handleChange('bold', !format.bold)} />
                <Button icon={<ItalicOutlined />}
                    onClick={(value) => handleChange('italic', !format.italic)}
                    type={format?.italic ? 'primary' : ''}
                />
                <Button icon={<UnderlineOutlined />}
                    type={format?.underline ? 'primary' : ''}
                    onClick={(value) => handleChange('underline', !format.underline)} />
                <Button icon={<UnorderedListOutlined />}
                    type={format?.list === 'bullet' ? 'primary' : ''}
                    onClick={(value) => handleChange('list', format?.list === 'bullet' ? false : 'bullet')} />
                <Button icon={<OrderedListOutlined />}
                    type={format?.list === 'ordered' ? 'primary' : ''}
                    onClick={(value) => handleChange('list', format?.list === 'ordered' ? false : 'ordered')} />

                <Popover content={linkMenu} trigger="click" destroyTooltipOnHide>
                    <Button icon={<LinkOutlined />}
                        onClick={openLink}
                        type={format?.link ? 'primary' : ''}
                    />
                </Popover>


                <Colors setColor={(value) => handleChange('color', value)} currentColor={format?.color || '#000'} />
                <Button icon={<CloseCircleOutlined />}
                    onClick={() => setEditMode(false)}
                />
            </Space>
            : null
    )

}