import React, { useEffect, useState } from 'react'
import ColorInput from "./ColorInput";
import { useEditorContext } from "../providers/EditorProvider";
import { Dropdown, Menu } from "antd";
import { useAccountContext } from "../providers/AccountProvider";
import { Link } from "react-router-dom";
import { getMessageColors } from "./editor/utils";

const defaultColors = [
    "rgba(255, 255, 255, 1)",
    "rgba(0, 0, 0, 0.85)",
    "rgba(25, 46, 91, 1)",
    "rgba(29, 101, 166)",
    "rgba(114, 162, 192, 1)",
    "rgba(0, 116, 63, 1)",
    "rgba(242, 161, 4, 1)",
    "rgb(244, 135, 75)",
    "rgba(244, 106, 78, 1)"
]

export default function Colors({ setColor, currentColor, isDropdown = true, title = '', icon = null }) {

    const [accountState] = useAccountContext()
    const [editorState, editorDispatch] = useEditorContext()
    const [documentColors, setDocumentColors] = useState([])
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        getDocColors(editorState.message)

    }, [editorState.message])

    function getDocColors() {
        setDocumentColors(getMessageColors(editorState))
    }

    function handleChange(color) {
        setColor(color)
    }

    function toggleVisible(e) {
        e.stopPropagation()
        setIsVisible(!isVisible)
    }

    const content = (
        <Menu style={{ width: "300px" }} onMouseLeave={toggleVisible} onClick={({ domEvent }) => domEvent.stopPropagation()}>
            <Menu.ItemGroup title="Nouvelle couleur">
                <Menu.Item>
                    <div className="iconsGrid color">
                        <ColorInput color={currentColor} setColor={handleChange} />
                        <div className="transparent-bg radius-5 color-container" key={`remove_color`}>
                            <div className="colorSquare " onClick={() => handleChange('rgba(0,0,0,0)')}
                                style={{ backgroundColor: 'rgba(0,0,0,0)' }} />
                        </div>
                    </div>
                </Menu.Item>
            </Menu.ItemGroup>

            <Menu.ItemGroup title="Mes couleurs">
                <Menu.Item>
                    <div className="iconsGrid color">
                        {
                            accountState.account?.colors && accountState.account?.colors.length > 0 ?
                                accountState.account.colors.map(color =>
                                    <div className="transparent-bg radius-5 color-container" key={`brand_${color}`}>
                                        <div className="colorSquare"
                                            onClick={() => handleChange(color)}
                                            style={{ backgroundColor: color }} />
                                    </div>
                                )
                                : <Link to={"/account"}>Cliquez ici pour choisir vos couleurs</Link>
                        }
                    </div>
                </Menu.Item>
            </Menu.ItemGroup>
            {
                documentColors.length ?
                    <Menu.ItemGroup title="Couleurs dans le document">
                        <Menu.Item>
                            <div className="iconsGrid color">
                                {
                                    documentColors.map(color =>
                                        <div className="transparent-bg radius-5 color-container" key={`document_${color}`}>
                                            <div className="colorSquare " onClick={() => handleChange(color)}
                                                style={{ backgroundColor: color }} />
                                        </div>
                                    )
                                }
                            </div>
                        </Menu.Item>
                    </Menu.ItemGroup>
                    : null
            }
            <Menu.ItemGroup title="Couleurs standard">
                <Menu.Item>
                    <div className="iconsGrid color">
                        {
                            defaultColors.map(color =>
                                <div className="transparent-bg radius-5 color-container" key={`default_${color}`}>
                                    <div className="colorSquare" onClick={() => handleChange(color)}
                                        style={{ backgroundColor: color }} />
                                </div>
                            )
                        }
                    </div>
                </Menu.Item>
            </Menu.ItemGroup>
        </Menu>
    )

    if (isDropdown) {
        return (
            <Dropdown trigger={['click']} overlay={content} visible={isVisible}>
                <div className="transparent-bg radius-5 d-table color-container">
                    <div className="colorSquare" style={{ backgroundColor: currentColor || '#FFF', color: currentColor || '#FFF' }}
                        title={title}
                        onClick={toggleVisible}>
                            {icon ? icon : null}
                    </div>
                </div>
            </Dropdown>
        )
    }

    return (
        content
    )
}