import React, {useRef, useState} from "react";
import {Form, Input, message, Modal, Spin} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import {LockOutlined, MailOutlined, UserOutlined} from "@ant-design/icons";

export function UserForm({modal, onClose, item, visible = true}) {

    const [apiDispatch] = useApiContext();
    const {apiPostEntity, apiUpdateEntity} = apiDispatch
    const [loading, setLoading] = useState(false)

    const formRef = useRef()

    async function onFinish(values) {
        setLoading(true)

        if ((values.password && !values.confirm))
            return message.error('veuillez confirmer votre mot de passe');

        if ((values.password && values.confirm) && (values.password.trim() !== values.confirm.trim()))
            return message.error('les deux mots de passe ne sont pas identique');

        let params = {
            email: values.email.trim(),
            username: values.username.trim(),
            firstName: values.firstname.trim(),
            lastName: values.lastname.trim(),
        }
        if (values.password) {
            params.password = values.password.trim()
        }

        let succes;

        if (item) {
            succes = await update(params)
        } else {
            succes = await postEntity(params)
        }

        if (succes) {
            setLoading(false)
            onClose()
        }
    }

    async function postEntity(params) {

        let response = await apiPostEntity('users', params)

        if (response['@type'] === "hydra:Error") {
            message.error(response['hydra:description'])
            return false
        }
        message.success('Utilisateur crée avec succes')
        return true
    }

    async function update(params) {
        let response = await apiUpdateEntity('users', item.id, params)
        if (response['@type'] === "hydra:Error") {
            message.error(response['hydra:description'])
            return false
        }
        message.success('Utilisateur mis à jour avec succes')
        return true
    }


    function handleOk() {
        formRef.current.submit()
    }

    const content = (
        <Spin spinning={loading}>
            <Form
                ref={formRef}
                name="basic"
                onFinish={onFinish}
                initialValues={{
                    email: item ? item.email : '',
                    username: item ? item.username : '',
                    firstname: item ? item.firstName : '',
                    lastname: item ? item.lastName : '',
                }}
            >
                <Form.Item
                    name="username"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Entrer un username!',
                        },
                    ]}
                >
                    <Input placeholder="Username"
                           prefix={<UserOutlined />}/>

                </Form.Item>
                <Form.Item
                    name="lastname"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Entrer un nom!',
                        },
                    ]}
                >
                    <Input placeholder="Nom"
                           prefix={<UserOutlined />}/>
                </Form.Item>
                <Form.Item
                    name="firstname"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Entrer un prénom!',
                        },
                    ]}
                >
                    <Input placeholder="Prénom"
                           prefix={<UserOutlined />}/>
                </Form.Item>
                <Form.Item
                    name="email"
                    hasFeedback
                    rules={[
                        {required: true, message: 'Entrer un email!',},
                        {type: 'email', message: 'The input is not valid E-mail!'}
                    ]}
                >
                    <Input placeholder="Email"
                           prefix={<MailOutlined />}/>
                </Form.Item>

                {
                    item && <p>Laissez le mot de passe vide si vous ne souhaitez pas le modifier</p>
                }
                <Form.Item
                    name="password"
                    hasFeedback
                    rules={[
                        {
                            required: !item,
                            message: 'Entrer un mot de passe!',
                        },
                    ]}
                >
                    <Input.Password autoComplete="new-password" placeholder="Mot de passe" prefix={<LockOutlined />}/>
                </Form.Item>
                <Form.Item
                    name="confirm"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: !item,
                            message: 'Entrer un mot de passe!',
                        },
                        ({getFieldValue}) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value)
                                    return Promise.resolve();

                                return Promise.reject('Les deux mots de passe ne sont pas identiques');
                            }
                        })
                    ]}
                >
                    <Input.Password placeholder={"Confirmer le mot de passe"} prefix={<LockOutlined />}/>
                </Form.Item>
            </Form>
        </Spin>
    )

    if (modal) {
        return (
            <Modal visible={visible}
                   destroyOnClose
                   title={item ? `Editer ${item.username}` : "Créer un utilisateur"}
                   onOk={handleOk}
                   okText={item ? "Modifier" : "Créer"}
                   onCancel={onClose}
                   cancelText="Retour"
            >
                {content}
            </Modal>
        )
    } else {
        return content;
    }
}