import { Quill } from 'react-quill'; // ES6

export function registerSpacing() {

  var Parchment = Quill.import('parchment');
  var letterSpacingConfig = {
    scope: Parchment.Scope.INLINE,
    whitelist: null
  };
  var letterSpacingClass = new Parchment.Attributor.Class('letterspacing', 'ql-line-height', letterSpacingConfig);
  var letterSpacingStyle = new Parchment.Attributor.Style('letterspacing', 'letter-spacing', letterSpacingConfig);
  Parchment.register(letterSpacingClass);
  Parchment.register(letterSpacingStyle);
  Quill.register(letterSpacingStyle, true);
}