import React from 'react'
import {Button, Dropdown, Menu} from "antd";

import {useAccountContext} from "../../providers/AccountProvider";
import {useApiContext} from "../../providers/ApiProvider";

export default function DropdownCSV({lists}) {

    const [accountState] = useAccountContext();
    const {account} = accountState;

    const [apiDispatch] = useApiContext();
    const {apiPostEntity} = apiDispatch;

    async function menuSwitch(e) {
        const data = {
            ids: e.key === "all" ? lists.map(list => list.id) : [e.key]
        }

        const response = await apiPostEntity('export-form-data', data);

        dlCsv(response);
    }

    function dlCsv(response) {
        const now = new Date();
        const arr = [addZero(now.getDate()), addZero(now.getMonth() + 1), now.getFullYear()]
        const date = arr.join('-')
        const filename = 'hpm-' + date + '.csv'
        const link = document.createElement('a');
        link.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(response);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function addZero(number) {
        if (number < 10)
            return "0" + number;

        return number;
    }

    const menu = (
        <Menu onClick={menuSwitch}>
            <Menu.Item key="all">
                Toutes les campagnes
            </Menu.Item>
            {lists.map(item => (
                <>
                    <Menu.Divider/>
                    <Menu.Item key={item.id}>
                        {item.name}
                    </Menu.Item>
                </>
            ))}
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button onClick={e => e.preventDefault()}>Exporter CSV</Button>
        </Dropdown>
    )

}