import React, { useEffect, useState } from 'react'
import { useAccountContext } from "../providers/AccountProvider";
import {
    Badge,
    Button,
    Dropdown,
    Menu,
    message,
    Modal,
    PageHeader,
    Pagination,
    Space,
    Spin,
    Switch,
    Tooltip
} from "antd";
import { Link, Redirect } from "react-router-dom";
import { useApiContext } from "../providers/ApiProvider";
import MessageCard from "../components/MessageCard";
import Preview from "../components/editor/Preview";
import ModalMessageName from "../components/modal/ModalMessageName";
import Icon, {
    CopyTwoTone,
    EditTwoTone,
    ExclamationCircleOutlined,
    EyeTwoTone,
    SettingOutlined,
} from "@ant-design/icons";
import CollectionTable from "../components/CollectionTable";
import { stringDateDMY } from "../components/Helpers";
import { ReactComponent as CardIcon } from '../assets/icons/cardview_icon.svg'
import { ReactComponent as ListIcon } from '../assets/icons/listview_icon.svg'
import { ReactComponent as DeleteIcon } from "../assets/icons/delete_icon.svg";
import ModalAccountPermissions from "../components/modal/ModalAccountPermissions";
import ModalScript from "../components/modal/ModalScript";
import { ReactComponent as PlusIcon } from "../assets/icons/plus_icon.svg";
import Filters from "../components/dashboard/Filters";
import AccountTimeline from "../components/AccountTimeline";
import moment from "moment";

const { confirm } = Modal;

export default function Dashboard() {

    const [accountState] = useAccountContext();
    const { account } = accountState;
    const [apiDispatch] = useApiContext();
    const { apiFetchSubResource, apiUpdateEntity, apiDeleteEntity } = apiDispatch;

    const initialPagination = { page: 1, itemsPerPage: 20, totalItems: 0 };
    const [pagination, setPagination] = useState(initialPagination);

    const [messages, setMessages] = useState(null)
    const [messageID, setMessageID] = useState(null)
    const [needUpdate, setNeedUpdate] = useState(0)
    const [filters, setFilters] = useState([
        { name: "account.id", value: account?.id },
        { name: "exists[testingParent]", value: false },
        { name: 'isRequestHandler', value: false },
        { name: "name", value: "" }
    ]);
    const [isCardView, setIsCardView] = useState(true)
    const [loading, setLoading] = useState(true);

    const [visible, setVisible] = useState(false);
    const [showTimeline, setShowTimeline] = useState(false);
    const [record, setRecord] = useState({});
    const [mode, setMode] = useState("");

    const [permissionVisible, setPermissionVisible] = useState(false);
    const [scriptVisible, setScriptVisible] = useState(false);

    useEffect(() => {
        fetchMessages()
    }, [needUpdate, filters, pagination.page])

    const fetchMessages = async () => {
        const data = {
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            id: account.id,
            filters: filters
        }

        let response = await apiFetchSubResource('accounts', data, 'messages');
        if (response['hydra:member']) {
            setMessages(response['hydra:member']);
            pagination.totalItems = response['hydra:totalItems'];
            setPagination({ ...pagination });
        }

        setLoading(false);
    }

    const settings = (
        <Menu>
            <Menu.Item key="script" onClick={() => setScriptVisible(true)}>
                <span>Script</span>
            </Menu.Item>
            <Menu.Item key="permission" onClick={() => setPermissionVisible(true)}>
                <span>Permissions</span>
            </Menu.Item>
            <Menu.Item key="settings">
                <Link to="/account">Paramétrage</Link>
            </Menu.Item>
        </Menu>
    );

    const columns = [
        {
            title: "Nom",
            dataIndex: "name",
            render: (name, record) => (
                <span>
                    <Link to={`/editor/${record.id}`}>{name}</Link>
                    {record.needSaveDraft ? ' (brouillon)' : null}
                </span>)
        },
        {
            title: "Nombre de vues",
            dataIndex: "stats",
            render: stats => stats.views
        },
        {
            title: "Taux de clic",
            dataIndex: "stats",
            render: stats => <span>{stats.click.toFixed(0)}%</span>
        },
        {
            title: "Créé le ",
            dataIndex: "createdAt",
            render: createdAt => stringDateDMY(createdAt)
        },
        {
            title: "Modifié le",
            dataIndex: "updatedAt",
            render: updatedAt => stringDateDMY(updatedAt)
        },
        {
            title: "Action",
            dataIndex: "id",
            render: (id, record) => (
                <Space>
                    <Tooltip title="Activer/désactiver">
                        <Switch size="small" defaultChecked={record.status === "active"}
                            onChange={(value) => toggleStatus(record.id, value ? "active" : "inactive")} />
                    </Tooltip>
                    <Tooltip title="Voir">
                        <EyeTwoTone onClick={() => setMessageID(id)} />
                    </Tooltip>
                    <Tooltip title="renommer">
                        <EditTwoTone onClick={() => openModal(record, "rename")} />
                    </Tooltip>
                    <Tooltip title="dupliquer">
                        <CopyTwoTone onClick={() => openModal(record, "duplicate")} />
                    </Tooltip>
                    <Tooltip title="supprimer">
                        <Icon component={DeleteIcon} style={{ color: "#E64D3D" }} onClick={() => showDeleteConfirm(id)} />
                    </Tooltip>
                </Space>
            )
        }
    ]

    const toggleStatus = async (id, status) => {
        const response = await apiUpdateEntity('messages', id, { status: status });
    }

    function openModal(record, mode) {
        setMode(mode);
        setRecord(record);
        setVisible(true);
    }

    function showDeleteConfirm(id) {
        confirm({
            title: 'Êtes-vous sur de vouloir supprimer ce message ?',
            icon: <ExclamationCircleOutlined />,
            content: 'Tous les message supprimé ne seront plus visible même avec le statut actif et seront disponible pendant 30 jours dans votre corbeille.',
            okText: 'Supprimer',
            okType: 'danger',
            cancelText: 'Annuler',
            onOk() {
                handleRemove(id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    async function handleRemove(id) {
        setLoading(true);
        const response = await apiDeleteEntity("messages", id);

        message.success("Message placé à la corbeille");
        setNeedUpdate(needUpdate + 1);
        setLoading(false);
    }

    function reload() {
        setMode("");
        setRecord({});
        setNeedUpdate(needUpdate + 1);
        setVisible(false);
    }

    function handlePageChange(page) {
        setLoading(true);
        pagination.page = page;
        setPagination({ ...pagination });
    }

    function resetPage() {
        if (pagination.page !== 1) {
            pagination.page = 1;
            setPagination({ ...pagination });
        }
    }

    const now = moment();

    if (!account?.id)
        return <Redirect to={'/'} />

    return (
        <div>
            <PageHeader
                title={<Space>
                    <h1>{account.name}</h1>
                    <Dropdown overlay={settings} trigger={['click']}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <SettingOutlined />
                        </a>
                    </Dropdown>
                </Space>}
                extra={!account.isPremium &&
                    <div>
                        {moment(account.subscriptionEnd).isAfter(now) ?
                            <div>
                                <Badge status="warning" />
                                freemium actif jusqu'au {moment(account.subscriptionEnd).format('DD/MM/YYYY')}
                            </div> :
                            <div>
                                <Badge status="error" />
                                freemium inactif
                            </div>}
                    </div>}
                style={{ borderBottom: '2px solid #2494D1' }}
            >
                <div className="flex between align-baseline">
                    <div>
                        <p>Au total, <strong>{pagination.totalItems} messages</strong> sont associés à ce compte</p>
                    </div>
                    <Space>
                        <Button icon={<Icon component={() => <CardIcon fill="none" width="1em" height="1em" />} />}
                            size="large"
                            onClick={() => setIsCardView(true)}
                            className={isCardView ? 'selected-view' : ''}>Cartes</Button>
                        <Button icon={<Icon component={() => <ListIcon fill="none" width="1em" height="1em" />} />}
                            onClick={() => setIsCardView(false)}
                            size="large"
                            className={!isCardView ? 'selected-view' : ''}>Liste</Button>
                    </Space>
                </div>
            </PageHeader>

            <div className="flex between align-center" style={{ margin: "20px 0" }}>
                <div>
                    <h2>Tous les messages</h2>
                </div>

                <Filters
                    filters={filters} setFilters={setFilters}
                    setLoading={setLoading} isAdminAccount={account.isAdminAccount}
                    resetPage={resetPage}
                />

                <Space>
                    {
                        account.isAdminAccount ?
                            <Button
                                type="primary"
                                size="large"
                                onClick={() => {
                                    setRecord({});
                                    setMode("new");
                                    setVisible(true);
                                }}
                            >
                                CRÉER (de zero)
                            </Button>
                            :
                            <Button size="large" onClick={() => setShowTimeline(true)}>
                                CARTE DES MESSAGES
                            </Button>
                    }
                    <Link to="/new">
                        <Button
                            type="primary"
                            size="large"
                            icon={<Icon component={() => <PlusIcon fill="none" width="1em" height="1em" />} />}
                        >
                            CRÉER
                        </Button>
                    </Link>
                </Space>
            </div>

            {
                isCardView ?
                    <Spin spinning={loading}>
                        <div className="message-card-grid">
                            {
                                messages ?
                                    messages.map(message =>
                                        <MessageCard
                                            message={message} setMessageID={setMessageID}
                                            key={message.id}
                                            openModal={openModal}
                                            toggleStatus={toggleStatus}
                                            showDeleteConfirm={showDeleteConfirm}
                                        />
                                    )
                                    : null
                            }
                        </div>

                        <div className="mt-20 flex justify-center">
                            {pagination.totalItems > pagination.itemsPerPage ?
                                <Pagination
                                    current={pagination.page}
                                    total={pagination.totalItems}
                                    pageSize={pagination.itemsPerPage}
                                    onChange={handlePageChange}
                                /> : null}
                        </div>
                    </Spin>
                    :
                    <CollectionTable
                        endpoint="messages"
                        filters={filters}
                        columns={columns}
                        reload={needUpdate}
                    />
            }

            {
                messageID ?
                    <Preview messageID={messageID} setMessageId={setMessageID} />
                    : null
            }
            {
                permissionVisible ?
                    <ModalAccountPermissions account={account} setAccount={setPermissionVisible} />
                    : null
            }
            {
                scriptVisible ?
                    <ModalScript visible={scriptVisible} setVisible={setScriptVisible}
                        accountId={account.id} />
                    : null
            }

            <ModalMessageName
                visible={visible}
                setVisible={setVisible}
                mode={mode}
                data={record}
                reload={reload}
            />
            <AccountTimeline visible={showTimeline} setVisible={setShowTimeline} />
        </div>
    )
}