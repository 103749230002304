import React from 'react';
import {Route} from 'react-router-dom';
import {useAuthContext} from '../providers/AuthProvider.js';
import MainLayout from "./Layout.js";

export default function PrivateRoute({component: Component, sidebar, searchbar, ...rest}) {

    const [authState, authDispatch] = useAuthContext();
    const {authIsLogged, authLogout} = authDispatch;

    const renderer = (sidebar, props) => {
        if (authIsLogged())
            return <MainLayout {...props}><Component {...props} /></MainLayout>

        authLogout();
        window.location.href = '/login';
    }

    return (
        <Route {...rest} render={renderer.bind(null, sidebar)}/>
    )
}
