import React, { useEffect, useRef, useState } from "react";
import Children from "./Children";
import { jsToCss, resolveAnimStyle, styles } from "./helpers";


export default function Message({ message, container, handleClick, language, debug = false, maxHeight = null, maxWidth = null, handleCustomization }) {

    const [style, setStyle] = useState('')
    const content = useRef()
    const [additionalStyle, setAdditionalStyle] = useState('')
    let percent = 1
    let timeoutId = null

    useEffect(() => {

        handleResize()
        handleStyle()
        window.addEventListener('resize', handleResizeTimeout)

        return () => {
            window.removeEventListener('resize', handleResizeTimeout)
            clearTimeout(timeoutId);
        }
    }, [message])


    useEffect(() => {

        if (debug && content.current) {
            setTimeout(() => {
                content.current.classList.remove('hidden')
            }, 300)
        }
    }, [message])

    //permet de transformer le style js du message en style css
    const handleStyle = () => {
        // console.log(message.options)
        let background = message.options.backgroundImage || ''
        background = navigator.userAgent.includes('Mobile') ? background : background.replace('size=sm', 'size=lg')
        let modalStyle = message.type === "modal" ?
            `#msgContainer_${message.id} .modal-cover{background-color:${message.options.modalBackground};background-image:url(${background})}.container.c_${message.id}${styles.modal__message}`
            : ''

        let containerStyle = `.container.c_${message.id + jsToCss({ ...message.style, zIndex: 8010, boxSizing: 'content-box' })}`

        let isModal = message.type === 'modal'
        let hiddenStyle = resolveAnimStyle(message.options.animation, isModal, percent, debug)

        let hiddenContainer = `.container.c_${message.id}.hidden{${hiddenStyle}}`
        let { childrenStyle, hoverStyle } = handleChildrenStyle()
        setStyle(modalStyle + containerStyle + childrenStyle + hiddenContainer + hoverStyle)
    }


    const handleChildrenStyle = () => {
        let childrenStyle = ""
        let hoverStyle = ""
        message.children.map(item => {
            let elemStyle = { ...item.style }
            let hover = item.hover || {}
            childrenStyle += `.c_${message.id} .element_${item.id + jsToCss(elemStyle)}`
            hoverStyle += `.element_${item.id}:hover${jsToCss({ ...hover })}`
        })

        return { childrenStyle, hoverStyle }
    }

    const handleResizeTimeout = () => {
        // console.log("resize")
        clearTimeout(timeoutId)

        timeoutId = setTimeout(() => {
            handleResize()
        }, 200)

    }

    // recalcule les style au resize afin d'adapter le scale pour avoir des messages responsive
    const handleResize = (e) => {

        let percentage = calculateScale()
        // console.log('percentage', percentage)
        let style;

        if (message.type === "modal") {
            style = { transform: `translate(-50%,-50%) scale(${percentage})` }
        } else {
            let offsetStyle = calculateOffsets(percentage)
            console.log("offsetStyle", offsetStyle)
            style = {
                transform: ` scale(${percentage})`,
                ...offsetStyle
            }
        }

        let newStyle = `.container.c_${message.id + jsToCss({ ...style })}`

        return setAdditionalStyle(newStyle)

    }

    const calculateOffsets = () => {

        let sizes = getSizes();

        let style = {}
        sizes.map((size) => {
            let { forceOffset, position, containerValue, offset, documentValue } = size
            // if the offset set in the options is below the minimum required for the close btn to be visible, then we force the value
            let newOffset = (((containerValue * percent) - containerValue) / 2)

            let offsetValue = (documentValue * offset / 100) * percent;
            let forcedValue = position === forceOffset.position ? forceOffset.value * percent : Math.abs(forceOffset.value * percent);

            if (offsetValue < forcedValue && position === forceOffset.position) {
                offsetValue = forcedValue
            } else if (percent < 1 && position != forceOffset.position) {
                offsetValue += -(forceOffset.value * percent)
            }
            style[position] = newOffset + offsetValue + 'px'
        })

        return style

    }

    // if the message container with his offset is bigger than the document, we calculate the difference
    const calculateScale = () => {
        let percentage = 1

        let sizes = getSizes();

        sizes.map(size => {
            let { documentValue, containerValue, forceOffset, offset } = size
            let messageSize = containerValue + forceOffset.value + (documentValue * offset / 100)
            if (documentValue < messageSize) {
                let sizePercent = documentValue / (containerValue + forceOffset.value)
                percentage = sizePercent < percentage ? sizePercent : percentage
            }
        })
        percent = percentage
        return percentage
    }

    const closeBtnOffset = (message) => {
        let closeBtn = message.options.closeButton

        let btnSize = parseFloat(closeBtn.style.width);
        let msgWidth = parseFloat(message.style.width);
        let msgHeight = parseFloat(message.style.height);
        let hOffset = hasOffset(closeBtn.translate[0], msgWidth, 'h', btnSize)
        let vOffset = hasOffset(closeBtn.translate[1], msgHeight, 'v', btnSize)

        return [hOffset, vOffset]
    }

    // return if offset is negative or positive, else 0
    function hasOffset(offset, max, direction, btnSize) {

        if (offset < 0) return { position: direction === 'v' ? 'top' : "left", value: offset * -1 };
        let offsetSize = offset + btnSize
        if (offsetSize > max) {
            return { position: direction === 'v' ? 'bottom' : "right", value: offsetSize - max }
        }
        return { position: 'top', value: 0 };
    }


    //return an array with sizes value, first object is the width and second the height
    const getSizes = () => {

        let documentWidth = maxWidth ? maxWidth : Math.min(window.innerWidth, document.body.clientWidth)
        let documentHeight = maxHeight ? maxHeight : Math.min(window.innerHeight, document.body.clientHeight)
        let closeOffset = closeBtnOffset(message)

        let options = message.options
        let verticalOffset = options.verticalOffset * documentHeight / 100
        let horizontalOffset = options.horizontalOffset * documentWidth / 100
        let border = message.style.borderStyle !== 'none' ? parseFloat(message.style.borderWidth) * 2 : 0

        return [
            {
                position: options.horizontalPos,
                containerValue: parseFloat(message.style.width) + horizontalOffset + border,
                documentValue: documentWidth,
                offset: options.horizontalOffset,
                forceOffset: closeOffset[0]
            },
            {
                position: options.verticalPos,
                containerValue: parseFloat(message.style.height) + verticalOffset + border,
                documentValue: documentHeight,
                offset: options.verticalOffset,
                forceOffset: closeOffset[1]
            }
        ]

    }


    function handleMouseClick(e, action) {
        if (action === 'container' && !message.options?.action?.type && (message.stepTrigger !== action)) return
        e.preventDefault()
        e.stopPropagation()

        handleClick(message.id, action)
    }

    return (
        <>
            {
                message.type === 'modal' ?
                    <div className="modal-cover" onClick={(e) => handleMouseClick(e, 'close')} />
                    : null
            }
            <style>{style + additionalStyle}</style>
            <div ref={content} id={`container_${message.id}`}
                onClick={(e) => handleMouseClick(e, 'container')}
                className={`container c_${message.id} ${message.type === "modal" ? " modal__message" : ""} hidden ${message.options?.animate ? 'animate ' + message.options.animate : null}`}
                style={{ overflow: 'visible', cursor: message.options?.action?.type ? 'pointer' : 'initial' }}
            >
                <div className="container_overflow" style={{ borderRadius: message?.style?.borderRadius || 0 }}>
                    <Children elements={message.children} type={message.type}
                        updateOnClick={handleClick} messageId={message.id}
                        handleChildrenStyle={handleChildrenStyle}
                        handleCustomization={handleCustomization}
                        debug={debug}
                        container={container} lng={language} />
                </div>
                {
                    message.options.closable &&
                    <a id={message.options.closeButton.id} className={"close"} style={{ ...message.options.closeButton.style, padding: 0, cursor: 'pointer' }}
                        key={message.options.closeButton.id}
                        data-btn={'close'}
                        onClick={(e) => handleMouseClick(e, 'close')}
                    >
                        <svg width="100%" height="100%"
                            viewBox={"0 0 " + message.options.closeButton.content.icon[0] + " " + message.options.closeButton.content.icon[1]}>
                            <path d={message.options.closeButton.content.icon[4]} fill="currentColor" />
                        </svg>
                    </a>
                }

            </div>
        </>
    )
}