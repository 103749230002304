import React, { useEffect, useState } from "react";
import { Button, Form, PageHeader, Select } from "antd";
import { useApiContext } from "../providers/ApiProvider";

export default function AdminSetting() {

    const [apiDispatch] = useApiContext();
    const { apiFetchEntity, apiPostEntity, apiUpdateEntity } = apiDispatch;

    const [loading, setLoading] = useState(false);
    const [loadingStart, setLoadingStart] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        fetch()
    }, [])

    async function fetch() {
        const response = await apiFetchEntity('administrations', 1);

        if (response['@type'] !== 'hydra:Error')
            setData(response);

        setLoadingStart(false);
    }

    function onFinish(values) {
        if (data.id)
            update(values);
        else
            post(values);
    }

    async function post(values) {
        setLoading(true);
        const response = await apiPostEntity('administrations', values);
        setData(response);
        setLoading(false);
    }

    async function update(values) {
        setLoading(true);
        const response = await apiUpdateEntity('administrations', data.id, values);
        setData(response);
        setLoading(false);
    }

    return (
        <div>
            <PageHeader title={"Réglages"} />
            {
                loadingStart ? null :
                    <Form
                        layout="vertical"
                        initialValues={{
                            emails: data.emails,
                        }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label={'email'}
                            tooltip={'Liste d\'emails a prévenir pour les inscriptions'}
                            name="emails"
                        >
                            <Select
                                mode="tags"
                                style={{ width: 300 }}
                                placeholder="emails"
                            >
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                            >
                                Valider
                            </Button>
                        </Form.Item>
                    </Form>
            }
        </div>
    )
}