import React, {useState, useEffect} from "react";
import {useHistory} from 'react-router-dom';
import {Button, Input, message, PageHeader, Space} from "antd";
import TemplateChoice from "../components/TemplateChoice";
import {useAccountContext} from "../providers/AccountProvider";
import {useApiContext} from "../providers/ApiProvider";

export default function NewMessage() {

    const [apiDispatch] = useApiContext();
    const {apiPostEntity} = apiDispatch;
    const [accountState] = useAccountContext();
    const history = useHistory();

    const [name, setName] = useState("");
    const [template, setTemplate] = useState("");
    const [loading, setLoading] = useState(false);

    const [middle, setMiddle] = useState('45%');

    useEffect(() => {
        window.addEventListener("resize", calcMiddle);
        calcMiddle();
        return () => window.removeEventListener("resize", calcMiddle);
    }, [window.innerWidth])

    useEffect(()=> {
        if (template !== ''){
            createMessage()
        }
    },[template])

    function calcMiddle() {
        setMiddle((window.innerWidth / 2) - (document.getElementById('div-buttons').offsetWidth / 2));
    }
    
    async function createMessage() {
        if (!name || !template)
            return message.error("Nom et template obligatoire");

        setLoading(true);

        const params = {
            messageId: template,
            name: name,
            accountId: accountState.account.id,
        }

        const responsePost = await apiPostEntity("duplicate-message", params);

        if (responsePost["@type"] === "hydra:Error")
            return displayErr(responsePost["hydra:description"]);

        setLoading(false);
        history.push(`/editor/${responsePost.id}`);
    }

    return (
        <Space direction="vertical" size="large" style={{width: "100%", paddingBottom: 30, position: 'relative'}}>
            <PageHeader
                title={
                    <h1>Nouveau message</h1>
                }
                style={{borderBottom: '2px solid #2494D1'}}
            />

            <div>
                <p className="mb">
                    <span style={{color: "red"}}>*</span>&nbsp;
                    <strong style={{fontSize: "1.3em"}}>Nom de votre message :</strong>
                </p>
                <Input value={name} onChange={e => setName(e.target.value)} style={{width: 300}}/>
            </div>

            <div>
                <p className="mb">
                    <span style={{color: "red"}}>*</span>&nbsp;
                    <strong style={{fontSize: "1.3em"}}>Choisissez un des modèles ci-dessous :</strong>
                </p>
                <TemplateChoice setTemplate={setTemplate}/>
            </div>

            <div>
                <p><span style={{color: "red"}}>*</span> <em>Champs obligatoire</em></p>
            </div>

            <div
                id="div-buttons"
                className="flex justify-center mt-20"
                style={{position: "fixed", bottom: 10, left: middle}}
            >
                <Button
                    size="large"
                    onClick={() => history.push("/dashboard")}
                    className="mr-10"
                >
                    Retour
                </Button>
                <Button
                    loading={loading}
                    type="primary"
                    onClick={createMessage}
                    size="large"
                >
                    Valider
                </Button>
            </div>
        </Space>
    )
}