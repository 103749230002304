import React, { useEffect, useState } from "react";
import { Col, DatePicker, PageHeader, Row, Select, Space, Spin } from "antd";
import { useAccountContext } from "../providers/AccountProvider";
import { useApiContext } from "../providers/ApiProvider";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import moment from "moment";
import { Redirect } from "react-router-dom";

export default function FormData() {
  const [accountState] = useAccountContext();
  const { account } = accountState;

  const [apiDispatch] = useApiContext();
  const { apiFetchSubResource, apiDeleteEntity } = apiDispatch;

  const [stats, setStats] = useState([]);
  const [actions, setActions] = useState([]);
  const [users, setUsers] = useState([]);
  const [dataFilter, setDataFilter] = useState("total");
  const [dateFilter, setDateFilter] = useState(new Date());
  const [totalActions, setTotalActions] = useState({});
  const [totalActionsArray, setTotalActionsArray] = useState([]);
  const [totalUsersArray, setTotalUsersArray] = useState([]);
  const [loading, setLoading] = useState(true);

  if (!account) return <Redirect to="/" />;

  useEffect(() => {
    setLoading(true);
    fetchList();
  }, [dateFilter]);

  const fetchList = async () => {
    let before = dateFormatter();
    let after = startDateFormatter();
    let response = await apiFetchSubResource(
      "accounts",
      {
        id: account.id,
        filters: [
          { name: "date[before]", value: before },
          { name: "date[after]", value: after },
        ],
      },
      "consolidated_customization_stats"
    );

    setStats(response["hydra:member"]);
    parseStats(response["hydra:member"]);
  };

  const parseStats = (data) => {
    handleActions(data);
    handleUsers(data);
    setLoading(false);
  };

  const handleUsers = (data) => {
    let totalUsers = {};
    let users = data.map((item) => {

      for (const [key, value] of Object.entries(item.totalByUser)) {
        totalUsers[key] = (totalUsers[key] || 0) + parseFloat(value);
      }

      return {
        date: new Date(item.date).getTime(),
        total: { ...item.totalByUser },
        ...item.userByLng,
      };
    });
    buildTotalUserData(totalUsers);
    setUsers(users);
  };

  const buildTotalUserData = (totalUsers) => {
    let dataSet = [];
    for (const [key, value] of Object.entries(totalUsers)) {
      dataSet.push({
        name: key,
        value: value,
        fill: USERS_INFOS[key].color,
        tooltipPayload: [{ name: key, value: value }],
      });
    }

    setTotalUsersArray(dataSet);
  };

  const handleActions = (data) => {
    let totalAct = { close: 0, submit: 0 };
    let actions = data.map((item) => {
      totalAct = {
        close: totalAct.close + item.totalClose,
        submit: totalAct.submit + item.totalSubmit,
      };
      return {
        date: new Date(item.date).getTime(),
        total: {
          close: item.totalClose,
          submit: item.totalSubmit,
        },
        ...item.actionByLng,
      };
    });
    setActions(actions);

    setTotalActions(totalAct);
    setTotalActionsArray([
      {
        name: "Fermeture",
        value: totalAct.close,
        fill: "#FFBB28",
        tooltipPayload: [{ name: "Fermeture", value: totalActions.close }],
      },
      {
        name: "Soumission",
        value: totalAct.submit,
        fill: "#0088FE",
        tooltipPayload: [{ name: "Soumission", value: totalActions.submit }],
      },
    ]);
  };

  const tickFormatter = (tick) => moment(tick).format("DD/MM/YYYY");
  const dateFormatter = (tick) => moment(dateFilter).format("YYYY-MM-DD");
  const startDateFormatter = (tick) =>
    moment(dateFilter).subtract(15, "days").format("YYYY-MM-DD");

  const renderPieLegendText = (value, entry) => {
    return (
      <span>
        {value} : {entry.payload.value} (
        {Math.round(entry.payload.percent * 100)}%)
      </span>
    );
  };

  const USERS_INFOS = {
    couple: { color: "#FF4233", name: "Couple" },
    family: { color: "#E2A61A", name: "Famille" },
    professionnel: { color: "#775542", name: "Professionnel" },
    friends: { color: "#DAA27C", name: "Amis" },
  };
  
  return (
    <div>
      <PageHeader
        title={<h1>Custom popup Analytics</h1>}
        style={{ borderBottom: "2px solid #2494D1" }}
      />
      <div className="mt-2em">
        <div className="mb">
          <Space>
            <Select value={dataFilter} onChange={(val) => setDataFilter(val)}>
              <Select.Option value="total">Total</Select.Option>
              <Select.Option value="fr">Fr</Select.Option>
              <Select.Option value="en">En</Select.Option>
            </Select>
            <DatePicker
              value={moment(dateFilter)}
              onChange={(value) => setDateFilter(value)}
              allowClear={false}
              disabledDate={(currentDate) =>
                moment(currentDate).isAfter(new Date())
              }
            />
          </Space>
        </div>
        {!loading && !stats.length ? (
          <>
            <p>Aucune statistique n'existe pour ce compte pour cet période</p>
          </>
        ) : (
          <Spin spinning={loading}>
            <Row>
              <Col span={12}>
                <p>Actions des utilisateurs</p>
                <ResponsiveContainer width="95%" height={400}>
                  <BarChart
                    data={actions}
                    margin={{ top: 5, right: 30, left: 20, bottom: 50 }}
                  >
                    <XAxis
                      interval={0}
                      dataKey="date"
                      scale="time"
                      tickCount={7}
                      angle={-45}
                      textAnchor="end"
                      tickFormatter={tickFormatter}
                      type="number"
                      domain={[
                        new Date(startDateFormatter(dateFilter)).valueOf(),
                        dateFilter.valueOf(),
                      ]}
                    />
                    <YAxis />
                    <Tooltip labelFormatter={tickFormatter} />
                    <Legend verticalAlign="top" />
                    <Bar
                      dataKey={`${dataFilter}.close`}
                      stackId="action"
                      fill="#FFBB28"
                      name="Fermeture"
                    />
                    <Bar
                      dataKey={`${dataFilter}.submit`}
                      stackId="action"
                      fill="#0088FE"
                      name="Soumission"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Col>
              <Col span={12}>
                <p>Choix des utilisateurs</p>
                <ResponsiveContainer width="95%" height={400}>
                  <BarChart
                    data={users}
                    margin={{ top: 5, right: 30, left: 20, bottom: 50 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      interval={0}
                      dataKey="date"
                      scale="time"
                      tickCount={15}
                      angle={-45}
                      textAnchor="end"
                      tickFormatter={tickFormatter}
                      type="number"
                      domain={[
                        new Date(startDateFormatter(dateFilter)).valueOf(),
                        dateFilter.valueOf(),
                      ]}
                    />
                    <YAxis />
                    <Tooltip labelFormatter={tickFormatter} />
                    <Legend verticalAlign="top" />
                    <Bar
                      stackId="users"
                      dataKey={`${dataFilter}.couple`}
                      fill={USERS_INFOS.couple.color}
                      name={USERS_INFOS.couple.name}
                    />
                    <Bar
                      stackId="users"
                      dataKey={`${dataFilter}.friends`}
                      fill={USERS_INFOS.friends.color}
                      name={USERS_INFOS.friends.name}
                    />
                    <Bar
                      stackId="users"
                      dataKey={`${dataFilter}.professionnel`}
                      fill={USERS_INFOS.professionnel.color}
                      name={USERS_INFOS.professionnel.name}
                    />
                    <Bar
                      stackId="users"
                      dataKey={`${dataFilter}.family`}
                      fill={USERS_INFOS.family.color}
                      name={USERS_INFOS.family.name}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Col>
              <Col span={12}>
                <p>Total des actions sur la période</p>
                <ResponsiveContainer width="95%" height={400}>
                  <PieChart width={400} height={400}>
                    <Pie
                      isAnimationActive={true}
                      data={totalActionsArray}
                      cx={200}
                      cy={200}
                      fill="#8884d8"
                      dataKey="value"
                      label
                    />
                    <Tooltip />
                    <Legend
                      formatter={renderPieLegendText}
                      verticalAlign="top"
                    />
                  </PieChart>
                </ResponsiveContainer>
              </Col>
              <Col span={12}>
                <p>Total des choix sur la période</p>
                <ResponsiveContainer width="95%" height={400}>
                  <PieChart width={400} height={400}>
                    <Pie
                      isAnimationActive={true}
                      data={totalUsersArray}
                      cx={200}
                      cy={200}
                      fill="#8884d8"
                      dataKey="value"
                      label
                    />
                    <Tooltip />
                    <Legend
                      formatter={renderPieLegendText}
                      verticalAlign="top"
                    />
                  </PieChart>
                </ResponsiveContainer>
              </Col>
            </Row>
          </Spin>
        )}
      </div>
    </div>
  );
}
