import React from 'react';
import ErrorPlaceholder from '../components/ErrorPlaceholder'

const Error = (props) => {

    return (
        <ErrorPlaceholder code={props.location.state !== null ? props.location.state.status : null}
                          titre={props.location.state !== null ? props.location.state.title : null}
                          message={props.location.state !== null ? props.location.state.subtitle : null}
                          page={props.location.state !== null ? props.location.state.page : null}
                          action={props.history.goBack}
        />
    );

};

export default Error;
