import React from 'react'
import {Space, Tooltip} from "antd";
import Icon, {AimOutlined, EyeOutlined} from "@ant-design/icons";
import {ReactComponent as ModalIcon} from "../assets/icons/modal_icon.svg";
import {ReactComponent as NotificationIcon} from "../assets/icons/notification_icon.svg";

export function TimelineCard({content, title, empty, setMessageID}) {

    function isTargeting(behavior) {
        if (behavior.isDeviceActive === true ||
            behavior.isFrequencyActive === true ||
            behavior.isLocalisationActive === true ||
            behavior.isMobile === true ||
            behavior.isPageActive === true ||
            behavior.isPeriodActive === true ||
            behavior.isSourceActive === true) {
            return <Tooltip placement="top" title={'Message ciblé'}>
                <AimOutlined style={{fontSize: '16px'}}/>
            </Tooltip>
        }
        return null
    }

    return (
        <Space direction="vertical" className="timeline-box">
            {
                content.length ?
                    <Space direction="vertical" style={{width: '100%'}}>
                        <p style={{color: "#2494D1"}}><strong>{title}</strong></p>
                        {
                            content.map(item =>
                                <div className="flex between" key={item.id}>
                                                    <span>
                                                        <>
                                                        <Tooltip placement="top" title={item.online.type}>
                                                        {item.online.type === 'modal' ?
                                                            <Icon style={{fontSize: '16px'}}
                                                                  component={() => <ModalIcon width="1em"
                                                                                              height="1em"/>}/> :
                                                            <Icon style={{fontSize: '16px'}}
                                                                  component={() => <NotificationIcon width="1em"
                                                                                                     height="1em"/>}/>
                                                        }
                                                        </Tooltip>&nbsp;
                                                            {isTargeting(item.online.behavior)} &nbsp;
                                                            {item.name}
                                                        </>
                                                </span>
                                    <Tooltip placement="top" title="Voir">
                                        <EyeOutlined style={{fontSize: '16px', cursor: 'pointer'}}
                                                     onClick={() => setMessageID(item.id)}/>
                                    </Tooltip>
                                </div>
                            )
                        }
                    </Space>
                    :
                    <div>
                        <p style={{color: "#2494D1"}}><strong>{title}</strong></p>
                        <p>{empty}</p>
                    </div>
            }
        </Space>
    )
}