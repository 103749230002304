import React, {useEffect, useState} from 'react'
import {Select, Slider, Space, Tabs} from "antd";
import {useEditorContext} from "../../../providers/EditorProvider";
import Colors from "../../Colors";
import GooglePlaceAutocomplete from "../../GooglePlaceAutocomplete";
import {useAccountContext} from "../../../providers/AccountProvider";
import FontSelect from "../FontSelect";

const parisLocation = {
    "city": "Paris",
    "zip": "",
    "country": "France",
    "countryCode": "FR",
    "name": "Paris",
    "gps": [
        48.856614,
        2.3522219
    ],
    "place_id": "ChIJD7fiBh9u5kcRYJSMaMOCCwQ"
}

const templates = [
    {height: 200, width: 140, name: 'Vertical'},
    {height: 85, width: 280, name: 'Horizontal'}
]


const defaultElement = {
    type: 'weather',
    name: `Météo`,
    style: {
        width: `140px`,
        height: `200px`,
        position: 'absolute',
        top: '0',
        left: '0',
        transform: 'translate(0, 0)',
        textAlign: "center",
        backgroundColor: "rgba(55, 55, 55, 1)",
        color: 'rgba(255, 255, 255, 1)',
        fontFamily: 'Helvetica',
        borderRadius: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    template: null,
    location: null,
    translate: [0, 0],
    rotate: 0,
    ratio: true
}


export default function WeatherTab() {

    const [accountState, accountDispatch] = useAccountContext();
    const [editorState, editorDispatch] = useEditorContext()
    const {addElement, selectElement, updateElement} = editorDispatch
    const [needUpdate, setNeedUpdate] = useState(0)
    const [element, setElement] = useState(getDefault)
    const [locations, setLocations] = useState([element.location] || []);

    const [radius, setRadius] = useState(parseFloat(element.style.borderRadius) || 0)


    function getDefault() {
        let defaultLocation = accountState.account.location ? {...accountState.account.location} : parisLocation
        return editorState.message.children.find(item => item.type === 'weather') ?? {
            ...defaultElement,
            location: defaultLocation
        }
    }

    useEffect(() => {

        let weatherElement = editorState.message.children.find(item => item.type === 'weather')

        if (!weatherElement) {
            setElement(getDefault)
        } else if (weatherElement !== element) {
            setElement(weatherElement)
        }
    }, [editorState.message])

    useEffect(() => {

        if (needUpdate !== 0) {
            let editing = !!element.id

            if (!editing) {
                addWeather()
            } else if (editing) {
                updateElement({...element})
            }
        }
    }, [needUpdate])


    function addWeather() {

        let id = Date.now()

        let newElement = {
            ...element,
            id: id,
        }
        selectElement(newElement)
        addElement(newElement)
    }

    const handleChange = (property, value, suffix = '') => {
        let newStyle = {...element.style, [property]: value + suffix}

        setElement({...element, style: newStyle})
        setNeedUpdate((new Date()).getTime())
    }


    const handlePlace = (value) => {

        setElement({...element, location: value})
        setNeedUpdate((new Date()).getTime())
    }

    const updateTemplate = (label) => {
        let template = templates.find(item => item.name === label)
        setElement(prev => {
            return {...prev, template: {...template}, style: {...prev, ...updateStyle(template)}}
        })

        setNeedUpdate((new Date()).getTime())
    }

    const updateStyle = (template) => {

        return {...element.style, height: template.height + 'px', width: template.width + 'px'}
    }

    return (
        <div style={{width: '100%', maxWidth: '300px', minWidth: '300px'}}>
            <Tabs defaultActiveKey="visual" size="small" tabBarGutter={5} style={{width: '100%', maxWidth: '300px'}}>
                <Tabs.TabPane tab="Visuel" key="visual">
                    <Space direction="vertical">
                        {
                            !element.template ? <p>Choisissez un template :</p> : <p>Modèle :</p>
                        }
                        <Select onChange={updateTemplate} style={{width: 200}} placeholder={'Modèle'}
                                value={element.template?.name}>
                            {
                                templates.map(template =>
                                    <Select.Option key={template.name}
                                                   value={template.name}>{template.name}</Select.Option>
                                )
                            }
                        </Select>
                        {
                            !!element.template &&
                            <>
                                <Space>
                                    Couleur du texte :
                                    <Colors currentColor={element.style.color}
                                            setColor={(value) => handleChange('color', value)}/>
                                </Space>

                                <Space>
                                    Couleur du fond :
                                    <Colors currentColor={element.style.backgroundColor}
                                            setColor={(value) => handleChange('backgroundColor', value)}/>
                                </Space>
                                <Space>
                                    <span>Arrondi : </span>
                                    <Slider defaultValue={radius}
                                            style={{width: '150px'}}
                                            min={0} max={50}
                                            onAfterChange={(value) => handleChange('borderRadius', value, 'px')}/>
                                </Space>
                                <Space>
                                    <FontSelect handleChange={(value) => handleChange('fontFamily', value)}
                                                font={element.style.fontFamily}/>
                                </Space>
                            </>
                        }
                    </Space>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Paramètres" key="params">
                    <p>Choisissez une ville ... </p>
                    <GooglePlaceAutocomplete setValues={handlePlace} values={locations} displayValues={false}/>
                    <p>Emplacement actuel : {element.location.name}</p>
                </Tabs.TabPane>

            </Tabs>
        </div>
    )
}