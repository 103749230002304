import React from 'react'
import {Layout} from 'antd';

const {Content} = Layout;

export default function PublicLayout({children}) {

    return (
        <Layout className="mainLayout">
            <Content className="flex">
                <Layout >
                    <Content>
                        {children}
                    </Content>
                </Layout>
            </Content>

        </Layout>
    )

}