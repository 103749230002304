import React, {useRef, useState} from 'react'
import {Button, Form, Input, Modal} from "antd";
import {useApiContext} from "../../providers/ApiProvider";

export default function ModalForms({isVisible, setIsVisible, accoundId, setNeedUpdate}) {

    const [initValues, setInitValues] = useState({})

    const [apiDispatch] = useApiContext();
    const {apiPostEntity} = apiDispatch;
    const [loading, setLoading] = useState(false)

    const form = useRef()


    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 12,
        },
    };

    const onFinish = (values) => {
        console.log('Success:', values);
        createCategory(values)
    };

    const createCategory = async (values) => {
        setLoading(true)
        let params = {...values, account: accoundId}
        console.log(params)
        let response = await apiPostEntity('form_lists', params)
        console.log(response)
        setNeedUpdate((new Date()).getTime())
        setLoading(false)
        setIsVisible(false)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleSubmit = () => {
        if (form.current)
            form.current.submit()
    }

    return (
        <Modal
            visible={isVisible}
            onCancel={() => setIsVisible(false)}
            title={'Créer une catégorie de formulaire'}
            footer={[
                <Button key="cancel_create" onClick={() => setIsVisible(false)}>
                    Retour
                </Button>,
                <Button key="create" type="primary" htmlType="submit" onClick={handleSubmit}>
                    Créer
                </Button>
            ]}>
            <Form
                {...layout}
                name="categoryForm"
                initialValues={initValues}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                ref={form}

            >
                <Form.Item
                    label="Nom de la campagne"
                    name="name"
                    rules={[{required: true, message: 'Le nom est requis'}]}
                >
                    <Input/>
                </Form.Item>

            </Form>
        </Modal>
    )

}

