import React from 'react';
import {Route} from 'react-router-dom';
import PublicLayout from "./PublicLayout";

export default function PublicRoute({component: Component, layout, ...rest}) {

    const renderer = (layout, props) => {
        return <PublicLayout  layout={layout} {...props}><Component {...props} /></PublicLayout>
    }

    return (
        <Route {...rest} render={renderer.bind(null, layout)}/>
    )
}
