import React, {useEffect, useState} from 'react'
import {Button, DatePicker, Input, Space, Tabs, TimePicker} from "antd";
import {useEditorContext} from "../../../providers/EditorProvider";
import moment from 'moment';
import Colors from "../../Colors";
import {getCount, updateTimerStyle} from "../utils";
import {useApiContext} from "../../../providers/ApiProvider";
import Count from "../Count";
import ModalCountdown from "../../modal/ModalCountdown";


const tempTest = {
    id: 2,
    textColor: "#191919",
    firstColor: "#989898",
    secondColor: "#486235",
    width: 150,
    height: 100,
    dividerWidth: 0,
    containerCss: 'color: $$firstColor$$;background-color:$$secondColor$$;border-radius:15px;',
    dividerContent: '',
    content: '<div style="width: 150px;height: 100px;display:flex;flex-direction: column;align-items:center;justify-content:center;font-size: 35px;font-weight: 700;">\n' +
        '$$time$$\n' +
        '<span style="font-size:14px;color:$$textColor$$">$$text$$</span>\n' +
        '</div>'
}

const defaultTemplate =
    {
        id: 1,
        textColor: "#191919",
        firstColor: "#989898",
        secondColor: "#486235",
        width: 100,
        dividerWidth: 10,
        containerCss: '',
        dividerContent: '<div style="width:10px;height:100%"></div>',
        height: 105,
        content: "<div style=\"width: 100px;height: 105px;display:flex;flex-direction: column;align-items:center;justify-content:center;background-color: $$secondColor$$;color: $$firstColor$$;border-radius:5px;font-size: 35px;font-weight: 700;\">$$time$$<span style='display: block;margin-top: -2px;font-size: 15px;font-weight: 500'>$$text$$</span></div>"
    }

const thirdTemplate = {
    id: 4,
    textColor: "#191919",
    firstColor: "#989898",
    secondColor: "#486235",
    width: 100,
    dividerWidth: 0,
    containerCss: '',
    dividerContent: '',
    height: 120,
    content: "  <div style=\"width:100px;height:120px;background-color: $$firstColor$$;display: flex;justify-content: center;align-items: center;flex-direction: column;font-family: fantasy;letter-spacing: 3px;\">\n" +
        "    <div style=\"font-size: 45px;font-weight:bold;width:50%;color:$$secondColor$$\">$$time$$</div>\n" +
        "    <div style=\"font-size: 12px;width: 50%;color: $$textColor$$;\">$$text$$</div>\n" +
        "  </div>"
}

const secondTemplate = {
    id: 3,
    textColor: "#191919",
    firstColor: "#989898",
    secondColor: "#486235",
    width: 100,
    dividerWidth: 15,
    containerCss: '',
    dividerContent: '<div style="width:15px"></div>',
    height: 108,
    content: "  <div style=\"width:100px;height:108px;text-align: center;font-family: fantasy;\">\n" +
        "    <div style=\"font-size: 45px;font-weight:bold;width:100%;border-radius:10px;color:$$firstColor$$;background-color:$$secondColor$$;padding: 10px 5px;letter-spacing: 3px;\">$$time$$</div>\n" +
        "    <div style=\"font-size: 14px;width: 100%;color: $$secondColor$$;\">$$text$$</div>\n" +
        "  </div>"
}


const defaultCount = {
    type: 'countdown',
    name: `Décompte`,
    date: moment().add(10, 'days'),
    items: {
        days: {fr: 'jours', en: 'days'},
        hours: {fr: 'heures', en: 'hours'},
        minutes: {fr: 'minutes', en: 'minutes'},
        seconds: {fr: 'secondes', en: 'seconds'},
    },
    style: {
        width: `300px`,
        height: `105px`,
        position: 'absolute',
        top: '0',
        left: '0',
        transform: 'translate(0, 0)',
    },
    template: null,
    count: 'date',
    translate: [0, 0],
    rotate: 0,
    ratio: true
}


export default function CountDownTab() {

    const [apiDispatch] = useApiContext();
    const {apiFetchCollection} = apiDispatch;
    const [editorState, editorDispatch] = useEditorContext()
    const {addElement, selectElement, updateElement} = editorDispatch
    const [countNeedUpdate, setCountNeedUpdate] = useState(0)
    const [countdown, setCountdown] = useState(editorState.message.children.find(item => item.type === 'countdown') ?? defaultCount)

    const [days, setDays] = useState(countdown.items.days[editorState.lng])
    const [hours, setHours] = useState(countdown.items.hours[editorState.lng])
    const [minutes, setMinutes] = useState(countdown.items.minutes[editorState.lng])
    const [seconds, setSeconds] = useState(countdown.items.seconds[editorState.lng])
    const [visible, setVisible] = useState(false)

    const [templates, setTemplates] = useState([])

    useEffect(() => {
        fetchTimers()
    }, [])

    useEffect(() => {
        setDays(countdown.items.days[editorState.lng])
        setHours(countdown.items.hours[editorState.lng])
        setMinutes(countdown.items.minutes[editorState.lng])
        setSeconds(countdown.items.seconds[editorState.lng])
    }, [editorState.lng])

    useEffect(() => {

        let countElement = editorState.message.children.find(item => item.type === 'countdown')

        if (!countElement) {
            setCountdown(defaultCount)
        } else if (countElement !== countdown) {
            setCountdown(countElement)
        }
    }, [editorState.message.children])

    useEffect(() => {
        console.log(countNeedUpdate)
        console.log(countdown)

        if (countNeedUpdate !== 0) {
            let editing = !!countdown.id

            if (countdown.template && !editing) {
                addCountDown()
            } else if (editing) {
                updateElement({...countdown})
            }
        }
    }, [countNeedUpdate])

    async function fetchTimers() {
        let response = await apiFetchCollection('timers', {})
        console.log(response)
        setTemplates(response['hydra:member'])
    }

    function addCountDown() {
        let id = Date.now()

        const element = {
            ...countdown,
            id: id,
            name: `Décompte`,
        }
        selectElement(element)
        addElement(element)
    }

    const handleChange = (property, value) => {

        let newStyle = {...countdown.style}
        if (property === 'count') {
            newStyle = updateTimerStyle(value, countdown, countdown.template)
        }

        setCountdown({...countdown, type: 'countdown', [property]: value, style: newStyle})
        setCountNeedUpdate((new Date()).getTime())
    }

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }

    function PickerWithType({onChange}) {
        // console.log(countdown)
        if (countdown.count === 'time') return <TimePicker onChange={onChange} format={'HH:mm'}
                                                           value={moment(countdown.date) || undefined}
                                                           placeholder={"Heure"}/>;
        return <DatePicker disabledDate={disabledDate} format="DD-MM-YYYY HH:mm"
                           value={moment(countdown.date) || undefined}
                           onChange={onChange} placeholder={"Date"}
                           showTime={{defaultValue: moment('00:00', 'HH:mm')}}/>;
    }

    function changeTemplate(template, forceColor = false) {
        setVisible(false)
        let element = {...countdown}

        if (!element.template || forceColor) {
            element = updateColors(element, template)
        }

        let newStyle = updateTimerStyle(countdown.count, countdown, template)
        setCountdown({...element, template: template, style: newStyle})
        setCountNeedUpdate((new Date()).getTime())

    }

    function handleTextUpdate(text, value, setter) {
        setter(value)
        let newItems = {...countdown.items}
        let textContent = {...newItems[text]}
        textContent[editorState.lng] = value

        newItems = {...newItems, [text]: textContent}

        handleChange('items', newItems)
    }

    function updateColors(element, template) {
        if (template.textColor) {
            element.textColor = template.textColor
        }
        if (template.firstColor) {
            element.firstColor = template.firstColor
        }
        if (template.secondColor) {
            element.secondColor = template.secondColor
        }
        if (template.thirdColor) {
            element.thirdColor = template.thirdColor
        }

        return element
    }

    return (
        <div style={{width: '100%', maxWidth: '300px', minWidth: '300px'}}>
            <Tabs defaultActiveKey="visual" size="small" tabBarGutter={5} style={{width: '100%', maxWidth: '300px'}}>
                <Tabs.TabPane tab="Visuel" key="visual">
                    <Space direction="vertical" style={{width: '90%'}}>
                        <div>
                            {
                                countdown.template &&
                                <>
                                    <p>Couleurs :</p>
                                    <Space>
                                        {
                                            countdown.template.textColor &&
                                            <Colors currentColor={countdown.textColor}
                                                    setColor={(value) => handleChange('textColor', value)}/>
                                        }
                                        {
                                            countdown.template.firstColor &&
                                            <Colors currentColor={countdown.firstColor}
                                                    setColor={(value) => handleChange('firstColor', value)}/>
                                        }
                                        {
                                            countdown.template.secondColor &&
                                            <Colors currentColor={countdown.secondColor}
                                                    setColor={(value) => handleChange('secondColor', value)}/>
                                        }
                                        {
                                            countdown.template.thirdColor &&
                                            <Colors currentColor={countdown.thirdColor}
                                                    setColor={(value) => handleChange('thirdColor', value)}/>
                                        }
                                    </Space>
                                </>
                            }
                            <p>Modèle actuel: </p>
                            {
                                countdown.template ?
                                <Space direction="vertical">
                                    <div key={countdown.template.id}>
                                        <Count element={getCount(countdown.template)} onContextMenu={() => {
                                        }} style={getCount(countdown.template).style} lng={editorState.lng}/>
                                    </div>
                                    <Button onClick={() => changeTemplate(countdown.template, true)}>Réinitialiser les
                                        couleurs</Button>
                                </Space>
                                    :
                                    <p>Choisissez un modèle pour ajouter un décompte</p>
                            }
                            <Button onClick={() => setVisible(true)} style={{margin: '1em 0'}}>Choisir un modèle</Button>
                            <ModalCountdown setVisible={setVisible} visible={visible} selectTemplate={changeTemplate}/>
                        </div>
                    </Space>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Paramètres" key="params">
                    <Space direction="vertical" style={{width: '100%'}}>
                        <div>
                            <p>Fin du décompte</p>
                            <PickerWithType onChange={(value) => handleChange('date', value)}/>
                        </div>
                        <div>
                            <p>Texte</p>
                            <Space direction="vertical">

                                <div>
                                    Jour
                                    <Input value={days}
                                           onChange={(e) => handleTextUpdate('days', e.target.value, setDays)}/>
                                </div>
                                <div>
                                    Heures
                                    <Input value={hours}
                                           onChange={(e) => handleTextUpdate('hours', e.target.value, setHours)}/>
                                </div>
                                <div>
                                    Minutes
                                    <Input value={minutes}
                                           onChange={(e) => handleTextUpdate('minutes', e.target.value, setMinutes)}/>
                                </div>
                                <div>
                                    Secondes
                                    <Input value={seconds}
                                           onChange={(e) => handleTextUpdate('seconds', e.target.value, setSeconds)}/>
                                </div>

                            </Space>
                        </div>
                    </Space>
                </Tabs.TabPane>

            </Tabs>
        </div>
    )
}