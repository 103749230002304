import React, {useState, useEffect} from 'react'
import {useApiContext} from "../providers/ApiProvider";
import {Select, Skeleton} from "antd";

export default function InputCategories({messageId}) {

    const [apiDispatch] = useApiContext();
    const {apiFetchSubResource, apiFetchCollection, apiUpdateEntity, apiPostEntity} = apiDispatch;

    const [messageCategories, setMessageCategories] = useState([]);
    const [allCategories, setAllCategories] = useState([]);

    const [loading, setLoading] = useState(true);
    const [loadingPost, setLoadingPost] = useState(false);

    useEffect(() => {
        fetchMessageCategories()
    }, [])

    useEffect(() => {
        fetchAllCategories()
    }, [])

    async function fetchMessageCategories() {
        const response = await apiFetchSubResource("messages", {id: messageId}, "template_categories");

        setMessageCategories(response['hydra:member'].map(mc => {
            return {key: mc['@id'], value: mc.name, children: mc.name};
        }));

        setLoading(false);
    }

    async function fetchAllCategories() {
        const response = await apiFetchCollection("template_categories", {});
        setAllCategories(response['hydra:member']);
    }

    async function handleChange(value, options) {
        if (value.length > messageCategories.length) {
            let unitToWork = value.pop();

            if (messageCategories.some(mc => normalize(mc.value) === normalize(unitToWork)))
                return;

            if (allCategories.some(ac => normalize(ac.name) === normalize(unitToWork))) {
                let index = allCategories.findIndex(ac => normalize(ac.name) === normalize(unitToWork));
                let category = allCategories[index];
                const newValue = [...messageCategories, {
                    key: category['@id'],
                    value: category.name,
                    children: category.name
                }];
                update(newValue.map(nv => nv.key));
                setMessageCategories(newValue);
            } else {
                setLoadingPost(true);

                const data = {name: unitToWork.trim(), messages: [`/api/messages/${messageId}`]};
                const response = await apiPostEntity('template_categories', data);

                setAllCategories([...allCategories, response]);
                setMessageCategories([...messageCategories, {
                    key: response['@id'],
                    value: response.name,
                    children: response.name
                }]);

                setLoadingPost(false);
            }
        } else {
            update(options.map(o => o.key));
            setMessageCategories(options);
        }
    }

    function update(categories) {
        apiUpdateEntity("messages", messageId, {templateCategories: categories});
    }

    function normalize(value) {
        return value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim();
    }

    return (
        loading ? <Skeleton.Input style={{width: 300}} active/>
            : <Select
                mode="tags"
                style={{width: 300}}
                placeholder="Sélectionner une catégorie"
                value={messageCategories.map(mc => mc.value)}
                onChange={handleChange}
                loading={loadingPost}
                disabled={loadingPost}
            >
                {allCategories.map(category => (
                    <Select.Option key={category['@id']} value={category.name}>{category.name}</Select.Option>
                ))}
            </Select>
    )
}