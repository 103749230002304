import React, {useState, useEffect} from "react";
import TimerDisplay from "./TimerDisplay";

export default function Count({element, style, onContextMenu, lng}) {

    const [css, setCss] = useState(getCss)

    useEffect(()=> {
        setCss(getCss())
    }, [element])

    const date = new Date(element.date)

    const calculateTimeLeft = () => {

        let difference = date - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    }

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft)

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);

    }, [element.date, timeLeft]);


    function getCss() {
        let css = element.template.containerCss
            .replaceAll('$$firstColor$$', element.firstColor)
            .replaceAll('$$textColor$$', element.textColor)
            .replaceAll('$$secondColor$$', element.secondColor)
        return '.element_'+element.id+'{'+css+'}'
    }

    return (
        <div
            className={'element_'+element.id}
            style={style}
            id={element.id}
            onContextMenu={onContextMenu}
        >
            <style>{css}</style>
            <TimerDisplay time={timeLeft} message={'hello'} element={element} lng={lng}/>
        </div>
    )

}