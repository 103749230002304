import React, { useEffect, useState } from 'react'
import { useAccountContext } from "../providers/AccountProvider";
import { Button, Form, Input, message, PageHeader, Popover, Select, Space, Switch, Tooltip } from "antd";
import { useApiContext } from "../providers/ApiProvider";
import { MinusCircleOutlined, PlusOutlined, } from "@ant-design/icons";
import ColorInput from "../components/ColorInput";
import GoogleFonts from "../../static/fonts.json"
import CheckEmailMarketing from "../components/CheckEmailMarketing";
import GooglePlaceAutocomplete from "../components/GooglePlaceAutocomplete";
import { useAuthContext } from '../providers/AuthProvider';

export default function AccountSettings() {

    const [authState] = useAuthContext();
    const { auth } = authState;
    const isSuper = auth.roles.includes('ROLE_ADMIN');

    const [accountState, accountDispatch] = useAccountContext();
    const { accountSet } = accountDispatch;
    const [apiDispatch] = useApiContext();
    const { apiUpdateEntity, apiFetchEntity } = apiDispatch;
    const [form] = Form.useForm();
    const [hapidamClient, setHapidamClient] = useState(null);
    const [isHapidamListener, setIsHapidamListener] = useState(false)

    const [accountColors, setAccountColors] = useState([])
    const [selectedColor, setSelectedColor] = useState(null)
    const [loading, setLoading] = useState(false);
    const [account, setAccount] = useState({});
    const [loadingStart, setLoadingStart] = useState(true);
    const [locations, setLocations] = useState([]);

    const [emailMarketing, setEmailMarketing] = useState({});

    useEffect(() => {
        setHapidamClient(new Hapidam())
        fetch()
    }, [])

    function connect() {
        if (!isHapidamListener) {
            hapidamClient.on('select', handleResponse);
            setIsHapidamListener(true)
        }
        hapidamClient.connect()
    }

    function handleResponse(response) {
        hapidamClient.off('select', handleResponse);
        setIsHapidamListener(false)
        form.setFieldsValue({ hapidamKey: response.token })
    }

    async function fetch() {
        const response = await apiFetchEntity('get-all-account-infos', accountState.account.id)

        setAccount(response);
        setEmailMarketing(response.emailMarketing || {})
        setAccountColors(response.colors || [])
        setLocations(response.location ? [response.location] : [])
        setLoadingStart(false);
    }

    const onFinish = (values) => {
        if (!values.languages.length)
            return message.error("Une langue au moins doit être sélectionnée !(");

        updateAccount(values);
    };

    const updateAccount = async (values) => {
        setLoading(true);
        values.colors = accountColors
        values.emailMarketing.isActive = values.emailMarketing.platform !== undefined;
        values.location = locations[0] ? locations[0] : null

        let response = await apiUpdateEntity('accounts', account.id, values);

        setAccount(response);
        delete response.hapidamKey;
        delete response.emailMarketing;
        accountSet(response);

        message.success('Modification enregistré avec succès')
        setLoading(false);
    }

    const [fontData, setFontData] = useState([])
    const [searchTimeout, setSearchTimeout] = useState()
    const searchFont = (e) => {
        let value = e.trim().toLowerCase()
        clearTimeout(searchTimeout)
        if (!value) return

        setSearchTimeout(setTimeout(() => {

            setFontData(filterFonts(value))
        }, 200))
        console.log(e)
    }

    const filterFonts = (value) => {
        let maxSize = 10;
        let count = 0;
        let i = 0;
        let results = []
        while (count < maxSize && i < GoogleFonts.length) {
            // console.log(GoogleFonts[i].toLowerCase().includes(value))
            if (GoogleFonts[i].toLowerCase().includes(value)) {
                results.push(GoogleFonts[i])
                count++;
            }
            i++;
        }
        return results
    }

    const handleColor = (color) => {
        setAccountColors(prevColors => Array.from(new Set([...prevColors, color])))
        setSelectedColor(null)
    }

    const handleRemove = (color) => {
        setAccountColors(prevColors => [...prevColors.filter(item => item !== color)])

    }

    function updateEmailMarketing(key, value) {
        emailMarketing[key] = value;
        setEmailMarketing({ ...emailMarketing });
    }

    const colorMenu = (
        <div>
            <p>Choisissez un couleur</p>
            <Space onClick={(e) => e.stopPropagation()}>
                <ColorInput setColor={(color) => setSelectedColor(color)} />
                {selectedColor ?
                    <div className="transparent-bg radius-5" style={{ width: '32px', height: '32px' }}
                        key={`selection_${selectedColor}`}>
                        <div className="colorSquare" onClick={() => handleColor(selectedColor)}
                            style={{ backgroundColor: selectedColor }} />
                    </div>
                    : null
                }
                {
                    selectedColor ?
                        <Button onClick={() => handleColor(selectedColor)}>Valider cette couleur</Button>
                        : null
                }
            </Space>
        </div>
    )

    const handlePlace = (value) => {
        console.log(value)
        setLocations([value])
    }

    return (
        <div>
            <PageHeader
                onBack={() => window.history.back()}
                title={<h1>Paramétrages du compte : {account.name}</h1>}
                style={{ borderBottom: '2px solid #2494D1' }}
            />
            {loadingStart ? null :
                <Form
                    className="mt-20"
                    form={form}
                    layout="vertical"
                    initialValues={{
                        bookingButton: account.bookingButton,
                        hapidamKey: account.hapidamKey,
                        ipRanges: account.ipRanges,
                        displayRequestHandler: account.displayRequestHandler,
                        isIpRangeActive: account.isIpRangeActive,
                        languages: account.languages || [],
                        fonts: account.fonts || [],
                        emailMarketing: account.emailMarketing || {}
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item label="Localisation de votre hôtel" name={"location"}
                        tooltip="Cette localisation est utilisée dans les comportements (météo)">
                        <>
                            {locations[0]?.name && <p>Emplacement actuel : {locations[0]?.name}</p>}
                            <GooglePlaceAutocomplete setValues={handlePlace} values={locations} displayValues={false} />
                        </>
                    </Form.Item>

                    <Form.Item label="Bouton de réservation" name={"bookingButton"}
                        tooltip="Ajouter une classe ou un id">
                        <Input placeholder="ex: #btn" style={{ width: '250px' }} />
                    </Form.Item>

                    <Form.Item label="Clé api photothèque hapidam" name={"hapidamKey"}
                        tooltip="Ajouter une clé api privé Hapidam">
                        <Input.Search enterButton="Modifier" onSearch={connect}
                            placeholder="ex : 2695de9-95de31-ed56e9d" style={{ width: '300px' }} />
                    </Form.Item>

                    <Form.Item label="Langages" name="languages" tooltip="Langages de traduction pour les messages">
                        <Select
                            mode="multiple"
                            placeholder="ex : Français, Anglais"
                            style={{ width: '250px' }}
                            optionFilterProp="children"
                        >
                            <Select.Option value="fr">Français</Select.Option>
                            <Select.Option value="en">Anglais</Select.Option>
                            <Select.Option value="es">Espagnol</Select.Option>
                            <Select.Option value="de">Allemand</Select.Option>
                            <Select.Option value="it">Italien</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label="Polices" name="fonts" tooltip="Sélectionner vos polices favorites">
                        <Select
                            mode="multiple"
                            placeholder="Rechercher une google font par nom"
                            style={{ width: '250px' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                            onSearch={searchFont}
                        >
                            {fontData.map(d => (
                                <Select.Option key={`GoogleFont_${d}`} value={d}>{d}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Couleurs" name="colors" tooltip="Sélectionner vos couleurs favorites">
                        <Space direction="vertical">
                            <Popover content={colorMenu} destroyTooltipOnHide trigger={['click']} placement={'right'}>
                                <Button>Ajouter une couleur</Button>
                            </Popover>
                            <Space wrap className="color">
                                {
                                    accountColors.map(color =>
                                        <div className="transparent-bg radius-5 color-container"
                                            key={`default_${color}`}>
                                            <Tooltip title={'Cliquer pour supprimer cette couleur'}>
                                                <div className="colorSquare"
                                                    style={{ backgroundColor: color }}
                                                    onClick={() => handleRemove(color)} />
                                            </Tooltip>
                                        </div>
                                    )
                                }
                            </Space>
                        </Space>
                    </Form.Item>

                    {
                        isSuper &&
                        <Form.Item name="displayRequestHandler" label="Activer le popup de customisation" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                    }

                    <Form.Item name="isIpRangeActive" label="Activer le blocage par ip" valuePropName="checked">
                        <Switch />
                    </Form.Item>

                    <Form.List name="ipRanges">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(field => (
                                    <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'lower']}
                                            fieldKey={[field.fieldKey, 'lower']}
                                            rules={[
                                                { required: true, message: 'Missing lower ip' },
                                                {
                                                    pattern: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                                                    message: 'ip non valide, ex: "60.61.62.0"'
                                                }
                                            ]}
                                        >
                                            <Input placeholder="Lower ip" />
                                        </Form.Item>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'higher']}
                                            fieldKey={[field.fieldKey, 'higher']}
                                            rules={[
                                                { required: true, message: 'Missing higher ip' },
                                                {
                                                    pattern: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                                                    message: 'ip non valide, ex : "190.180.170.0"'
                                                }
                                            ]}
                                        >
                                            <Input placeholder="Higher ip" />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()} block icon={<PlusOutlined />}
                                        style={{ width: '250px' }}
                                    >
                                        Ajouter une ip
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>

                    <Form.Item label="Email Marketing" name="emailMarketing">
                        <Input.Group style={{ display: 'flex' }}>
                            <Form.Item name={['emailMarketing', 'platform']}>
                                <Select
                                    style={{ width: 200 }}
                                    placeholder="Plateforme"
                                    allowClear
                                    onChange={value => updateEmailMarketing('platform', value)}
                                >
                                    <Select.Option value="mailchimp">MailChimp</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item name={['emailMarketing', 'apiKey']}>
                                <Input
                                    placeholder="Api key"
                                    style={{ width: 250, marginLeft: 10 }}
                                    allowClear
                                    onChange={e => updateEmailMarketing('apiKey', e.target.value)}
                                />
                            </Form.Item>

                            <div style={{ marginLeft: 10 }}>
                                <CheckEmailMarketing emailMarketing={emailMarketing} />
                            </div>
                        </Input.Group>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary" htmlType="submit"
                            loading={loading}
                        >
                            Valider
                        </Button>
                    </Form.Item>
                </Form>}
        </div>
    )
}