import React, {useState} from "react"
import {TagsOutlined} from "@ant-design/icons";
import {Button, Modal} from "antd";
import InputCategories from "../InputCategories";

export default function ModalInputCategory({message}) {

    const [visible, setVisible] = useState(false);

    return (
        <>
            <Button size="small" onClick={() => setVisible(true)} icon={<TagsOutlined/>} title="Taguer"/>

            <Modal
                visible={visible}
                onCancel={() => setVisible(false)}
                onOk={() => setVisible(false)}
                title="Lier des catégories"
            >
                <InputCategories messageId={message.id}/>
            </Modal>
        </>
    )
}