import React, {useEffect, useState} from 'react';

export default function TimerDisplay({time, element, lng}) {

    const [days, setDays] = useState('')
    const [hours, setHours] = useState('')
    const [minutes, setMinutes] = useState('')
    const [seconds, setSeconds] = useState('')
    const [scale, setScale] = useState('')
    const [divider, setDivider] = useState('')

    useEffect(() => {
        let content = element.template.content
        setDays(parseElem('days', content))
        setHours(parseElem('hours', content))
        setMinutes(parseElem('minutes', content))
        setSeconds(parseElem('seconds', content))
    }, [time, element])

    useEffect(() => {
        getScale()

        if (element.template.dividerContent) {
            setDivider(parseElem(false, element.template.dividerContent))
        }
    }, [element])

    function getScale() {

        let template = {...element.template}
        let elementwidth = parseFloat(element.style.width)
        // console.log(elementwidth, element.elementWidth)
        let nbElem = element.count === 'time' ? 2 : 4
        // console.log(element.elementWidth * nbElem + 10 * (nbElem - 1))
        let scale = (elementwidth / (template.width * nbElem + template.dividerWidth * (nbElem - 1))).toFixed(2)

        setScale(scale)
    }

    function parseElem(text, content) {

        let result = content
            .replaceAll('$$firstColor$$', element.firstColor)
            .replaceAll('$$textColor$$', element.textColor)
            .replaceAll('$$secondColor$$', element.secondColor)
            .replaceAll('$$thirdColor$$', element.thirdColor)
        if (text) {
            let timeText = time[text] || 0
            timeText = timeText < 10 ? '0' + timeText : timeText
            result = result.replaceAll('$$time$$', timeText)
                .replace('$$text$$', element.items[text][lng] ? element.items[text][lng] : element.items[text].fr)
        }
        return result

    }


    return (
        <div style={{
            display: "flex",
            transformOrigin: 'top left',
            transform: `scale(${scale})`
        }}
        >
            {<div dangerouslySetInnerHTML={{__html: days}}/>}
            {
                element.template.dividerContent &&
                <div dangerouslySetInnerHTML={{__html: divider}}/>
            }
            {<div dangerouslySetInnerHTML={{__html: hours}}/>}
            {
                element.template.dividerContent &&
                <div dangerouslySetInnerHTML={{__html: divider}}/>
            }
            {<div dangerouslySetInnerHTML={{__html: minutes}}/>}
            {
                element.template.dividerContent &&
                <div dangerouslySetInnerHTML={{__html: divider}}/>
            }
            {<div dangerouslySetInnerHTML={{__html: seconds}}/>}


        </div>
    )
}
