import React, {useEffect, useState} from 'react'
import {ChromePicker} from 'react-color';
import FaIcon from "./FaIcon";
import {faPalette} from "@fortawesome/pro-solid-svg-icons";
import {Dropdown} from "antd";

export default function ColorInput({color, setColor, title = ''}) {

    const [currentColor, setCurrentColor] = useState(color)

    useEffect(() => {
        setCurrentColor(color)
    }, [color])

    function handleRGB(color) {
        let {r, g, b, a} = color;
        return `rgba(${r}, ${g}, ${b}, ${a})`
    }

    const picker = (
            <ChromePicker color={currentColor}
                          onChange={(value) => setCurrentColor(handleRGB(value.rgb))}
                          onChangeComplete={(value) => setColor(handleRGB(value.rgb))}
                          onClick={(e) => e.stopPropagation()}/>
    )

    return (
        <Dropdown trigger={['click']} overlay={picker} className="colorPicker colorSquare" >
                <FaIcon icon={faPalette} style={{color: color}} title={title}/>
        </Dropdown>
    )
}