import React from "react"
import { CopyOutlined } from "@ant-design/icons";
import { Button, message, Modal } from "antd";

export default function ModalScript({ visible, setVisible, accountId }) {

    function copyCode(id) {
        const copyText = document.getElementById(id).textContent;
        const textArea = document.createElement('textarea');
        textArea.textContent = copyText;
        document.body.append(textArea);
        textArea.select();
        document.execCommand("copy");
        message.success('Texte copié');
        document.body.removeChild(textArea);
    }

    return (
        <Modal
            width={1200}
            title="Importer le script depuis votre site"
            visible={visible}
            cancelText='Fermer'
            onCancel={() => setVisible(false)}
            onOk={() => setVisible(false)}
        >
            <div>
                <h4>Méthode 1 (script)</h4>
                <div className="flex between">
                    <div style={{ width: '49%' }}>
                        <p>
                            <strong className="mr-10">Balise script à placer dans la balise head de votre site</strong>
                            <Button type="link" icon={<CopyOutlined />} onClick={() => copyCode('script-tag')}>
                                copier le code
                            </Button>
                        </p>
                        <div>
                            <pre id="script-tag" className="script-tag" style={{ fontSize: '0.9em', backgroundColor: '#eee', padding: 5 }}>
                                {`<script type="text/javascript" src="https://v2.hotelpushmarketing.com/widget.js" id="__hpmv2_Ul^HwX$gqxUG__" data-account="${accountId}" async></script>`}
                            </pre>
                        </div>
                    </div>
                    <div style={{ width: '49%' }}>
                        <p>
                            <strong className="mr-10">Balise script à utiliser avec <span style={{color: '#2494D1'}}>GTM</span></strong>
                            <Button type="link" icon={<CopyOutlined />} onClick={() => copyCode('script-gtm')}>
                                copier le code
                            </Button>
                        </p>
                        <div>
                            <pre id="script-gtm" className="script-tag" style={{ fontSize: '0.9em', backgroundColor: '#eee', padding: 5 }}>
                                {`<script >
    (function (window, document) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://v2.hotelpushmarketing.com/widget.js';
        script.id = "__hpmv2_Ul^HwX$gqxUG__";
        script.setAttribute('data-account', '${accountId}');
        var before = document.getElementsByTagName('script')[0];
        before.parentNode.insertBefore(script, before);
    })(window, document)
</script>`}
                            </pre>
                            <p>

                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <h4>Méthode 2</h4>
                <p>
                    <strong className="mr-10">Code javascript</strong>
                    <Button type="link" icon={<CopyOutlined />} onClick={() => copyCode('javascript-code')}>
                        copier le code
                    </Button>
                </p>
                <div>
                    <pre id="javascript-code" style={{ fontSize: '0.9em', backgroundColor: '#eee', padding: 5 }}>
                        {`(function (window, document) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://v2.hotelpushmarketing.com/widget.js';
    script.id = "__hpmv2_Ul^HwX$gqxUG__";
    script.setAttribute('data-account', '${accountId}');
    var before = document.getElementsByTagName('script')[0];
    before.parentNode.insertBefore(script, before);
})(window, document)`}
                    </pre>
                </div>
            </div>
        </Modal>
    )
}