import React, {useState} from 'react'
import {Button, Popover} from "antd";
import {ReactComponent as SpaceIcon} from '../../assets/space.svg'
import PositionPanel from "./tabs/Component/PositionPanel";
import {useEditorContext} from "../../providers/EditorProvider";


export default function PositionOptions() {

    const [editorState, editorDispatch] = useEditorContext()
    const {setMessage, setter} = editorDispatch
    const [isOpen, setIsOpen] = useState(false)

    const content = (
        <div className="insert" style={{height: "auto", maxHeight: "400px"}}>
            <PositionPanel message={editorState.message} setMessage={setMessage} setter={setter}/>
        </div>
    )

    const handleVisible = (value) => {
        console.log(value)
        setIsOpen(value)
    }

    return (
        <Popover placement="right"
                 title={
                     <div className="insert-title">
                         Position du message
                     </div>
                 }
                 content={content}
                 trigger="click"
                 onVisibleChange={handleVisible}
                 destroyTooltipOnHide={true}>
            <Button className={`action-btn ${isOpen ? 'active' : ''}`} title="Positionnement"><SpaceIcon
                fill={isOpen ? 'white' : 'black'}/></Button>
        </Popover>
    )
}