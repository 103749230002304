import React from 'react'
import {Layout, Space} from 'antd';
import AvatarDropdown from "./AvatarDropdown";
import {useAccountContext} from "../providers/AccountProvider";
import HeaderMenu from "./HeaderMenu";

const {Header, Content} = Layout;

export default function MainLayout({children}) {

    const [accountState] = useAccountContext();

    return (
        <Layout className="mainLayout">
            <Header className="header">
                <HeaderMenu accountState={accountState}/>
                <Space>
                    <AvatarDropdown/>
                </Space>
            </Header>
            <Content className="flex">
                <Layout className="site-layout-background">
                    <Content style={{padding: '0 84px 84px', minHeight: 280}}>
                        {children}
                    </Content>
                </Layout>
            </Content>
        </Layout>
    )

}