import React from 'react'
import {useEditorContext} from "../../../../providers/EditorProvider";
import {Input} from "antd";
import { CheckOutlined} from '@ant-design/icons';

export default function AddVideo({setVisible}) {

    const [editorState, editorDispatch] = useEditorContext();
    const {addElement,selectElement} = editorDispatch;

    function addVideo(value) {
        let id = Date.now();
        const url = getUrl(value);

        const video = {
            id: id,
            name: `video ${id}`,
            type: "video",
            style: {
                position: 'absolute',
                top: '0',
                left: '0',
                whitespace: 'normal',
                transform: 'translate(0, 0)',
                width: '100%',
                height: '30%',
                color: 'rgba(0,0,0,0.85)'
            },
            translate: [0, 0],
            rotate: 0,
            url: url
        }
        selectElement(video)
        addElement(video);
        setVisible(false)
    }

    function getUrl(url) {
        let response = url;

        if (response.includes("youtube.com/"))
            response = getYoutubeUrl(response);
        else if (response.includes("vimeo.com/"))
            response = getVimeoUrl(response);

        return response;
    }

    function getYoutubeUrl(url) {
        let id = url;

        if (id.includes("?v="))
            id = id.split("?v=").pop();

        if (id.includes("&t="))
            id = id.split("&t=").shift();

        return `https://www.youtube.com/embed/${id}?autoplay=1&rel=0&mute=1&loop=1&controls=0&showinfo=0&modestbranding=1`;
    }

    function getVimeoUrl(url) {
        let id = url;

        if (id.includes("vimeo.com/"))
            id = id.split("vimeo.com/").pop();

        return `https://player.vimeo.com/video/${id}?autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1&autopause=0&background=1`;
    }

    return (
        <div>
            <p>Ajouter une video youtube ou vimeo</p>

                <div>
                    <Input.Search
                        placeholder="https://www.youtube.com/watch?v=c0RVfpsXG5E"
                        enterButton={<CheckOutlined/>}
                        onSearch={addVideo}
                    />
                </div>
        </div>
    )
}