import React, {useEffect, useState} from "react";
import {useApiContext} from "../../providers/ApiProvider";
import {Pagination, Space, Spin} from "antd";
import Count from "../editor/Count";
import {getCount} from "../editor/utils";

export default function TimerList({selectTemplate, lng = 'fr', items = 30}) {

    const [apiDispatch] = useApiContext();
    const {apiFetchCollection} = apiDispatch;

    const [templates, setTemplates] = useState([])
    const [loading, setLoading] = useState(false)
    const initialPagination = {page: 1, itemsPerPage: items, totalItems: 0};
    const [pagination, setPagination] = useState(initialPagination);


    useEffect(() => {
        fetchTimers()
    }, [pagination.page])

    async function fetchTimers() {
        setLoading(true)
        let params = {
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
        }
        let response = await apiFetchCollection('timers', params)
        if (response['hydra:member']) {
            setTemplates(response['hydra:member'])
            pagination.totalItems = response['hydra:totalItems'];
            setPagination({...pagination});
        }
        setLoading(false)
    }

    function pageChange(page) {
        setPagination(prev => {return {...prev, page}})
    }


    return (
        <Spin spinning={loading}>
            <p>Listes des décomptes</p>
            <div style={{textAlign: 'center'}}>
                <Space wrap>
                    {
                        templates.map(template =>
                            <div key={template.id} onClick={() => selectTemplate(template)} className="pointer">
                                <Count element={getCount(template)} onContextMenu={() => {
                                }} style={getCount(template).style} lng={lng}/>
                            </div>
                        )
                    }
                </Space>
            </div>

            <div style={{textAlign:'center', margin: '1em'}}>
                <Pagination size='small' current={pagination.page} total={pagination.totalItems}
                            pageSize={pagination.itemsPerPage} onChange={pageChange}/>
            </div>

        </Spin>
    )
}