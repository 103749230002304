import React, { useEffect, useRef, useState } from 'react'
import Moveable from "react-moveable";
import { DimensionViewable, RotationViewable } from "./CustomAbles";
import { Button, Dropdown, Menu, Slider, Space } from "antd";
import Icon, { BgColorsOutlined, EditOutlined, RadiusSettingOutlined } from "@ant-design/icons";
import { useEditorContext } from "../../providers/EditorProvider";
import Colors from "../Colors";
import { ReactComponent as CircleIcon } from "../../assets/icons/circle_icon.svg";

export default function CloseButton({ element, isSelected, elementGuidelines }) {

    const [style, setStyle] = useState(element.style)
    const [editorState, editorDispatch] = useEditorContext()
    const { selectElement, setOptions } = editorDispatch;
    const [isLoaded, setIsLoaded] = useState(false);
    const [isDragging, setIsDragging] = useState(false)
    const [isResizing, setIsResizing] = useState(false)
    const [isRotating, setIsRotating] = useState(false)

    const moveable = useRef()
    const btnTarget = useRef()

    const [frame, setFrame] = useState({
        translate: element.translate || [0, 0],
        rotate: element.rotate || 0,
    });


    const closeIcon = (
        <svg width="100%" height="100%"
            viewBox={"0 0 " + element.content.icon[0] + " " + element.content.icon[1]}>
            <path d={element.content.icon[4]} fill="currentColor" />
        </svg>
    )


    useEffect(() => {
        setFrame({
            ...frame,
            translate: element.translate || [0, 0],
            rotate: element.rotate || 0
        })

        moveable.current.updateRect();

        if (isLoaded)
            setStyle(element.style);
        else
            setIsLoaded(true);
    }, [element])

    useEffect(() => {
        if (isLoaded) {
            setOptions({ ...editorState.message.options, closeButton: { ...element, style: style, translate: frame.translate, rotate: frame.rotate } })
        } else
            setIsLoaded(true);
    }, [style])

    const handleStyle = (property, color) => {
        setStyle(prevStyle => {
            return { ...prevStyle, [property]: color }
        })
    }

    function formatter(value) {
        return `${value}%`;
    }

    const radiusSlider = (
        <Menu>
            <Menu.Item style={{ width: '200px' }}>
                <Slider tipFormatter={formatter}
                    marks={{ 0: 0, 100: 100, [parseFloat(element.style.borderRadius)]: parseFloat(element.style.borderRadius) }}
                    defaultValue={parseFloat(element.style.borderRadius)}
                    min={0} max={100}
                    onAfterChange={(val) => handleStyle('borderRadius', `${val}%`)} />
            </Menu.Item>
        </Menu>
    )
    const baseMenu = (
        <Menu>
            <Menu.Item key={`menu-${element.id}`}>
                <Space>
                    <Colors currentColor={style.color} setColor={(color) => handleStyle("color", color)} title="Couleur de l’icône" icon={<EditOutlined />} />
                    <Colors currentColor={style.backgroundColor} setColor={(color) => handleStyle('backgroundColor', color)} title="Couleur du fond" icon={<BgColorsOutlined />} />
                    <Colors currentColor={style.borderColor} setColor={(color) => handleStyle('borderColor', color)} title="Couleur de la bordure" icon={<Icon component={CircleIcon} />} />
                    <Dropdown trigger={['click']} overlay={radiusSlider}>
                        <Button icon={<RadiusSettingOutlined />} title="Arrondi du bouton" />
                    </Dropdown>
                </Space>
            </Menu.Item>
        </Menu>
    )



    return (
        <>
            <div id={element.id} ref={btnTarget} style={{ ...element.style, padding: 0 }}>
                {closeIcon}
            </div>

            <Dropdown visible={isSelected && !isDragging && !isResizing && !isRotating} overlay={baseMenu}
                placement='topCenter'>
                <Moveable
                    ables={[isResizing ? DimensionViewable : {}, isRotating ? RotationViewable : {}]}
                    props={{
                        dimensionViewable: true,
                        rotationViewable: true
                    }}
                    bounds={{ "left": -15, "top": -15, "right": parseFloat(editorState.message.style.width) + 15, "bottom": parseFloat(editorState.message.style.height) + 15 }}
                    target={btnTarget.current}
                    ref={moveable}
                    className={isSelected || isDragging ? '' : 'targetNotSelected'}
                    snappable={true}
                    origin={false}
                    elementGuidelines={elementGuidelines}
                    verticalGuidelines={[0, 200, 400]}
                    horizontalGuidelines={[0, 200, 400]}
                    snapThreshold={2}
                    isDisplaySnapDigit={true}
                    snapGap={true}
                    snapElement={true}
                    snapVertical={true}
                    snapHorizontal={true}
                    snapCenter={true}
                    snapDigit={0}
                    keepRatio={true}
                    resizable={isSelected}
                    throttleResize={0}
                    draggable={true}
                    throttleDrag={0}
                    startDragRotate={0}
                    throttleDragRotate={0}
                    rotatable={isSelected}
                    throttleRotate={5}
                    rotationPosition={"bottom"}
                    zoom={(parseFloat(element.style.width) < 50 && parseFloat(element.style.height) < 50) || element.type === 'text' ? .5 : 1}
                    dragArea={isSelected ? true : null}
                    dragWithClip={true}
                    onDragOriginStart={({ dragStart }) => {
                        dragStart && dragStart.set(frame.translate);
                    }}
                    onDragOrigin={({ target, drag, transformOrigin }) => {
                        frame.translate = drag.beforeTranslate;
                        frame.transformOrigin = transformOrigin;
                    }}
                    onDragStart={({ set, inputEvent, datas }) => {
                        setIsDragging(true)
                        // console.log(frame)
                        set(frame.translate);
                    }}
                    onDrag={({ target, beforeTranslate }) => {
                        frame.translate = beforeTranslate;
                    }}
                    onDragEnd={(target) => {
                        const { translate, rotate } = frame;
                        setIsDragging(false)
                        if (element.translate[0] !== translate[0] || element.translate[1] !== translate[1] || element.rotate !== rotate)
                            setStyle({
                                ...style,
                                transform: `translate(${translate[0]}px, ${translate[1]}px) rotate(${rotate}deg)`
                            });
                        selectElement(element)
                    }}
                    onRotateStart={({ set }) => {
                        set(frame.rotate);
                        setIsRotating(true)
                    }}
                    onRotate={({ beforeRotate }) => {
                        frame.rotate = beforeRotate;
                    }}
                    onRotateEnd={(target) => {
                        setIsRotating(false)
                        const { translate, rotate } = frame;
                        setStyle({
                            ...style,
                            transform: `translate(${translate[0]}px, ${translate[1]}px) rotate(${rotate}deg)`
                        });
                    }}
                    onRender={({ target }) => {
                        const { translate, rotate, transformOrigin } = frame;
                        target.style.transformOrigin = transformOrigin;
                        target.style.transform = `translate(${translate[0]}px, ${translate[1]}px)`
                            + ` rotate(${rotate}deg)`;
                    }}
                    onResizeStart={({ setOrigin, dragStart, setMin }) => {
                        setMin([15, 15])
                        setIsResizing(true)
                        setOrigin(["%", "%"]);
                        dragStart && dragStart.set(frame.translate);
                    }}
                    onResize={({ target, width, height, drag }) => {
                        const beforeTranslate = drag.beforeTranslate;

                        frame.translate = beforeTranslate;
                        target.style.width = `${width}px`;
                        target.style.height = `${height}px`;
                        target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
                    }}
                    onResizeEnd={(target) => {
                        setIsResizing(false)
                        if (target.lastEvent) {
                            let newStyle = {
                                width: `${target.lastEvent.width}px`,
                                transform: `translate(${frame.translate[0]}px, ${frame.translate[1]}px)`
                                    + ` rotate(${frame.rotate}deg)`
                            }
                            if (element.type !== 'text') {
                                newStyle.height = `${target.lastEvent.height}px`
                            }
                            setStyle({
                                ...style, ...newStyle
                            })
                        }
                    }}
                />
            </Dropdown>
        </>
    )
}