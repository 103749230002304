import React, { useEffect, useState } from 'react';
import { Checkbox, Col, InputNumber, Row, Select, Space, Tag } from 'antd';
import GooglePlaceAutocomplete from "../../../GooglePlaceAutocomplete";
import { useAccountContext } from "../../../../providers/AccountProvider";
import { Link } from "react-router-dom";

const baseWeather = { condition: undefined, value: undefined }

export default function LocationPanel({ behavior, setBehavior, setNeedUpdate }) {

    const [accountState] = useAccountContext();
    const [locations, setLocations] = useState(behavior.location || []);
    const [excludedLocations, setExcludedLocations] = useState(behavior.excludedLocation || []);
    const [weather, setWeather] = useState(null);
    const [temperature, setTemperature] = useState(null);
    const [userTypes, setUserTypes] = useState([]);
    const [locationUpdated, setLocationUpdated] = useState(0)

    useEffect(() => {
        if (behavior.id) {
            setLocations(behavior.location || [])
            setExcludedLocations(behavior.excludedLocation || [])
            setUserTypes(behavior.userTypes || [])
            if (!temperature) setTemperature(behavior.temperature || baseWeather)
            if (!weather) setWeather(behavior.weather || baseWeather)
        }
    }, [behavior])

    useEffect(() => {
        if (locationUpdated !== 0)
            handleChange()
    }, [locationUpdated])


    function handleDeSelect(value, setValues) {
        setValues(previousLocations => [...previousLocations.filter(location => location.place_id !== value)])
        setLocationUpdated((new Date()).getTime())
    }

    function handleSelect(value, setValues) {

        setValues(previousLocations => [...previousLocations, value])
        setLocationUpdated((new Date()).getTime())
    }

    function handleChange() {

        const values = {
            location: locations || null,
            excludedLocation: excludedLocations || null,
            temperature: typeof temperature.condition === 'boolean' && temperature.value ? temperature : null,
            userTypes: typeof userTypes === 'object' ? userTypes : [],
            weather: typeof weather.condition === 'boolean' && weather.value ? weather : null,
        };

        setBehavior(prev => {
            return { ...prev, ...values }
        })
        setNeedUpdate((new Date()).getTime())
    }

    const handleWeatherChange = (setter, property, value) => {
        setter(previousValue => {
            return { ...previousValue, [property]: value }
        })
        setLocationUpdated((new Date()).getTime())
    }

    
    const handleUserChange = (values) => {
        console.log('values', values)
        setUserTypes(values)
        setLocationUpdated((new Date()).getTime())
    }

    return (
        behavior.id ?
            <>
                <Row gutter={[48]}>
                    <Col span={12}>
                        <Space direction="vertical">
                            <h4>Afficher ce message si l'utilisateur se situe ... </h4>
                            <GooglePlaceAutocomplete displayValues={false}
                                setValues={(value) => handleSelect(value, setLocations)}
                                values={locations}
                                setLocationUpdated={setLocationUpdated} />
                            <Space className="wrap">
                                {
                                    locations.map(location =>
                                        <Tag closable onClose={() => handleDeSelect(location.place_id, setLocations)}
                                            key={`tag_${location.place_id}`}>{location.name}</Tag>
                                    )
                                }
                            </Space>
                        </Space>
                    </Col>

                    <Col span={12}>
                        <Space direction="vertical">

                            <h4>Ne pas afficher ce message si l'utilisateur se situe ... </h4>

                            <GooglePlaceAutocomplete displayValues={false}
                                setValues={(value) => handleSelect(value, setExcludedLocations)}
                                values={excludedLocations}
                                setLocationUpdated={setLocationUpdated} />
                            <Space className="wrap">
                                {
                                    excludedLocations.map(location =>
                                        <Tag closable
                                            onClose={() => handleDeSelect(location.place_id, setExcludedLocations)}
                                            key={`tag_${location.place_id}`}>{location.name}</Tag>
                                    )
                                }
                            </Space>
                        </Space>
                    </Col>
                </Row>
                <Row gutter={[48]}>
                    <Col span={12}>
                        <Space direction="vertical">
                            <h4>Afficher selon la météo du weekend ... </h4>

                            <Space className="wrap">
                                SI
                                <Select
                                    style={{ width: 170 }}
                                    optionFilterProp="children"
                                    value={weather?.condition}
                                    allowClear
                                    onChange={(value => handleWeatherChange(setWeather, 'condition', value))}
                                >
                                    <Select.Option value={true}>Il y a </Select.Option>
                                    <Select.Option value={false}>Il n'y a pas</Select.Option>
                                </Select>
                                <Select
                                    style={{ width: 170 }}
                                    optionFilterProp="children"
                                    allowClear
                                    value={weather?.value}
                                    onChange={(value => handleWeatherChange(setWeather, 'value', value))}
                                >
                                    <Select.Option value="rain">de la pluie</Select.Option>
                                    <Select.Option value="sun">du beau temps</Select.Option>
                                    <Select.Option value="snow">de la neige</Select.Option>
                                </Select>
                            </Space>
                            <Space className="wrap">
                                SI la température est
                                <Select
                                    style={{ width: 170 }}
                                    optionFilterProp="children"
                                    value={temperature?.condition}
                                    allowClear
                                    onChange={(value => handleWeatherChange(setTemperature, 'condition', value))}
                                >
                                    <Select.Option value={true}>supérieure</Select.Option>
                                    <Select.Option value={false}>inférieure</Select.Option>
                                </Select>
                                à
                                <InputNumber value={temperature?.value}
                                    onChange={(value => handleWeatherChange(setTemperature, 'value', value))} />
                                °C
                            </Space>
                            <p>Emplacement actuel : {accountState.account.location ? accountState.account.location.name : <Link to={'/account'} >Configurer un emplacement sur votre compte</Link>}</p>
                        </Space>
                    </Col>
                    <Col span={12}>
                        <h4>Afficher uniquement au visiteur venant ... </h4>
                        <Checkbox.Group style={{ width: '100%' }} onChange={handleUserChange} value={userTypes}>
                            <Checkbox style={{display: 'block', marginLeft: 0}} value={'family'}>En famille</Checkbox>
                            <Checkbox style={{display: 'block', marginLeft: 0}} value={'couple'}>En couple</Checkbox>
                            <Checkbox style={{display: 'block', marginLeft: 0}} value={'friends'}>Entre amis</Checkbox>
                            <Checkbox style={{display: 'block', marginLeft: 0}} value={'professionnel'}>En séjour professionnel</Checkbox>
                        </Checkbox.Group>
                    </Col>
                </Row>
            </>
            :
            <div>
                Can't get infos
            </div>
    )
}