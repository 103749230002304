import React, {useState} from 'react'
import {Button, Input, Space} from "antd";
import {useEditorContext} from "../../../providers/EditorProvider";


export default function ButtonTab({setVisible}) {

    const [editorState, editorDispatch] = useEditorContext()
    const {addElement, selectElement} = editorDispatch

    const [btnText, setBtnText] = useState('')

    function addButton() {

        let text = btnText.trim()
        let id = Date.now()

        const element = {
            id: id,
            name: `${editorState.message.children.length} Bouton`,
            type: 'button',
            style: {
                width: `150px`,
                height: `50px`,
                position: 'absolute',
                top: '0',
                left: '0',
                fontSize: '16px',
                color: 'rgba(0,0,0,1)',
                backgroundColor: 'rgba(24,144,255)',
                borderColor: 'rgba(24,144,255)',
                borderStyle: 'solid',
                borderWidth: '1px',
                transform: 'translate(0, 0)',
                cursor: 'pointer'
            },
            hover: {},
            action: {
                type: 'close'
            },
            content: {[editorState.lng]: text},
            translate: [0, 0],
            rotate: 0,
        }
        selectElement(element)
        addElement(element)
        setVisible(false)
    }


    return (
        <div>
            <Space direction="vertical">
                <div> Texte</div>
                <Input placeholder="texte du bouton" value={btnText} onChange={(e) => setBtnText(e.target.value)}/>
                <Button type='primary' onClick={addButton}>Ajouter</Button>
            </Space>

        </div>
    )
}