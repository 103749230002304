import React, { useEffect, useRef, useState } from "react";
import { Form, InputNumber, Radio, Select } from "antd";
import Colors from "../../../Colors";
import { animationStyle } from "../../../../widget/helpers";
import PictureTab from "../PictureTab";

let defaultPosition = {
    horizontalPos: "left",
    horizontalOffset: 0,
    verticalPos: "top",
    verticalOffset: 0
}

export default function PositionPanel({ message, setMessage, setter }) {

    const [type, setType] = useState(message.type)
    const [modalBackground, setModalBackground] = useState(message.options.modalBackground)
    const [backgroundImage, setBackgroundImage] = useState(message.options.backgroundImage)
    const [saveTimeout, setSaveTimeout] = useState()
    const formRef = useRef()

    useEffect(() => {
        if (formRef.current && (modalBackground !== message.options.modalBackground || backgroundImage !== message.options.backgroundImage)) {
            let values = formRef.current.getFieldsValue()
            handleValueChange({ modalBackground: null }, values)
        }
    }, [modalBackground, backgroundImage])

    function updateStyle(allvalues) {

        let style = { ...message.style }
        // console.log('val', allvalues)
        // console.log('msg', message.style)

        delete style.top
        delete style.left
        delete style.right
        delete style.bottom

        style[allvalues.horizontalPos] = allvalues.horizontalOffset + '%'
        style[allvalues.verticalPos] = allvalues.verticalOffset + '%'

        return style
    }

    const handleValueChange = (value, allvalues) => {
console.log(allvalues)
        setType(allvalues.type)
        if (value.animation) {
            setter('playAnimation', value.animation)
        }
        // console.log(modalBackground)
        clearTimeout(saveTimeout)
        setSaveTimeout(setTimeout(() => {

            if (!allvalues.horizontalPos) {
                allvalues = { ...allvalues, ...defaultPosition }
            }
            let positions = defaultPosition

            positions.horizontalOffset = allvalues.horizontalOffset
            positions.horizontalPos = allvalues.horizontalPos
            positions.verticalPos = allvalues.verticalPos
            positions.verticalOffset = allvalues.verticalOffset

            let messageOpt = { ...message.options, ...positions, animate: allvalues.animate, animation: allvalues.animation, modalBackground: modalBackground, backgroundImage: backgroundImage }

            setMessage({ ...message, options: messageOpt, type: allvalues.type, style: updateStyle(allvalues) })

        }, 500))

    }

    const radioStyle = {
        display: 'block',
    };

    return (
        <Form
            ref={formRef}
            layout="vertical"
            name="positionForm"
            onValuesChange={handleValueChange}
            initialValues={{
                type: message.type,
                modalBackground: message.options.modalBackground,
                verticalPos: message.options?.verticalPos || 'top',
                horizontalPos: message.options?.horizontalPos || 'left',
                verticalOffset: message.options?.verticalOffset || 0,
                horizontalOffset: message.options?.horizontalOffset || 0,
                animation: message.options?.animation || 'slideLeft',
                animate: message.options?.animate || '',
            }}
        >
            <Form.Item name="type" label="Type">
                <Radio.Group>
                    <Radio style={radioStyle} value={"modal"}>Modal</Radio>
                    <Radio style={radioStyle} value={"notification"}>Flottant</Radio>
                </Radio.Group>
            </Form.Item>

            {
                type === 'modal' ?
                    <>
                        <Form.Item name="modalBackground" label="Couleur du fond">
                            <Colors currentColor={modalBackground}
                                setColor={setModalBackground} />
                        </Form.Item>
                        <Form.Item name="backgroundImage" label="Image de fond">
                            <>
                                {
                                    backgroundImage && <p style={{ cursor: 'pointer', textAlign: 'center', color: 'red' }} onClick={() => setBackgroundImage('')}>Supprimer l'image actuelle</p>
                                }
                                <PictureTab getLink={setBackgroundImage} />
                            </>
                        </Form.Item>
                    </>
                    :
                    <>
                        <Form.Item name="verticalPos" label="Position vertical" hidden={message.options?.size?.includes('vertical')}>
                            <Radio.Group>
                                <Radio style={radioStyle} value={'top'}>En haut de l'écran</Radio>
                                <Radio style={radioStyle} value={'bottom'}>En bas de l'écran</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name="verticalOffset" label="Ecart avec le bord" hidden={message.options?.size !== 'free'}>
                            <InputNumber min={0} max={50}
                                formatter={value => `${value}%`}
                                parser={value => value.replace('%', '')}
                            />
                        </Form.Item>

                        <Form.Item name="horizontalPos" label="Position horizontale" hidden={message.options?.size?.includes('horizontal')}>
                            <Radio.Group>
                                <Radio style={radioStyle} value={'left'}>A gauche de l'écran</Radio>
                                <Radio style={radioStyle} value={'right'}>A droite de l'écran</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name="horizontalOffset" label="Ecart avec le bord" hidden={message.options?.size !== 'free'}>
                            <InputNumber min={0} max={50}
                                formatter={value => `${value}%`}
                                parser={value => value.replace('%', '')}
                            />
                        </Form.Item>
                    </>
            }
            <Form.Item name="animation" label="Animation lors de l'apparition">
                <Select>
                    {
                        Object.keys(animationStyle).map(key =>
                            <Select.Option key={`animation_${key}`}
                                value={key}>{animationStyle[key].name}</Select.Option>
                        )
                    }
                </Select>
            </Form.Item>

            <Form.Item name="animate" label="Animation du message durant l'affichage">
                <Select>
                    <Select.Option key={`animate_none`}
                        value={''}>Aucune</Select.Option>
                    <Select.Option key={`animate_balancing`}
                        value={'clock'}>Pendule</Select.Option>
                    <Select.Option key={`animate_wave`}
                                   value={'wave'}>Vague</Select.Option>
                </Select>
            </Form.Item>

        </Form>

    )
}