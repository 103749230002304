import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import {Button, Form, Input, message, Modal, Switch} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import {useAccountContext} from "../../providers/AccountProvider";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {useAuthContext} from "../../providers/AuthProvider";
import {getBasicTemplate} from "../editor/utils";

export default function ModalMessageName({visible, setVisible, mode, data = {}, reload}) {

    const [authState] = useAuthContext();
    const {auth} = authState;

    const [apiDispatch] = useApiContext();
    const {apiPostEntity, apiUpdateEntity} = apiDispatch;
    const [accountState] = useAccountContext();
    const {account} = accountState;
    const history = useHistory();

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [modalDescription, setModalDescription] = useState({
        title: "Nom de votre message",
        name: {label: "Nom du message"}
    });

    useEffect(() => {
        if (mode === "rename")
            form.setFieldsValue({name: data.name});

        getTitleAndLabel();
    }, [mode, data])

    function getTitleAndLabel() {
        switch (mode) {
            case "new":
                setModalDescription({
                    title: "Nom de votre message",
                    name: {label: "Nom du message"}
                });
                break;
            case "duplicate":
                setModalDescription({
                    title: "Dupliquer votre message",
                    name: {label: "Nom du nouveau message"}
                });
                break;
            case "rename":
                setModalDescription({
                    title: "Renommer votre message",
                    name: {label: "Nouveau nom"}
                });
                break;
            default:
                setModalDescription({
                    title: "Nom de votre message",
                    name: {label: "Nom du message"}
                });
                break;
        }
    }

    function handleCancel() {
        setVisible(false);
        if (mode === 'new')
            history.push("/dashboard");
    }

    function handleOk() {
        form.validateFields()
            .then(values => {
                setLoading(true)
                form.resetFields();
                dispatcher(values)
            })
    }

    function dispatcher(values) {
        switch (mode) {
            case "new":
                saveNew(values);
                break;
            case "duplicate":
                saveDuplicate(values);
                break;
            case "rename":
                saveRename(values);
                break;
            default:
                console.log("error");
                break;
        }
    }

    async function saveRename(values) {
        const response = await apiUpdateEntity("messages", data.id, values)

        if (response["@type"] === "hydra:Error")
            return displayErr(response["hydra:description"]);

        setLoading(false);
        reload();
    }

    async function saveDuplicate(values) {
        const params = {
            messageId: data.id,
            name: values.name,
            isTemplate: accountState.account.isAdminAccount ? true : values.isTemplate
        }

        const responsePost = await apiPostEntity("duplicate-message", params);

        if (responsePost["@type"] === "hydra:Error")
            return displayErr(responsePost["hydra:description"]);

        setLoading(false);
        reload();
    }

    function displayErr(description) {
        setLoading(false);
        message.error(description);
    }

    async function saveNew(values) {
        let message = getBasicTemplate(account['@id'])
        message.name = values.name

        const response = await apiPostEntity("messages", message);

        if (response["@type"] === "hydra:Error")
            return displayErr(response["hydra:description"]);

        setLoading(false);
        setVisible(false);
        history.push(`/editor/${response.id}`);
    }

    return (
        <Modal
            title={modalDescription.title}
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Retour
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                    Valider
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
            >
                <Form.Item
                    name="name"
                    label={modalDescription.name.label}
                    rules={[{required: true, message: 'Ce champs est obligatoire'}]}
                >
                    <Input placeholder="Ex: Message pour noël"/>
                </Form.Item>
                {
                    auth.roles.includes('ROLE_ADMIN') && mode === 'duplicate' ?
                        <Form.Item
                            name="isTemplate"
                            label={'Template'}
                            valuePropName="checked"
                            hidden={accountState.account.isAdminAccount}
                        >
                            <Switch
                                checkedChildren={<CheckOutlined/>}
                                unCheckedChildren={<CloseOutlined/>}
                            />
                        </Form.Item>
                        : null
                }

            </Form>
        </Modal>
    )
}