import React, {useState} from 'react'
import {Button, DatePicker, Form, Input, InputNumber, Modal} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import moment from "moment";

export default function ModalCategories({ isVisible, setIsVisible, category, setNeedUpdate}) {

    const formatInitialValues = (values) => {
        console.log(values)
        return {
            name: values.name ?? "",
            showAt: values.showAt ? moment(values.showAt) : null,
            importance: values.importance ?? null
        }
    }

    const [initValues, setInitValues] = useState(category ? formatInitialValues(category) : {})
    const [isNew, setIsNew] = useState(!category)



    console.log(initValues)
    const [apiDispatch] = useApiContext();
    const {apiPostEntity, apiUpdateEntity} = apiDispatch;

    console.log(isNew)

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    const onFinish = (values) => {
        console.log('Success:', values);
        isNew ? createCategory(values) : updateCategory(values)
    };

    const createCategory = async (values) => {
        let response = await apiPostEntity('template_categories', values)
        console.log(response)
        setNeedUpdate((new Date()).getTime())
        setIsVisible(false)
    }

    const  updateCategory = async (values) => {
        let response = await apiUpdateEntity('template_categories', category.id, values)
        console.log(response)
        setNeedUpdate((new Date()).getTime())
        setIsVisible(false)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Modal
            visible={isVisible}
            onCancel={() => setIsVisible(false)}
            title={isNew ? 'Créer une catégorie' : 'Editer la catégorie'}
            footer={[
                <Button key="back" onClick={() => setIsVisible(false)}>
                    Retour
                </Button>
            ]}>
            <Form
                name="categoryForm"
                initialValues={initValues}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'The name is required!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Show at"
                    name="showAt"
                >
                    <DatePicker />
                </Form.Item>

                <Form.Item label={"Importance"} name={"importance"}>
                    <InputNumber />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Ajouter
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )

}

