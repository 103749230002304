export const styles = {
    modal__message: '.modal__message{top: 50%;left: 50%;z-index: 8025;}'
}

export const animationStyle = {
    flipIn: {name: 'Flip in', code: 'perspective(400px) rotateY(90deg)'},
    flipDown: {name: 'Flip down', code: 'perspective(400px) rotateX(90deg)'},
    rotating: {name: 'Rotating', code: 'rotate(-180deg)'},
    slideLeft: {name: 'Slide from left', code: 'translateX(-100%)'},
    slideDown: {name: 'Slide from top', code: 'translateY(-100%)'},
    slideRight: {name: 'Slide from right', code: 'translateX(100%)'},
    slideUp: {name: 'Slide from bottom', code: 'translateY(100%)'},
    zoomIn: {name: 'Scaling up', code: 'scale(0)'},

}

export const jsToCss = (jsStyle) => {

    let pattern = /([A-Z])/g
    let style = '{'
    Object.keys(jsStyle).map(key => {
            const found = key.match(pattern)
            let start = key;
            if (found) {
                found.map(letter => {
                    let replacement = '-' + letter
                    start = start.replace(letter, replacement.toLowerCase())
                })
            }
            let rule = `${start}:${jsStyle[key]};`
            if (start === 'clip-path') {
                style += `-webkit-clip-path:${jsStyle[key]};`
            }
            style += rule
        }
    )

    return style + '}'
}

export const resolveAnimStyle = (animation, isModal, percent, debug) => {

    let baseStyle = `${isModal ? 'translateY(-50%) translateX(-50%)' : ''} scale(${percent})`
    let anim = animation || 'slideLeft'

    return ` transform: ${baseStyle} ${animationStyle[anim].code} ;opacity: 0; ${debug ? 'transition: 0s all' : ''}`

}

