import React, { createRef, useState } from "react";
import IconFinder from "../components/editor/IconFinder";
import Count from "../components/editor/Count";
import axios from "axios";
import Weather from "../components/editor/Weather";
import UserCustom from "../components/editor/UserCustom";

export default function Children({ elements, updateOnClick, handleCustomization, messageId, lng = 'fr', debug = false }) {

    let form = createRef()
    let submitButton = createRef()

    const [formData, updateFormData] = useState(getFormData(elements));
    const [isSubmitting, setIsSubmitting] = useState(false)

    function getFormData(elements) {
        let formElement = elements.find(elem => elem.type === 'form')

        if (!formElement) return null;
        let formBaseData = {}
        formElement.items.map(item => {
            if (item.type !== 'submit') {
                formBaseData[item.name] = { ...item, value: null }
            }
        })

        if (formElement.form && formElement.form.marketingIsActive && !debug) {
            formBaseData.marketingIsActive = { name: 'marketingIsActive', value: true };
            formBaseData.listName = { name: 'listName', value: formElement.form.listName };
            formBaseData.marketingTags = { name: 'marketingTags', value: formElement.form.marketingTags };
        }

        return formBaseData;
    }

    function handleFormChange(name, value) {
        let changedItem = { ...formData[name] }
        changedItem.value = value

        updateFormData(previousData => {
            return {
                ...previousData,
                [name]: changedItem
            }
        });
    }

    function handleClick(e, action) {
        e.stopPropagation()
        e.preventDefault()
        if (debug) return updateOnClick(messageId, 'action');

        if (!!form.current && action?.type === 'submit') {
            submitButton.current.click()
        } else if (action?.type === 'link' && action?.link) {
            let targetBlank = action?.blank ? true : false
            updateOnClick(messageId, 'action', action?.link, targetBlank)
        } else if (action?.type === 'close') {
            updateOnClick(messageId, 'close')
        }
    }

    function handleTextClick(e, id) {
        let elems = e?.nativeEvent?.path || []
        let isBeforeParent = true;
        elems.map(item => {

            if (item.id == id) isBeforeParent = false
            if (isBeforeParent && item.tagName === 'A') {
                updateOnClick(messageId, 'textLink')
            }
        })
    }

    function getFormValues() {
        let values = {}
        let hasValidValue = false
        Object.keys(formData).map(item => {
            let value = formData[item].value ? formData[item].value.trim() : null
            values[formData[item].name] = value
            if (value) hasValidValue = true
        })

        return hasValidValue ? values : false
    }

    async function handleUserCustom(e) {

        e.preventDefault()
        e.stopPropagation()

        if (debug) return;
        let data = {user: e.target.user.value, season: e.target.season.value}
        handleCustomization(messageId, "submit", data)

    }

    async function handleSubmit(e, actions, formId) {
        e.preventDefault()
        e.stopPropagation()

        if (debug || isSubmitting) return;

        setIsSubmitting(true)

        let data = getFormValues()

        if (data) {
            let response = await axios({
                method: 'post',
                url: process.env.API_ENTRYPOINT + "/submit-form",
                data: {
                    formId: formId || null,
                    messageId: messageId,
                    data: data
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });

            let formElem = elements.find(elem => elem.type === 'form')
            let endLink = ''
            if (formElem.action === 'link') {
                endLink = formElem.link
            }
            //next step / close / go to link
            updateOnClick(messageId, 'submit', endLink)
        }

        setIsSubmitting(false)
    }

    function displayElt(element) {
        switch (element.type) {
            case "button":
                return (
                    <a
                        key={element.id}
                        id={element.id}
                        className={`element_${element.id} action ${isSubmitting ? 'isSubmitting' : ''}`}
                        style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', textAlign: 'center' }}
                        onClick={(e) => handleClick(e, element?.action)}
                        type="button"
                    >
                        {element.content[lng] || element.content.fr}
                    </a>
                )
            case "text":
                return (
                    <div
                        key={element.id}
                        id={element.id}
                        className={`element_${element.id} text-element`}
                        onClick={(e) => handleTextClick(e, element.id)}
                        dangerouslySetInnerHTML={{ __html: element.content[lng] || element.content.fr }}
                    />
                )
            case "icon":
                return (
                    <div id={element.id} className={`element_${element.id}`} key={element.id}>
                        <svg width="100%" height="100%"
                            viewBox={"0 0 " + element.content.icon[0] + " " + element.content.icon[1]}>
                            <path d={element.content.icon[4]} fill="currentColor" />
                        </svg>
                    </div>
                )
            case "video":
                return (
                    <div id={element.id} className={`element_${element.id}`} style={element.style} key={element.id}>
                        <iframe
                            style={{
                                pointerEvents: "none",
                            }}
                            width="100%" height="100%"
                            src={element.url} frameBorder="0"
                        />
                    </div>
                )
            case "iconFinder":
                return (
                    <IconFinder element={element} className={`element_${element.id}`} key={element.id} />
                )
            case "countdown":
                return (
                    <Count element={element} className={`${element.id}`} key={element.id} lng={lng} />
                )
            case "weather":
                return (
                    <Weather id={element.id} key={element.id} lassName={`element_${element.id}`} element={element} style={element.style} lng={lng} />
                )
            case 'form':
                return (
                    <form ref={form}
                        className={`element_${element.id} user-form`} key={element.id}
                        onSubmit={(e) => handleSubmit(e, element.action, element?.form?.id)}>
                        <input type="submit" ref={submitButton} style={{ display: "none" }}
                            onClick={(e) => e.stopPropagation()} />
                        {
                            element.items.map((item, index) => {
                                let height = (parseFloat(element.style.height) - (10 * (element.items.length - 1))) / element.items.length
                                height = Math.max(height, 25)
                                return (
                                    <div key={item.name}
                                        style={{
                                            width: "100%",
                                            height: `${height}px`,
                                            marginBottom: element.items.length === index + 1 ? 0 : '10px',
                                            display: 'flex'
                                        }}>
                                        <input placeholder={item.placeholder[lng] || item.placeholder.fr}
                                            required={item.required}
                                            onChange={(e) => handleFormChange(item.name, e.target.value)}
                                            type={item.type}
                                            id={item.name === 'consent' ? element.id + 'consent' : item.name}
                                        />
                                        {
                                            item.type === 'checkbox' ?
                                                <label
                                                    htmlFor={element.id + 'consent'}>{item.placeholder[lng] || item.placeholder.fr}</label>
                                                : null

                                        }
                                    </div>
                                )
                            }
                            )
                        }
                    </form>
                )
            case 'request':
                return (
                    <form ref={form}
                        className={`element_${element.id} user-form`} key={element.id}
                        onSubmit={(e) => handleUserCustom(e, 'submit')}>
                        <input type="submit" ref={submitButton} style={{ display: "none" }}
                            onClick={(e) => e.stopPropagation()} />
                        <UserCustom element={element} style={element.style} lng={lng} />
                    </form>
                )
            case 'picture':
                let style = element.content[lng]?.style || element.content[Object.keys(element.content)[0]]?.style
                return (
                    <div
                        key={element.id}
                        id={element.id}
                        className={`element_${element.id}`}
                        style={{
                            ...style,
                            backgroundImage: style.backgroundImage.replace('size=sm', 'size=md'),
                            imageRendering: "crisp-edges",
                            imageRendering: "-moz-crisp-edges",          /* Firefox */
                            imageRendering: "-o-crisp-edges",            /* Opera */
                            imageRendering: "-webkit-optimize-contrast", /* Webkit (non-standard naming)*/
                            msInterpolationMode: "nearest-neighbor"   /* IE (non-standard property) */
                        }}
                    />
                )
            default:
                return (
                    <div
                        key={element.id}
                        id={element.id}
                        className={`element_${element.id}`}
                        style={{
                            backgroundImage: navigator.userAgent.includes('Mobile') ? element.style.backgroundImage : element.style.backgroundImage.replace('size=sm', 'size=md')
                        }}
                    >
                        {element.content ? element.content : ''}
                    </div>
                )
        }
    }

    return (
        <>
            {elements.map((element, index) =>
                displayElt(element, index === elements.length - 1)
            )}
        </>
    )

}