import React, {useState} from "react";
import {useApiContext} from "../providers/ApiProvider";
import {Select, Space, Spin} from "antd";


export default function GooglePlaceAutocomplete({values, setValues, setLocationUpdated = () => {}, displayValues = true, placeholder = "Rechercher une ville, une région, un pays..."}) {

    const [apiDispatch] = useApiContext();
    const {apiFetchCollection} = apiDispatch;

    const [fetching, setFetching] = useState(false)

    const [placeSearchTimeout, setPlaceSearchTimeout ] = useState(null)
    const [options, setOptions] = useState([])

    async function fetchLocation(value) {
        clearTimeout(placeSearchTimeout);

        setPlaceSearchTimeout(setTimeout(async () => {
            if (!value.trim()) return
            setFetching(true)
            let results = await apiFetchCollection('googlePlaceAutocomplete', {filters: [{name:'q',value:value.trim()}]})
            console.log(results)
            if (results.status === 'OK') {
                setOptions(results.predictions)
            }
            setFetching(false)

        },500 ))

    }

    async function handleChange(value) {
        console.log(value)

        let hasPlace = values.find(location => location.place_id === value.value)
        if (hasPlace) return

        let results = await apiFetchCollection('googlePlaceDetail', {filters: [{name:'place_id',value:value.value}]})
        console.log(results)
        if (results.status === 'OK') {
            setValues(results.result)
        }
        setOptions([])
    }



    return (
        <Select
            mode={"multiple"}
            labelInValue
            value={displayValues ? values : []}
            placeholder={placeholder}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={fetchLocation}
            onSelect={handleChange}
            style={{ width: '250px' }}
        >
            {options.map(d => (
                <Select.Option key={d.place_id} value={d.place_id}>{d.description}</Select.Option>
            ))}
        </Select>
    )


}