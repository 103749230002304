import React, {useRef, useState} from "react";
import {Button, Form, Input, message, Modal, Space, Spin} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

export function AccountForm({modal, onClose, item, visible = true}) {

    const [apiDispatch] = useApiContext();
    const {apiPostEntity, apiUpdateEntity, apiFetchCollection} = apiDispatch
    const [loading, setLoading] = useState(false)

    const formRef = useRef()

    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 12,
        },
    };

    const searchUsername = async (rule, value) => {
        console.log(value)
        if (!value || !value.trim()) return
        console.log(value)
        let params = {
            filters: [
                {
                    name: "username", value: value.trim()
                }
            ]
        }

        let response = await apiFetchCollection('users', params)
        let usernameExist = response['hydra:member'].find(user => user.username === value.trim())

        if (!!usernameExist) {
            return Promise.reject('Nom d\'utilisateur est déjà pris!')
        }
        return Promise.resolve()

    }


    async function onFinish(values) {
        setLoading(true)

        if (item) {
            await update(values)
        } else {
            await postEntity(values)
        }

        setLoading(false)
        onClose()
    }

    async function postEntity(values) {
        let response = await apiPostEntity('accounts', values)
        message.success('Compte crée avec succès')
        console.log(response)
        if (values.perms && values.perms.length > 0) {
            await handlePerms(response, values.perms)
        }
        console.log('done')
        formRef.current.resetFields()
    }

    async function update(values) {
        let params = {
            name: values.name.trim(),
            url: values.url.trim()
        }

        let response = await apiUpdateEntity('accounts', item.id, params)
    }

    const handlePerms = async (account, perms) => {

        await perms.map(async perm => {
            let data = {
                account  :  account['@id'],
                email    :  perm.email.trim(),
                username :  perm.username.trim(),
                firstName:  perm.firstName.trim(),
                lastName :  perm.lastName.trim(),
                isAdmin  :  true,
                sendMail :  true,
            }

            console.log(data)
            let response = await apiPostEntity('permissions', data)
        })

    }

    const handleSubmit = () => {
        if (formRef.current)
            formRef.current.submit()
    }


    const content = (
        <Spin spinning={loading}>
            <Form
                {...layout}
                name="basic"
                onFinish={onFinish}
                ref={formRef}
                initialValues={{
                    name: item ? item.name : '',
                    url: item ? item.url : ''
                }}
            >
                <Form.Item
                    label="Nom du compte"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Le nom est requis !',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Website url"
                    name="url"
                    rules={[
                        {
                            required: true,
                            message: 'Url is required!',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                {!item &&
                <>
                    <p>Permissions</p>
                    <Form.List name="perms" label="Permissions">
                        {(fields, {add, remove}) => (
                            <>
                                {fields.map((field, index) => (
                                    <div className="form-field" key={`field_${index}`}>
                                        <p>Nouvel utilisateur</p>

                                        <Space key={field.key} style={{display: 'flex', marginBottom: 8}}
                                               align="baseline">
                                            <Form.Item
                                                {...{
                                                    labelCol: {span: 0},
                                                    wrapperCol: {span: 24},
                                                }}
                                                name={[field.name, 'email']}
                                                fieldKey={[field.fieldKey, 'email']}
                                                rules={[
                                                    {required: true, message: 'Missing email'},
                                                    {type: 'email', message: 'The input is not valid E-mail!'}
                                                ]}
                                            >
                                                <Input placeholder="Email"/>
                                            </Form.Item>
                                            <Form.Item
                                                {...{
                                                    labelCol: {span: 0},
                                                    wrapperCol: {span: 24},
                                                }}
                                                validateTrigger={"onBlur"}
                                                name={[field.name, 'username']}
                                                fieldKey={[field.fieldKey, 'username']}
                                                rules={[
                                                    {required: true, message: 'Missing username'},
                                                    {validator: searchUsername}
                                                ]}
                                            >
                                                <Input placeholder="Username"/>
                                            </Form.Item>
                                        </Space>
                                        <Space style={{display: 'flex', marginBottom: 8}} align="baseline">
                                            <Form.Item
                                                {...{
                                                    labelCol: {span: 0},
                                                    wrapperCol: {span: 24},
                                                }}
                                                name={[field.name, 'lastName']}
                                                fieldKey={[field.fieldKey, 'lastName']}
                                                rules={[
                                                    {required: true, message: 'Nom requis'},
                                                ]}
                                            >
                                                <Input placeholder="Nom"/>
                                            </Form.Item>
                                            <Form.Item
                                                {...{
                                                    labelCol: {span: 0},
                                                    wrapperCol: {span: 24},
                                                }}
                                                name={[field.name, 'firstName']}
                                                fieldKey={[field.fieldKey, 'firstName']}
                                                rules={[
                                                    {required: true, message: 'Prénom requis'},
                                                ]}
                                            >
                                                <Input placeholder="Prénom"/>
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(field.name)}/>
                                        </Space>

                                    </div>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                        Ajouter des permissions
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </>
                }
            </Form>
        </Spin>
    )

    if (modal) {
        return (
            <Modal visible={visible}
                   destroyOnClose
                   title={item ? `Éditer ${item.name}` : "Créer un compte"}
                   onOk={handleSubmit}
                   okText={item ? "Modifier" : "Créer"}
                   onCancel={onClose}
                   cancelText="Retour"
            >
                {content}
            </Modal>
        )
    } else {
        return content;
    }
}