import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Button, Dropdown, Layout, Menu, message, Modal, PageHeader, Select, Space, Spin, Switch } from "antd";
import Board from "../components/editor/Board";
import { useAccountContext } from "../providers/AccountProvider";
import { useApiContext } from "../providers/ApiProvider";
import { useEditorContext } from "../providers/EditorProvider";
import Insert from "../components/editor/Insert";
import Icon, {
    CheckOutlined,
    CloseOutlined,
    ExclamationCircleOutlined,
    EyeInvisibleOutlined,
    EyeOutlined
} from "@ant-design/icons";
import Layers from "../components/editor/Layers";
import PositionOptions from "../components/editor/PositionOptions";
import MessageOptions from "../components/editor/MessageOptions";

import ModalBehavior from "../components/modal/ModalBehavior";
import ModalTesting from "../components/modal/ModalTesting";

import { ReactComponent as BehaviourIcon } from '../assets/icons/behaviour_icon.svg'
import { ReactComponent as RedoIcon } from '../assets/icons/redo_icon.svg'
import { ReactComponent as UndoIcon } from '../assets/icons/undo_icon.svg'
import { ReactComponent as DeleteIcon } from '../assets/icons/delete_icon.svg'
import Preview from "../components/editor/Preview";
import moment from "moment";
import ColorOptions from "../components/editor/ColorOptions";
import { injectFonts } from "../components/editor/utils";

export default function Editor() {

    const history = useHistory();
    const [accountState] = useAccountContext();
    const { account } = accountState;
    const [editorState, editorDispatch] = useEditorContext()
    const {
        setMessage, autoSave, resetEditor, setter, changeStep, setOptions, selectElement,
        undo, redo, refresh, unSelectElement, removeElement, duplicateElement, updateElement
    } = editorDispatch
    const [apiDispatch] = useApiContext();
    const { apiFetchEntity, apiPostEntity, apiUpdateEntity } = apiDispatch;
    const params = useParams();
    const [behaviorVisible, setBehaviorVisible] = useState(false)
    const [showPreview, setShowPreview] = useState(false)

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [messageName, setMessageName] = useState("");
    const [shortcutTimeout, setShortcutTimeout] = useState(null)
    const [isRequestHandler, setIsRequestHandler] = useState(null)

    useEffect(() => {
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [editorState])

    function listener(e) {
        clearTimeout(shortcutTimeout)

        setShortcutTimeout(setTimeout(async () => {
            if (editorState.editMode) return
            console.log(e.key)
            if (editorState.selectedElement && (e.key.toLowerCase() === 'delete' || e.key.toLowerCase() === 'backspace')) {
                removeElement(editorState.selectedElement.id)
            }
            if (editorState.selectedElement && (e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'v') {
                duplicateElement(editorState.selectedElement.id)
            }
            if ((e.ctrlKey || e.metaKey) && !e.shiftKey && e.key.toLowerCase() === 'z') {
                undo()
            }
            if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key.toLowerCase() === 'z') {
                redo()
            }
            if (editorState.selectedElement && e.key.toLowerCase() === 'escape') {
                unSelectElement()
            }

            if (editorState.selectedElement && (e.key.toLowerCase() === 'arrowright' || e.key.toLowerCase() === 'arrowleft')) {
                updateHPos(e.key.toLowerCase() === 'arrowright', e.shiftKey)
            }
            if (editorState.selectedElement && (e.key.toLowerCase() === 'arrowup' || e.key.toLowerCase() === 'arrowdown')) {
                updateVPos(e.key.toLowerCase() === 'arrowdown', e.shiftKey)
            }

            if (e.key.toLowerCase() === 'tab') {
                let elements = editorState.message.children
                console.log(elements)
                if (elements.length === 0) return;
                if (editorState.selectedElement) {
                    // select next one
                    let index = elements.findIndex(item => item.id === editorState.selectedElement.id)
                    index = index + 1 === elements.length ? 0 : ++index

                    selectElement(elements[index])

                } else {
                    // select first
                    selectElement(elements[0])
                }
            }

        }, 100))
    }

    function updateHPos(gotoRight, shiftKey) {
        let currentElement = { ...editorState.message.children.find(item => item.id === editorState.selectedElement.id) }
        let value = shiftKey ? 10 : 1
        let horizontalValue = gotoRight ? currentElement.translate[0] + value : currentElement.translate[0] - value

        currentElement.style = {
            ...currentElement.style,
            transform: `translate(${horizontalValue}px, ${currentElement.translate[1]}px) rotate(${currentElement.rotate}deg)`
        }
        currentElement.translate = [horizontalValue, currentElement.translate[1]]
        updateElement({ ...currentElement })
    }

    function updateVPos(gotoBot, shiftKey) {
        let currentElement = { ...editorState.message.children.find(item => item.id === editorState.selectedElement.id) }
        let value = shiftKey ? 10 : 1
        let verticalValue = gotoBot ? currentElement.translate[1] + value : currentElement.translate[1] - value

        currentElement.style = {
            ...currentElement.style,
            transform: `translate(${currentElement.translate[0]}px, ${verticalValue}px) rotate(${currentElement.rotate}deg)`
        }
        currentElement.translate = [currentElement.translate[0], verticalValue]
        updateElement({ ...currentElement })
    }


    useEffect(() => {
        if (params.id)
            fetch(params.id);

        return () => {
            resetEditor();
        }
    }, [params.id, editorState.needUpdate])

    useEffect(() => {
        if (!isLoading && editorState.message.id) {
            autoSave()
        }
    }, [editorState.message])

    async function fetch() {
        setIsLoading(true);

        const id = editorState.messageId || params.id;
        const response = await apiFetchEntity("messages", id);

        if (response["@type"] === "hydra:Error") {
            message.error("Je ne trouve pas votre message désolé");
            return setError(true)
        }

        injectFonts(response.draft.fonts)
        setter('fonts', response.draft.fonts)
        setIsRequestHandler(response?.isRequestHandler)
        if (response.testing) {
            setter("versionB", response.testing.id);
            setter("testIsActive", response.testIsActive);
        }
        if (response.draft.nextStep) {
            setter("nextStep", response.draft.nextStep);
        }

        if (response.testingParent) {
            setter("versionA", response.testingParent.id);
            setter("testIsActive", response.testingParent.testIsActive);
        }

        setter("messageId", response.id);
        setter("needSaveDraft", response.needSaveDraft);
        setter("lng", "fr");
        setMessage(response.draft, false);
        setter("playAnimation", response.draft.options.animation);
        setMessageName(response.name)


        setIsLoading(false);

    }

    const [loadingCancel, setLoadingCancel] = useState(false);

    async function cancelDraft() {
        setLoadingCancel(true);
        setShowPreview(false)

        const data = {
            messageId: editorState.messageId
        }

        const response = await apiPostEntity("cancel-draft", data);

        if (!response.success)
            message.error("Oups, une petite erreur, réessayez plus tard (");

        refresh();
        setLoadingCancel(false);
    }

    const [abLoading, setAbLoading] = useState(false);


    async function createAB() {
        setAbLoading(true);
        const response = await apiPostEntity("clone-testing", { messageId: editorState.messageId });

        setter("messageId", response.id);
        setAbLoading(false);
        refresh();
    }

    function changeVersion(version) {
        switch (version) {
            case "a":
                setter("messageId", editorState.versionA);
                break;
            case "b":
                setter("messageId", editorState.versionB);
                break;
        }

        refresh();
    }

    function onChange(checked) {
        apiUpdateEntity("messages", params.id, { testIsActive: checked });
        setter("testIsActive", checked);
    }

    if (error) {
        return <Redirect to={{
            pathname: "/",
            state: { status: '404', subtitle: '' }
        }} />
    }

    // console.log(editorState)
    async function createStep() {

        let data = {
            parentIri: editorState.message.id
        }
        const response = await apiPostEntity("create-step", data);

        setter("nextStep", response.nextStep);
    }

    function updateOption(name, value) {
        let options = { ...editorState.message.options }
        setOptions({ ...options, [name]: value })
    }

    function togglePreview() {
        setShowPreview(prev => !prev)
    }

    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={changeStep}>
                Voir la {editorState.currentStep === 'nextStep' ? 'premiere étape ' : 'deuxième étape'}
            </Menu.Item>
            {
                editorState.currentStep === 'parent' ?
                    <Menu.SubMenu key="sub1" title="Ouvrir la deuxième étape">
                        <Menu.Item key="5" onClick={() => updateOption('stepTrigger', 'container')}>
                            {editorState.message?.options?.stepTrigger === 'container' && <CheckOutlined />} Au clic sur
                            le message
                        </Menu.Item>
                        <Menu.Item key="6" onClick={() => updateOption('stepTrigger', 'close')}>
                            {editorState.message?.options?.stepTrigger === 'close' && <CheckOutlined />} A la fermeture
                        </Menu.Item>
                        <Menu.Item key="7" onClick={() => updateOption('stepTrigger', 'action')}>
                            {editorState.message?.options?.stepTrigger === 'action' && <CheckOutlined />} Au clic du
                            bouton / soumission
                        </Menu.Item>
                    </Menu.SubMenu>
                    :
                    <Menu.SubMenu key="sub1" title="Action lors de la fermeture">
                        <Menu.Item key="5" onClick={() => updateOption('closeAction', 'openParent')}>
                            {editorState.message?.options?.closeAction === 'openParent' && <CheckOutlined />} Ouvrir la
                            première étape
                        </Menu.Item>
                        <Menu.Item key="6" onClick={() => updateOption('closeAction', null)}>
                            {editorState.message?.options?.closeAction === null && <CheckOutlined />} Ne rien faire
                        </Menu.Item>
                    </Menu.SubMenu>
            }
            <Menu.Item key="3" onClick={confirm}>
                Supprimer cette étape
            </Menu.Item>
        </Menu>
    );

    async function deleteStep() {
        let data = {
            id: editorState.message.id
        }
        const response = await apiPostEntity("delete-step", data);
        setter("nextStep", null);
        setter("currentStep", 'parent');
        refresh()

    }

    function confirm() {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Cette action supprimera l\'étape actuellement visible sur ce brouillon.',
            okText: "supprimer",
            cancelText: "annuler",
            onOk: deleteStep
        });
    }

    const [comparaisonVisible, setComparaisonVisible] = useState(false)
    const versionMenu = (
        <Menu>
            <Menu.Item key="1" onClick={() => changeVersion(editorState.versionA ? "a" : 'b')}>
                Voir la {editorState.versionA ? 'Version A' : 'Version B'}
            </Menu.Item>
            <Menu.Item onClick={() => onChange(!editorState.testIsActive)}>
                <Space>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={editorState.testIsActive}
                        onChange={onChange}
                        size="small"
                    />
                    {
                        editorState.testIsActive ? 'Désactiver' : 'Activer'
                    } l'ab test
                </Space>
            </Menu.Item>
            <Menu.Item key="3" onClick={() => setComparaisonVisible(true)}>
                Comparer les versions
            </Menu.Item>
        </Menu>
    );

    if (!account)
        return <Redirect to='/' />

    return (
        <Layout style={{ padding: '0 84px' }} onClick={unSelectElement}>
            <Spin spinning={isLoading}>
                <PageHeader
                    onBack={() => history.push('/dashboard')}
                    title={
                        <>
                            <h1>{messageName}</h1>
                            <p style={{ color: 'black', fontSize: '16px', position: 'absolute', top: '5px', fontWeight: 'initial', left: '35px' }}>Écran d'édition</p>
                        </>}
                    style={{ borderBottom: '2px solid #2494D1' }}
                    extra={[
                        <Button size="large" key={'delete-change'}
                            loading={loadingCancel}
                            onClick={cancelDraft}
                            icon={<Icon component={DeleteIcon} />}
                            danger>
                            Effacer toutes les modifications
                        </Button>
                    ]}
                />
                <div className="flex between" style={{ height: '50px', marginLeft: 140 }}>
                    <Space>
                        {
                            editorState.versionA || editorState.versionB ?
                                <>
                                    <Dropdown.Button onClick={() => changeVersion(editorState.versionA ? "a" : 'b')}
                                        overlay={versionMenu} placement="bottomRight">
                                        {editorState.versionA ? 'Version B' : 'Version A'}
                                    </Dropdown.Button>
                                    <ModalTesting messageId={params.id} size="default" visible={comparaisonVisible}
                                        setVisible={setComparaisonVisible} />
                                </>
                                : <Button onClick={createAB} loading={abLoading}>Créer un a/b test</Button>
                        }
                        {
                            editorState.nextStep ?
                                <Dropdown.Button onClick={changeStep} overlay={menu} placement="bottomRight">
                                    {editorState.currentStep === 'nextStep' ? 'Etape 2' : 'Etape 1'}
                                </Dropdown.Button>
                                : <Button onClick={createStep}>Créer une seconde étape</Button>
                        }
                    </Space>
                    <Space>
                        {
                            account.languages && account.languages.length > 1 &&
                            <Select value={editorState.lng} style={{ width: 70 }}
                                onChange={(value) => setter('lng', value)}>
                                {
                                    account.languages.map((accountLanguage, id) =>
                                        <Select.Option value={accountLanguage}
                                            key={accountLanguage}>{accountLanguage}</Select.Option>
                                    )
                                }
                            </Select>
                        }
                        <Button onClick={togglePreview} title={"Voir un aperçu"}>
                            {showPreview ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                        </Button>
                    </Space>
                </div>
                <div style={{ flex: '1 1 auto', height: '500px' }} className="flex between">
                    <div>
                        <div className="editor-sidebar">
                            <Space direction="vertical">
                                <Insert isRequestHandler={isRequestHandler} />
                                <Button className="action-btn" type="primary"
                                    onClick={() => setBehaviorVisible(true)}
                                    title="Comportement"
                                    icon={<BehaviourIcon height={40} width={40} />} />
                                <div className="secondary-actions">
                                    <Layers />
                                    <PositionOptions />
                                    <MessageOptions />
                                    <ColorOptions />
                                </div>
                            </Space>
                        </div>
                    </div>
                    <div style={{ width: 'Calc(100% - 140px)', flexDirection: 'column' }} className="flex">
                        <Board />
                        <div className="flex justify-center" style={{ padding: '24px 0', position: 'relative' }}>
                            {
                                editorState.lastSave &&
                                <div className="last-save">
                                    <span style={{ display: 'block' }}>*Brouillon enregistré
                                        à {moment(editorState.lastSave).format('HH:mm:ss')}</span>
                                    <span>Publier pour le voir sur votre site</span>
                                </div>
                            }
                            <Space>
                                <Button icon={<Icon component={UndoIcon} />}
                                    size="large"
                                    disabled={!editorState.undoLog.length}
                                    onClick={undo}>
                                    Revenir en arrière
                                </Button>
                                <Button icon={<Icon component={RedoIcon} style={{ transform: 'rotate(180deg)' }} />}
                                    size="large"
                                    disabled={!editorState.redoLog.length}
                                    onClick={redo}>
                                    Aller vers l'avant
                                </Button>
                            </Space>
                        </div>
                    </div>
                </div>

                {
                    showPreview &&
                    <Preview messageItem={editorState.message} setMessageId={setShowPreview} lng={editorState.lng} />
                }
                <ModalBehavior isVisible={behaviorVisible} setIsVisible={setBehaviorVisible} />
            </Spin>
        </Layout>
    )

}