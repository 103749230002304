import React, {useRef} from 'react'
import Colors from "../../Colors";
import {useEditorContext} from "../../../providers/EditorProvider";
import {Button, Popover, Space} from "antd";
import {SettingOutlined} from "@ant-design/icons";
import IconTab from "../tabs/IconTab";


export default function IconActions({element}) {

    const [editorState, editorDispatch] = useEditorContext()
    const {updateElement, setter} = editorDispatch
    const popRef = useRef()


    function handleChange(property, previousValue, newValue) {
        let style = {...element.style}
        let colors = element.colors;
        let content = element.content;

        if (property !== 'color' && colors && colors.length > 0) {
            content = content.replaceAll(previousValue, newValue)
            colors = colors.map(color => {
                return color === previousValue ? newValue : color
            })
            colors = Array.from(new Set([...colors]))
        }
        if (property === 'color') {
            style[property] = newValue;
        }

        updateElement({...element, style: style, colors: colors, content: content})

    }

    const dropMenu = () => {
        let colors = element.colors;

        return (
            <div className="insert">
                <div className="mb">
                    <p>Modifier les couleurs</p>
                    {
                        colors && colors.length > 0 ?
                            <Space wrap size={[6, 8]}>
                                {
                                    element.useCurrent ?
                                        <Colors currentColor={element.style.color} key={`${element.id}_color_fill`}
                                                setColor={(value) => handleChange('color', element.style.color, value)}/>
                                        : null
                                }
                                {
                                    colors.map((color, index) => (
                                        <Colors currentColor={color} key={`${element.id}_color_${index}`}
                                                setColor={(value) => handleChange('iconColor', color, value)}/>
                                    ))
                                }
                            </Space>
                            :
                            <Colors currentColor={element.style.color}
                                    setColor={(value) => handleChange('color', element.style.color, value)}/>
                    }
                </div>
                <IconTab setVisible={() => popRef.current.close()} target={element}/>
            </div>

        )
    }


    return (
        <Popover content={dropMenu} title={'Modifier l\'icone'} trigger='click' placement="left" ref={popRef} onVisibleChange={(val)=> setter('editMode', val)}>
            <Button icon={<SettingOutlined/>}/>
        </Popover>
    )
}