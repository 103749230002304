import React, {useEffect, useState} from 'react';
import {useEditorContext} from "../../../providers/EditorProvider";
import {getMessageColors} from "../utils";
import {Button, Popover, Space, Spin} from "antd";
import Colors from "../../Colors";


export default function ColorTab({isOpen}) {

    const [editorState, editorDispatch] = useEditorContext();
    const {setMessage} = editorDispatch;
    const [replacedColor, setReplacedColor] = useState('')
    const [destinationColor, setDestinationColor] = useState('')
    const [docColor, setDocColor] = useState([])
    const [loading, setLoading] = useState(false)
    const [needUpdate, setNeedUpdate] = useState(0)

    useEffect(() => {
        if (isOpen) {
            setLoading(true)
            setDocColor(getMessageColors(editorState))
            setLoading(false)
        }
    }, [isOpen, needUpdate])

    const content = (
        <div className="iconsGrid color">
            {
                docColor.map(color =>
                    <div className="transparent-bg radius-5 color-container" key={`brand_${color}`}>
                        <div className="colorSquare"
                             onClick={() => setReplacedColor(color)}
                             style={{backgroundColor: color}}/>
                    </div>
                )
            }
        </div>
    );

    const updateColor = async () => {
        setLoading(true)
        let msg = {...editorState.message}
        msg.children = await updateChildren([...msg.children])
        msg.style = updateStyleColor({...msg.style})
        msg.options = updateOptions({...msg.options})
        setMessage(msg)
        setNeedUpdate((new Date()).getTime())
        setLoading(false)
    }

    const updateOptions = (options) => {
        let closeBtn = {...options.closeButton}
        closeBtn.style = updateStyleColor({...closeBtn.style})
        return {...options, closeButton: closeBtn}
    }

    const updateChildren = async (children) => {
        let newChildren = []
        await children.map(child => {
            let updatedChild = {...child}
            updatedChild.style = updateStyleColor({...child.style})
            if (updatedChild.type === 'button') {
                updatedChild.hover = updateStyleColor({...child.hover})
            } else if (updatedChild.type === 'text') {
                let content = {...updatedChild.content}
                content[editorState.lng] = content[editorState.lng].replace(replacedColor, destinationColor)
                updatedChild.content = content;
                updatedChild.update = (new Date()).getTime()
            }
            newChildren.push(updatedChild)
        })
        return newChildren
    }

    const updateStyleColor = (style) => {
        if (style?.borderColor === replacedColor) style.borderColor = destinationColor
        if (style?.color === replacedColor) style.color = destinationColor
        if (style?.backgroundColor === replacedColor) style.backgroundColor = destinationColor

        return style;
    }


    return (
        <Spin spinning={loading}>
            <p style={{fontSize: '11px'}}>*Remplacer une couleur par une autre dans tout le message</p>
            <Space>
                <>
                    <p>De :</p>
                    <Popover content={content} title="Choisissez la couleur a remplacer">
                        <div className="transparent-bg radius-5 color-container" key={`replacedColor`}>
                            <div className="colorSquare"
                                 style={{backgroundColor: replacedColor}}/>
                        </div>
                    </Popover>
                </>
                <>
                    <p>à : </p>
                    <Colors currentColor={destinationColor}
                            setColor={color => setDestinationColor(color)}/>
                </>
                <Button onClick={updateColor} disabled={loading}>Modifier</Button>
            </Space>
        </Spin>
    )
}
