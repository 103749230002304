import React, { useState } from 'react';
import { Checkbox, Input, Space } from "antd";

export default function Filters({ filters, setFilters, setLoading, resetPage, isAdminAccount }) {

    const [searchValue, setSearchValue] = useState("");
    const [searchMessage, setSearchMessage] = useState(null);

    function onChange(e) {
        setSearchValue(e.target.value);
        setLoading(true);
        clearTimeout(searchMessage);
        setSearchMessage(setTimeout(() => {
            updateFilters('name', e.target.value);
        }, 200))
    }

    function updateFilters(key, value) {
        resetPage();
        const index = filters.findIndex(filter => filter.name === key);
        filters[index].value = value;
        setFilters([...filters]);
    }

    function onActiveChange(e) {
        setLoading(true);
        resetPage();
        console.log(e.target.checked)
        if (e.target.checked)
            setFilters([...filters, { name: 'status', value: 'active' }]);
        else
            setFilters(filters.filter(filter => filter.name !== 'status'));
    }

    function onCustomPopup(e) {
        setLoading(true);
        resetPage();
        console.log(e.target.checked)
        if (e.target.checked)
            setFilters([...filters, { name: 'isRequestHandler', value: true }]);
        else {
            let data = filters.filter(filter => filter.name !== 'status')

            setFilters([...data, { name: 'isRequestHandler', value: false }]);
        }
    }

    return (
        <div>
            <Space>
                <Input
                    value={searchValue}
                    style={{ width: 200 }}
                    onChange={onChange}
                    placeholder="Rechercher"
                >
                </Input>
                <Checkbox onChange={onActiveChange}>
                    Actif
                </Checkbox>
                {
                    isAdminAccount &&
                    <Checkbox onChange={onCustomPopup}>
                        custom popup
                    </Checkbox>
                }

            </Space>
        </div>
    )
}