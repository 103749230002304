import React from "react";
import {localiseDay} from "./utils";

export default function WeatherTemplate({scale, weather, lng, template }) {

    const card = (
        <div style={{
            display: 'flex',
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textTransform: 'capitalize',
            height: '200px',
            width: '140px',
            padding: '15px 10px',
            transform: `scale(${scale})`
        }}>
            <div style={{position: "relative"}}>
                <div style={{
                    fontSize: '16px',
                }}>{localiseDay(weather.current.dt, lng)}</div>
                <img alt="weather icon"
                     src={`https://openweathermap.org/img/wn/${weather.current.weather[0].icon}@2x.png`}/>
                <div style={{
                    fontWeight: 'bold',
                    fontSize: '16px',
                }}>{Math.round(weather.current.temp)}°C
                </div>
            </div>
            <div style={{fontSize: '14px'}}>{weather.current.weather[0].description}</div>
        </div>
    )


    const horizontal = (
        <div style={{
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            padding: '0 15px',
            height: '85px',
            width: '280px',
            transform: `scale(${scale})`
        }}>
            <div style={{
                fontSize: '15px',
                textTransform: 'capitalize',
            }}>{localiseDay(weather.current.dt, lng, {weekday: 'long', month: 'short', day: "2-digit"})}</div>
            <div>
                <img alt="weather icon" style={{width: '60%'}}
                     src={`https://openweathermap.org/img/wn/${weather.current.weather[0].icon}@2x.png`}/>
            </div>
            <div style={{
                fontWeight: 'bold',
                fontSize: '17px',
            }}>{Math.round(weather.current.temp)}°C</div>
        </div>
    )


    if (template.name === 'Vertical')
        return (card)


    return (
        horizontal
    )


}