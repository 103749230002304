import React, { useEffect, useState } from "react";

const userOptions = [
    { emoji: "👨‍👨‍👧‍👦", text: { fr: 'En famille', en: 'Family' }, id: 'family', color: '#E2A61A' },
    { emoji: "❤️", text: { fr: 'En couple', en: 'Couple' }, id: 'couple', color: '#FF4233' },
    { emoji: "👐🏻", text: { fr: 'Entre amis', en: 'With friends' }, id: 'friends', color: '#DAA27C' },
    { emoji: "💼", text: { fr: 'Séjour professionnel', en: 'Professional trip' }, id: 'professionnel', color: '#775542' },
]

const seasonsOption = [
    {
        emoji: "🌱☀️",
        text: {
            fr: <><span style={{ color: '#68A921' }}>Printemps</span>-<span style={{ color: '#F19A12' }}>été</span></>,
            en: <><span style={{ color: '#68A921' }}>Spring</span>-<span style={{ color: '#F19A12' }}>summer</span></>
        },
        id: 'printemps-été', color: "#F19A12"
    },
    {
        emoji: "🍂❄️",
        text: {
            fr: <><span style={{ color: '#A74A09' }}>Automne</span>-<span style={{ color: '#81BDE4' }}>hiver</span></>,
            en: <><span style={{ color: '#A74A09' }}>Fall</span>-<span style={{ color: '#81BDE4' }}>winter</span></>
        },
        id: 'automne-hiver', color: "#81BDE4"
    },
]

export default function UserCustom({ lng }) {

    const [selectedUseCases, setSelectedUseCases] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)

    const handleUseCase = (value) => {
        setSelectedUseCases(prev => prev === value ? null : value)
    }

    const handleTime = (value) => {
        setSelectedTime(prev => prev === value ? null : value)
    }

    return (
        <>
            <input type='hidden' value={selectedUseCases} name='user' />
            <input type='hidden' value={selectedTime} name='season' />
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                {
                    userOptions.map((item, index) =>
                        <div key={item.id}
                            onClick={() => handleUseCase(item.id)}
                            className={`radio-option ${index < 2 && 'mb'}`}
                            style={{
                                borderColor: selectedUseCases === item.id ? item.color : 'white',
                                color: item.color
                            }}>
                            <span className="emoji">{item.emoji}</span> {item.text[lng]}
                        </div>
                    )
                }
            </div>
            <hr style={{ margin: '1em 0' }} />
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                {
                    seasonsOption.map((item) =>
                        <div key={item.id}
                            onClick={() => handleTime(item.id)}
                            className={`radio-option`}
                            style={{
                                borderColor: selectedTime === item.id ? item.color : 'white',
                                color: item.color
                            }}>
                            <span className="emoji">{item.emoji}</span> {item.text[lng]}
                        </div>
                    )
                }
            </div>
        </>
    )

}