import React, {useState} from 'react';
import {Button, Form, Input, Select, Space} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

export default function PagePanel({behavior, setBehavior, setNeedUpdate}) {

    const [form] = Form.useForm();
    const [formChangeTimeout, setFormChangeTimeout] = useState(null)

    function handleChange(changedValues, allValues) {

        clearTimeout(formChangeTimeout)

        setFormChangeTimeout(setTimeout(() => {

            let values = {
                locationHref: []
            }

            allValues.pages.map(item => {
                let value = item.value.trim()
                if (value !== '') {
                    values.locationHref.push({...item, value: value})
                }
            })

            setBehavior(prev => {
                return {...prev, ...values}
            })
            setNeedUpdate((new Date()).getTime())
        }, 150))

    }

    return (
        behavior.id ?
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    pages: behavior.locationHref && behavior.locationHref.length > 0 ? behavior.locationHref : [{
                        mode: "contain",
                        value: ""
                    }],
                }}
                onValuesChange={handleChange}
                className="form-multiple"
            >
                <h4>Je souhaite afficher ce message si la page...</h4>
                <Form.List name="pages">
                    {(fields, {add, remove}) => (
                        <>
                            {fields.map(field => (
                                <Space key={field.key} style={{display: 'flex', marginBottom: 8}} align="baseline">
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'mode']}
                                        fieldKey={[field.fieldKey, 'mode']}
                                    >
                                        <Select
                                            style={{width: 170}}
                                            showSearch
                                            optionFilterProp="children"
                                        >
                                            <Select.Option value="contain">Contient</Select.Option>
                                            <Select.Option value="notContain">Ne contient pas</Select.Option>
                                            <Select.Option value="equal">Égale</Select.Option>
                                            <Select.Option value="notEqual">Pas égale</Select.Option>
                                            <Select.Option value="begin">Commence par</Select.Option>
                                            <Select.Option value="notBegin">Ne commence pas par</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'value']}
                                        fieldKey={[field.fieldKey, 'value']}
                                    >
                                        <Input placeholder="/catégorie"/>
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(field.name)}/>
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add({
                                    mode: "contain",
                                    value: ""
                                })} block icon={<PlusOutlined/>}
                                        style={{width: '250px'}}>
                                    Ajouter une url
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

            </Form>
            :
            <div>
                Can't get infos
            </div>
    )
}