import React, { useEffect, useRef, useState } from 'react'
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { fonts } from "./utils";
import { registerSpacing } from './Quill/Blots'

export default function QuillEditor({ content, updateContent, disable, setQuill, setFormat, format }) {

    const reactQuillRef = useRef()
    const [updateValueTimout, setUpdateValueTimout] = useState()
    const modules = { toolbar: false }
    const [blockInitialChange, setBlockInitialChange] = useState(true)

    useEffect(() => {

        if (reactQuillRef.current) {
            registerSpacing()
            let editor = reactQuillRef.current.getEditor()

            setQuill(editor)
            setFormat(editor.getFormat())
        }
    }, [reactQuillRef])


    const formats = [
        "header",
        "align",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "list",
        "indent",
        "link",
        "color",
        "letterspacing",
    ];

    // Add sizes to whitelist and register them
    const Size = Quill.import("attributors/style/size");
    Size.whitelist = null;
    Quill.register(Size, true);

    // Add fonts to whitelist and register them
    const Font = Quill.import("attributors/style/font");
    Font.whitelist = null;
    Quill.register(Font, true);

    // Add align to whitelist and register them
    const Align = Quill.import("attributors/style/align");
    Align.whitelist = ['left', 'center', 'right', 'justify'];
    Quill.register(Align, true);

    // Quill.register('modules/clipboard', PlainClipboard, true);
    // Quill.debug('info')

    function getContentFonts() {

        let regex = /font-family:(.+?);/gm
        let fonts = [];

        if (content) {
            let text = content.replaceAll('&quot;', '')

            let matches = text.matchAll(regex)

            for (let match of matches) {
                if (match[1]) {
                    fonts.push(match[1].trim())
                }
            }
        }
        return fonts
    }

    const handleChange = (html, delta, source) => {

        if (source === 'silent') return
        if (source === 'api' && blockInitialChange) {
            return setBlockInitialChange(false)
        }
        clearTimeout(updateValueTimout)
        setUpdateValueTimout(setTimeout(() => {
            if (reactQuillRef.current.getEditor().getSelection()) {
                setFormat(reactQuillRef.current.getEditor().getFormat())
                updateContent(html)
            }
        }, 350))
    }

    const handleSelection = (range) => {
        if (reactQuillRef.current && !!range) {
            let newFormat = reactQuillRef.current.getEditor().getFormat()
            if (format !== newFormat)
                setFormat(reactQuillRef.current.getEditor().getFormat())
        }
    }

    return (
        <ReactQuill onChange={handleChange}
            ref={reactQuillRef}
            readOnly={disable}
            defaultValue={content}
            onChangeSelection={disable ? null : handleSelection}
            modules={modules}
            formats={formats}
        />
    )
}