import React, {useEffect, useState} from 'react'
import {Button, PageHeader} from "antd";
import TimerList from "./TimerList";
import moment from "moment";
import TimerEdit from "./TImerEdit";
import Icon from "@ant-design/icons";
import {ReactComponent as DeleteIcon} from "../../assets/icons/delete_icon.svg";


const defaultTemplate =
    {
        id: 'new',
        textColor: "#FB0053",
        firstColor: "#FB0053",
        secondColor: "#090A16",
        width: 100,
        dividerWidth: 10,
        dividerContent: '<div style="width:10px; height:100%;"><div style="width:2px;height:100%;padding:30% 0;background-color:$$thirdColor$$"></div></div>',
        height: 105,
        containerCss: 'background-color: $$secondColor$$;border-radius:15px',
        content: "<div style=\"width: 100px;height: 105px;display:flex;flex-direction: column;align-items:center;justify-content:center;;color: $$firstColor$$;font-size: 35px;font-weight: 700;\">$$time$$<span style='display: block;margin-top: -2px;font-size: 15px;font-weight: 500;color:$$textColor$$'>$$text$$</span></div>"
    }

const defaultCount = {
    id: defaultTemplate.id,
    type: 'countdown',
    name: `Décompte`,
    date: moment().add(10, 'days'),
    items: {
        days: {fr: 'jours', en: 'days'},
        hours: {fr: 'heures', en: 'hours'},
        minutes: {fr: 'minutes', en: 'minutes'},
        seconds: {fr: 'secondes', en: 'seconds'},
    },
    style: {
        width: `430px`,
        height: `105px`,
    },
    textColor: defaultTemplate.textColor,
    firstColor: defaultTemplate.firstColor,
    secondColor: defaultTemplate.secondColor,
    template: {...defaultTemplate},
    count: 'date',
    translate: [0, 0],
    rotate: 0,
    ratio: true
}

export default function Timers() {

    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [timer, setTimer] = useState(null)

    useEffect(() => {
        if (selectedTemplate)
            setTimer({
                ...defaultCount,
                template: selectedTemplate,
                textColor: selectedTemplate.textColor,
                firstColor: selectedTemplate.firstColor,
                secondColor: selectedTemplate.secondColor,
            })

    }, [selectedTemplate])


    function cancelEdit () {
        setTimer(null)
        setSelectedTemplate(null)
    }


    return (
        <div>
            <PageHeader
                title="Timers"
                extra={[
                    timer &&
                    <Button key={'cancelEdit'}
                            onClick={cancelEdit}
                            danger>
                        Retour
                    </Button>
                    ,
                    !selectedTemplate &&
                    <Button key={'cancelEdit'}
                            onClick={() => setSelectedTemplate(defaultTemplate)}
                            >
                        Nouveau
                    </Button>
                ]}
            />


            {
                timer ?
                    <TimerEdit countDown={timer} template={selectedTemplate} cancelEdit={cancelEdit}/> :
                    <TimerList selectTemplate={setSelectedTemplate}/>
            }

        </div>
    )
}