import React, { useState } from 'react'
import { Button } from "antd";
import { useEditorContext } from "../../../providers/EditorProvider";


const defaultRequest = {
    type: 'request',
    name: `Requete`,
    items: {
        days: { fr: 'jours', en: 'days' },
        hours: { fr: 'heures', en: 'hours' },
        minutes: { fr: 'minutes', en: 'minutes' },
        seconds: { fr: 'secondes', en: 'seconds' },
    },
    style: {
        width: `300px`,
        height: `105px`,
        position: 'absolute',
        top: '0',
        left: '0',
        transform: 'translate(0, 0)',
    },
    translate: [0, 0],
    rotate: 0,
    ratio: true
}


export default function RequestTab() {

    const [editorState, editorDispatch] = useEditorContext()
    const { addElement, selectElement } = editorDispatch
    const [requestElement, setRequestElement] = useState(editorState.message.children.find(item => item.type === 'request') ?? defaultRequest)

    function add() {
        let id = Date.now()

        const element = {
            ...requestElement,
            id: id,
            name: `Requete`,
        }
        selectElement(element)
        addElement(element)
    }

    return (
        <div style={{ width: '100%', maxWidth: '300px', minWidth: '300px' }}>
            {
                requestElement?.id ? <p>Cet élément est présent dans le message</p> : <Button onClick={add}>Add</Button>
            }
        </div>
    )
}