import React from 'react'
import {Checkbox, Col, DatePicker, Form, Row, TimePicker} from "antd";
import moment from "moment";

export default function TemporalPanel({behavior, setBehavior, setNeedUpdate}) {

    const hourFormat = 'HH'
    const dateFormat = 'DD/MM/YYYY';


    function handleChange(changedValues, allValues) {
        const values = {
            minHour: allValues.hours[0] ? allValues.hours[0].format(hourFormat) : null,
            maxHour: allValues.hours[1] ? allValues.hours[1].format(hourFormat) : null,
            startedAt: allValues.dates ? allValues.dates[0] : null,
            finishedAt: allValues.dates ? allValues.dates[1] : null,
            days: allValues.days
        };

        setBehavior(prev => {
            return {...prev, ...values}
        })
        setNeedUpdate((new Date()).getTime())
    }

    return (
        behavior.id ?
            <Form
                name="temporalForm"
                layout={"vertical"}
                initialValues={{
                    hours: [
                        behavior.minHour ? moment(behavior.minHour, hourFormat) : null,
                        behavior.maxHour ? moment(behavior.maxHour, hourFormat) : null
                    ],
                    days: behavior.days,
                    dates: [
                        behavior.startedAt ? moment(behavior.startedAt) : null,
                        behavior.finishedAt ? moment(behavior.finishedAt) : null
                    ]
                }}
                onValuesChange={handleChange}
            >
                <h4>Je veux que ce message s'affiche...</h4>

                <Form.Item label={<strong>... à un certain créneau horaire</strong>}
                           name="hours">
                    <TimePicker.RangePicker format={hourFormat} placeholder={['Début', 'Fin']} order={false}/>
                </Form.Item>

                <Form.Item label={<strong>... durant certains jours</strong>} name="days">
                    <Checkbox.Group style={{width: '100%'}}>
                        <Row>
                            <Col span={8}>
                                <Checkbox value={1}>Lundi</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={2}>Mardi</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={3}>Mercredi</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={4}>Jeudi</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={5}>Vendredi</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={6}>Samedi</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value={0}>Dimanche</Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                </Form.Item>

                <Form.Item label={<strong>... durant une période précise</strong>} name="dates">
                    <DatePicker.RangePicker
                        placeholder={["Début", "Fin"]}
                        format={dateFormat}
                    />
                </Form.Item>

            </Form>

            :
            <div>
                Missing Data
            </div>
    )
}