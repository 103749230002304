import React from "react";
import {Form, Input, message, Space} from "antd";

export default function PermissionForm({formRef, apiPostEntity, account, setIsAddingPermission}) {

    const handleFinish = async (values) => {
        console.log(values)
        let data = {
            account: account['@id'],
            email: values.email.trim(),
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
        }
        let response = await apiPostEntity('permissions', data)
        if (response["@type"] === "hydra:Error") {
            return message.error('La permission, n\'a pas pu être crée. Si le problème persiste contacter un administrateur')
        }

        message.success('Permission ajoutée avec succès')
        formRef.current.resetFields()
        setIsAddingPermission(false)
    }

    return (
        <Form ref={formRef} onFinish={handleFinish}>
            <p><strong>Donnez une permission à votre compte</strong></p>
            <Form.Item
                {...{
                    labelCol: {span: 0},
                    wrapperCol: {span: 18},
                }}
                name={'email'}
                rules={[
                    {required: true, message: 'Email requis'},
                    {type: 'email', message: 'The input is not valid E-mail!'}
                ]}
            >
                <Input placeholder="Email"/>
            </Form.Item>
            <Space style={{display: 'flex', marginBottom: 8}}
                   align="baseline">
                <Form.Item
                    {...{
                        labelCol: {span: 0},
                        wrapperCol: {span: 24},
                    }}
                    name={'lastName'}
                    rules={[
                        {required: true, message: 'Nom Requis'}
                    ]}
                >
                    <Input placeholder="Nom"/>
                </Form.Item>
                <Form.Item
                    {...{
                        labelCol: {span: 0},
                        wrapperCol: {span: 24},
                    }}
                    name={'firstName'}
                    rules={[
                        {required: true, message: 'Prénom Requis'}
                    ]}
                >
                    <Input placeholder="Prénom"/>
                </Form.Item>
            </Space>

        </Form>
    )
}