import moment from "moment";

// elements that cannot be duplicated (used for context menu)
export const uniqueElementList = ['form', 'countdown', 'weather']

export const fonts = [
    {name: "Andale Mono",            value: "Andale Mono, AndaleMono, monospace"},
    {name: "Arial",                  value: 'Arial,Helvetica Neue,Helvetica,sans-serif'},
    {name: "Arial Black",            value: "Arial Black,Arial Bold,Gadget,sans-serif"},
    {name: "Baskerville",            value: "Baskerville, Baskerville Old Face, Hoefler Text, Garamond, Times New Roman, serif"},
    {name: "Book Antiqua",           value: "Book Antiqua, Palatino, Palatino Linotype, Palatino LT STD, Georgia, serif"},
    {name: "Calibri",                value: "Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif"},
    {name: "Calisto MT",             value: "Calisto MT, Bookman Old Style, Bookman, Goudy Old Style, Garamond, Hoefler Text, Bitstream Charter, Georgia, serif"},
    {name: "Cambria",                value: "Cambria, Georgia, serif"},
    {name: "Candara",                value: "Candara, Calibri, Segoe, Segoe UI, Optima, Arial, sans-serif"},
    {name: "Century Gothic",         value: "Century Gothic, CenturyGothic, AppleGothic, sans-serif"},
    {name: "Courier New",            value: "Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace"},
    {name: "Didot",                  value: "Didot, Didot LT STD, Hoefler Text, Garamond, Times New Roman, serif"},
    {name: "Franklin Gothic Medium", value: "Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif"},
    {name: "Garamond",               value: "Garamond, Baskerville, Baskerville Old Face, Hoefler Text, Times New Roman, serif"},
    {name: "Georgia",                value: "Georgia, Times, Times New Roman, serif"},
    {name: "Gill Sans",              value: "Gill Sans, Gill Sans MT, Calibri, sans-serif"},
    {name: "Goudy Old Style",        value: "Goudy Old Style, Garamond, Big Caslon, Times New Roman, serif"},
    {name: "Helvetica",              value: "Helvetica Neue, Helvetica, Arial, sans-serif"},
    {name: "Impact",                 value: "Impact,Haettenschweiler,Franklin Gothic Bold,Charcoal,Helvetica Inserat,Bitstream Vera Sans Bold,Arial Black,sans serif"},
    {name: "Lucida Bright",          value: "Lucida Bright, Georgia, serif"},
    {name: "Open Sans",              value: "Open Sans, Droid Sans, Arial, sans-serif"},
    {name: "Palatino",               value: "Palatino, Palatino Linotype, Palatino LT STD, Book Antiqua, Georgia, serif"},
    {name: "Rockwell",               value: "Rockwell, Courier Bold, Courier, Georgia, Times, Times New Roman, serif"},
    {name: "Tahoma",                 value: "Tahoma, Verdana, Segoe, sans-serif"},
    {name: "Times New Roman",        value: "Times New Roman, TimesNewRoman, Times, Baskerville, Georgia, serif"},
    {name: "Trebuchet MS",           value: "Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif"},
    {name: "Verdana",                value: "Verdana, Geneva, sans-serif"}
]

export const fontSizes = ['10px', '12px', '14px', "16px", "18px", "20px", "22px", "24px", "26px", "28px", "30px", "32px", "34px", "36px", "38px", "40px", "42px", "44px", "46px", "48px", "50px", "52px", "54px", "56px", "58px", "60px"]

export const shadowArray = [
    "",
    "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)"
]


export const positionFrenchName = {
    left: 'gauche',
    right: 'droite',
    top: 'haut',
    bottom: 'bas'
}

export const borderStyles = [
    {name: 'Pas de bordure', value: 'none'},
    {name: 'Double', value: 'double'},
    {name: 'Pointillé', value: 'dotted'},
    {name: 'Simple', value: 'solid'},
    {name: 'Tiret', value: 'dashed'},
]

export const getBasicTemplate = (accountIri) => {

    return {
        style: {
            width: '250px',
            height: '600px',
            borderColor: 'rgba(36,148,209,1)',
            borderStyle: 'none',
            borderWidth: '0px',
            backgroundColor: 'rgba(255,255,255,1)',
            overflow: 'hidden',
            borderRadius: 0,
            position: 'fixed',
            bottom: '0',
            right: '0',
        },
        type: "modal",
        children: [],
        account: accountIri,
        isTemplate: true,
        options: {
            closable: true,
            closeButton: {
                id: 'closeBTN',
                position: 'left',
                style: {
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    color: 'rgba(36,148,209,1)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    width: '20px',
                    height: '20px',
                    padding: '0px',
                    borderRadius: '20px',
                    borderColor: 'rgba(36,148,209,1)',
                    borderWith: '1px',
                    borderStyle: 'solid',
                    display: 'flex',
                    transform: "translate(230px, 0px) rotate(0deg)"
                },
                content: {
                    icon: [352, 512, [], "f00d", "M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"],
                    iconName: "times",
                    prefix: "fas"
                },
                translate: [230, 0, 0],
                rotate: 0,
                ratio: true
            },
            modalBackground: "rgba(0, 0, 0, 0.65)",
            horizontalPos: "left",
            horizontalOffset: 0,
            verticalPos: "top",
            verticalOffset: 0,
            animation: 'slideLeft',
            size: 'free',
        }
    }
}

export const getMessageColors = (editorState) => {
    let colors = []

    editorState.message.children.map(child => {
        let childStyle = {...child.style}
        colors = [...colors, ...extractColorFromStyle(childStyle)]
        if (child.type === 'button') {
            colors = [...colors, ...extractColorFromStyle({...child.hover})]
        } else if (child.type === 'text') {
            let regex = /rgb(a)?.+?\)/gm
            let matches = child.content[editorState.lng] ? child.content[editorState.lng].match(regex) || [] : []
            colors = [...colors, ...matches]
        } else if (child.type === 'countdown') {
            colors = [...colors, ...extractCountdownColors(child)]
        }
    })
    colors = [...colors,
        ...extractColorFromStyle(editorState.message.style),
        ...extractColorFromStyle(editorState.message.options.closeButton.style)]

    return [...new Set(colors)]
}

function extractCountdownColors(countdown) {
    let colors = []
    if (countdown.textColor) colors.push(countdown.textColor)
    if (countdown.firstColor) colors.push(countdown.firstColor)
    if (countdown.secondColor) colors.push(countdown.secondColor)
    if (countdown.thirdColor) colors.push(countdown.thirdColor)

    return colors
}

function extractColorFromStyle(styleObject) {
    let colors = []
    styleObject?.color ? colors.push(styleObject.color) : null
    styleObject?.borderColor && parseFloat(styleObject?.borderWidth) > 0 ? colors.push(styleObject.borderColor) : null
    styleObject?.backgroundColor ? colors.push(styleObject.backgroundColor) : null
    return colors
}


export const updateTimerStyle = (type, timer, template) => {
    let nbElem = type === 'time' ? 2 : 4
    let ratio = parseFloat(timer.style.width) / (template.width * nbElem + template.dividerWidth * (nbElem - 1))
    let newHeight = template.height * ratio

    return {...timer.style, height: newHeight + 'px'}

}


export const getCount = (template) => {

    let timer = {
        id: template.id,
        type: 'countdown',
        name: `Décompte`,
        date: moment().add(10, 'days'),
        items: {
            days: {fr: 'jour', en: 'days'},
            hours: {fr: 'heures', en: 'hours'},
            minutes: {fr: 'minutes', en: 'minutes'},
            seconds: {fr: 'secondes', en: 'seconds'},
        },
        style: {
            width: `300px`,
            height: `105px`,
        },
        textColor: template.textColor,
        firstColor: template.firstColor,
        secondColor: template.secondColor,
        thirdColor: template.thirdColor,
        template: {...template},
        count: 'date',
        translate: [0, 0],
        rotate: 0,
        ratio: true
    }
    timer.style = updateTimerStyle('date', timer, template)

    return timer;
}

export const injectFonts = (fonts) => {
    if (fonts.length === 0) return

    let fontStr = fonts.map(font => {
        return `family=${font.trim().replace(' ', '+')}&`
    })
    let link = document.createElement("link")
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "//fonts.googleapis.com/css2?"+fontStr.join('')+"display=swap";
    document.head.appendChild(link)
}

export const localiseDay = (inputFormat, lng = 'fr', resultFormat = {weekday: 'long'}) => {
    var dAujourdhui = new Date(inputFormat * 1000);
    return dAujourdhui.toLocaleDateString(lng, resultFormat);
}