import React, {useEffect, useState} from "react";
import {Button, Modal, Skeleton, Space, Spin} from "antd";
import {ClockCircleOutlined, EyeOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {useApiContext} from "../../providers/ApiProvider";
import {useHistory} from "react-router-dom";
import {useEditorContext} from "../../providers/EditorProvider";
import {MessageBoxPreview} from "../MessageBoxPreview";

export default function ModalTesting({ messageId, visible = false, setVisible = () => {}, size = "small"}) {

    const [apiDispatch] = useApiContext();
    const {apiFetchEntity, apiPostEntity} = apiDispatch;
    const [editorState, editorDispatch] = useEditorContext()
    const {setter, refresh} = editorDispatch
    const history = useHistory();

    const [versionA, setVersionA] = useState(null)
    const [versionB, setVersionB] = useState(null)

    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState({});

    const skeleton = (
        <div className="flex between">
            <Space direction="vertical">
                <Skeleton.Image style={{width: 300, height: 300}}/>
                <Skeleton active/>
            </Space>
            <Space direction="vertical">
                <Skeleton.Image style={{width: 300, height: 300}}/>
                <Skeleton active/>
            </Space>
        </div>
    );

    useEffect(() => {
        if (visible)
            fetch();
    }, [visible])

    async function fetch() {
        let response = await apiFetchEntity('messages', messageId)
        console.log(response)
        handleMessageContent(response.testing.online, setVersionB)
        handleMessageContent(response.online, setVersionA)
        setMessage(response);
        setLoading(false);
    }

    function handleMessageContent(content, setContent) {
        let msg = {...content}
        let style = {...msg.style}
        style.position = 'relative'

        setContent({...msg, style: style})
    }

    const [validateLoading, setValidateLoading] = useState(false);

    async function validateVersion(versionId) {
        setValidateLoading(true);
        const response = await apiPostEntity("validate-version", {messageId: versionId});

        setter('messageId', null);

        if (messageId === response.id)
            refresh();
        else
            history.push('/editor/' + response.id);

        setValidateLoading(false);
        setVisible(false);
    }

    function genContent(message, version) {
        return (
            <Space direction="vertical" style={{width: 300}}>
                <div className="text-center">
                    <h4>Version {version}</h4>
                </div>

                <MessageBoxPreview messageToDisplay={version === 'A' ? versionA : versionB}/>

                <div>
                    <p className="flex between">
                        <span><EyeOutlined/> Nombre de vue</span><span>{message.stats.views}</span></p>
                    <p className="flex between">
                        <span><QuestionCircleOutlined/> Taux de clic</span><span>{message.stats.click.toFixed(0)}%</span>
                    </p>
                    <p className="flex between">
                        <span><ClockCircleOutlined/> Temps d'affichage moyen</span><span>{Math.floor(message.stats.timePerView)} s</span>
                    </p>
                    <p className="flex between">
                        <span><ClockCircleOutlined/> Temps d'affichage total</span><span>{message.stats.timeDisplayed} s</span>
                    </p>
                </div>

                <div className="text-center">
                    <Button
                        type="primary"
                        onClick={() => validateVersion(message.id)}>
                        Valider cette version
                    </Button>
                </div>
            </Space>
        )
    }

    return (
        <Modal
            width={800}
            title="Statistique A/B Testing"
            visible={visible}
            onCancel={() => setVisible(false)}
            onOk={() => setVisible(false)}
            footer={null}
        >
            <Spin spinning={validateLoading}>

                {loading ? skeleton :
                    <div className="flex around">
                        {genContent(message, 'A')}
                        {genContent(message.testing, 'B')}
                    </div>
                }
            </Spin>
        </Modal>
    )
}