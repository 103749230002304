import React, {useEffect, useState} from "react";
import {Button, Col, Input, InputNumber, Row, Space, message} from "antd";
import ColorInput from "../ColorInput";
import Count from "../editor/Count";
import {updateTimerStyle} from "../editor/utils";
import {useApiContext} from "../../providers/ApiProvider";


export default function TimerEdit({countDown, template, cancelEdit}) {

    const [apiDispatch] = useApiContext();
    const {apiUpdateEntity, apiPostEntity} = apiDispatch;
    const [text, setText] = useState(template.content)
    const [divider, setDivider] = useState(template.dividerContent)
    const [containerCss, setContainerCss] = useState(template.containerCss)
    const [height, setHeight] = useState(template.height)
    const [dividerWidth, setDividerWidth] = useState(template.dividerWidth)
    const [width, setWidth] = useState(template.width)
    const [firstColor, setFirstColor] = useState(template.firstColor)
    const [secondColor, setSecondColor] = useState(template.secondColor)
    const [thirdColor, setThirdColor] = useState('#13285e')
    const [textColor, setTextColor] = useState(template.textColor)
    const [element, setElement] = useState({...countDown})
    const [colors, setColors] = useState({})
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        showPreview()
        checkColor()
    }, [text, firstColor, secondColor, thirdColor, textColor, height, width, dividerWidth, divider, containerCss])

    function showPreview() {

        let temp = {
            ...element.template,
            height,
            dividerWidth,
            width,
            content: text,
            dividerContent: divider,
            containerCss
        }
        let newStyle = updateTimerStyle('date', element, temp)

        console.log(newStyle)
        setElement({
            ...element,
            firstColor: firstColor,
            textColor: textColor,
            secondColor: secondColor,
            thirdColor: thirdColor,
            template: temp,
            style: newStyle
        })
    }

    async function saveTimer() {
        console.log(colors)
        let currentTemplate = {
            containerCss: element.template.containerCss || '',
            content: element.template.content || null,
            dividerContent: element.template.dividerContent || '',
            dividerWidth: element.template.dividerWidth || 0,
            width: element.template.width || null,
            height: element.template.height || null,
            textColor: colors.textColor ? textColor : null,
            firstColor: colors.firstColor ? firstColor : null,
            secondColor: colors.secondColor ? secondColor : null,
            thirdColor: colors.thirdColor ? thirdColor : null,
        }

        setLoading(true)

        let response;
        if (template.id === 'new') {
            response = await apiPostEntity('timers', currentTemplate)
        } else {
            response = await apiUpdateEntity('timers', template.id, currentTemplate)
        }
        // console.log(response)

        if (response['@type'] === 'hydra:Error') {
            message.error('il y a eu un problème')
        } else {
            message.success('Décompte ajouté / modifié avec succès')
            cancelEdit()
        }
        setLoading(false)
    }


    function checkColor() {
        setColors({
            textColor: checkVariables('$$textColor$$'),
            firstColor: checkVariables('$$firstColor$$'),
            secondColor: checkVariables('$$secondColor$$'),
            thirdColor: checkVariables('$$thirdColor$$')
        })
    }

    function checkVariables(variable) {
        return element.template.content.includes(variable) || element.template.dividerContent.includes(variable) || element.template.containerCss.includes(variable);
    }


    return (
        <div>
            {
                template.id === 'testing' ?
                    <p>Ajouter un nouveau décompte</p>
                    :  <p>Modifier un décompte</p>
            }
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Space direction="vertical" style={{width: '100%'}}>
                        <h4>Code d'un élément</h4>
                        <Input.TextArea rows={10} onChange={(e) => setText(e.target.value)} value={text}/>
                        <Space>
                            Hauteur en px* : <InputNumber value={height} onChange={(value) => setHeight(value)}/>
                            Largeur en px* : <InputNumber value={width} onChange={(value) => setWidth(value)}/>
                        </Space>
                        <h4>Css div contenant le décompte</h4>
                        <Input.TextArea rows={10} onChange={(e) => setContainerCss(e.target.value)}
                                        value={containerCss}/>
                    </Space>
                </Col>
                <Col span={12}>
                    <Space direction="vertical" style={{width: '100%'}}>
                        <h4>Code d'un séparateur</h4>
                        <Input.TextArea rows={10} onChange={(e) => setDivider(e.target.value)} value={divider}/>
                        <Space>
                            Largeur en px* : <InputNumber value={dividerWidth}
                                                          onChange={(value) => setDividerWidth(value)}/>
                        </Space>

                        <h4>Couleurs</h4>

                        <Space>
                            {
                                colors.textColor &&
                                <ColorInput color={textColor} setColor={setTextColor}
                                            title={'Couleur du texte, remplace "$$textColor$$"'}/>

                            }
                            {
                                colors.firstColor &&
                                <ColorInput color={firstColor} setColor={setFirstColor}
                                            title={'Première couleur, remplace "$$firstColor$$"'}/>
                            }
                            {
                                colors.secondColor &&
                                <ColorInput color={secondColor} setColor={setSecondColor}
                                            title={'Deuxième couleur, remplace "$$secondColor$$"'}/>
                            }
                            {
                                colors.thirdColor &&
                                <ColorInput color={thirdColor} setColor={setThirdColor}
                                            title={'Troisième couleur, remplace "$$thirdColor$$"'}/>
                            }
                        </Space>
                        <h4>Preview</h4>
                        <div>
                            <Count style={{...element.style}} element={element} className={element.id}
                                   onContextMenu={() => {
                                   }}/>
                        </div>
                        <Button onClick={saveTimer} disabled={loading}>Enregistrer le timer</Button>

                    </Space>

                </Col>
            </Row>
        </div>

    )
}