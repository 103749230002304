import React, {useState} from 'react'
import { Form, Input, Select} from "antd";

export default function TemplateFilters({setActiveFilters}) {

    const [searchTimeout, setSearchTimeout] = useState()


    function handleChange(changedValues, allValues) {
        console.log(allValues)

        clearTimeout(searchTimeout)

        setSearchTimeout(setTimeout(()=> {
            let filters = [
                {
                    name:'name',
                    value: allValues.name ? allValues.name : ''
                },
            ]

            if (allValues.status) {
                filters.push(
                    {
                    name:'status',
                    value: allValues.status
                })
            }
            setActiveFilters(filters)
        }, 200))

    }

    function formatFilters(filters) {
        let values = {}
        filters.map(filter => {
            values[filter.name] = filter.value
        })
        return values
    }

    return (
            <Form
                name="normal_login"
                layout="inline"
                initialValues={{status:null}}
                onValuesChange={handleChange}
                key={'template_form'}
            >
                <Form.Item name={"name"} key={'template_name'}>
                    <Input placeholder="Recherche par nom"/>
                </Form.Item>
                <Form.Item name={"status"} key={'template_status'}>
                    <Select style={{width: 120}} allowClear placeholder="Statut">
                        <Select.Option value="active">Actif</Select.Option>
                        <Select.Option value="inactive">Inactif</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
    )
}