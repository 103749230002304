import React, {useState} from 'react'
import {Button, Layout, message, Space} from 'antd';
import {useAccountContext} from "../providers/AccountProvider";
import HeaderMenu from "./HeaderMenu";
import Icon from "@ant-design/icons";
import {useEditorContext} from "../providers/EditorProvider";
import {useApiContext} from "../providers/ApiProvider";
import {ReactComponent as PublishIcon} from '../assets/icons/publish_icon.svg'
import ModalComparaison from "./modal/ModalComparaison";

const {Header} = Layout;

export default function EditorLayout({children}) {

    const [accountState] = useAccountContext();
    const [apiDispatch] = useApiContext();
    const {apiPostEntity} = apiDispatch;
    const [editorState, editorDispatch] = useEditorContext()
    const {setter, refresh} = editorDispatch

    const [loading, setLoading] = useState(false)

    async function draftToOnline() {
        setLoading(true);
        setTimeout(async () => {
            let formData = new FormData()
            formData.append('messageId', editorState.messageId);

            const response = await apiPostEntity("draft-to-online", formData);

            if (!response.success)
                message.error("Oups, une petite erreur, réessayez plus tard (");
            else {
                const data = {
                    messageId: editorState.messageId
                }
                message.success("Brouillon enregistré avec succès");
                await apiPostEntity("cancel-draft", data);
                refresh();
            }

            apiPostEntity("take-screenshot", {messageId: editorState.messageId});

            setter("needSaveDraft", false);
            setLoading(false);

        }, editorState.waitingSave ? 3000 : 0)
    }

    async function cancelDraft() {
        setLoading(true);

        const data = {
            messageId: editorState.messageId
        }

        const response = await apiPostEntity("cancel-draft", data);

        if (!response.success)
            message.error("Oups, une petite erreur, réessayez plus tard (");

        refresh();
        setLoading(false);
    }


    return (
        <Layout className="mainLayout">
            <Header className="header">

                <HeaderMenu accountState={accountState}/>

                <Space>
                    <ModalComparaison size="large" messageId={editorState.messageId} cancelDraft={cancelDraft}
                                      draftToOnline={draftToOnline}/>

                    <Button size="large" icon={<Icon component={PublishIcon}/>} loading={loading}
                            onClick={draftToOnline}>Publier</Button>
                </Space>

            </Header>
            {children}
        </Layout>
    )

}