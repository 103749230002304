import React, { useEffect, useState } from "react";
import { Dropdown, Menu } from "antd";
import QuillEditor from "./QuillEditor";
import IconFinder from "./IconFinder";
import Count from "./Count";
import QuillToolbar from "./QuillToolbar";
import { useEditorContext } from "../../providers/EditorProvider";
import { useAccountContext } from "../../providers/AccountProvider";
import Weather from "./Weather";
import UserCustom from "./UserCustom";

export default function ElementContent({ element, isSelected, editMode, setEditMode, contextMenu }) {

    const [accountState] = useAccountContext();
    const { account } = accountState;
    const [editorState, editorDispatch] = useEditorContext()
    const { updateElement, setter, selectElement } = editorDispatch;
    const [quill, setQuill] = useState(null)
    const [format, setFormat] = useState(null)
    const defaultLng = account.languages[0];

    useEffect(() => {
        if (!isSelected) {
            setEditMode(false)
            setter('editMode', false)
        }
    }, [isSelected])


    useEffect(() => {
        if (editMode && quill) {
            quill.focus()
            setter('editMode', true)

        }

    }, [editMode])

    useEffect(() => {
        if (quill && element.type === "text") {
            let delta = quill.clipboard.convert(element.content[editorState.lng] || element.content[defaultLng]);
            quill.setContents(delta, 'silent');
        }
    }, [editorState.lng, element.update, editorState?.updated])

    const updateText = (content) => {
        updateElement({
            ...element,
            content: { ...element.content, [editorState.lng]: content }
        })
    }


    const menu = (
        <Menu>
            <Menu.Item key={`toolbar-${element.id}`}>
                <QuillToolbar quill={quill} format={format} editMode={editMode}
                    setEditMode={setEditMode} />
            </Menu.Item>
        </Menu>
    )
    const [hover, setHover] = useState(false)
    const toggleHover = (state) => {
        setHover(state)
    }

    function handleContextMenu(e, element) {
        if (!editorState.editMode) {
            e.preventDefault()
        }
        selectElement(element)

    }

    function displayElt() {
        switch (element.type) {
            case "button":
                return (
                    <Dropdown overlay={editorState.editMode || !editorState.selectedElement ? <></> : contextMenu}
                        trigger={editorState.editMode ? [] : ["contextMenu"]}>
                        <button id={element.id} className={isSelected ? "selected" : "targets"}
                            style={hover ? { ...element.style, ...element.hover } : { ...element.style }}
                            onContextMenu={(e) => handleContextMenu(e, element)}
                            onMouseLeave={() => toggleHover(false)}
                            onMouseEnter={() => toggleHover(true)}>
                            {element.content[editorState.lng] || element.content[defaultLng]}
                        </button>
                    </Dropdown>
                )
            case "text":
                return (
                    <Dropdown overlay={editorState.editMode || !editorState.selectedElement ? <></> : contextMenu}
                        trigger={editorState.editMode ? [] : ["contextMenu"]}>
                        <Dropdown visible={editMode} overlay={menu} placement='topCenter' destroyPopupOnHide>
                            <div
                                id={element.id}
                                className={`targets ${editMode ? 'editing' : ''}`}
                                style={element.style}
                                onContextMenu={(e) => handleContextMenu(e, element)}
                            >
                                <QuillEditor updateContent={updateText} content={element.content[editorState.lng]}
                                    disable={!editMode} setQuill={setQuill} setFormat={setFormat}
                                    format={format}
                                    accountFont={account.fonts || []} />
                            </div>
                        </Dropdown>
                    </Dropdown>
                )
            case "iconFinder":
                return (
                    <Dropdown overlay={editorState.editMode || !editorState.selectedElement ? <></> : contextMenu}
                        trigger={editorState.editMode ? [] : ["contextMenu"]}>
                        <IconFinder element={element} style={element.style}
                            onContextMenu={(e) => handleContextMenu(e, element)} />
                    </Dropdown>
                )
            case "countdown":
                return (
                    <Dropdown overlay={editorState.editMode || !editorState.selectedElement ? <></> : contextMenu}
                        trigger={editorState.editMode ? [] : ["contextMenu"]}>
                        <Count element={element} style={element.style} lng={editorState.lng}
                            onContextMenu={(e) => handleContextMenu(e, element)} />
                    </Dropdown>
                )
            case "video":
                return (
                    <Dropdown overlay={editorState.editMode || !editorState.selectedElement ? <></> : contextMenu}
                        trigger={editorState.editMode ? [] : ["contextMenu"]}>
                        <div id={element.id} className={"targets"} style={element.style}
                            onContextMenu={(e) => handleContextMenu(e, element)}>
                            <iframe
                                style={{
                                    pointerEvents: "none",
                                }}
                                width="100%" height="100%"
                                src={element.url} frameBorder="0"
                            />
                        </div>
                    </Dropdown>
                )
            case "weather":
                return (
                    <Dropdown overlay={editorState.editMode || !editorState.selectedElement ? <></> : contextMenu}
                        trigger={editorState.editMode ? [] : ["contextMenu"]}>
                        <Weather element={element} style={element.style} lng={editorState.lng}
                            onContextMenu={(e) => handleContextMenu(e, element)} />
                    </Dropdown>
                )
            case 'form':
                return (
                    <Dropdown overlay={editorState.editMode || !editorState.selectedElement ? <></> : contextMenu}
                        trigger={editorState.editMode ? [] : ["contextMenu"]}>
                        <div id={element.id} className={"targets user-form"} style={element.style}
                            onContextMenu={(e) => handleContextMenu(e, element)}>
                            {
                                element.items.map((item, index) => {
                                    let height = (parseFloat(element.style.height) - (10 * (element.items.length - 1))) / element.items.length
                                    height = Math.max(height, 25)

                                    return (
                                        <div key={item.name}
                                            style={{
                                                width: "100%",
                                                height: `${height}px`,
                                                marginBottom: element.items.length === index + 1 ? 0 : '10px',
                                                display: 'flex'
                                            }}>
                                            <input
                                                placeholder={item.placeholder[editorState.lng] || item.placeholder[defaultLng]}
                                                required={item.required}
                                                type={item.type} id={item.name}
                                            />
                                            {
                                                item.type === 'checkbox' ?
                                                    <label
                                                        htmlFor={item.name}>{item.placeholder[editorState.lng] || item.placeholder[defaultLng]}</label>
                                                    : null
                                            }
                                        </div>
                                    )
                                }
                                )
                            }
                        </div>
                    </Dropdown>
                )
            case 'picture':
                let style = element.content[editorState.lng]?.style || element.content[Object.keys(element.content)[0]]?.style
                return (
                    <Dropdown overlay={editorState.editMode || !editorState.selectedElement ? <></> : contextMenu}
                        trigger={editorState.editMode ? [] : ["contextMenu"]}>
                        <div
                            id={element.id}
                            className={"targets"}
                            onContextMenu={(e) => handleContextMenu(e, element)}
                            style={{
                                ...style,
                                backgroundImage: navigator.userAgent.includes('Mobile') ? style.backgroundImage : style.backgroundImage.replace('size=sm', 'size=md'),
                                imageRendering: "crisp-edges",
                                imageRendering: "-moz-crisp-edges",          /* Firefox */
                                imageRendering: "-o-crisp-edges",            /* Opera */
                                imageRendering: "-webkit-optimize-contrast", /* Webkit (non-standard naming)*/
                                msInterpolationMode: "nearest-neighbor"   /* IE (non-standard property) */
                            }}
                        />
                    </Dropdown>
                )
            case 'request':
                return (
                    <Dropdown overlay={editorState.editMode || !editorState.selectedElement ? <></> : contextMenu}
                        trigger={editorState.editMode ? [] : ["contextMenu"]}>
                        <div
                            className={'element_' + element.id}
                            id={element.id}
                            onContextMenu={(e) => handleContextMenu(e, element)}
                            style={{ ...element.style }}>
                            <UserCustom element={element} style={element.style} lng={editorState.lng} />
                        </div>
                    </Dropdown>
                )
            default:
                return (
                    <Dropdown overlay={editorState.editMode || !editorState.selectedElement ? <></> : contextMenu}
                        trigger={editorState.editMode ? [] : ["contextMenu"]}>
                        <div
                            id={element.id}
                            className={"targets"}
                            onContextMenu={(e) => handleContextMenu(e, element)}
                            style={{
                                ...element.style,
                                backgroundImage: navigator.userAgent.includes('Mobile') ? element.style.backgroundImage : element.style.backgroundImage.replace('size=sm', 'size=md')
                            }}
                        >
                            {element.content ? element.content : ''}
                        </div>
                    </Dropdown>
                )
        }
    }

    return displayElt()

}