import React, {useEffect, useState} from 'react'
import {Input, Select, Space, Switch} from "antd";
import {useApiContext} from "../../../../providers/ApiProvider";

export default function FormListSelection({form, setForm, accountId}) {

    const [apiDispatch] = useApiContext();
    const {apiFetchCollection, apiFetchEntity} = apiDispatch;

    const [options, setOptions] = useState([])
    const [activeMailMarketing, setActiveMailMarketing] = useState("");

    useEffect(() => {
        fetchFormList()
    }, [])

    const fetchFormList = async () => {
        let response = await apiFetchCollection(`accounts/${accountId}/form_lists`, {})

        const accountInfos = await apiFetchEntity('get-all-account-infos', accountId)
        setActiveMailMarketing(accountInfos.emailMarketing.isActive ? accountInfos.emailMarketing.platform : "")

        setOptions(response['hydra:member'])
    }

    const handleSearch = async (val) => {
        let params = {
            filters: [
                {
                    name: 'name',
                    value: val.trim()
                }
            ]
        }
        let response = await apiFetchCollection(`accounts/${accountId}/form_lists`, params)
        setOptions(response['hydra:member'])
    }

    const handleSelectChange = (e) => {
        let list = options.find(item => item.id === e)
        setForm('form', {name: list.name, id: list.id})
    }

    const formatData = (dataArray) => {
        return dataArray.map(item => <Select.Option key={item.id}>{item.name}</Select.Option>)
    }

    function updateForm(key, value) {
        setForm('form', {
            ...form,
            [key]: value
        })
    }

    return (
        <Space direction="vertical">
            <div>
                <Select
                    showSearch
                    value={form?.id}
                    style={{width: 200}}
                    placeholder="Rechercher une campagne"
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={handleSearch}
                    onChange={handleSelectChange}
                    notFoundContent={null}
                >
                    {formatData(options)}
                </Select>
            </div>

            {activeMailMarketing ?
                <Space direction="vertical">
                    <div className="flex between align-center">
                        Plateforme d'email Marketing ({activeMailMarketing}) :
                        <Switch
                            checked={form?.marketingIsActive}
                            onChange={value => updateForm('marketingIsActive', value)}
                            size="small"
                        />
                    </div>

                    <div className="flex between align-center">
                        Liste
                        <Input
                            disabled={!form?.marketingIsActive}
                            style={{width: 250}}
                            placeholder="Nom de la liste"
                            value={form?.listName}
                            onChange={e => updateForm('listName', e.target.value)}
                        />
                    </div>

                    <div className="flex between align-center">
                        Tags
                        <Select
                            disabled={!form?.marketingIsActive}
                            mode="tags"
                            style={{width: 250}}
                            placeholder="tags mailchimp"
                            onChange={value => updateForm('marketingTags', value)}
                            value={form?.marketingTags}
                        >
                        </Select>
                    </div>
                </Space> : null}
        </Space>
    )
}