import React from 'react';
import {useEditorContext} from "../../../providers/EditorProvider";
import {useAccountContext} from "../../../providers/AccountProvider";

const defaultFonts = ['Georgia', "Times New Roman", 'Book Antiqua']

export default function TextTab({setVisible}) {

    const [editorState, editorDispatch] = useEditorContext();
    const {addElement, selectElement} = editorDispatch;
    const [accountState, accountDispatch] = useAccountContext();

    function addTxt(content) {
        let id = Date.now();

        let color = pickColor()

        const txt = {
            id: id,
            name: `${editorState.message.children.length} - texte`,
            type: "text",
            style: {
                position: 'absolute',
                top: '0',
                left: '0',
                whitespace: 'normal',
                transform: 'translate(0, 0)',
                width: "200px",
                color: color
            },
            translate: [0, 0],
            rotate: 0,
            content: {[editorState.lng]: content}
        }

        addElement(txt);
        selectElement(txt)
        setVisible(false)
    }

    const pickColor = () => {
        let bgColor = editorState.message.style.backgroundColor;

        if (bgColor.includes('rgba')) {
            bgColor = bgColor.replace(/rgba\(|\)/g, '').split(',')

            return (parseFloat(bgColor[0]) * 0.299 + parseFloat(bgColor[1]) * 0.587 + parseFloat(bgColor[2]) * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
        }
        return bgColor === '#000000'  ? '#fff' : '#000';
    }
    
    return (
        <div>
            <p style={{fontSize: '11px'}}>*Cliquez sur un texte pour l'ajouter</p>
            {
                accountState.account.fonts.length > 0 &&
                <>
                    <p><strong>Polices favorites</strong></p>
                    {
                        accountState.account.fonts.map(font =>
                            <div className="text-example"
                                 key={`font_${font}`}
                                 onClick={() => addTxt(`<span style='font-size: 20px;font-family: ${font}'>Bienvenue sur notre site</span>`)}>
                                <p style={{fontSize: '20px', fontFamily: font}}>Bienvenue sur notre site</p>
                                <p className="font">{font}</p>
                            </div>
                        )
                    }
                </>
            }
            <p><strong>Police d'exemple</strong></p>
            {
                defaultFonts.map(font =>
                    <div className="text-example" key={`example_${font}`}
                         onClick={() => addTxt(`<span style='font-size: 20px;font-family: ${font}'>Bienvenue sur notre site</span>`)}>
                        <p style={{fontSize: '20px', fontFamily: font}}>Bienvenue sur notre site</p>
                        <p className="font">{font}</p>
                    </div>
                )
            }
        </div>
    )
}
