import React, {useState} from 'react'
import {Link, Redirect} from "react-router-dom";
import {useAuthContext} from "../providers/AuthProvider";
import {Button, message, Modal, PageHeader, Space, Switch, Tooltip} from "antd";
import {useApiContext} from "../providers/ApiProvider";
import TemplateFilters from "../components/TemplateFilters";
import Icon, {CopyTwoTone, DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined, EyeTwoTone} from "@ant-design/icons";
import CollectionTable from "../components/CollectionTable";
import ModalMessageName from "../components/modal/ModalMessageName";
import Preview from "../components/editor/Preview";
import {ReactComponent as DeleteIcon} from "../assets/icons/delete_icon.svg";
import InputCategories from "../components/InputCategories";


export default function Templates() {


    const baseFilter = [{name: 'isTemplate', value: true}, {name: "exists[testingParent]", value: false}]
    const [apiDispatch] = useApiContext();
    const {apiUpdateEntity, apiDeleteEntity} = apiDispatch;
    const [authState, authDispatch] = useAuthContext();
    const {auth} = authState;
    const isSuper = auth.roles.includes('ROLE_ADMIN');
    const [filters, setFilters] = useState([{name: 'name', value: ''}])
    const [needUpdate, setNeedUpdate] = useState(0)

    const [visible, setVisible] = useState(false);
    const [record, setRecord] = useState({});
    const [mode, setMode] = useState("");
    const [messageID, setMessageID] = useState(null)
    const [selectedRows, setSelectedRows] = useState([])

    if (!isSuper) {
        return <Redirect to={{
            pathname: "/erreur",
            state: {status: '403', subtitle: 'vous n\'êtes pas autorisé à accéder à cette page'}
        }}/>
    }


    async function handleChange(e, id) {
        console.log(e)
        let response = await apiUpdateEntity('messages', id, {status: e ? 'active' : 'inactive'})
        console.log(response)
    }

    function showDeleteConfirm(selection) {

        Modal.confirm({
            title: 'Êtes-vous sur de vouloir supprimer ce template ?',
            icon: <ExclamationCircleOutlined/>,
            okText: 'Supprimer',
            okType: 'danger',
            cancelText: 'Annuler',
            onOk() {
                handleRemove(selection)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    async function handleRemove(selection) {

        await Promise.all(selection.map(async id => {
            const response = await apiDeleteEntity("messages", id);
        }))

        message.success("Elements supprimés avec succes")
        setSelectedRows([])
        setNeedUpdate(needUpdate + 1)
    }

    const columns = [
        {
            title: 'Image',
            dataIndex: "media",
            render: (media) => (
                <div style={{textAlign: 'center'}}>
                    <img style={{maxWidth: '200px', maxHeight: '150px', objectFit: 'contain'}}
                         src={media?.contentUrl ? media.contentUrl : 'no image'}/>
                </div>
            )
        },
        {
            title: 'Nom',
            dataIndex: "name",
            render: (name, record) => (
                <span>
                    <Link to={`/editor/${record.id}`}>{name}</Link>
                </span>)
        },
        {
            title: 'Tag',
            dataIndex: "templateCategories",
            render: (templateCategories, record) => <InputCategories messageId={record.id}/>
        },
        {
            title: 'Statut',
            dataIndex: "status",
            render: (status, record) => (
                <Switch size="small" onChange={val => handleChange(val, record.id)}
                        defaultChecked={status === 'active'}/>
            )
        },
        {
            title: 'Actions',
            dataIndex: "id",
            render: (id, record) => (
                <Space >
                    <Tooltip title="Voir">
                        <EyeTwoTone onClick={()=>setMessageID(record.online.id)}/>
                    </Tooltip>
                    <Tooltip title="renommer">
                        <EditTwoTone onClick={() => openModal(record, "rename")}/>
                    </Tooltip>
                    <Tooltip title="dupliquer">
                        <CopyTwoTone onClick={() => openModal(record, "duplicate")}/>
                    </Tooltip>
                    <Tooltip title="supprimer">
                        <Icon component={DeleteIcon} style={{color:"#E64D3D"}} onClick={() => showDeleteConfirm([id])}/>
                    </Tooltip>
                </Space>
            )
        }
    ]

    function openModal(record, mode) {
        setMode(mode)
        setRecord(record);
        setVisible(true);
    }

    function reload() {
        setMode("")
        setRecord({})
        setNeedUpdate(needUpdate + 1);
        setVisible(false)
    }

    return (
        <div>
            <PageHeader
                title="Templates"
                extra={[
                    <TemplateFilters setActiveFilters={setFilters} key={'template_component'}/>
                ]}
            />

            {/*<TemplateFilters activeFilters={filters} setActiveFilters={setFilters}/>*/}

            {selectedRows.length ?
                <div style={{marginBottom: 16}}>
                    <p>Actions sur une sélection ({`${selectedRows.length} élément(s))`}</p>
                    <p style={{marginLeft: 8}}>
                    </p>
                    <Space direction="vertical">
                        {/*<TagAutoComplete selectedMessage={selectedRows} callback={() => setNeedUpdate(needUpdate+1)}/>*/}
                        <Button icon={<DeleteTwoTone/>} onClick={() => showDeleteConfirm(selectedRows)}>Supprimer</Button>
                    </Space>
                </div>
                : ''}

            <CollectionTable columns={columns} filters={[...filters, ...baseFilter]}
                             endpoint={"messages"} reload={needUpdate}
                             selectedRowKeys={selectedRows} setSelectedRowKeys={setSelectedRows}
            />
            <ModalMessageName
                visible={visible}
                setVisible={setVisible}
                mode={mode}
                data={record}
                reload={reload}
            />
            {
                messageID ?
                    <Preview messageID={messageID} setMessageId={setMessageID} />
                    : null
            }

        </div>

    )


}