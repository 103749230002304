import React, {useEffect, useState} from "react";
import axios from "axios";
import WeatherTemplate from "./WeatherTemplate";

export default function Weather({element, style, onContextMenu, lng}) {

    const [weather, setWeather] = useState(null)
    const [scale, setScale] = useState('')

    useEffect(() => {
        // if (element.lat && element.long)
        getScale()
        fetchWeather();
    }, [element])


    const fetchWeather = async () => {
        let params = {
            q: element.location.name,
            lat: element.location.gps[0],
            lon: element.location.gps[1],
            lng: lng,
        }
        let response = await axios({
            method: 'post',
            url: process.env.API_ENTRYPOINT + "/weather",
            data: params,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        // console.log(response.data)
        setWeather(response.data)
    }

    function getScale() {

        let template = {...element.template}

        let elementwidth = parseFloat(element.style.width)
        // console.log(elementwidth, element.elementWidth)
        let scale = (elementwidth / template.width)
        setScale(scale)
    }


    return (
        <div
            className={'element_' + element.id}
            id={element.id}
            onContextMenu={onContextMenu}
            style={{
                ...style,
            }}
        >
            {
                weather ?
                        <WeatherTemplate weather={weather} lng={lng} scale={scale} template={element.template}/>
                    : <></>
            }

        </div>
    )

}