import React from 'react'
import Message from "../widget/Message";

export function MessageBoxPreview({messageToDisplay}) {


    return (
        <div style={{backgroundColor: "#eee", width: '300px', maxHeight: '400px', overflow: "hidden"}}>
            <div style={{
                backgroundColor: "#eee",
                width: '250px',
                height: '300px',
                position: "relative",
                margin: "auto"
            }}>
                <Message message={{...messageToDisplay, type: 'modal'}}
                         handleClick={() => {
                         }}
                         debug={true} maxWidth={250} maxHeight={300}/>
            </div>

        </div>
    )
}