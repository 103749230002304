import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom';
import {useApiContext} from "../providers/ApiProvider";
import {Button, Card, Input, PageHeader, Spin, Typography} from 'antd';
import {useAccountContext} from "../providers/AccountProvider";
import {useAuthContext} from "../providers/AuthProvider";
import {ReactComponent as DialogIcon} from '../assets/icons/dialog_icon.svg'

export default function Home() {

    const [authState] = useAuthContext();
    const {auth} = authState
    const [apiDispatch] = useApiContext();
    const {apiFetchCollection} = apiDispatch;
    const [accountState, accountDispatch] = useAccountContext();
    const {accountSet} = accountDispatch;
    const history = useHistory();

    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [filters, setFilters] = useState([{name: "name", value: ""}]);
    const [searchTimeout, setSearchTimeout] = useState()

    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(false)
    const itemsPerPage = 20;

    useEffect(() => {
        fetch();
    }, [filters])

    async function fetch() {
        const response = await apiFetchCollection("accounts", {itemsPerPage: itemsPerPage,filters: filters});

        setAccounts(response["hydra:member"]);
        setHasMore(itemsPerPage < response["hydra:totalItems"]);
        setLoading(false);
    }

    async function onLoadMore() {
        setLoadingMore(true)

        const newPage = page + 1;
        const params = {
            page: newPage,
            itemsPerPage: itemsPerPage,
            filters: filters
        }

        const response = await apiFetchCollection("accounts", params);

        setAccounts([...accounts, ...response["hydra:member"]]);
        setHasMore(newPage * itemsPerPage < response["hydra:totalItems"]);
        setPage(newPage);
        setLoadingMore(false);
    }

    function handleClick(account) {
        delete account.totalMessages;
        delete account.totalActives;

        accountSet(account);
        history.push('/dashboard');
    }

    function updateFilter(key, value) {
        var index = filters.findIndex(filter => filter.name === key);
        filters[index].value = value;

        setFilters([...filters]);
    }

    function search(e) {
        clearTimeout(searchTimeout)
        setSearchTimeout(setTimeout(() => {
            setLoading(true);
            console.log(e.target.value)
            updateFilter("name", e.target.value.trim());
        }, 200))

    }

    const loadMore = (
        hasMore ?
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 20,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button loading={loadingMore} onClick={onLoadMore}>Afficher plus</Button>
            </div> : null
    );

    return (
        <div>
            <p style={{color: 'black', fontSize: '20px', margin: '1em 0 -1em'}}>Bonjour {auth.username} !</p>
            <PageHeader
                title={<h1>Choix du compte</h1>}
                style={{borderBottom: '2px solid #2494D1'}}
            >
                <div className="flex between align-baseline">
                    <span>Quel compte souhaitez-vous administrer ?</span>
                    <Input
                        size={"large"}
                        allowClear
                        style={{width: 210}}
                        placeholder="Rechercher ..."
                        onChange={search}
                    />
                </div>
            </PageHeader>
            <Spin spinning={loading}>

                <div className="account-card-grid">
                    {
                        accounts.map(account =>
                            <Card key={account.id} className="card-style" style={{width: '300px'}}>
                                <div>
                                    <Typography.Title
                                        ellipsis level={3}
                                        style={{
                                            fontSize: '28px',
                                            fontWeight: 'bold',
                                            lineHeight: "45px",
                                            marginBottom: 0
                                        }}
                                    >
                                        {account.name}
                                    </Typography.Title>
                                    <a href={account.url}
                                       style={{color: 'black', textDecoration: 'underline'}}>{account.url}</a>
                                </div>
                                <div style={{marginTop: '32px'}}>
                                    <p>
                                        <DialogIcon width="1em" height="1em"/> {account.totalMessages}  {account.totalMessages > 1 ? 'messages crées' : 'message crée'}
                                    </p>
                                    <p>
                                        <DialogIcon width="1em" height="1em"/> {account.totalActives} {account.totalActives > 1 ? 'messages actifs' : 'message actif'}
                                    </p>
                                </div>
                                <Button
                                    type="primary"
                                    style={{width: '100%', marginTop: '48px'}}
                                    size="large"
                                    onClick={() => handleClick(account)}
                                >
                                    Accéder à ce compte
                                </Button>
                            </Card>)
                    }
                </div>
                {loadMore}
            </Spin>
        </div>
    )
}