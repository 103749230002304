import React from 'react';
import {Route} from 'react-router-dom';
import {useAuthContext} from '../providers/AuthProvider.js';
import EditorLayout from './EditorLayout.js';
import {EditorProvider} from "../providers/EditorProvider";

const EditorRoute = ({component: Component, ...rest}) => {

    const [authState, authDispatch] = useAuthContext();
    const {auth} = authState;
    const {authIsLogged, authLogout} = authDispatch

    const renderer = (sidebar, props) => {
        if (authIsLogged() && !auth.visitor) {
            return <EditorLayout {...props}><Component {...props} /></EditorLayout>
        }
        authLogout()
        window.location.href = '/login'
    }

    return (
        <EditorProvider>
            <Route {...rest} render={renderer.bind(null)}/>
        </EditorProvider>
    );
};

export default EditorRoute;
