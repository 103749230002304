import React, {useState} from 'react';
import {Redirect} from 'react-router-dom'

import {Button, Card, Form, Input, message} from 'antd';
import {useAuthContext} from "../providers/AuthProvider";

import ModalForgetPassword from "../components/modal/ModalForgetPassword";

import background from '../assets/bg-login.jpg'
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import logo from '../assets/logo.png'
import logoMmc from '../assets/logo-mmc-white.png'


const layout = {
    wrapperCol: {
        span: 24,
    },
};

export default function Login() {

    const [authState, authDispatch] = useAuthContext();
    const {authLogin, authIsLogged} = authDispatch;
    const [processing, setProcessing] = useState(false)

    const onFinish = (values) => {
        setProcessing(true)
        authLogin(values.email, values.password, response => {
            if (response.error) {
                message.error(response.error)

                setProcessing(false)
            }
        });
    };

    if (authIsLogged())
        return <Redirect to="/"/>

    return (
        <div
            style={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%), center / cover url(${background})`,
                justifyContent: "space-between",
                alignItems: "center",
                padding: '0 84px',
                height: '100%',
                display: "flex"
            }}
        >
            <div style={{width: '43%'}}>
                <img style={{height: '62px', marginBottom: '40px'}} src={logo} alt="logo hotel push marketing"/>
                <p className="log-description">
                    Personnalisez votre site web selon le profil de chaque visiteur et boostez ainsi ses performances !
                </p>
            </div>

            <Card className="card-style" style={{width: '38%', padding:'55px 2em 0', margin:0, maxWidth: '550px'}}>
                <div>
                    <h2 style={{textAlign: 'center'}}>Connexion</h2>
                </div>
                <div>
                    <Form
                        {...layout}
                        name="login"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        style={{
                            margin: '1em auto',
                            minWidth: "50%",
                            padding: "1em",
                        }}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input size="large" className="log-input" placeholder="Nom d'utilisateur ou email" prefix={<UserOutlined />} />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password className="log-input" size="large" placeholder="Votre mot de passe" prefix={<LockOutlined />} />

                        </Form.Item>
                        <Form.Item style={{textAlign: 'center', fontStyle:'italic'}}>
                                <ModalForgetPassword/>
                        </Form.Item>
                        <Form.Item style={{textAlign: 'center'}}>
                            <Button size="large" type="primary" htmlType="submit" loading={processing}>
                                Se connecter
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Card>

            <img src={logoMmc} alt="" className="mmc-logo"/>
        </div>
    );
};
