import React, {useEffect} from "react";
import {Redirect, Switch, useLocation} from 'react-router-dom';
import {useAccountContext} from "../providers/AccountProvider";

import PrivateRoute from "./PrivateRoute";

import Admin from '../pages/Admin'
import Home from "../pages/Home";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import PublicRoute from "./PublicRoute";
import AccountSettings from "../pages/AccountSettings";
import Dashboard from "../pages/Dashboard";
import Editor from "../pages/Editor";
import EditorRoute from "./EditorRoute";
import Profile from "../pages/Profile";
import Analytics from "../pages/Analytics";
import PageScreen from "../pages/PageScreen";
import Templates from "../pages/Templates";
import TemplateCategories from "../pages/TemplateCategories";
import CreateAccount from "../pages/CreateAccount";
import FormData from "../pages/FormData";
import NewMessage from "../pages/NewMessage";
import Error from '../pages/Error';
import NewPassword from "../pages/NewPassword";
import SignUp from "../pages/SignUp";

export default function Router() {

    const location = useLocation();
    const [accountState, accountDispatch] = useAccountContext();
    const {injectFonts, resetState} = accountDispatch

    useEffect(() => {
        if (location.pathname === "/")
            resetState();

    }, [location.pathname])

    useEffect(()=> {
        if (accountState.account?.fonts) {
            if (accountState.account.fonts.length) {
                injectFonts(accountState.account.fonts)
            }
        }
    },[accountState.account?.fonts])

    return (
        <Switch>
            <PublicRoute exact path="/new-password/:token" component={NewPassword}/>
            <PublicRoute path="/signup" component={SignUp}/>

            <PrivateRoute exact path="/erreur" component={Error}/>
            <EditorRoute exact path="/editor" component={Editor}/>
            <EditorRoute exact path="/editor/:id" component={Editor}/>

            <PrivateRoute exact path="/" component={Home}/>
            <PrivateRoute exact path="/admin" component={Admin}/>
            <PrivateRoute exact path="/account" component={AccountSettings}/>

            <PrivateRoute exact path="/dashboard" component={Dashboard}/>
            <PrivateRoute exact path="/analytics" component={Analytics}/>
            <PrivateRoute exact path="/formulaires" component={FormData}/>
            <PrivateRoute exact path="/new" component={NewMessage}/>

            {/*<PrivateRoute exact path="/test" component={TestPage}/>*/}
            <PrivateRoute exact path="/profile" component={Profile}/>

            <PrivateRoute exact path="/admin/categories" component={TemplateCategories}/>
            <PrivateRoute exact path="/admin/create-account" component={CreateAccount}/>
            <PrivateRoute exact path="/admin/templates" component={Templates}/>

            <PrivateRoute exact path="/logout" component={Logout}/>
            <PublicRoute exact path="/login" component={Login}/>
            <PublicRoute exact path="/screenshot/:id" component={PageScreen}/>

            <Redirect to="/" />

        </Switch>
    )
}
