import React, {useState} from 'react';
import {Button, Form, Input, Select, Space} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

export default function RefererPanel({behavior, setBehavior, setNeedUpdate}) {

    const [form] = Form.useForm();
    const [formChangeTimeout, setFormChangeTimeout] = useState(null)

    function handleChange(changedValues, allValues) {

        clearTimeout(formChangeTimeout)

        setFormChangeTimeout(setTimeout(() => {

            let values = {
                referers: []
            }

            allValues.referers.map(item => {
                let value = item.value.trim()
                if (value !== '') {
                    values.referers.push({...item, value: value})
                }
            })

            setBehavior(prev => {
                return {...prev, ...values}
            })
            setNeedUpdate((new Date()).getTime())
        }, 150))

    }

    return (
        behavior.id ?
        <Form
            form={form}
            layout="vertical"
            initialValues={{
                referers: behavior.referers && behavior.referers.length > 0 ? behavior.referers : [{
                    mode: "contain",
                    value: ""
                }],
            }}
            onValuesChange={handleChange}
            className="form-multiple"
        >
            <h4>Je souhaite afficher ce message si le site référent...</h4>

            <Form.List name="referers" noStyle>
                {(fields, {add, remove}) => (
                    <>
                        {fields.map(field => (
                            <Space key={'referer'+field.key} style={{display: 'flex'}} align="baseline">
                                <Form.Item
                                    {...field}
                                    name={[field.name, 'mode']}
                                    fieldKey={[field.fieldKey, 'mode']}
                                >
                                    <Select
                                        style={{width: 170}}
                                        showSearch
                                        optionFilterProp="children"
                                    >
                                        <Select.Option value="contain">Contient</Select.Option>
                                        <Select.Option value="notContain">Ne contient pas</Select.Option>
                                        <Select.Option value="equal">Égale</Select.Option>
                                        <Select.Option value="notEqual">Pas égale</Select.Option>
                                        <Select.Option value="begin">Commence par</Select.Option>
                                        <Select.Option value="notBegin">Ne commence pas par</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    {...field}
                                    name={[field.name, 'value']}
                                    fieldKey={[field.fieldKey, 'value']}
                                >
                                    <Input placeholder="/catégorie"/>
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(field.name)}/>
                            </Space>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add({
                                mode: "contain",
                                value: ""
                            })} block icon={<PlusOutlined/>}
                                    style={{width: '250px'}}>
                                Ajouter un site référent
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>

        </Form>
            :
            <div>
                Can't get infos
            </div>
    )
}