import React, { useState } from 'react'
import { Button, Dropdown, Menu } from 'antd';
import { Link } from "react-router-dom";
import { UserOutlined } from '@ant-design/icons';
import { useAuthContext } from "../providers/AuthProvider";
import { AccountForm } from "./Account/AccountForm";


export default function AvatarDropdown() {

    const [authState] = useAuthContext();
    const { auth } = authState;
    const isSuper = auth.roles.includes('ROLE_ADMIN');
    const [isCreateVisible, setIsCreateVisible] = useState(false)

    const close = () => {
        setIsCreateVisible(false)
    }

    const menu = (
        <Menu>
            <Menu.Item key="profile">
                <Link to="/profile">Mon profil</Link>
            </Menu.Item>
            <Menu.Divider />
            {
                isSuper ?
                    <>
                        <Menu.Item key="stat">
                            <Link to="/analytics">Statistiques</Link>
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item key="admin">
                            <Link to="/admin">Administration</Link>
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item key="categories">
                            <Link to="/admin?tab=tags">Catégories</Link>
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item key="createAccount">
                            <span onClick={() => setIsCreateVisible(true)}>Créer un compte</span>
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item key="templates">
                            <Link to="/admin?tab=templates">Templates</Link>
                        </Menu.Item>
                        <Menu.Divider />
                    </>
                    : null
            }
            <Menu.Item key="logout">
                <Link to="/logout">Déconnexion</Link>
            </Menu.Item>
        </Menu>
    )

    return (
        <>
            <Dropdown overlay={menu} trigger={['click']}>
                <Button size="large" icon={<UserOutlined />} ghost />
            </Dropdown>
            <AccountForm onClose={close} visible={isCreateVisible} modal />
        </>
    )

}