import React, {useEffect, useState} from 'react'
import {Button, message, Modal, PageHeader, Select, Space} from "antd";
import ModalForms from "../components/modal/ModalForms";
import CollectionTable from "../components/CollectionTable";
import {useAccountContext} from "../providers/AccountProvider";
import moment from "moment";
import {useApiContext} from "../providers/ApiProvider";
import {DeleteTwoTone, ExclamationCircleOutlined} from "@ant-design/icons";
import DropdownCSV from "../components/formdata/DropdownCSV";
import ModalFormList from "../components/modal/ModalFormList";

export default function FormData() {

    const [accountState] = useAccountContext();
    const {account} = accountState;

    const baseFilter = {name: 'formList.account.id', value: accountState.account.id}

    const [apiDispatch] = useApiContext();
    const {apiFetchSubResource, apiDeleteEntity} = apiDispatch;

    const [lists, setLists] = useState([])
    const [filters, setFilters] = useState([baseFilter])
    const [showModal, setShowModal] = useState(false)
    const [showListModal, setShowListModal] = useState(false)
    const [needUpdate, setNeedUpdate] = useState(0)

    useEffect(() => {
        fetchList()
    }, [needUpdate])

    const fetchList = async () => {
        let response = await apiFetchSubResource('accounts', {id: accountState.account.id}, 'form_lists')
        console.log(response)
        setLists(response['hydra:member'])
    }

    const columns = [
        {
            title: 'Nom',
            dataIndex: "name",
            render: (name) => (
                <span>
                    {name}
                </span>)
        },
        {
            title: 'Téléphone',
            dataIndex: "phone",
            render: (phone) => (
                <span>
                    {phone}
                </span>)
        },
        {
            title: 'Email',
            dataIndex: "email",
            render: (email) => (
                <span>
                    {email}
                </span>)
        },
        {
            title: 'Campagne / Date',
            dataIndex: "formList",
            render: (formList, record) => (
                <div>
                    <span>{formList.name}</span> <br/>
                    <small>
                        {moment(record.createdAt).format('HH:mm DD-MM-YYYY')}
                    </small>
                </div>
            )
        },
    ]

    function showDeleteConfirm(selection) {
        Modal.confirm({
            title: 'Êtes-vous sur de vouloir supprimer ces informations ?',
            icon: <ExclamationCircleOutlined/>,
            okText: 'Supprimer',
            okType: 'danger',
            cancelText: 'Annuler',
            onOk() {
                handleRemove(selection)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    async function handleRemove(selection) {
        await Promise.all(selection.map(async id => {
            const response = await apiDeleteEntity("form_datas", id);
            console.log('done')
        }))

        message.success("Elements supprimés avec succès")
        setSelectedRows([])
        setNeedUpdate(needUpdate + 1)
    }

    const handleChange = (val) => {
        if (val) {
            setFilters([baseFilter, {name: 'formList.id', value: val}])
        } else {
            setFilters([baseFilter])
        }
    }

    const [selectedRows, setSelectedRows] = useState([]);

    return (
        <div>
            <PageHeader
                title={<h1>Formulaires</h1>}
                style={{borderBottom: '2px solid #2494D1'}}
            >
                <div className="flex between align-baseline">

                    <Select defaultValue={null} style={{width: '200px'}} onChange={handleChange}>
                        <Select.Option key={'all'} value={null}>Tous</Select.Option>
                        {
                            lists.map(item =>
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            )
                        }
                    </Select>

                    <div>
                        <Space>
                            <Button key="3" type="primary" onClick={() => setShowListModal(true)}>Listes</Button>
                            <DropdownCSV lists={lists}/>
                            <Button key="3" type="primary" onClick={() => setShowModal(true)}>Créer</Button>
                        </Space>
                    </div>
                </div>
            </PageHeader>

            <div className="mt-2em">
                {selectedRows.length ?
                    <div style={{marginBottom: 16}}>
                        <p>Actions sur une sélection ({`${selectedRows.length} élément(s))`}</p>
                        <p style={{marginLeft: 8}}>
                        </p>
                        <Space direction="vertical">
                            <Button icon={<DeleteTwoTone/>}
                                    onClick={() => showDeleteConfirm(selectedRows)}>Supprimer</Button>
                        </Space>
                    </div>
                    : ''}

                <CollectionTable columns={columns} filters={filters}
                                 endpoint={`form_datas`} reload={needUpdate}
                                 selectedRowKeys={selectedRows} setSelectedRowKeys={setSelectedRows}
                />
            </div>
            {
                showModal &&
                <ModalForms setIsVisible={setShowModal} isVisible={showModal} accoundId={account["@id"]}
                            setNeedUpdate={setNeedUpdate}/>
            }
            {
                showListModal &&
                <ModalFormList setIsVisible={setShowListModal} isVisible={showListModal} accoundId={account.id}
                               setNeedUpdate={setNeedUpdate}/>
            }
        </div>
    )

}