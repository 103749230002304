import React, { useEffect, useRef, useState } from "react"
import { useEditorContext } from "../../../providers/EditorProvider";
import { Button, Checkbox, Input, Popover, Radio, Select, Slider, Space, Tabs } from "antd";
import { BoldOutlined, ItalicOutlined, SettingOutlined, UnderlineOutlined } from "@ant-design/icons";
import Colors from "../../Colors";
import { borderStyles, fontSizes, shadowArray } from "../utils";
import FontSelect from "../FontSelect";

export default function ButtonActions({ element }) {

    const [editorState, editorDispatch] = useEditorContext()
    const { updateElement, setter } = editorDispatch

    const [actionChangeTimeout, setActionChangeTimeout] = useState(null)
    const [radius, setRadius] = useState(parseFloat(element.style.borderRadius) || 0)
    const [linkValue, setLinkValue] = useState(element?.action?.link?.[editorState.lng])

    useEffect(() => {
        if (typeof element.action?.link === 'string') {
            let value = { fr: element.action.link }
            updateElement({ ...element, action: { ...element.action, link: value } })
            setLinkValue(value[editorState.lng])
        } else {
            setLinkValue(element.action?.link?.[editorState.lng])
        }
    }, [editorState.lng])

    function handleTextChange(e) {
        let elementContent = e.target.value
        let newContent = { ...element.content }
        newContent[editorState.lng] = elementContent
        updateElement({ ...element, content: newContent })
    }

    function handleActionType(e) {
        let action = element.action ?? {}
        updateElement({ ...element, action: { ...action, type: e.target.value } })
    }

    function handleActionTarget(e) {
        console.log(e)
        let action = element.action ?? {}
        updateElement({ ...element, action: { ...action, blank: e.target.checked } })
    }


    function handleLinkChange(e) {

        setLinkValue(e.target.value)
        clearTimeout(actionChangeTimeout)
        setActionChangeTimeout(setTimeout(() => {

            let action = element.action ?? {}
            let value = e.target.value
            let match = value.match(/^((http|https):\/\/)|(tel|mailto):/)
            if (!match) {
                value = 'https://' + value
            }

            updateElement({ ...element, action: { ...action, link: { ...element.action.link, [editorState?.lng]: value } } })
        }, 500))

    }

    function handleChange(property, value, suffix = '') {
        let style = { ...element.style }
        style[property] = value + suffix;

        updateElement({ ...element, style: style })
    }

    function handleHoverChange(property, value, suffix = '') {
        let hover = { ...element.hover } || {}
        hover[property] = value + suffix;
        // console.log(value)
        // console.log('rgb ?')
        updateElement({ ...element, hover: hover })
    }

    function formatBorderWidth(e) {
        let numbers = e.target.value.trim().match(/\d+/);
        numbers = numbers ? Math.min(10, numbers[0]) : 0;

        return numbers
    }

    const popRef = useRef()

    const dropMenu = () => {
        return (
            <div style={{ width: '300px', minHeight: '300px' }}>
                <Tabs defaultActiveKey="1" size="small" tabBarGutter={5}>
                    <Tabs.TabPane tab="Visuel" key="style">
                        <>
                            <div>
                                <p>Bordure : </p>
                                <Space>
                                    <Colors currentColor={element.style.borderColor}
                                        setColor={(value) => handleChange('borderColor', value)} />
                                    <Select onChange={(value) => handleChange('borderStyle', value)}
                                        onClick={(e) => e.stopPropagation()}
                                        value={element.style.borderStyle || 'none'}
                                        style={{ width: '150px' }}>
                                        {borderStyles.map(item =>
                                            <Select.Option key={'border_' + item.value}
                                                value={item.value}>{item.name}</Select.Option>
                                        )}
                                    </Select>
                                    <Input onChange={(e) => handleChange('borderWidth', formatBorderWidth(e), 'px')}
                                        value={parseFloat(element.style.borderWidth) || 0}
                                        style={{ width: '60px' }}
                                        onClick={(e) => {
                                            console.log(e)
                                            e.stopPropagation()
                                        }} />
                                </Space>
                            </div>
                            <div className="mt">

                                <Space>
                                    <span>Ombre : </span>
                                    <Select placeholder={"shadow"}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={value => handleChange('boxShadow', value)}
                                        value={element.style.boxShadow}>
                                        {
                                            shadowArray.map((item, index) =>
                                                <Select.Option key={item}
                                                    onClick={(e) => e.stopPropagation()}
                                                    value={item}>shadow {index}</Select.Option>
                                            )
                                        }
                                    </Select>
                                </Space>
                            </div>
                            <div className="mt">

                                <Space>
                                    <span>Arrondi : </span>
                                    <Slider defaultValue={radius}
                                        marks={{ 0: 0, 25: 25, 50: 50 }}
                                        style={{ width: '150px' }}
                                        min={0} max={50}
                                        onAfterChange={(value) => handleChange('borderRadius', value, 'px')} />
                                </Space>
                            </div>
                            <div className="mt">
                                <Space>
                                    <span>Couleur du fond : </span>
                                    <Colors currentColor={element.style.backgroundColor}
                                        setColor={(value) => handleChange('backgroundColor', value)} />
                                </Space>
                            </div>
                        </>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Texte" key="text">
                        <>
                            <div className="flex space-between">
                                <Input value={element?.content[editorState.lng]}
                                    placeholder="Texte du bouton"
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={handleTextChange} style={{ marginRight: '8px', flexGrow: 1 }} />
                                <Colors currentColor={element.style.color}
                                    setColor={(value) => handleChange('color', value)} />
                            </div>
                            <div className="mt">
                                <Space>
                                    <FontSelect handleChange={(value) => handleChange('fontFamily', value)}
                                        font={element.style.fontFamily} />

                                    <Button icon={<BoldOutlined />}
                                        type={element.style.fontWeight === 'bold' ? 'primary' : ''}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleChange('fontWeight', element.style.fontWeight === 'bold' ? 'normal' : 'bold')
                                        }} />
                                    <Button icon={<ItalicOutlined />}
                                        type={element.style.fontStyle === 'fontStyle' ? 'primary' : ''}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleChange('fontStyle', element.style.fontStyle === 'italic' ? 'normal' : 'italic')
                                        }} />
                                    <Button icon={<UnderlineOutlined />}
                                        type={element.style.textDecoration === 'underline' ? 'primary' : ''}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleChange('textDecoration', element.style.textDecoration === 'underline' ? 'none' : 'underline')
                                        }} />
                                </Space>
                            </div>
                            <div className="mt mb-lg">
                                <p>Taille de la police</p>
                                <Slider tipFormatter={val => `${val}px`}
                                    style={{ width: '90%', margin: '0 auto' }}
                                    marks={{ 8: 8, 20: 20, 40: 40, 60: 60 }}
                                    defaultValue={parseFloat(element.style.fontSize)}
                                    min={8} max={60} step={1}
                                    onAfterChange={value => handleChange('fontSize', value + 'px')} />
                            </div>
                            <div className="mt mb-lg">
                                <p>Espacement des lettres</p>
                                <Slider tipFormatter={val => `${val}px`}
                                    style={{ width: '90%', margin: '0 auto' }}
                                    marks={{ '-2': -2, '-1': 1, 0: 0, 1: 1, 2: 2 }}
                                    defaultValue={parseFloat(element.style.letterSpacing)}
                                    min={-2} max={2} step={0.1}
                                    onAfterChange={value => handleChange('letterSpacing', value + 'px')} />
                            </div>
                        </>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Action" key="action">
                        <div>
                            <p>Action lors du clique sur le bouton</p>
                            <Radio.Group onChange={handleActionType} value={element?.action?.type}>
                                <Radio value={"close"}>Fermer le message</Radio>
                                <Radio value={"submit"}>Envoyer le formulaire</Radio>
                                <Radio value={"link"}>Envoyer sur un lien</Radio>
                            </Radio.Group>
                            {
                                element?.action?.type === 'link' &&
                                <div className="mt">
                                    <p>Lien</p>
                                    <Input placeholder="Lien vers la page de destination" value={linkValue}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={(e) => handleLinkChange(e)} />
                                    <Checkbox onChange={handleActionTarget} checked={element?.action?.blank}>Ouvrir dans un nouvel onglet</Checkbox>
                                </div>
                            }
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Survol" key="hover">
                        <>
                            <p>
                                Bordure :
                            </p>
                            <Space>
                                <Colors currentColor={element.hover.borderColor || element.style.borderColor}
                                    setColor={(value) => handleHoverChange('borderColor', value)} />
                                <Select onChange={(value) => handleHoverChange('borderStyle', value)}
                                    onClick={(e) => e.stopPropagation()}
                                    value={element.hover.borderStyle || element.style.borderStyle}
                                    style={{ width: '150px' }}>
                                    {borderStyles.map(item =>
                                        <Select.Option key={'border_' + item.value}
                                            value={item.value}>{item.name}</Select.Option>
                                    )}
                                </Select>
                                <Input onChange={(e) => handleHoverChange('borderWidth', formatBorderWidth(e), 'px')}
                                    value={parseFloat(element.hover.borderWidth) || element.style.borderWidth}
                                    style={{ width: '60px' }}
                                    onClick={(e) => {
                                        console.log(e)
                                        e.stopPropagation()
                                    }} />
                            </Space>
                            <div className="mt">
                                <Space>
                                    <span>Couleur du fond : </span>
                                    <Colors
                                        currentColor={element.hover.backgroundColor || element.style.backgroundColor}
                                        setColor={(value) => handleHoverChange('backgroundColor', value)} />
                                </Space>
                            </div>
                            <div className="mt">
                                <Space>
                                    <span>Ombre : </span>
                                    <Select placeholder={"shadow"}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={value => handleHoverChange('boxShadow', value)}
                                        value={element.hover.boxShadow || element.style.boxShadow}>
                                        {
                                            shadowArray.map((item, index) =>
                                                <Select.Option key={item}
                                                    onClick={(e) => e.stopPropagation()}
                                                    value={item}>shadow {index}</Select.Option>
                                            )
                                        }
                                    </Select>
                                </Space>
                            </div>
                            <div className="mt">
                                <Space>
                                    <span>Police : </span>
                                    <Select
                                        value={parseFloat(element.hover.fontSize) || parseFloat(element.style.fontSize)}
                                        style={{ width: '70px' }}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={(value) => handleHoverChange('fontSize', value)}>

                                        {
                                            fontSizes.map(size =>
                                                <Select.Option key={`fontsize-${size}`}
                                                    value={size}>{parseFloat(size)}</Select.Option>
                                            )
                                        }
                                    </Select>
                                    <Button icon={<BoldOutlined />}
                                        type={element.hover.fontWeight === 'bold' ? 'primary' : ''}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleHoverChange('fontWeight', element.hover.fontWeight === 'bold' ? 'normal' : 'bold')
                                        }} />
                                    <Button icon={<UnderlineOutlined />}
                                        type={element.hover.textDecoration === 'underline' ? 'primary' : ''}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleHoverChange('textDecoration', element.hover.textDecoration === 'underline' ? 'none' : 'underline')
                                        }} />
                                    <Colors currentColor={element.hover.color || element.style.color}
                                        setColor={(value) => handleHoverChange('color', value)} />
                                </Space>
                            </div>
                        </>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        )
    }

    return (
        <Popover content={dropMenu} trigger='click' placement="left" ref={popRef} onVisibleChange={(val) => setter('editMode', val)}>
            <Button icon={<SettingOutlined />} />
        </Popover>
    )


}