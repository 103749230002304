import React, {useEffect, useState} from 'react';
import {Col, InputNumber, Radio, Row, Slider} from 'antd';

export default function DisplayPanel({behavior, setBehavior, setNeedUpdate, editorState}) {

    const [closeDelayActive, setCloseDelayActive] = useState(undefined);
    const [booked, setBooked] = useState(undefined);

    useEffect(() => {
        if (Object.keys(behavior).length && closeDelayActive === undefined)
            setCloseDelayActive(behavior.closeDelay > 0);
    }, [behavior])

    useEffect(() => {
        if (Object.keys(behavior).length && booked === undefined)
            setBooked(('booked' in behavior) ? behavior.booked : 'all');
    }, [behavior])

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    function onRadioChange(e) {
        behavior.type = e.target.value;
        setBehavior({...behavior});
        setNeedUpdate((new Date()).getTime())
    }

    function onSecondChange(value) {
        behavior.delay = value
        setBehavior({...behavior});
        setNeedUpdate((new Date()).getTime())
    }

    function handleChange(value, property) {
        behavior[property] = value;
        setBehavior({...behavior});
        setNeedUpdate((new Date()).getTime());
    }

    function changeCloseDelay(e) {
        const delay = e.target.value ? 20 : 0;
        handleChange(delay, 'closeDelay');
        setCloseDelayActive(e.target.value);
    }

    function onBookedChange(e) {
        const value = e.target.value === 'all' ? null : e.target.value;
        handleChange(value, 'booked');
        setBooked(e.target.value);
    }

    return (
        <Row gutter={[48]}>
            {
                editorState.currentStep !== 'nextStep' &&
                <Col span={12}>
                    <h4>Je souhaite que mon message apparaisse</h4>
                    <div className="mb">
                        <Radio.Group onChange={onRadioChange} value={behavior.type}>
                            <Radio style={radioStyle} value="delay">
                                Après un intervalle de temps
                            </Radio>
                            <Radio style={radioStyle} value="inactive">
                                Après un temps d'inactivité du visiteur
                            </Radio>
                            <Radio style={radioStyle} value="out">
                                À la sortie du curseur
                            </Radio>
                            <Radio style={radioStyle} value="scroll">
                                Lorsque que la page a été consultée ( % )
                            </Radio>
                            <Radio style={radioStyle} value="pageCount">
                                Nombre de pages vues minimum
                            </Radio>
                        </Radio.Group>
                    </div>
                    {behavior.type !== "scroll" && behavior.type !== 'pageCount' ?
                        <div>
                            <p><strong>Durée minimum de la visite avant l'affichage (en secondes) : </strong></p>
                            <Row>
                                <Col span={12}>
                                    <Slider
                                        min={0}
                                        max={300}
                                        onChange={onSecondChange}
                                        value={behavior.delay}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumber
                                        min={0}
                                        max={300}
                                        style={{margin: '0 16px'}}
                                        value={behavior.delay}
                                        onChange={onSecondChange}
                                    />
                                </Col>
                            </Row>
                        </div>
                        : null}
                    {behavior.type === "scroll" ?
                        <div>
                            <p><strong>Pourcentage de la page scroller avant l'affichage : </strong></p>
                            <Row>
                                <Col span={12}>
                                    <Slider
                                        min={0}
                                        max={100}
                                        onChange={(value) => handleChange(value, 'scrollPercent')}
                                        value={behavior.scrollPercent || 0}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        style={{margin: '0 16px'}}
                                        value={behavior.scrollPercent || 0}
                                        onChange={(value) => handleChange(value, 'scrollPercent')}
                                    />
                                </Col>
                            </Row>
                        </div>
                        : null}
                    {behavior.type === "pageCount" ?
                        <div>
                            <p><strong>Nombre minimum de page vu : </strong></p>
                            <Row>
                                <Col span={4}>
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        style={{margin: '0 16px'}}
                                        value={behavior.pageCount || 0}
                                        onChange={(value) => handleChange(value, 'pageCount')}
                                    />
                                </Col>
                            </Row>
                        </div>
                        : null}
                </Col>
            }


            <Col span={editorState.currentStep !== 'nextStep' ? 12 : 24}>
                <div>
                    <h4>Fermeture du message : </h4>
                    <Radio.Group
                        size="small" value={closeDelayActive}
                        onChange={changeCloseDelay}
                    >
                        <Radio style={radioStyle} value={false}>Au clic sur le bouton fermeture</Radio>
                        <Radio style={radioStyle} value={true}>Automatique après un délai</Radio>
                    </Radio.Group>
                    {closeDelayActive ?
                        <div>
                            <p><strong>Durée d'affichage avant la fermeture automatique (en seconde) : </strong></p>
                            <Row>
                                <Col span={12}>
                                    <Slider
                                        min={5}
                                        max={300}
                                        onChange={(value) => handleChange(value, 'closeDelay')}
                                        value={behavior.closeDelay}
                                        disabled={behavior.closeDelay === 0}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumber
                                        min={5}
                                        max={300}
                                        style={{margin: '0 16px'}}
                                        value={behavior.closeDelay}
                                        onChange={(value) => handleChange(value, 'closeDelay')}
                                        disabled={behavior.closeDelay === 0}
                                    />
                                </Col>
                            </Row>
                        </div> : null}
                </div>
                {
                    editorState.currentStep !== 'nextStep' &&
                    <div className="mt-20">
                        <h4>Visiteur ayant réservé : </h4>
                        <Radio.Group
                            size="small" value={booked}
                            onChange={onBookedChange}
                        >
                            <Radio style={radioStyle} value="all">Tous</Radio>
                            <Radio style={radioStyle} value={true}>Ayant réservé</Radio>
                            <Radio style={radioStyle} value={false}>N'ayant pas réservé </Radio>
                        </Radio.Group>
                    </div>
                }
            </Col>
        </Row>
    )
}