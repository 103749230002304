import React, {useRef, useState} from "react";
import {DatePicker, Form, Modal, Spin} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import moment from "moment";

export function ModalSubDate({onClose, item, visible = true}) {

    const [apiDispatch] = useApiContext();
    const {apiUpdateEntity} = apiDispatch
    const [loading, setLoading] = useState(false)

    const formRef = useRef()


    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 12,
        },
    };


    async function onFinish(values) {
        setLoading(true)

        if (item) {
            await update(values)
        }

        setLoading(false)
        onClose()
    }

    async function update(values) {
        let params = {
            subscriptionEnd: values.subscriptionEnd,
        }

        let response = await apiUpdateEntity('accounts', item.id, params)
    }


    const handleSubmit = () => {
        if (formRef.current)
            formRef.current.submit()
    }


    const content = (
        <Spin spinning={loading}>
            <Form
                {...layout}
                name="basic"
                onFinish={onFinish}
                ref={formRef}
                initialValues={{
                    subscriptionEnd: item ? moment(item.subscriptionEnd) : null,
                }}
            >
                <Form.Item
                    label="Date de fin"
                    name="subscriptionEnd"
                >
                    <DatePicker/>
                </Form.Item>
            </Form>
        </Spin>
    )

    return (
        <Modal visible={visible}
               destroyOnClose
               title={`Modifier la fin de l'abonnement du compte : ${item?.name}` }
               onOk={handleSubmit}
               okText={"Modifier"}
               onCancel={onClose}
               cancelText="Retour"
        >
            {content}
        </Modal>
    )
}