import React, {useEffect, useRef, useState} from 'react'
import {Button, Carousel, Modal, Spin} from "antd";
import {useEditorContext} from "../../providers/EditorProvider";
import {useApiContext} from "../../providers/ApiProvider";
import DisplayPanel from "../editor/behavior/panelContent/DisplayPanel";
import BehaviorSelection from "../editor/behavior/BehaviorSelection";
import DevicePanel from "../editor/behavior/panelContent/DevicePanel";
import FrequencyPanel from "../editor/behavior/panelContent/FrequencyPanel";
import PagePanel from "../editor/behavior/panelContent/PagePanel";
import LocationPanel from "../editor/behavior/panelContent/LocationPanel";
import RefererPanel from "../editor/behavior/panelContent/RefererPanel";
import TemporalPanel from "../editor/behavior/panelContent/TemporalPanel";

export default function ModalBehavior({isVisible, setIsVisible}) {

    const [editorState] = useEditorContext();
    const {message} = editorState;
    const [apiDispatch] = useApiContext();
    const {apiFetchSubResource, apiUpdateEntity} = apiDispatch;

    const [loading, setLoading] = useState(false);
    const [needUpdate, setNeedUpdate] = useState(0);
    const [behavior, setBehavior] = useState({});
    const [changeTimeout, setChangeTimeout] = useState(null)

    const behaviorRef = useRef(behavior);

    const [selectedBehavior, setSelectedBehavior] = useState(0)

    useEffect(() => {
        if (isVisible) {
            if (editorState.currentStep === 'nextStep')  {
                car.current.goTo(0)
                setSelectedBehavior(0)
            }
            fetch();
        }
    }, [isVisible])

    useEffect(() => {
        behaviorRef.current = behavior;
    }, [behavior])

    useEffect(() => {
        if (!needUpdate) return
        clearTimeout(changeTimeout)
        setChangeTimeout(setTimeout(() => {
            hydrateBehavior(behaviorRef.current, () => {})
        }, 1000))

    }, [needUpdate])

    async function fetch() {
        setLoading(true)

        const response = await apiFetchSubResource("message_contents", {id: message.id}, "behavior")

        if (response["@type"] === "hydra:Error" && response["hydra:description"] === "Not Found")
            return setLoading(false);

        setBehavior(response);
        setLoading(false);
    }

    async function hydrateBehavior(data, setLoading) {
        setLoading(true);

        let response = await apiUpdateEntity("behaviors", behavior.id, data);
        setBehavior(response);

        setLoading(false);
    }

    function handleChange(e, property, value) {
        e.stopPropagation()
        setBehavior(previousBehavior => {
            return {...previousBehavior, [property]: value}
        })
        setNeedUpdate((new Date()).getTime())
    }

    const car = useRef()
    const [tabTimeout, setTabTimeout] = useState()

    const pageChange = (pageNumber) => {
        console.log('click')
        clearTimeout(tabTimeout)
        setTabTimeout(setTimeout(() => {
            car.current.goTo(pageNumber)
            setSelectedBehavior(pageNumber)
        }, 200))
    }

    return (
        <Modal
            visible={isVisible}
            onCancel={() => setIsVisible(false)}
            title={<>
                <div className="mb">Comportement</div>
                {
                    editorState.currentStep !== 'nextStep' &&
                    <BehaviorSelection pageChange={pageChange} selected={selectedBehavior} behavior={behavior} setIsActive={handleChange}/>
                }
            </>}
            width={"100%"}
            style={{maxWidth: editorState.currentStep !== 'nextStep' ? '1050px' : '520px'}}

            footer={[
                <Button key="back" onClick={() => setIsVisible(false)}>
                    Retour
                </Button>
            ]}>

            <Spin spinning={loading}>
                <Carousel dots={false} ref={car} effect="fade">
                    <DisplayPanel
                        behavior={behavior}
                        setBehavior={setBehavior}
                        setNeedUpdate={setNeedUpdate}
                        setIsActive={handleChange}
                        editorState={editorState}
                    />
                    <DevicePanel
                        behavior={behavior}
                        setBehavior={setBehavior}
                        setNeedUpdate={setNeedUpdate}
                        setIsActive={handleChange}
                    />
                    <FrequencyPanel
                        behavior={behavior}
                        setBehavior={setBehavior}
                        setNeedUpdate={setNeedUpdate}
                        setIsActive={handleChange}
                    />
                    <PagePanel
                        behavior={behavior}
                        setBehavior={setBehavior}
                        setNeedUpdate={setNeedUpdate}
                        setIsActive={handleChange}
                    />

                    <LocationPanel
                        behavior={behavior}
                        setBehavior={setBehavior}
                        setNeedUpdate={setNeedUpdate}
                        setIsActive={handleChange}
                    />
                    <RefererPanel
                        behavior={behavior}
                        setBehavior={setBehavior}
                        setNeedUpdate={setNeedUpdate}
                        setIsActive={handleChange}
                    />
                    <TemporalPanel
                        behavior={behavior}
                        setBehavior={setBehavior}
                        setNeedUpdate={setNeedUpdate}
                        setIsActive={handleChange}
                    />
                </Carousel>
            </Spin>
        </Modal>
    )

}