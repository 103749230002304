import React, {useEffect, useState} from 'react'
import {useApiContext} from '../providers/ApiProvider.js';
import {useParams} from 'react-router-dom';
import {Button, Card, Form, Input, Spin} from 'antd';
import background from "../assets/bg-login.jpg";
import logo from "../assets/logo.png";
import logoMmc from "../assets/logo-mmc-white.png";

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};
const api_endpoint = process.env.API_ENTRYPOINT

export default function NewPassword() {

    const [apiDispatch] = useApiContext();
    const {post} = apiDispatch;
    const params = useParams();
    const [message, setMessage] = useState({visible: false, type: null, content: null})
    const [loading, setLoading] = useState(true)
    const [form] = Form.useForm();
    const [processing, setProcessing] = useState(false);


    useEffect(() => {
        checkToken()
    }, [])

    async function checkToken() {
        let response = await post(`${api_endpoint}/new-password`, {token: params.token})
        if (response.status === "error") {
            setMessage({visible: true, type: 'Désolé,', content: response.message});
            goToLogin(3000)

            return;
        }
        setLoading(false)
    }

    const onFinish = async (values) => {
        setProcessing(true);
        const data = {
            token: params.token,
            password: values.password
        }
        console.log(data)

        const response = await post(`${api_endpoint}/new-password`, data);
        console.log(response)

        if (response.status === 'good') {

            setMessage({
                visible: true,
                type: 'Félicitation',
                content: 'Votre mot de passe a été mise à jour, vous allez être redirigé vers la page de connexion d\'ici quelques secondes ...'
            })
            goToLogin(3000)
        }


    };

    function goToLogin(ms = 1) {
        setTimeout(() => {
            window.location.href = '/login';
        }, ms);
    }

    return (
        <div
            style={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%), center / cover url(${background})`,
                justifyContent: "space-between",
                alignItems: "center",
                padding: '0 84px',
                height: '100%',
                display: "flex"
            }}
        >
            <div style={{width: '43%'}}>
                <img style={{height: '62px', marginBottom: '40px'}} src={logo} alt="logo hotel push marketing"/>
                <p className="log-description">
                    Personnalisez votre site web selon le profil de chaque visiteur et boostez ainsi ses performances !
                </p>
            </div>

            <Card className="card-style" style={{width: '38%', padding: '55px 2em', margin: 0, maxWidth: '550px'}}>
                {
                    message.visible ?
                        <div>
                            <div style={{textAlign: 'center'}}>
                                <h2>{message.type}</h2>
                                <p>{message.content}</p>
                            </div>
                        </div>
                        :
                        <>
                            <div style={{textAlign: 'center'}}>
                                <h2>Reinitialisation</h2>
                                <p>Vous pouvez changez votre mot de passe</p>
                            </div>
                            <div>
                                <Spin spinning={loading}>
                                    <Form
                                        form={form}
                                        name="login"
                                        layout={"vertical"}
                                        initialValues={{
                                            remember: true,
                                        }}
                                        onFinish={onFinish}
                                        style={{
                                            margin: '1em auto',
                                            width: "100%",
                                            padding: "1em",
                                        }}
                                    >
                                        <Form.Item
                                            name="password"
                                            label="Mot de passe"
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'entrer un mot de passe!',
                                                },
                                            ]}
                                        >
                                            <Input.Password autoComplete="new-password"/>
                                        </Form.Item>

                                        <Form.Item
                                            name="confirm"
                                            label="Confirmer le mot de passe"
                                            dependencies={['password']}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please confirm your password!',
                                                },
                                                ({getFieldValue}) => ({
                                                    validator(rule, value) {
                                                        if (!value || getFieldValue('password') === value)
                                                            return Promise.resolve();

                                                        return Promise.reject('les deux mots de passe ne sont pas indentiques');
                                                    }
                                                })
                                            ]}
                                        >
                                            <Input.Password/>
                                        </Form.Item>

                                        <Form.Item {...tailLayout}>
                                            <Button type="primary" htmlType="submit" loading={processing}>
                                                Modifier
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Spin>
                            </div>
                        </>
                }
            </Card>

            <img src={logoMmc} alt="" className="mmc-logo"/>
        </div>
    );
}