import React from 'react'
import {Col, Form, Radio, Row, Select, Switch} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";

export default function FrequencyPanel({behavior, setBehavior, setNeedUpdate}) {


    function handleChange(changedValues, allValues) {
        console.log(allValues)
        const values = {
            firstVisit: allValues.firstVisit ?? null,
            frequency: allValues.frequency,
            converted: allValues.converted
        };

        setBehavior(prev => {
            return {...prev, ...values}
        })
        setNeedUpdate((new Date()).getTime())
    }


    return (
        behavior.id ?
            <Form
                layout="vertical"
                name="frequencyForm"
                initialValues={{
                    firstVisit: behavior.firstVisit,
                    frequency: behavior.frequency,
                    converted: behavior.converted
                }}
                onValuesChange={handleChange}
            >
                <Row gutter={[48]}>
                    <Col span={12}>
                        <h4>Rendre ce message visible pour ... </h4>
                        <Form.Item
                            name="firstVisit"
                        >
                            <Radio.Group size="small">
                                <Radio style={{display: "block"}} key={"new"} value={true}>Les nouveaux
                                    visiteurs</Radio>
                                <Radio style={{display: "block"}} key={"return"} value={false}>Les visiteurs étant déjà
                                    venus</Radio>
                                <Radio style={{display: "block"}} key={"all"} value={undefined}>Les deux</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="Si le visiteur a cliqué dans mon message"
                            name="converted"
                            valuePropName="checked"
                            extra="visiteur ayant cliqué sur votre message (bouton, soumission de formulaire ou lien)"
                        >
                            <Switch
                                checkedChildren={<CheckOutlined/>}
                                unCheckedChildren={<CloseOutlined/>}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <h4>À une fréquence de ... </h4>
                        <Form.Item
                            name="frequency"
                        >
                            <Select style={{width: '200px'}}>
                                <Select.Option key={"oncePerDay"} value={"oncePerDay"}>Une fois par jour</Select.Option>
                                <Select.Option key={"oncePerSession"} value={"oncePerSession"}>Une fois par
                                    session</Select.Option>
                                <Select.Option key={"everyPages"} value={"everyPages"}>Toutes les pages</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            :
            <div>
                Can't get infos
            </div>
    )


}