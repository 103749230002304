import React, {useEffect, useState} from 'react'
import {Button, Modal, Space} from "antd";
import {LineChartOutlined} from "@ant-design/icons";
import {useApiContext} from "../../providers/ApiProvider";
import { positionFrenchName} from "../editor/utils";
import {MessageBoxPreview} from "../MessageBoxPreview";


export default function ModalComparaison({messageId, size = "small", cancelDraft, draftToOnline}) {

    const [apiDispatch] = useApiContext()
    const {apiFetchEntity} = apiDispatch
    const [draft, setDraft] = useState(null)
    const [online, setOnline] = useState(null)
    const [loading, setLoading] = useState(true)
    const [visible, setVisible] = useState(false);


    useEffect(() => {
        if (messageId && visible)
            fetchMessage()

    }, [messageId, visible])

    async function fetchMessage() {
        setLoading(true)
        let response = await apiFetchEntity('messages', messageId)
        console.log(response)
        handleMessageContent(response.draft, setDraft)
        handleMessageContent(response.online, setOnline)
        setLoading(false)
    }

    function handleMessageContent(content, setContent) {
        let msg = {...content}
        let style = {...msg.style}
        style.position = 'relative'

        setContent({...msg, style: style})
    }

    function displayMessage(messageToDisplay, title) {
        return (
            <Space direction="vertical" style={{width: 300}}>
                <div className="text-center">
                    <h4>{title}</h4>
                </div>

               <MessageBoxPreview messageToDisplay={messageToDisplay}/>

                <div>
                    <p className="flex between">
                        <span>Type de message </span><span>{messageToDisplay.type}</span></p>
                    <p className="flex between">
                        <span>Taille largeur/hauteur</span><span>{messageToDisplay.style.width} / {messageToDisplay.style.height}</span>
                    </p>
                    <p className="flex between">
                        <span>Position</span><span>{messageToDisplay.type === 'modal' ? 'Centrer' : `En ${positionFrenchName[messageToDisplay.options.verticalPos]} à ${positionFrenchName[messageToDisplay.options.horizontalPos]}`}</span>
                    </p>

                </div>
            </Space>
        )
    }

    async function handleDelete() {
        setLoading(true)

        await cancelDraft()
        setLoading(false)
        setVisible(false)
    }

    async function handlePublish() {
        setLoading(true)
        await draftToOnline()
        setLoading(false)
        setVisible(false)
    }

    return (
        <>
            <Button
                size={size}
                icon={<LineChartOutlined/>}
                onClick={() => setVisible(true)}>Comparer les versions</Button>
            <Modal
                width={800}
                title="Comparaison Brouillon/Publier"
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={[
                    <Button className="ml-10" key={'back'}
                            onClick={() => setVisible(false)}>Retour</Button>,
                    <Button className="ml-10" danger key={'deleteDraft'}
                            onClick={handleDelete}>Effacer le brouillon</Button>,
                    <Button className="ml-10" type="primary" key={'publish'}
                            onClick={handlePublish}>Publier le brouillon</Button>
                ]}

            >
                <Space direction="vertical" style={{width: 300}}>

                </Space>
                <Space direction="vertical" style={{width: 300}}>

                </Space>
                {draft && online && !loading &&
                <div className="flex between">
                    {displayMessage(draft, 'Version brouillon')}
                    {displayMessage(online, 'Version publiée')}
                </div>
                }


            </Modal>
        </>
    )

}