import React, {useEffect, useState} from 'react';
import {useApiContext} from "../providers/ApiProvider";
import {Button, Divider, Space, Spin} from "antd";
import {EyeOutlined} from '@ant-design/icons';
import Preview from "./editor/Preview";

export default function TemplateChoice({setTemplate}) {

    const [apiDispatch] = useApiContext();
    const {apiPostEntity, apiFetchCollection} = apiDispatch;

    const [loading, setLoading] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [categories, setCategories] = useState([]);
    const [activeFilter, setActiveFilter] = useState(0);
    const [templateSelected, setTemplateSelected] = useState("");
    const [previewId, setPreviewId] = useState(null)

    const cssTagSelected = {border: "1px solid #2494D1", color: "#2494D1"};
    const cssTemplateSelected = {border: "3px solid #2494D1"};

    useEffect(() => {
        fetchCategories();
    }, [])

    async function fetchCategories() {
        const response = await apiPostEntity("get-dynamic-categories",);

        fetchTemplates(response.map(r => r.id));
        setCategories(response);
    }

    async function fetchTemplates(tags) {
        if (!loading)
            setLoading(true);

        let params = {filters: [{name: 'isTemplate', value: true}, {name:'status', value: 'active'}]};
        tags.map(tag => {
            params.filters.push({name: 'templateCategories.id[]', value: tag});
        })

        const response = await apiFetchCollection("get_messages_templates", params);

        setTemplates(response['hydra:member']);
        setLoading(false);
    }

    function filter(id) {
        setActiveFilter(id);
        fetchTemplates([id])
    }

    function resetFilters() {
        setActiveFilter(0);
        fetchTemplates(categories.map(category => category.id));
    }

    return (
        <Spin spinning={loading}>

            <div style={{minHeight: 480}}>
                <Space size="large">
                    <div
                        style={!activeFilter ? cssTagSelected : null}
                        className="pointer template-tag"
                        onClick={() => resetFilters()}
                    >
                        Tout
                    </div>
                    {categories.map(category => (
                        <div
                            style={activeFilter === category.id ? cssTagSelected : null}
                            className="pointer template-tag"
                            key={category.id}
                            onClick={() => filter(category.id)}
                        >
                            {category.name}
                        </div>
                    ))}
                </Space>

                <Divider/>

                <div className="flex wrap align-stretch mt-20">
                    {templates.map((template, id) => (
                        <div
                            style={template.id === templateSelected ? cssTemplateSelected : null}
                            className="template-card"
                            key={id}
                        >
                            <div className="h-100per back-grey-light">
                                <div className="flex justify-center align-center h-90per relative">
                                    <img style={{maxHeight: "80%", maxWidth: "80%"}}
                                         src={template.media ? template.media.contentUrl : null} alt="template"/>
                                    <div className="template-overlay flex justify-center align-center">
                                        <Button
                                            className="overlay-btn mr-10"
                                            type="primary"
                                            onClick={() => {
                                                setTemplateSelected(template.id)
                                                if (typeof setTemplate === "function")
                                                    setTemplate(template.id);
                                            }}
                                        >
                                            Utiliser ce modèle
                                        </Button>
                                        <Button
                                            className="overlay-btn"
                                            icon={<EyeOutlined/>}
                                            onClick={() => setPreviewId(template.id)}
                                        >
                                            Voir
                                        </Button>
                                    </div>
                                </div>
                                <div className="h-10per flex align-center justify-center">
                                    <div>
                                        {template.name}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {previewId ? <Preview messageID={previewId} setMessageId={setPreviewId}/> : null}
        </Spin>
    )
}