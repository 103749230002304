import React, {useEffect, useRef, useState} from 'react'
import {Button, List, Modal, Popconfirm, Skeleton} from "antd";
import {useAuthContext} from "../../providers/AuthProvider";
import {useApiContext} from "../../providers/ApiProvider";
import {DeleteTwoTone} from "@ant-design/icons";
import PermissionForm from "../PermissionForm";

export default function ModalAccountPermissions({account, setAccount}) {

    const [authState, authDispatch] = useAuthContext();
    const {auth} = authState;
    const [apiDispatch] = useApiContext();
    const {apiFetchSubResource, apiDeleteEntity, apiPostEntity} = apiDispatch;
    const [permissions, setPermissions] = useState([])
    const [loading, setLoading] = useState(false)
    const [needUpdate, setNeedUpdate] = useState(0)

    const [isAddingPermission, setIsAddingPermission] = useState(false)

    useEffect(() => {
        if (!!account.id) {
            setLoading(true)
            fetchPermissions()
        }
    }, [account.id, needUpdate, isAddingPermission])


    const fetchPermissions = async () => {
        const params = {
            id: account.id,
        }
        let response = await apiFetchSubResource('accounts', params, 'permissions')
        if (response['@type'] === "hydra:Collection") {
            setPermissions(response['hydra:member'])
        }
        console.log(response)
        setLoading(false)
    }

    const handleDelete = async (permission) => {
        await apiDeleteEntity('permissions', permission.id)
        setNeedUpdate(needUpdate + 1)
    }


    const formRef = useRef()
    console.log(formRef)

    const handleAdd = async () => {
        console.log(formRef)
        if (!isAddingPermission)
            return setIsAddingPermission(true)


        if (formRef.current) {
            formRef.current.submit()
        }
    }
    return (
        <Modal
            visible
            title={"Permissions : " + account.name}
            onCancel={() => setAccount(null)}
            footer={[
                <Button key="back" onClick={() => setAccount(null)}>
                    Fermer
                </Button>,
                isAddingPermission ?
                    <Button key="cancel" danger onClick={() => setIsAddingPermission(false)}>
                        Annuler
                    </Button>
                    : null,
                <Button type="primary" key="add" onClick={handleAdd}>
                    {!isAddingPermission ? "Ajouter une permission" : "Valider"}
                </Button>
            ]}>
            {
                isAddingPermission ?
                    <PermissionForm formRef={formRef} apiPostEntity={apiPostEntity}
                                    setIsAddingPermission={setIsAddingPermission} account={account}/>
                    :
                    <List
                        loading={loading}
                        itemLayout="horizontal"
                        dataSource={permissions}
                        renderItem={item => (
                            <List.Item>
                                <Skeleton avatar title={false} loading={item.loading} active>
                                    <List.Item.Meta
                                        title={<span>{item.user.email}</span>}
                                        description={`${item.user.lastName ? item.user.lastName : ''} ${item.user.firstName ? item.user.firstName : ''}`}
                                    />
                                    <div style={{textAlign: 'right'}}>
                                        {
                                            item.user && item.user.id === auth.id ? null :
                                                <Popconfirm placement="top"
                                                            title='Êtes-vous sûr de vouloir supprimer cette permission ?'
                                                            onConfirm={() => handleDelete(item)} okText='oui'
                                                            cancelText="non">
                                                    <DeleteTwoTone/>
                                                </Popconfirm>
                                        }
                                    </div>
                                </Skeleton>
                            </List.Item>
                        )}
                    />
            }

        </Modal>

    )
}