import React, { useEffect, useRef, useState } from "react";
import Moveable from "react-moveable";
import { useEditorContext } from "../../providers/EditorProvider";
import { DimensionViewable, RotationViewable } from "./CustomAbles";
import { Button, Dropdown, Menu, Popover, Space } from "antd";
import Icon, {
    CloseCircleOutlined,
    EditOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SettingOutlined,
    VerticalAlignMiddleOutlined
} from "@ant-design/icons";
import ButtonActions from "./actions/ButtonActions";
import IconActions from "./actions/IconActions";
import FormTab from "./tabs/FormTab";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete_icon.svg";
import { ReactComponent as CropIcon } from "../../assets/icons/crop_icon.svg";
import { ReactComponent as RectIcon } from "../../assets/icons/rect_icon.svg";
import { ReactComponent as CircleIcon } from "../../assets/icons/circle_icon.svg";
import { ReactComponent as EllipseIcon } from "../../assets/icons/ellipse_icon.svg";
import { ReactComponent as PolygonIcon } from "../../assets/icons/polygon_icon.svg";
import ImageActions from "./actions/ImageAction";
import CountDownTab from "./tabs/CountDownTab";
import WeatherTab from "./tabs/WeatherTab";

const ElementMoveable = ({ target, isSelected, element, elementGuidelines, editMode, setEditMode, lng }) => {

    const [style, setStyle] = useState({})
    const [editorState, editorDispatch] = useEditorContext()
    const { selectElement, updateElement, removeElement, setter } = editorDispatch;
    const [isLoaded, setIsLoaded] = useState(false);
    const [isDragging, setIsDragging] = useState(false)
    const [isResizing, setIsResizing] = useState(false)
    const [isRotating, setIsRotating] = useState(false)
    const [isClipping, setIsClipping] = useState(false)
    const [clipType, setClipType] = useState('inset')

    const [frame, setFrame] = useState({
        translate: element.translate,
        rotate: element.rotate,
        clipStyle: "inset",
    });

    const moveable = useRef()

    useEffect(() => {
        let lngContent = {}
        if (element.type === 'picture') {
            lngContent = getPictureContent(element)
        }

        setFrame({
            ...frame,
            translate: element.type !== 'picture' ? element.translate : lngContent.translate,
            rotate: element.type !== 'picture' ? element.rotate : lngContent.rotate
        })

        moveable.current.updateRect();

        setStyle(element.type !== 'picture' ? element.style : lngContent.style);

    }, [element, lng])


    useEffect(() => {
        if (isLoaded) {
            let newElement = { ...element, style: style, translate: frame.translate, rotate: frame.rotate };

            if (element.type === 'picture') {
                let lngContent = getPictureContent(element)
                newElement = { ...element, content: { ...element.content, [lng]: { ...lngContent, style: style, translate: frame.translate, rotate: frame.rotate } } }
            }

            updateElement(newElement)
        } else
            setIsLoaded(true);
    }, [style])

    useEffect(() => {
        if (!isSelected) {
            setEditMode(false)
            setIsClipping(null)
        }
    }, [isSelected])

    const getPictureContent = (element) => {
        return element.content[lng] || element.content[Object.keys(element.content)[0]]
    }

    const clipMenu = (
        <Menu>
            <Menu.Item key={`menu-${element.id}`}>
                <Space>
                    <Button icon={<Icon component={RectIcon} />} onClick={() => updateClip('inset')} />
                    <Button icon={<Icon component={EllipseIcon} />} onClick={() => updateClip('ellipse')} />
                    <Button icon={<Icon component={CircleIcon} />} onClick={() => updateClip('circle')} />
                    <Button icon={<Icon component={PolygonIcon} />} onClick={() => updateClip('polygon')} />
                    <Button icon={<CloseCircleOutlined />} onClick={() => updateClip(false)} />
                </Space>
            </Menu.Item>
        </Menu>
    )

    const updateClip = (clip) => {
        if (!clip) return setIsClipping(false)

        setFrame(prev => {
            return { ...prev, clipStyle: clip }
        })

        setClipType(clip)
        let newStyle = { ...style }
        delete newStyle.clipPath
        setStyle({ ...newStyle })
    }

    const baseMenu = (
        <Menu>
            <Menu.Item key={`menu-${element.id}`}>
                <Space>
                    {
                        element.type === 'text' ?
                            <Button icon={<EditOutlined />} onClick={() => setEditMode(true)} />
                            : null
                    }
                    {
                        element.type === 'button' ?
                            <ButtonActions element={element} />
                            : null
                    }
                    {
                        element.type === 'icon' || element.type === 'iconFinder' ?
                            <IconActions element={element} />
                            : null
                    }
                    {
                        element.type === 'picture' &&
                        <>
                            <ImageActions element={element} />
                            <Button icon={<Icon component={CropIcon} />} onClick={() => setIsClipping(true)} />
                        </>
                    }
                    {
                        element.type === 'form' &&
                        <Popover content={<FormTab />} trigger="click" placement={'left'} onVisibleChange={(val) => setter('editMode', val)}>
                            <Button icon={<SettingOutlined />} />
                        </Popover>
                    }
                    {
                        element.type === 'countdown' &&
                        <Popover content={<CountDownTab />} trigger="click" placement={'left'} onVisibleChange={(val) => setter('editMode', val)}>
                            <Button icon={<SettingOutlined />} />
                        </Popover>
                    }
                    {
                        element.type === 'weather' &&
                        <Popover content={<WeatherTab />} trigger="click" placement={'left'} onVisibleChange={(val) => setter('editMode', val)}>
                            <Button icon={<SettingOutlined />} />
                        </Popover>
                    }
                    <Button icon={<RotateRightOutlined />} onClick={() => rotate(true)} />
                    <Button icon={<RotateLeftOutlined />} onClick={() => rotate(false)} />
                    <Button icon={<VerticalAlignMiddleOutlined rotate={90} />} onClick={centerHorizontally} />
                    <Button icon={<VerticalAlignMiddleOutlined />} onClick={centerVertically} />
                    <Button icon={<Icon component={DeleteIcon} style={{ color: "#E64D3D" }} />} onClick={() => removeElement(editorState.selectedElement.id)} />
                </Space>
            </Menu.Item>
        </Menu>
    )

    function rotate(clockRotate) {
        const breakpoint = [-360, -270, -180, -90, 0, 90, 180, 270, 360]
        let currentElement = element.type !== 'picture' ? { ...element } : getPictureContent(element)
        let { rotate, translate } = currentElement

        let closest = breakpoint.reduce(function (prev, curr) {
            return (Math.abs(curr - rotate) < Math.abs(prev - rotate) ? curr : prev);
        });

        let newRotate;

        let base = Math.abs(closest) === 360 ? 0 : closest

        if (clockRotate) {
            newRotate = closest > rotate ? closest : base + 90
        } else {
            newRotate = closest < rotate ? closest : base - 90
        }

        let updatedData = {
            rotate: newRotate,
            style: {
                ...currentElement.style,
                transform: `translate(${translate[0]}px, ${translate[1]}px) rotate(${newRotate}deg)`
            }
        }

        updateElement(element.type === 'picture' ?
            { ...element, content: { ...element.content, [lng]: { ...currentElement, ...updatedData } } } :
            { ...currentElement, ...updatedData })
    }

    function centerVertically() {
        let rect = document.getElementById(element.id).getBoundingClientRect()
        let currentElement = element.type !== 'picture' ? { ...element } : getPictureContent(element)


        let elementHeight = element.type === 'text' ? rect.height : parseFloat(currentElement.style.height)
        let verticalValue = Number((parseFloat(editorState.message.style.height) / 2 - elementHeight / 2).toFixed())

        let updatedData = {
            translate: [currentElement.translate[0], verticalValue],
            style: {
                ...currentElement.style,
                transform: `translate(${currentElement.translate[0]}px, ${verticalValue}px) rotate(${currentElement.rotate}deg)`
            },
        }
        updateElement(element.type === 'picture' ?
            { ...element, content: { ...element.content, [lng]: { ...currentElement, ...updatedData } } } :
            { ...currentElement, ...updatedData })
    }

    function centerHorizontally() {
        let currentElement = element.type !== 'picture' ? { ...element } : getPictureContent(element)

        let horizontalValue = Number((parseFloat(editorState.message.style.width) / 2 - parseFloat(currentElement.style.width) / 2).toFixed())
        let updatedData = {
            translate: [horizontalValue, currentElement.translate[1]],
            style: {
                ...currentElement.style,
                transform: `translate(${horizontalValue}px, ${currentElement.translate[1]}px) rotate(${currentElement.rotate}deg)`
            }
        }

        updateElement(element.type === 'picture' ?
            { ...element, content: { ...element.content, [lng]: { ...currentElement, ...updatedData } } } :
            { ...currentElement, ...updatedData })
    }

    const handleDbClick = (e) => {
        if (e.isDouble && element.type === 'text') {
            setEditMode(true)
        }
    }

    return (
        <Dropdown visible={isSelected && !isDragging && !isRotating && !editMode && !isResizing}
            overlay={isClipping ? clipMenu : baseMenu}
            overlayStyle={{ zIndex: 1000 }}
            placement='topCenter'>
            <Moveable
                onClick={handleDbClick}
                renderDirections={element.type === 'text' ? ["w", "e"] : ["n", "nw", "ne", "s", "se", "sw", "e", "w"]}
                ables={[isResizing ? DimensionViewable : {}, isRotating ? RotationViewable : {}]}
                props={{
                    dimensionViewable: true,
                    rotationViewable: true
                }}
                passDragArea={editMode}
                target={target}
                ref={moveable}
                className={isSelected || isDragging ? '' : 'targetNotSelected'}
                snappable={true}
                origin={false}
                elementGuidelines={elementGuidelines}
                verticalGuidelines={[0, 200, 400]}
                horizontalGuidelines={[0, 200, 400]}
                snapThreshold={2}
                isDisplaySnapDigit={true}
                snapGap={true}
                snapElement={true}
                snapVertical={true}
                snapHorizontal={true}
                snapCenter={true}
                snapDigit={0}
                keepRatio={element.type !== 'picture' ? !!element.ratio : !!getPictureContent(element).ratio}
                resizable={isSelected}
                throttleResize={0}
                draggable={!isClipping}
                throttleDrag={0}
                startDragRotate={0}
                throttleDragRotate={0}
                zoom={(parseFloat(style.width) < 50 && parseFloat(style.height) < 50) || element.type === 'text' ? .5 : 1}
                rotatable={isSelected}
                throttleRotate={0}
                rotationPosition={"bottom"}
                clippable={isSelected && isClipping}
                clipRelative={true}
                clipArea={true}
                dragArea={isSelected ? true : null}
                defaultClipPath={clipType}
                clipVerticalGuidelines={[0, 100, 200]}
                clipHorizontalGuidelines={[0, 100, 200]}
                onClip={(e) => {
                    if (e.clipType === "rect") {
                        e.target.style.clip = e.clipStyle;
                    } else {
                        e.target.style.clipPath = e.clipStyle;
                    }
                    frame.clipStyle = e.clipStyle;
                }}
                onClipEnd={(e) => {
                    if (e.lastEvent) {
                        if (e.clipType === "rect") {
                            setStyle({ ...style, clip: e.lastEvent.clipStyle })
                        } else {
                            setStyle({ ...style, clipPath: e.lastEvent.clipStyle })
                        }
                    }
                }}
                onDragOriginStart={({ dragStart }) => {
                    dragStart && dragStart.set(frame.translate);
                }}
                onDragOrigin={({ drag, transformOrigin }) => {
                    frame.translate = drag.beforeTranslate;
                    frame.transformOrigin = transformOrigin;
                }}
                onDragStart={({ set }) => {
                    setIsDragging(true)
                    if (editMode) {
                        return false
                    }
                    set(frame.translate);
                }}
                onDrag={({ beforeTranslate }) => {
                    frame.translate = beforeTranslate;
                }}
                onDragEnd={() => {
                    const { translate, rotate } = frame;
                    setIsDragging(false)
                    let content = element.type === 'picture' ? getPictureContent(element) : element

                    let elementTranslate = element.type !== 'picture' ? element.translate : content.translate
                    let elementRotate = element.type !== 'picture' ? element.rotate : content.rotate
                    if (elementTranslate[0] !== translate[0] || elementTranslate[1] !== translate[1] || elementRotate !== rotate)
                        setStyle({
                            ...style,
                            transform: `translate(${translate[0]}px, ${translate[1]}px) rotate(${rotate}deg)`
                        });
                    selectElement(element)

                }}
                onRotateStart={({ set }) => {
                    set(frame.rotate);
                    setIsRotating(true)
                }}
                onRotate={({ beforeRotate }) => {
                    frame.rotate = beforeRotate;
                }}
                onRotateEnd={() => {
                    setIsRotating(false)
                    const { translate, rotate } = frame;
                    setStyle({
                        ...style, transform: `translate(${translate[0]}px, ${translate[1]}px)`
                            + ` rotate(${rotate}deg)`
                    });
                }}
                onRender={({ target }) => {
                    const { translate, rotate, transformOrigin } = frame;
                    target.style.transformOrigin = transformOrigin;
                    target.style.transform = `translate(${translate[0]}px, ${translate[1]}px)`
                        + ` rotate(${rotate}deg)`;
                }}
                onResizeStart={({ setOrigin, dragStart }) => {
                    setIsResizing(true)
                    setOrigin(["%", "%"]);
                    dragStart && dragStart.set(frame.translate);
                }}
                onResize={({ target, width, height, drag }) => {
                    const beforeTranslate = drag.beforeTranslate;

                    frame.translate = beforeTranslate;
                    target.style.width = `${width}px`;
                    if (element.type !== 'text') {
                        target.style.height = `${height}px`;
                    }
                    target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
                }}
                onResizeEnd={(target) => {
                    setIsResizing(false)

                    if (target.lastEvent) {
                        let newStyle = {
                            width: `${target.lastEvent.width}px`,
                            transform: `translate(${frame.translate[0]}px, ${frame.translate[1]}px)`
                                + ` rotate(${frame.rotate}deg)`
                        }
                        if (element.type !== 'text') {
                            newStyle.height = `${target.lastEvent.height}px`
                        }
                        setStyle({
                            ...style, ...newStyle
                        })
                    }
                }}
            />
        </Dropdown>
    )

}

export default ElementMoveable