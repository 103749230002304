import React, {useState} from 'react'
import {Button, Popover} from "antd";
import {ReactComponent as TextIcon} from '../../assets/icons/text_icon.svg'
import {ReactComponent as ButtonIcon} from '../../assets/icons/outils-de-conception.svg'

import {useEditorContext} from "../../providers/EditorProvider";
import DimensionPanel from "./tabs/Component/DimensionPanel";


export default function MessageOptions() {

    const [editorState, editorDispatch] = useEditorContext()
    const {setMessage} = editorDispatch
    const [isOpen, setIsOpen] = useState(false)

    const content = (
        <div className="insert" style={{height: "auto", maxHeight: "400px"}}>
            <DimensionPanel message={editorState.message} setMessage={setMessage}/>
        </div>
    )

    const handleVisible = (value) => {
        setIsOpen(value)
    }

    return (
        <Popover placement="right"
                 title={
                     <div className="insert-title">
                         Message
                     </div>
                 }
                 content={content}
                 trigger="click"
                 onOpenChange={handleVisible}
                 destroyTooltipOnHide={true}>
            <Button className={`action-btn ${isOpen ? 'active' : ''}`} title="Options du message">
                <ButtonIcon fill={isOpen ? 'white' : 'black'} style={{width:'30px'}}/></Button>
        </Popover>
    )
}