import React, {useState} from "react";
import {Form, message, Select} from "antd";
import {useApiContext} from "../../providers/ApiProvider";

export default function UserPermissionForm({formRef, apiPostEntity, user, setIsAddingPermission}) {

    const [apiDispatch] = useApiContext();
    const {apiFetchCollection} = apiDispatch;
    const [searchTimeout, setSearchTimeout] = useState()
    const [options, setOptions] = useState([])

    const handleFinish = async (values) => {

        console.log(values)
        await Promise.all(values.accounts.map(async account => {
            let data = {
                account: account,
                email: user.email,
            }
            let response = await apiPostEntity('permissions', data)
            if (response["@type"] === "hydra:Error") {
                return message.error('La permission, n\'a pas pu être crée. Si le problème persiste contacter un administrateur')
            }
        }))

        message.success('Permission ajoutée avec succès')
        formRef.current.resetFields()
        setIsAddingPermission(false)
    }

    function handleSearch(value) {

        clearTimeout(searchTimeout)
        setSearchTimeout(setTimeout(async () => {
            let params = {
                filters: [{value: value.trim(), name: 'name'}]
            }
            let response = await apiFetchCollection('accounts', params)
            console.log(response['hydra:member'])
            setOptions(response['hydra:member'])
        }, 200))

    }

    return (
        <Form ref={formRef} onFinish={handleFinish}>
            <p><strong>Donnez une permission à cet utilisateur</strong></p>
            <Form.Item
                {...{
                    labelCol: {span: 0},
                    wrapperCol: {span: 18},
                }}
                name={'accounts'}
                rules={[
                    {required: true, message: 'Sélectionner au moins un compte'},
                ]}
            >
                <Select
                    placeholder="Rechercher un compte"
                    onSearch={handleSearch}
                    mode="multiple"
                    notFoundContent={null}
                    showSearch>
                    {
                        options.map(account => <Select.Option key={account.id} value={account['@id']}>{account.name}</Select.Option>)
                    }
                </Select>
            </Form.Item>
        </Form>
    )
}