import React, {useEffect, useState} from 'react'
import {Button, Checkbox, Input, message, Space, Tabs} from "antd";
import {useEditorContext} from "../../../providers/EditorProvider";
import {useAccountContext} from "../../../providers/AccountProvider";
import FormListSelection from "./Component/FormListSelection";
import FormInputs from "./Component/FormInputs";
import {PlusOutlined} from "@ant-design/icons";
import {useApiContext} from "../../../providers/ApiProvider";
import Radio from "antd/es/radio/radio";

const defaultForm = {
    name: `Formulaire`,
    type: 'form',
    items: [],
    style: {
        width: "250px",
        height: "100px",
        display: "flex",
        flexDirection: "column",
    },
    action: 'close',
    link: '',
    form: {name: null, id: null},
    translate: [0, 0],
    rotate: 0,
}


const defaultInput = {
    name: {
        label: 'Nom',
        name: 'name',
        type: 'text',
        placeholder: {fr: 'Entrer votre nom'},
        required: true
    },
    email: {
        label: 'Email',
        name: 'email',
        type: 'email',
        placeholder: {fr: 'Entrer votre email'},
        required: true,
        validation: false,
        regex: '/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/'
    },
    phone: {
        label: 'Téléphone',
        name: 'phone',
        type: 'phone',
        placeholder: {fr: 'Entrer votre téléphone'},
        required: true,
    },
    consent: {
        label: 'Consentement',
        name: 'consent',
        type: 'checkbox',
        placeholder: {fr: 'J\'accepte d\'être contacté par email'},
        required: true,
    }
}

const baseOptions = [
    {value: 'name', label: 'Nom'},
    {value: 'phone', label: 'Téléphone'},
    {value: 'email', label: 'Email'},
    {value: 'consent', label: 'Consentement'},
    ]

export default function FormTab() {

    const getChecked = () => {
        let options = []
        baseOptions.map(option => {
            if (!!form.items.find(item => item.name === option.value)) {
                options.push(option.value)
            }
        })
        console.log('op', options)
        return options
    }

    const [editorState, editorDispatch] = useEditorContext()
    const {addElement, selectElement, updateElement, removeElement} = editorDispatch

    const [accountState] = useAccountContext();
    const {account} = accountState;

    const [apiDispatch] = useApiContext();
    const {apiPostEntity} = apiDispatch;

    const [form, setForm] = useState(editorState.message.children.find(item => item.type === 'form') ?? defaultForm)
    const [formNeedUpdate, setFormNeedUptate] = useState(0)

    const [checked, setChecked] = useState(getChecked)
    const [addingCategorie, setAddingCategorie] = useState(false)

    useEffect(() => {
        if (formNeedUpdate !== 0) {
            let editorForm = !!form.id

            if (form.items.length && !editorForm) {
                console.log('add')
                addForm()
            } else if (!form.items.length && editorForm) {
                console.log('remove')

                removeForm()
            } else if (editorForm) {
                console.log('update')
                updateForm()
            }
        }
    }, [formNeedUpdate])

    useEffect(() => {

        let formElement = editorState.message.children.find(item => item.type === 'form')
        if (!formElement) {
            setForm(defaultForm)
            setChecked([])
        } else if (formElement !== form) {
            setForm(formElement)
        }


    }, [editorState.message.children])

    function removeForm() {
        removeElement(form.id)
    }

    function addForm() {
        let newForm = {...form, id: Date.now()}
        setForm(newForm)
        selectElement(newForm)
        addElement(newForm)
    }

    const updateForm = () => {
        updateElement(form)
    }

    const handleChange = (val) => {
        let inputs = [...form.items]

        val.map(checkboxName => {
            if (!checked.includes(checkboxName)) {
                inputs.push(defaultInput[checkboxName])
            }
        })

        let deletedVal = checked.filter(item => !val.includes(item))
        deletedVal.map(item => {
            inputs = inputs.filter(input => input.name !== item)
        })

        setForm({...form, items: inputs})
        setFormNeedUptate((new Date()).getTime())
        setChecked(val)
    }

    const updateInputValue = (values) => {
        console.log(values)
        console.log(form.items)
        let newItems = form.items.map(item => {
            if (item.name === values.name) return values
            return item
        })
        console.log(newItems)
        setForm({...form, items: newItems})
        setFormNeedUptate((new Date()).getTime())
    }

    const removeItem = (name) => {
        setForm(previousData => {
            return {...previousData, items: previousData.items.filter(item => item.name !== name)}
        })
        setFormNeedUptate((new Date()).getTime())
        setChecked(previous => [...previous.filter(item => item !== name)])

    }

    const handleAction = (property, value) => {
        setForm(previousData => {
            return {...previousData, [property]: value}
        })
        setFormNeedUptate((new Date()).getTime())
    }

    const handleSearch = async (value) => {
        let name = value.trim()
        if (!value) {
            message.error('Vous devez complétez le nom pour la créer')
        }
        let params = {name: name, account: account['@id']}

        let response = await apiPostEntity('form_lists', params)

        handleAction('form', {name: response.name, id: response.id})
        setAddingCategorie(false)

    }

    const [changeTimeout, setChangeTimeout] = useState(null)

    function handleInput(e) {
        clearInterval(changeTimeout)

        setChangeTimeout(setTimeout(function () {
            let value = e.target.value.trim() ||""
            let match = value.match(/^(http|https):\/\//)
            if (!match) {
                value = 'https://' + value
            }
            handleAction('link', value)
        }, 300))
    }


    return (
        <Tabs defaultActiveKey="params" size="small" tabBarGutter={5} style={{width: '100%', maxWidth: '300px'}}>
            <Tabs.TabPane tab="Paramètres" key="params">
                <Space direction="vertical" style={{width: '100%'}}>
                    <div>
                        <div className="flex between">
                            <p><strong>Campagne</strong></p>
                            <Button size="small" icon={<PlusOutlined/>}
                                    title="Créer une campagne"
                                    onClick={() => setAddingCategorie(prev => !prev)}/>
                        </div>
                        {
                            addingCategorie ?
                                <div>
                                    Créer une campagne
                                    <Input.Search placeholder="Nom de votre campagne" enterButton="Créer"
                                                  onSearch={handleSearch}/>
                                </div>
                                :
                                <FormListSelection accountId={account.id} form={form.form} setForm={handleAction}/>
                        }
                    </div>
                    <div>
                        <p><strong>Action lors de la validation</strong></p>

                        <Space direction="vertical">
                            <Radio.Group onChange={(e) => handleAction('action', e.target.value)}
                                         value={form.action}>
                                <Space direction="vertical">
                                    <Radio value={'close'}>Fermer le message</Radio>
                                    <Radio value={'link'}>Envoyer vers sur une page</Radio>
                                </Space>
                            </Radio.Group>
                            {
                                form.action === "link" &&
                                <Input defaultValue={form.link} placeholder={'Entrer url'} onChange={handleInput}/>
                            }
                        </Space>
                    </div>
                </Space>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Champs" key="fields">
                <Space direction="vertical" style={{width: '90%'}}>
                    <div>
                        <p><strong>Champs</strong></p>
                        <Checkbox.Group options={baseOptions} value={checked} onChange={handleChange}/>
                    </div>
                    <div>
                        <p><strong>Champs actifs</strong></p>

                        {
                            form.items.map(item =>
                                <FormInputs inputValues={item} key={item.name} setInputValues={updateInputValue}
                                            removeInput={removeItem}/>
                            )
                        }
                    </div>
                </Space>
            </Tabs.TabPane>
        </Tabs>
    )
}