import React, {useState} from 'react'
import {Button, Popover} from "antd";
import {ReactComponent as PaletteIcon} from '../../assets/icons/color-palette.svg'
import ColorTab from "./tabs/ColorTab";

export default function ColorOptions() {

    const [isOpen, setIsOpen] = useState(false)

    const content = (
        <div className="insert" style={{height: "auto", maxHeight: "400px"}}>
            <ColorTab isOpen={isOpen}/>
        </div>
    )

    const handleVisible = (value) => {
        console.log(value)
        setIsOpen(value)
    }

    return (
        <Popover placement="right"
                 title={
                     <div className="insert-title">
                         Modifier une couleur
                     </div>
                 }
                 content={content}
                 trigger="click"
                 onVisibleChange={handleVisible}
                 destroyTooltipOnHide={true}>
            <Button className={`action-btn ${isOpen ? 'active' : ''}`} title="Modifier une couleur">
                <PaletteIcon color={isOpen ? 'white' : 'black'} style={{width: '32px'}}/>
            </Button>
        </Popover>
    )
}