import React, {useEffect, useState} from 'react'
import {useApiContext} from "../providers/ApiProvider";
import {Button, Modal, Timeline} from "antd";
import { ClockCircleOutlined, HomeOutlined, UserSwitchOutlined} from "@ant-design/icons";
import Preview from "../components/editor/Preview";
import {TimelineCard} from "./TimelineCard";
import {useAccountContext} from "../providers/AccountProvider";

export default function AccountTimeline({visible, setVisible}) {

    const [accountState] = useAccountContext()
    const [apiDispatch] = useApiContext();
    const {apiPostEntity} = apiDispatch
    const [messages, setMessages] = useState([])
    const [content, setContent] = useState({
        arrival: [],
        visit: [],
        later: []
    })
    const [messageID, setMessageID] = useState(null)


    useEffect(() => {
        fetchActiveMessage()
    }, [visible])

    async function fetchActiveMessage() {
        let params = {
            accountId: accountState.account.id
        }
        let response = await apiPostEntity("get-timeline-messages", params)

        handleResponse(response)
        setMessages(response)
    }

    function handleResponse(messages) {
        let newContent = {
            arrival: [],
            visit: [],
            later: []
        }
        messages.map(msg => {

            let msgContent = msg.online

            if (msgContent.behavior.type === 'delay' && msgContent.behavior.delay < 10) {
                newContent.arrival.push(msg)
            } else if (msgContent.behavior.type === 'delay' && msgContent.behavior.delay < 30) {
                newContent.visit.push(msg)
            } else {
                newContent.later.push(msg)
            }
        })

        setContent(newContent)

    }

    return (
        <Modal title="Cartographie" visible={visible} style={{minWidth: '1000px'}}
            onCancel={()=>setVisible(false)}
               footer={[
                   <Button onClick={()=>setVisible(false)}>Fermer</Button>
               ]}
        >
            <div style={{width: '100%', minHeight: '500px', padding: '2em'}}>
                <Timeline mode={"alternate"}>
                    <Timeline.Item dot={<HomeOutlined style={{fontSize: '16px'}}/>}>
                        <TimelineCard title={'Lorsque le visiteur arrive'} content={content.arrival}
                                      empty={'Aucun message ne s\'affiche lors de l\'arrivé du visiteur'}
                                      setMessageID={setMessageID}/>
                    </Timeline.Item>
                    <Timeline.Item dot={<ClockCircleOutlined style={{fontSize: '16px'}}/>}>
                        <TimelineCard title={'Durant la visite'} content={content.visit}
                                      empty={'Aucun message ne s\'affiche plus tard lors de la visite'}
                                      setMessageID={setMessageID}/>
                    </Timeline.Item>
                    <Timeline.Item dot={<UserSwitchOutlined style={{fontSize: '16px'}}/>}>
                        <TimelineCard title={'Lors d\'une action spécifique'} content={content.later}
                                      empty={'Aucun message ne s\'affiche lorsque l\'utilisateur effectue une action'}
                                      setMessageID={setMessageID}/>
                    </Timeline.Item>
                </Timeline>
                {
                    messageID ?
                        <Preview messageID={messageID} setMessageId={setMessageID}/>
                        : null
                }
            </div>
        </Modal>
    )
}