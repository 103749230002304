import React, {useCallback, useEffect, useRef, useState} from "react"
import {SearchOutlined} from "@ant-design/icons";
import {Button, Checkbox, Input, Modal, Select, Space} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import TemplateColumn from "../TemplateColumn";
import {isObserverAvailable} from "../../helpers";
import {useAccountContext} from "../../providers/AccountProvider";
import pixabayLogo from "../../assets/icons/pixabay.svg"

const isObserverWorking = isObserverAvailable();

const keyWords = ["Paris", "Cannes", "Arc de triomphe", "Montmartre", "Marseille"];

export default function ModalPixabay({addImage, visible, setVisible}) {

    const [accountState] = useAccountContext();
    const [apiDispatch] = useApiContext();
    const {apiPostEntity} = apiDispatch;
    const observer = useRef();

    const [images, setImages] = useState([]);
    const [selected, setSelected] = useState("");
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [page, setPage] = useState(1);

    const [keyWord, setKeyWord] = useState(arrayRandom(keyWords));
    const [orientation, setOrientation] = useState("all");

    const [loadingBtn, setLoadingBtn] = useState(false);

    useEffect(() => {
        if (visible)
            fetch();
    }, [visible])

    useEffect(() => {
        if (visible)
            fetch(true);
    }, [page])

    async function fetch(next = false) {
        setLoading(true);
        const currentPage = next ? page : 1;

        const data = {
            q: keyWord,
            orientation: orientation,
            page: currentPage
        }

        const response = await apiPostEntity("pixabay", data);

        if (next)
            setImages(images => [...images, ...response.hits]);
        else
            setImages(response.hits);

        setHasMore(currentPage * 30 < response.total);

        if (!next && page !== 1)
            setPage(1);

        setTimeout(() => {
            setLoading(false);
        }, 500)

    }

    const lastAssetElement = useCallback(node => {
        if (!isObserverWorking) return;
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore)
                setPage(page => page + 1);
        })
        if (node) observer.current.observe(node);
    }, [loading, hasMore])

    function handleClick(url) {
        setSelected(selected === url ? "" : url);
    }

    function arrayRandom(array) {
        return array[Math.floor(Math.random() * array.length)]
    }

    async function handleOk() {
        if (selected) {
            setLoadingBtn(true);
            let data = {
                url: selected,
                accountId: accountState.account.id
            }

            const response = await apiPostEntity('push-image-to-dam', data);

            setLoadingBtn(false);

            if (response.status)
                addImage(response.link);
            else
                return message.error('Il y a eu une erreur')
        }

        return setVisible(false);
    }

    function displayImage(item, key, column, columnLength) {
        return (
            <div
                ref={column === 1 && columnLength === key + 1 ? lastAssetElement : null}
                key={key}
                className="img_container pointer"
                onClick={() => handleClick(item.fullHDURL)}
                style={
                    selected.includes(item.fullHDURL) ?
                        {boxShadow: "orange 0 0 10px"} : null
                }
            >
                <Checkbox
                    style={{position: "absolute", top: 10, right: 10}}
                    checked={selected.includes(item.fullHDURL)}
                />
                <img
                    src={item.webformatURL}
                    alt={item.tags}
                />
            </div>
        )
    }

    return (
        <>
            {/*<Button type="primary" onClick={() => setVisible(true)}>Open Pixabay</Button>*/}

            <Modal
                zIndex={1050}
                width={1200}
                style={{top: 20}}
                bodyStyle={{height: "80vh", overflow: "auto", position: "relative"}}
                title="Image Pixabay"
                visible={visible}
                onCancel={() => setVisible(false)}
                onOk={() => setVisible(false)}
                footer={[
                    <Button key="back" onClick={() => setVisible(false)}>
                        Retour
                    </Button>,
                    <Button key="submit" type="primary" loading={loadingBtn} onClick={handleOk}>
                        Valider
                    </Button>
                ]}
            >
                <div className="flex justify-center">
                    <div style={{position: "absolute", right: 10, top: 0}}>
                            <a href="https://pixabay.com/" target="_blank">
                                Images from &nbsp;
                                <img style={{width: 50}}
                                     src={pixabayLogo}
                                     alt="pixabay_logo"/>
                            </a>&nbsp;
                    </div>

                    <Space>
                        <Input
                            style={{width: 250}}
                            placeholder="rechercher par mot-clé"
                            value={keyWord}
                            onChange={e => setKeyWord(e.target.value)}
                            onPressEnter={() => fetch(false)}
                        />
                        <Select
                            value={orientation}
                            style={{width: 200, zIndex: 20000}}
                            placeholder="choisir une orientation"
                            onChange={value => setOrientation(value)}
                            allowClear
                        >
                            <Select.Option value="all">tout</Select.Option>
                            <Select.Option value="horizontal">paysage</Select.Option>
                            <Select.Option value="vertical">portrait</Select.Option>
                        </Select>
                        <Button type="primary" icon={<SearchOutlined/>} onClick={() => fetch(false)}/>
                    </Space>
                </div>

                <div className="galery" style={{maxWidth: 1400}}>
                    <TemplateColumn images={images} loading={loading} displayImage={displayImage}/>
                </div>
            </Modal>
        </>
    )
}