import React from 'react'
import logo from "../assets/logo.png";
import {Menu} from "antd";
import {Link} from "react-router-dom";

export default function HeaderMenu({accountState}) {

    return (
        <div className="flex between align-center">

            <div className="logo">
                <Link to={'/'}>
                    <img
                        alt="Hotel push marketing logo"
                        className="editor-shape-img"
                        src={logo}
                    />
                </Link>
            </div>
            <div>
                <Menu theme="dark" mode="horizontal" selectedKeys={[window.location.pathname]}>
                    <Menu.Item key="/" className={'header-menu-item'}>
                        <Link to="/">ACCUEIL</Link>
                    </Menu.Item>
                    {accountState.account ?
                        <>
                            <Menu.Item key="/dashboard"
                                       className={'header-menu-item'}>
                                <Link to="/dashboard">TABLEAU DE BORD</Link>
                            </Menu.Item>
                            <Menu.Item key="/formulaires"
                                       className={'header-menu-item'}>
                                <Link to="/formulaires">FORMULAIRES</Link>
                            </Menu.Item>
                        </> : null}
                </Menu>
            </div>
        </div>
    )
}