import React from "react";
import ReactDOM from "react-dom";

//The implementation of using react dom. Create portal
export function ShadowContent({ root, children }) {
    return ReactDOM.createPortal(children, root);
}

export class ShadowView extends React.Component {


    state = { root: null };
    setRoot = element => {
        if (!element) return
        const root = element.attachShadow({ mode: "open" });
        let fileCss = document.createElement("link");
        fileCss.setAttribute('rel', 'stylesheet');
        fileCss.setAttribute('href', 'https://v2.hotelpushmarketing.com/widget.css');
        // fileCss.setAttribute('href', 'http://localhost:1337/widget.css');

        root.appendChild(fileCss);
        this.setState({ root });
    };
    render() {
        const { children } = this.props;
        const { root } = this.state;
        return <div ref={this.setRoot} style={{position:'absolute'}}>
            {root && <ShadowContent root={root} >
                {children}
            </ShadowContent>}
        </div>;
    }
}