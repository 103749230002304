import React, {useRef, useState} from 'react'
import {Button, Popover} from "antd";
import {SettingOutlined} from "@ant-design/icons";
import PictureTab from "../tabs/PictureTab";
import {useEditorContext} from "../../../providers/EditorProvider";

export default function ImageActions({element}) {

    const popRef = useRef()
    const [editorState, editorDispatch] = useEditorContext()
    const {setter} = editorDispatch
    const [isOpen, setIsOpen] = useState()

    const dropMenu = () => {
        return (
            <PictureTab setVisible={() => popRef.current.close()} target={element} isOpen={isOpen}/>
        )
    }

    const handleVisibleChange = (val) => {
        setter('editMode', val)
        setIsOpen(val)
    }

    return (
        <Popover content={dropMenu} title={'Changer l\'image'} trigger='click' placement="left" ref={popRef}
                 onVisibleChange={handleVisibleChange}>
            <Button icon={<SettingOutlined/>}/>
        </Popover>
    )
}