import React, {useEffect, useRef, useState} from 'react'
import {useEditorContext} from "../../providers/EditorProvider";
import {Dropdown, Menu, Select} from "antd";
import ElementContent from "./ElementContent";
import ElementMoveable from "./ElementMoveable";
import CloseButton from "./CloseButton";
import {animationStyle} from "../../widget/helpers";
import {faArrowDown, faArrowUp, faBringFront, faSendBack} from "@fortawesome/pro-solid-svg-icons";
import {ReactComponent as CopyIcon} from "../../assets/icons/copy_icon.svg";
import {ReactComponent as DeleteIcon} from "../../assets/icons/delete_icon.svg";
import FaIcon from "../FaIcon";
import {uniqueElementList} from "./utils";


export default function Board() {

    const [editorState, editorDispatch] = useEditorContext()
    const {unSelectElement, duplicateElement, removeElement, bringToFront, sendToBack, updatePosition} = editorDispatch;
    const [elementGuidelines, setElementGuidelines] = React.useState([]);
    const [scale, setScale] = useState(1)
    const [editMode, setEditMode] = useState(false)
    const [initialScale, setInitialScale] = useState(null)
    const [animStyle, setAnimStyle] = useState({})
    const board = useRef()
    const messageContainer = useRef()
    const messageContainerDiv = useRef()

    useEffect(() => {
        if (!initialScale && editorState.messageId) handleResize()

        setElementGuidelines([
            ...document.querySelectorAll(".targets"),
            document.getElementById("container")
        ]);
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }

    }, [editorState.message.children])

    useEffect(() => {
        if (editorState.messageId) handleResize()
    }, [editorState.message.style.width, editorState.message.style.height])

    useEffect(() => {

        if (editorState.message.id !== 0 && editorState.playAnimation) {
            let animCode = animationStyle[editorState.playAnimation].code
            setAnimStyle({transform: `scale(1) ${animCode}`, opacity: 0, transition: '0s all'})

            setTimeout(() => {
                setAnimStyle({})
            }, 400)
        }

    }, [editorState.playAnimation, editorState.currentStep])


    const handleResize = () => {

        let initScale = calculateScale()

        initScale = initScale > 1 ? 1 : initScale
        setInitialScale(initScale)
        setScale(initScale)
    }

    const calculateScale = () => {
        let percentage = 1

        let sizes = getSizes();

        sizes.map(size => {
            if (size.documentValue < size.containerValue) {
                let sizePercent = size.documentValue / size.containerValue
                percentage = sizePercent < percentage ? sizePercent : percentage
            }
        })

        return percentage
    }

    //return an array with sizes value, first object is the width and second the height
    const getSizes = () => {

        let documentWidth = board.current.clientWidth - 30
        let documentHeight = board.current.clientHeight - 30;

        let options = editorState.message.options

        let verticalOffset = options.verticalOffset * documentHeight / 100
        let horizontalOffset = options.horizontalOffset * documentWidth / 100

        return [
            {
                containerValue: parseFloat(editorState.message.style.width) + horizontalOffset,
                documentValue: documentWidth,
                offset: options.horizontalOffset
            },
            {
                containerValue: parseFloat(editorState.message.style.height) + verticalOffset,
                documentValue: documentHeight,
                offset: options.verticalOffset

            }
        ]

    }

    const getAlign = (value) => {
        if (board.current && scale > initialScale) {
            if (board.current[value] < messageContainer.current[value])
                return '100%'
        }
        return 'center'
    }


    const menu = (
        <Menu>
            {
                !uniqueElementList.includes(editorState.selectedElement?.type) &&
                <Menu.Item key="duplicate" onClick={() => duplicateElement(editorState.selectedElement.id)}
                           icon={<CopyIcon style={{width: '12px', color: '#2494D1'}}/>}>&nbsp; Dupliquer</Menu.Item>
            }
            <Menu.Item key="delete" onClick={() => removeElement(editorState.selectedElement.id)}
                       icon={<DeleteIcon style={{width: '12px', color: '#2494D1'}}/>}>&nbsp; Supprimer</Menu.Item>
            <Menu.Item key="up" onClick={() => updatePosition('up', editorState.selectedElement.id)}
                       icon={<FaIcon icon={faArrowUp} style={{color: '#2494D1'}}/>}>&nbsp;Envoyer vers
                l'avant</Menu.Item>
            <Menu.Item key="front" onClick={() => bringToFront(editorState.selectedElement.id)}
                       icon={<FaIcon icon={faBringFront} style={{color: '#2494D1'}}/>}>&nbsp;Mettre à
                l'avant</Menu.Item>
            <Menu.Item key="down" onClick={() => updatePosition('bot', editorState.selectedElement.id)}
                       icon={<FaIcon icon={faArrowDown} style={{color: '#2494D1'}}/>}>&nbsp;Envoyer vers
                l'arrière</Menu.Item>
            <Menu.Item key="back" onClick={() => sendToBack(editorState.selectedElement.id)}
                       icon={<FaIcon icon={faSendBack} style={{color: '#2494D1'}}/>}>&nbsp;Mettre à
                l'arrière</Menu.Item>
        </Menu>
    );

    return (
        <div id="board-container" className="board"
             style={editorState.message.type === 'modal' ?
                 {
                     backgroundColor: editorState.message.options.modalBackground,
                     backgroundImage: `url(${editorState.message.options.backgroundImage ? editorState.message.options.backgroundImage.replace('size=sm', 'size=lg') : ''})`
                 }
                 : {}}
             ref={board}>

            <div style={{position: "absolute", top: "10px", left: "10px", display: 'flex', flexDirection: 'column'}}>
                <Select onChange={(value) => setScale(value)} value={scale}>
                    <Select.Option value={initialScale * 1.2}>120%</Select.Option>
                    <Select.Option value={initialScale}>100%</Select.Option>
                    <Select.Option value={initialScale * 0.5}>50%</Select.Option>
                </Select>

            </div>

            <div style={{
                transform: ` scale(${scale})`,
                padding: '1em',
                transformOrigin: scale > initialScale ? `${getAlign('clientWidth')} ${getAlign('clientHeight')}` : 'center'
            }}>
                <Dropdown overlay={editorState.editMode || !editorState.selectedElement ? <></> : menu}
                          trigger={editorState.editMode || !editorState.selectedElement || editorState?.selectedElement?.id == 'closeBTN' ? [] : ['contextMenu']}>
                    <div id="container"
                         ref={messageContainer}
                         onClick={(e) => {
                             e.stopPropagation()
                         }}
                         style={{
                             ...editorState.message.style,
                             overflow: 'visible',
                             position: 'relative',
                             top: '0',
                             left: '0',
                             transition: '.4s all',
                             ...animStyle
                         }}>
                        <div style={{
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            borderRadius: editorState.message.style.borderRadius,
                            zIndex: 0
                        }}
                             ref={messageContainerDiv}
                             onClick={(e) => {
                                 e.stopPropagation()
                                 if (e.target === messageContainerDiv.current) unSelectElement()
                             }}>
                            {
                                editorState.message.children.map(element =>
                                    <ElementContent element={element}
                                                    key={'content_' + element.id}
                                                    isSelected={editorState.selectedElement?.id === element.id}
                                                    setEditMode={setEditMode}
                                                    contextMenu={menu}
                                                    editMode={editMode && editorState.selectedElement?.id === element.id}
                                    />
                                )
                            }
                        </div>
                        {
                            editorState.message.children.map(element => {
                                let target = document.getElementById(element.id)
                                return (

                                    <ElementMoveable target={target} key={'moveable_' + element.id}
                                                     setEditMode={setEditMode} lng={editorState.lng}
                                                     editMode={editMode && editorState.selectedElement?.id === element.id}
                                                     isSelected={editorState.selectedElement?.id === element.id}
                                                     element={element} elementGuidelines={elementGuidelines}/>
                                )
                            })
                        }
                        {
                            editorState.message?.options?.closeButton && editorState.message.options.closable &&
                            <CloseButton element={editorState.message.options.closeButton}
                                         elementGuidelines={elementGuidelines}
                                         isSelected={editorState.selectedElement?.id === 'closeBTN'}/>
                        }

                    </div>
                </Dropdown>

            </div>

        </div>

    )
}