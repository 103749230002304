import React, {useState} from 'react'
import {Checkbox, Input} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {useEditorContext} from "../../../../providers/EditorProvider";

export default function FormInputs({inputValues, setInputValues, removeInput}) {

    const [editorState] = useEditorContext()
    const [values, setValues] = useState(inputValues)
    const [changeTimeout, setChangeTimeout] = useState(null)

    function handleChange(property, value) {
        clearInterval(changeTimeout)
        setChangeTimeout(setTimeout(() => {
            let newValues = {...values}
            if (property === 'required') {
                newValues[property]= value
            } else {
                newValues[property] ={...values.placeholder, [editorState.lng]: value}
            }
            setValues(newValues)
            setInputValues(newValues)

        }, 250))
    }

    return (
        <div className="form-field">
            <p>{values.label} <DeleteOutlined title={"Supprimer"} onClick={() => removeInput(inputValues.name)} style={{color: '#E64D3D'}}/></p>
            <Input defaultValue={values.placeholder[editorState.lng] || values.placeholder.fr}
                   onChange={(e) => handleChange('placeholder', e.target.value.trim())}/>
            <Checkbox defaultChecked={values.required}
                      onChange={(e) => handleChange('required', e.target.checked)}>Requis</Checkbox>
        </div>
    )
}