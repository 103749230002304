import React from "react";
import {Checkbox, Form, Radio} from "antd";
import {DesktopOutlined, MobileOutlined} from "@ant-design/icons";

const osOptions = ["Windows", "Mac", "Linux", "Ios", "Android", "Windows Phone"]

const CheckboxGroup = Checkbox.Group;


export default function DevicePanel({behavior, setBehavior, setNeedUpdate}) {


    function handleChange(changedValues, allValues) {

        const values = {
            isMobile: allValues.isMobile ?? null,
            osRestriction: allValues.osRestriction
        };

        setBehavior(prev=> {return {...prev,...values}})
        setNeedUpdate((new Date()).getTime())
    }

    return (
        behavior.id ?
        <Form
            name="deviceForm"
            layout="vertical"
            initialValues={{
                isMobile: behavior.isMobile,
                osRestriction: behavior.osRestriction,
            }}
            onValuesChange={handleChange}
        >
            <h4>Restreindre l’affichage de ce message...</h4>
            <Form.Item name="isMobile" label="Appareil">
                <Radio.Group
                    buttonStyle="solid"
                    size="small"
                >
                    <Radio.Button value={undefined}><DesktopOutlined/>/<MobileOutlined/></Radio.Button>
                    <Radio.Button value={false}><DesktopOutlined/></Radio.Button>
                    <Radio.Button value={true}><MobileOutlined/></Radio.Button>
                </Radio.Group>
            </Form.Item>

            <Form.Item name="osRestriction" label={'Afficher si le système d\'exploitation est dans '}>
                <CheckboxGroup options={osOptions}/>
            </Form.Item>
        </Form>
            : <div>Missing data</div>
    )

}