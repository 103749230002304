import React from 'react'
import {ReactComponent as CalendarIcon} from '../../../assets/icons/behavior_temp_icon.svg'
import {ReactComponent as CountDownIcon} from '../../../assets/icons/behaviour_countdown_icon.svg'
import {ReactComponent as LinkIcon} from '../../../assets/icons/behaviour_page_icon.svg'
import {ReactComponent as LocationIcon} from '../../../assets/icons/behaviour_geolocalisation_icon.svg'
import {ReactComponent as RefererIcon} from '../../../assets/icons/behaviour_referer_icon.svg'
import {ReactComponent as EyeIcon} from '../../../assets/icons/behaviour_eye_icon.svg'
import {ReactComponent as DeviceIcon} from '../../../assets/icons/behaviour_devices_icon.svg'
import {Switch} from "antd";


export default function BehaviorSelection({pageChange, selected, behavior, setIsActive}) {

    return (
        <div className="flex between" style={{padding: '8px 16px', backgroundColor: "white", borderRadius: '8px'}}>
            <div onClick={() => pageChange(0)} className={`behavior-btn ${selected === 0 ? 'selected' : ''} justify-center`}>
                <EyeIcon width="20px"/>
                <span>Visibilité</span>
            </div>

            <div onClick={() => pageChange(1)} className={`behavior-btn ${selected === 1 ? 'selected' : ''}`}>
                <div style={{marginBottom: '10px'}}>
                    {
                        behavior.isDeviceActive ? 'Actif' : 'Inactif'
                    }
                    <Switch checked={behavior.isDeviceActive}
                            size="small"
                            className="ml-10"
                            onClick={(value, e)=>setIsActive(e, 'isDeviceActive', value)} />
                </div>
                <DeviceIcon width="25px" />
                <span>Appareils</span>
            </div>

            <div onClick={() => pageChange(2)} className={`behavior-btn ${selected === 2 ? 'selected' : ''}`}>
                <div style={{marginBottom: '10px'}}>
                    {
                        behavior.isFrequencyActive ? 'Actif' : 'Inactif'
                    }
                    <Switch checked={behavior.isFrequencyActive}
                            size="small"
                            className="ml-10"
                            onClick={(value, e)=>setIsActive(e, 'isFrequencyActive', value)} />
                </div>
                <CountDownIcon width="20px"/>
                <span>Fréquence d'affichage</span>
            </div>

            <div onClick={() => pageChange(3)} className={`behavior-btn ${selected === 3 ? 'selected' : ''}`}>
                <div style={{marginBottom: '10px'}}>
                    {
                        behavior.isPageActive ? 'Actif' : 'Inactif'
                    }
                    <Switch checked={behavior.isPageActive}
                            size="small"
                            className="ml-10"
                            onClick={(value, e)=>setIsActive(e, 'isPageActive', value)} />
                </div>
                <LinkIcon width="20px"/>
                <span>Lier un scénario à une page</span>
            </div>

            <div onClick={() => pageChange(4)} className={`behavior-btn ${selected === 4 ? 'selected' : ''}`}>
                <div style={{marginBottom: '10px'}}>
                    {
                        behavior.isLocalisationActive ? 'Actif' : 'Inactif'
                    }
                    <Switch checked={behavior.isLocalisationActive}
                            size="small"
                            className="ml-10"
                            onClick={(value, e)=>setIsActive(e, 'isLocalisationActive', value)} />
                </div>
                <LocationIcon width="20px"/>
                <span>Géolocalisation et météo</span>
            </div>

            <div onClick={() => pageChange(5)} className={`behavior-btn ${selected === 5 ? 'selected' : ''}`}>
                <div style={{marginBottom: '10px'}}>
                    {
                        behavior.isSourceActive ? 'Actif' : 'Inactif'
                    }
                    <Switch checked={behavior.isSourceActive}
                            size="small"
                            className="ml-10"
                            onClick={(value, e)=>setIsActive(e, 'isSourceActive', value)} />

                </div>
                <RefererIcon width="20px"/>
                <span>Site référent</span>
            </div>

            <div onClick={() => pageChange(6)} className={`behavior-btn ${selected === 6 ? 'selected' : ''}`}>
                <div style={{marginBottom: '10px'}}>
                    {
                        behavior.isPeriodActive ? 'Actif' : 'Inactif'
                    }
                    <Switch checked={behavior.isPeriodActive}
                            size="small"
                            className="ml-10"
                            onClick={(value, e)=>setIsActive(e, 'isPeriodActive', value)} />
                </div>
                <CalendarIcon width="20px"/>
                <span>Ciblage temporel</span>
            </div>

        </div>
    )
}