import React, {useEffect, useState, useRef} from 'react'
import {useParams} from "react-router-dom";
import {useApiContext} from "../providers/ApiProvider";
import Message from "../widget/Message";
import {injectFonts} from "../components/editor/utils";

export default function PageScreen() {

    const [apiDispatch] = useApiContext();
    const {apiFetchEntityPublic} = apiDispatch;
    const params = useParams();

    const [message, setMessage] = useState({});
    const [loading, setLoading] = useState(true);
    const container = useRef();

    useEffect(() => {
        fetch();
    }, [])

    async function fetch() {
        const response = await apiFetchEntityPublic("get-message-for-screen", params.id);

        if (response.fonts.length)
            injectFonts(response.fonts);

        setMessage(response);
        setLoading(false);
    }

    return (
        loading ?
            null :
            <div ref={container} id="screenshot">
                <Message message={message} handleClick={() => {
                }} debug={true}/>
            </div>
    )
}