import React, {useState} from 'react'
import {Button, Popover} from "antd";
import { ReactComponent as LayerIcon }  from '../../assets/icons/layers.svg'

import LayersTab from "./tabs/LayersTab";

export default function Layers() {

    const [isOpen, setIsOpen] = useState(false)

    const content = (
        <div style={{width: '300px'}}>
                <div className="insert">
                    <LayersTab />
                </div>
        </div>
    )

    const handleVisible = (value) => {
        setIsOpen(value)
    }

    return (
        <Popover placement="right"
                 title={
                     <div className="insert-title">
                         Calques
                     </div>
                 }
                 content={content}
                 trigger="click"
                 onVisibleChange={handleVisible}
                 destroyTooltipOnHide={true}>
            <Button className={`action-btn ${isOpen ? 'active': ''}`} title="Calques"><LayerIcon fill={isOpen ? 'white' : 'black'} style={{width:'30px'}} /></Button>
        </Popover>
    )
}