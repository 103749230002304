import React, {useEffect, useState} from "react";
import Message from "../../widget/Message";
import {useApiContext} from "../../providers/ApiProvider";
import {ShadowView} from "../ShadowRoot";
import {message} from "antd";

export default function Preview({messageItem, messageID, setMessageId = () => {}, lng = 'fr'}) {

    const [apiDispatch] = useApiContext();
    const {apiFetchEntity} = apiDispatch;
    const [messageContent, setMessageContent] = useState(messageItem)
    const [isVisible, setIsVisible] = useState(false)
    const [isStepVisible, setIsStepVisible] = useState(false)
    const [closeTimeout, setCloseTimeout] = useState(null)

    useEffect(() => {
        setMessageContent(null)
        if (messageID) {
            let id = messageID.replace('/api/message_contents/', '')
            fetchMessage(id)
        }
    }, [messageID])


    useEffect(() => {
        setMessageContent(messageItem)
    }, [messageItem])

    useEffect(() => {
        if (messageContent) {
            setTimeout(() => {
                setIsVisible(true)
            }, 200)

            if (messageContent.behavior.closeDelay)
                setCloseTimeout(setTimeout(() => closeOnClick(), messageContent.behavior.closeDelay * 1000));
        }
    }, [messageContent])

    const fetchMessage = async (id) => {
        setIsVisible(false)
        let response = await apiFetchEntity('get-message-for-preview', id)
        if (response['@type'] === "hydra:Error")
            return message.error('Il y à eu une erreur, actualiser la page et réessayer, si le problème persiste contacter mmcréation');

        setMessageContent(response.online)
    }

    const closeOnClick = (id, action) => {
        if (id === messageContent.id && messageContent.nextStep && action === messageContent.options.stepTrigger) {
            console.log('in')
            setIsVisible(false)
            return showNextStep(messageContent.nextStep)
        }
        if (action === 'container') return
        setIsVisible(false)
        setIsStepVisible(false)
        clearTimeout(closeTimeout)
        setCloseTimeout(null)
        setMessageId(null)
    }

    const showNextStep = (step) => {
        setTimeout(() => {
            setIsStepVisible(true)
        }, 0)

        if (step.behavior.closeDelay)
            setTimeout(() => closeOnClick(), step.behavior.closeDelay * 1000);
    }

    return (
        messageContent ?
            <ShadowView>
                <div id={"msgContainer_" + messageContent.id}>
                    {
                        messageContent && isVisible &&
                        <Message message={messageContent} handleClick={closeOnClick} debug={true} language={lng}/>
                    }
                    {
                        messageContent.nextStep && isStepVisible &&
                        <Message message={messageContent.nextStep} handleClick={closeOnClick} debug={true}
                                 language={lng}/>
                    }
                </div>
            </ShadowView>
            : null
    )
}