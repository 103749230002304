import React from 'react'
import { Redirect} from "react-router-dom";
import {useAuthContext} from "../providers/AuthProvider";
import {Button, Form, Input, message, PageHeader} from "antd";
import {useApiContext} from "../providers/ApiProvider";

export default function CreateAccount({}) {

    const [authState] = useAuthContext();
    const {auth} = authState;
    const [apiDispatch] = useApiContext()
    const {apiPostEntity} = apiDispatch

    if (!auth.roles.includes('ROLE_ADMIN')) {
        return <Redirect to={{
            pathname: "/erreur",
            state: {status: '403', subtitle: t('vous n\'êtes pas autorisé à accéder à cette page')}
        }}/>
    }

    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 12,
        },
    };

    const tailLayout = {
        wrapperCol: {
            offset: 4,
            span: 8,
        },
    };

    const onFinish = (values) => {
        console.log('Success:', values);
        postAccount(values)
    };

    const postAccount = async (values) => {
        let response = await apiPostEntity('accounts', values)
        message.success('Compte crée avec succès')
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <div className="contenu-tab">
            <PageHeader
                title="Creation de compte"
            />
            <Form
                {...layout}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Name is required!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Website url"
                    name="url"
                    rules={[
                        {
                            required: true,
                            message: 'Url is required!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Valider
                    </Button>
                </Form.Item>
            </Form>

        </div>
    )

}