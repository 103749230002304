import React from "react"
import {Button, Modal} from "antd";
import TimerList from "../Timers/TimerList";


export default function ModalCountdown({visible, setVisible, selectTemplate}) {

    return (
        <>
            <Modal
                zIndex={1050}
                width={700}
                title="Décomptes"
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={[
                    <Button key="back" onClick={() => setVisible(false)}>
                        Retour
                    </Button>
                ]}
            >
                <TimerList selectTemplate={selectTemplate} items={10}/>
            </Modal>
        </>
    )
}