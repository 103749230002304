import React, {useState} from 'react'
import {Button, message, Modal, Popconfirm} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import CollectionTable from "../CollectionTable";
import {ReactComponent as DeleteIcon} from "../../assets/icons/delete_icon.svg";
import Icon from "@ant-design/icons";

export default function ModalFormList({isVisible, setIsVisible, accoundId, setNeedUpdate}) {

    const [apiDispatch] = useApiContext();
    const {apiDeleteEntity} = apiDispatch;
    const [update, setUpdate] = useState(false)


    const columns = [
        {
            title: 'Nom',
            dataIndex: "name",
            render: (name, record) => (
                <span>
                    {name}
                </span>)
        },
        {
            title: 'Enregistrement',
            dataIndex: "formData",
            render: (formData) => (
                <span>
                    {formData.length}
                </span>)
        },
        {
            title: 'Action',
            dataIndex: "id",
            render: (id, record) => (
                <span>
                    {
                        <Popconfirm
                            title="Etes vous sûr ? Les entrées de cette liste seront supprimées"
                            onConfirm={(e) => confirm(e, id)}
                            okText="Oui"
                            cancelText="Non"
                        >
                            <Icon component={DeleteIcon} title="Supprimer"/>
                        </Popconfirm>
                    }
                </span>
            )
        }
    ]

    function confirm(e, id) {
        console.log(id)
        handleRemove(id)
    }

    async function handleRemove(id) {
        const response = await apiDeleteEntity("form_lists", id);

        message.success("Element supprimé avec succès")
        setNeedUpdate(prev => prev + 1)
        setUpdate(update + 1)
    }

    return (
        <Modal
            visible={isVisible}
            onCancel={() => setIsVisible(false)}
            title={'Créer une catégorie de formulaire'}
            footer={[
                <Button key="cancel_create" onClick={() => setIsVisible(false)}>
                    Retour
                </Button>
            ]}>
            <CollectionTable columns={columns} reload={update}
                             endpoint={`accounts/${accoundId}/form_lists`}
            />
        </Modal>
    )

}

