import React, {useEffect, useRef, useState} from 'react'
import {Button, Carousel, Popover} from "antd";
import { LeftCircleOutlined} from "@ant-design/icons";
import PictureTab from "./tabs/PictureTab";
import TextTab from "./tabs/TextTab";
import ButtonTab from "./tabs/ButtonTab";
import IconTab from "./tabs/IconTab";
import FormTab from "./tabs/FormTab";
import CountDownTab from "./tabs/CountDownTab";
import AddVideo from "./tabs/Component/AddVideo";
import {ReactComponent as PictureIcon} from "../../assets/icons/picture_element.svg";
import {ReactComponent as CountdownIcon} from "../../assets/icons/countdown_element.svg";
import {ReactComponent as TextIcon} from "../../assets/icons/text_element.svg";
import {ReactComponent as FormIcon} from "../../assets/icons/form_element.svg";
import {ReactComponent as ButtonIcon} from "../../assets/icons/button_element.svg";
import {ReactComponent as VideoIcon} from "../../assets/icons/video_element.svg";
import {ReactComponent as IconsIcon} from "../../assets/icons/icons_element.svg";
import {ReactComponent as PlusIcon} from '../../assets/icons/plus_icon.svg'
import {ReactComponent as CloudyIcon} from '../../assets/icons/cloudy.svg'
import WeatherTab from "./tabs/WeatherTab";
import RequestTab from './tabs/RequestTab';


let TABS = [
    {id: "texte",      label: "Un texte",      icon: (<TextIcon style={{width: '20px', color: '#2494D1'}}/>),  component: (props) => <TextTab key="TextTab" {...props}/>},
    {id: "image",      label: "Une image",     icon: (<PictureIcon style={{width: '20px', color: '#2494D1'}}/>),  component: (props) => <PictureTab key="pictureTab" {...props}/>},
    {id: "bouton",     label: "Un bouton",     icon: (<ButtonIcon style={{width: '20px', color: '#2494D1'}}/>),  component: (props) => <ButtonTab key="ButtonTab" {...props}/>},
    {id: "formulaire", label: "Un formulaire", icon: (<FormIcon style={{width: '20px', color: '#2494D1'}}/>),  component: (props) => <FormTab key="FormTab" {...props}/>},
    {id: "icônes",     label: "Une icône",     icon: (<IconsIcon style={{width: '20px', color: '#2494D1'}}/>),  component: (props) => <IconTab key="IconTab" {...props}/>},
    {id: "décompte",   label: "Un décompte",   icon: (<CountdownIcon style={{width: '20px', color: '#2494D1'}}/>),  component: (props) => <CountDownTab key="CountDownTab" {...props}/>},
    {id: "video",      label: "Une vidéo",     icon: (<VideoIcon style={{width: '20px', color: '#2494D1'}}/>),  component: (props) => <AddVideo key="VideoTab" {...props} />},
    {id: "météo",    label: "La météo",      icon: (<CloudyIcon style={{width: '20px', color: '#2494D1'}}/>),  component: (props) => <WeatherTab key="WeatherTab" {...props} />},
]

export default function Insert({isRequestHandler}) {

    const [title, setTitle] = useState('Insérer')
    const [tab, setTab] = useState('')

    const car = useRef()
    const container = useRef()

    useEffect(() => {
        if (isRequestHandler) {
            TABS.push(
                {id: "request",    label: "La RequestTab",      icon: (<CloudyIcon style={{width: '20px', color: '#2494D1'}}/>),  component: (props) => <RequestTab key="RequestTab" {...props} />}
            )
        } else {
            TABS = TABS.filter(item => item.id !== 'request')
        }
    }, [isRequestHandler])

    const handleChange = (val) => {

        if (car.current) {
            setTitle(val)
            setTab(val)
            setTimeout(() => {
                car.current.goTo(1)
            }, 175)

        }
    }
    const back = () => {
        // console.log(car)
        if (car.current) {
            setTitle('Insérer')
            car.current.goTo(0)
        }
    }

    const clickToClose = () => {
        if (container.current)
            container.current.close()
    }

    const content = (
        <div style={{width: '300px'}} >
            <Carousel dots={false} ref={car}>
                <div className="insert">
                    <div className="insert-grid">
                        {
                            TABS.map(tab => 
                                <div className="insert-btn" onClick={() => handleChange(tab.id)} key={tab.id}>
                                    {tab.icon}
                                    <p>{tab.label}</p>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="insert">
                    {
                        TABS.map(item => {
                            if (item.id === tab.toLowerCase()) {
                                return item.component({setVisible: clickToClose});
                            }
                        })
                    }

                </div>
            </Carousel>
        </div>
    )



    return (
        <Popover placement="rightTop"
                 ref={container}
                 title={
                     <div className="insert-title">
                         {
                             title === 'Insérer' ? "Insérer" :
                                 <>
                                     <LeftCircleOutlined onClick={back}
                                                         style={{position: 'absolute', top: '8px', left: '0'}}/>
                                     {tab}
                                 </>
                         }
                     </div>
                 }
                 content={content}
                 trigger="click"
                 onVisibleChange={(e) => {
                     if (!e) {
                         setTitle('Insérer')
                     }
                 }}
                 destroyTooltipOnHide={true}>
            <Button className="action-btn" type="primary" ><span
                style={{fontSize: '16px'}}><PlusIcon width="1em" height="1em"/></span> <span>Insérer</span></Button>
        </Popover>
    )
}