import React, { useState } from 'react'
import { Button, Input, message, Modal, PageHeader, Space, Tag, Switch } from "antd";
import Icon, { CheckCircleOutlined, EditTwoTone, ExclamationCircleOutlined } from "@ant-design/icons";
import CollectionTable from "../CollectionTable";
import { useApiContext } from "../../providers/ApiProvider";
import { useHistory } from "react-router-dom";
import { useAccountContext } from "../../providers/AccountProvider";
import ModalAccountPermissions from "../modal/ModalAccountPermissions";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete_icon.svg";
import { AccountForm } from "./AccountForm";
import moment from "moment";
import { ModalSubDate } from "../modal/ModalSubDate";

const defaultFilter = { name: 'isAdminAccount', value: false }

export default function AccountList() {

    const [needUpdate, setNeedUpdate] = useState(0)
    const [apiDispatch] = useApiContext();
    const { apiFetchEntity, apiDeleteEntity, apiUpdateEntity } = apiDispatch;
    const [filters, setFilters] = useState([defaultFilter, { name: "name", value: "" }])
    const [accountState, accountDispatch] = useAccountContext();
    const { accountSet } = accountDispatch;
    const history = useHistory();
    const [isCreateVisible, setIsCreateVisible] = useState(false)
    const [isDateModalVisible, setIsDateModalVisible] = useState(false)
    const [showPermission, setShowPermission] = useState(null)
    const [selectedAccount, setSelectedAccount] = useState(null)

    function editAccount(account) {
        setSelectedAccount(account)
        setIsCreateVisible(true)
    }

    function editDate(account) {
        setIsDateModalVisible(true)
        setSelectedAccount(account)
    }

    const [switchTimeout, setSwitchTimeout] = useState(null)
    const [activeTimeout, setActiveTimeout] = useState(null)

    async function onChange(value, id) {
        clearTimeout(switchTimeout)

        setSwitchTimeout(setTimeout(async () => {
            let data = {
                isPremium: value,
                subscriptionDate: new Date()
            }
            let response = await apiUpdateEntity('accounts', id, data)

            if (response['@type'] === 'hydra:Error') {
                message.error('Oups, il y a eu un soucis')
                setNeedUpdate((new Date()).getTime())
            }
        }, 300))
    }


    function showDeleteConfirm(id) {
        Modal.confirm({
            title: 'Êtes-vous sur de vouloir supprimer ce compte ?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Supprimer',
            okType: 'danger',
            cancelText: 'Annuler',
            onOk() {
                handleRemove(id)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    async function handleActivation(id, value) {

        clearTimeout(activeTimeout)

        setActiveTimeout(setTimeout(async () => {
            let response = await apiUpdateEntity('accounts', id, { isActive: value })

            if (response['@type'] === 'hydra:Error') {
                message.error('Oups, il y a eu un soucis')
                setNeedUpdate((new Date()).getTime())
            }
        }))
    }

    function showAccountPermission(account) {
        setShowPermission(account)
    }

    async function handleRemove(id) {

        const response = await apiDeleteEntity("accounts", id);

        if (response["@type"] === "hydra:Error") {
            return message.error('Ce compte n\'a pas pu être supprimé')
        }
        message.success("Compte supprimé avec succès")
        setNeedUpdate(needUpdate + 1)
    }

    function handleClick(account) {
        accountSet(account);
        history.push('/dashboard');
    }


    const columns = [
        {
            title: 'Nom du compte',
            dataIndex: "name",
            render: (name, record) => (
                <span onClick={() => handleClick(record)}>
                    {name}
                </span>)
        },
        {
            title: 'Url',
            dataIndex: "url",
            render: (url) => (
                <span>
                    {url}
                </span>)
        },
        {
            title: 'Premium',
            dataIndex: "isPremium",
            render: (isPremium, record) => (
                <Space>
                    <Switch defaultChecked={isPremium} size="small"
                        onChange={(value) => onChange(value, record.id)} />
                </Space>)
        },
        {
            title: 'Renouvellement / Fin de l\'abonnement',
            dataIndex: "subscriptionEnd",
            render: (subscriptionEnd, record) =>
                subscriptionEnd ?
                    <div onClick={() => editDate(record)}>
                        <Tag className="pointer" title="Fin de l'abonnement"
                            icon={moment().diff(moment(subscriptionEnd)) > 0 ? null : <ExclamationCircleOutlined />}
                            color={moment().diff(moment(subscriptionEnd)) > 0 ? null : "#FF9900"}>{moment(subscriptionEnd).format('DD-MM-YYYY')}</Tag>
                    </div>
                    :
                    <Space title="Renouvellement automatique">
                        <CheckCircleOutlined title="Renouvellement automatique" />
                        {moment(record.subscriptionDate).format('DD-MM-YYYY')}
                    </Space>

        },
        {
            title: 'Actif',
            dataIndex: "isActive",
            render: (isActive, record) => (
                <Space>
                    <Switch defaultChecked={isActive} size="small"
                        onChange={(value) => handleActivation(record.id, value)} />
                </Space>)
        },
        {
            title: 'Actions',
            dataIndex: "id",
            render: (id, record) => (
                <Space>
                    <Tag onClick={() => showAccountPermission(record)} className="pointer">Permissions</Tag>
                    <EditTwoTone onClick={() => editAccount(record)} />
                    <Icon component={DeleteIcon} style={{ color: "#E64D3D" }} onClick={() => showDeleteConfirm(id)} />
                </Space>
            )
        }
    ]

    const [searchInterval, setSearchInterval] = useState(null)

    function handleSearch(e) {
        clearTimeout(searchInterval)
        console.log(e.target.value)
        setSearchInterval(setTimeout(function () {
            setFilters([defaultFilter, { name: 'name', value: e.target.value.trim() }])
        }, 200))

    }


    const handleCreationFormClose = () => {
        setIsDateModalVisible(false);
        setIsCreateVisible(false);
        setSelectedAccount(null)
        setNeedUpdate((new Date).getTime())
    }

    async function dlCsv() {
        let response = await apiFetchEntity('export-account-csv', '')

        const now = new Date();
        const date = `${now.getDate().toString().padStart(2, 0)}-${now.getMonth().toString().padStart(2, 0)}-${now.getFullYear()}`;
        const filename = `push-marketing_export-account-${date}.csv`
        const link = document.createElement('a');
        link.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(response);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <>
            <PageHeader
                title="Comptes"
                extra={[
                    <Input onChange={handleSearch} style={{ width: '200px' }} placeholder="Rechercher par nom"
                        key="user_search" />,
                    <Button type="primary"
                        key="account_create"
                        onClick={() => setIsCreateVisible(true)}>Créer</Button>,
                    <Button
                        key="accounts_export"
                        onClick={dlCsv}>Export</Button>,
                ]}
            />
            <CollectionTable columns={columns} filters={filters} endpoint={"accounts"} reload={needUpdate} />

            {
                showPermission &&
                <ModalAccountPermissions account={showPermission} setAccount={setShowPermission} />
            }
            {
                isDateModalVisible &&
                <ModalSubDate onClose={handleCreationFormClose} visible={isDateModalVisible} item={selectedAccount} />
            }
            <AccountForm onClose={handleCreationFormClose} visible={isCreateVisible} item={selectedAccount} modal />
        </>
    )

}