import React, { useState } from 'react'
import { Select } from "antd";
import { fonts } from "./utils";
import { useAccountContext } from "../../providers/AccountProvider";
import { useEditorContext } from '../../providers/EditorProvider';


export default function FontSelect({ handleChange, font = 'arial' }) {

    const [accountState] = useAccountContext();
    const { account } = accountState;
    const [editor, editorDispatch] = useEditorContext()
    const { setter } = editorDispatch
    const [recentFonts, setRecentFonts] = useState([...new Set([font, ...editor.fonts])])

    const onChange = (value) => {
        let fontsArray = [...new Set([value, ...editor.fonts.slice(0, 4)])]
        setter('fonts', fontsArray)
        setRecentFonts(prev => [...new Set([value, ...prev.slice(0, 4)])])
        handleChange(value)
    }

    return (
        <Select style={{ width: '150px' }} value={font}
            onChange={onChange}>
            {
                recentFonts.length ?
                    <Select.OptGroup label="Récent">
                        {recentFonts.map(item =>
                            <Select.Option
                                key={'recent_' + item}
                                value={item}
                                style={{ fontFamily: item }}
                            >
                                {item.split(',')[0]}
                            </Select.Option>
                        )}
                    </Select.OptGroup>
                    : null
            }
            {
                account.fonts.length ?
                    <Select.OptGroup label="Favorites">
                        {[...account.fonts].sort((a, b) =>
                            a.toLowerCase().localeCompare(b.toLowerCase())).map(item =>
                                <Select.Option
                                    key={'fav_' + item}
                                    value={item}
                                    style={{ fontFamily: item }}
                                >
                                    {item}
                                </Select.Option>
                            )}
                    </Select.OptGroup>
                    : null
            }
            <Select.OptGroup label="Populaires">
                {fonts.filter(item => !recentFonts.includes(item.value)).map(item =>
                    <Select.Option
                        key={item.value}
                        value={item.value}
                        style={{ fontFamily: item.value }}
                    >
                        {item.name}
                    </Select.Option>
                )}
            </Select.OptGroup>
        </Select>
    )
}