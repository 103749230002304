import React, {useContext, useState} from 'react';
import {useImmer} from 'use-immer';
import {useApiContext} from "./ApiProvider";

// ---------------------------------------------------
// Default contextual state values
// ---------------------------------------------------
let _message = {
        id: 0,
        name: "New message",
        type: 'notification',
        options: {
            closable: true,
            modalBackground: "rgba(0, 0, 0, 0.65)"
        },
        children: [],
        style: {}
    }
;

const defaultState = {
    messageId: null,
    message: _message,
    selectedElement: null,
    undoLog: [],
    redoLog: [],
    needUpdate: 0,
    needSaveDraft: false,
    versionA: null,
    versionB: null,
    testIsActive: false,
    lng: 'fr',
    playAnimation: null,
    lastSave: null,
    waitingSave: false,
    editMode: false,
    currentStep: 'parent',
    parent: null,
    nextStep: null,
    fonts: []
};

// ---------------------------------------------------
// Context provider declaration
// ---------------------------------------------------
const StateContext = React.createContext();
const DispatchContext = React.createContext();

const EditorProvider = ({children}) => {
    const [state, dispatch] = useImmer({...defaultState});

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    );
};

// ---------------------------------------------------
// Context usage function declaration
// ---------------------------------------------------
function useStateContext() {
    const state = useContext(StateContext);

    if (state === undefined) {
        throw new Error("Ut oh, where is my editor state?");
    }

    return state;
}

function useDispatchContext() {
    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);
    const [apiDispatch] = useApiContext();
    const {apiUpdateEntity} = apiDispatch;
    const [proccessTimeout, setProccessTimeout] = useState(null)

    if (state === undefined) {
        throw new Error("Ut oh, where is my editor state?");
    }

    if (dispatch === undefined) {
        throw new Error("Ut oh, where is my editor dispatch?");
    }

    function setMessage(message, undosed = true) {
        if (message.nextStep) {
            delete message.nextStep
        }
        if (undosed)
            pushToUndo();

        dispatch(draft => {
            draft.message = {...message};
        });
    }

    function setStyle(style) {
        pushToUndo();

        dispatch(draft => {
            draft.message.style = style;
        });
    }

    function setChildren(children) {
        pushToUndo();

        dispatch(draft => {
            draft.message.children = children;
        });
    }

    function setOptions(options, needSave = true) {
        dispatch(draft => {
            draft.message.options = options;
        });

        if (needSave)
            console.log("save style")
    }

    function addElement(value) {
        pushToUndo();

        dispatch(draft => {
            let elements = draft.message.children || []
            elements.push(value)
            draft.message.children = [...elements];
        });
    }

    function updateElement(element) {

        dispatch(draft => {
            // draft.selectedElement = element;
            draft.message.children = draft.message.children.map(child => {
                if (child.id === element.id) {
                    if (JSON.stringify(child) !== JSON.stringify(element)) {
                        draft.undoLog = [...draft.undoLog, state.message];
                        draft.redoLog = [];
                    }
                    if (element.type === 'button') {
                        let hover = {...element.hover}
                        delete hover.filter
                        console.log(hover)
                        element.hover = hover
                    }
                    return element;
                }
                return child;
            });
        })
    }

    function duplicateElement(id) {
        let elem = {...state.message.children.find(item => item.id === id)}
        elem.id = Date.now()
        elem.name = `${elem.name} copy`
        let style = {...elem.style}
        let translate = [...elem.translate]
        translate[0] = translate[0] + 10
        translate[1] = translate[1] + 10
        elem.translate = translate
        style.transform =  `translate(${translate[0]}px, ${translate[1]}px) rotate(${translate[2]}deg)`
        elem.style = style
        addElement(elem)
        selectElement(elem)
    }

    function pushToUndo() {
        dispatch(draft => {
            draft.undoLog = [...draft.undoLog, state.message];
            draft.redoLog = [];
        })
    }

    function undo() {
        if (state.undoLog.length) {
            dispatch(draft => {
                let pop = draft.undoLog.pop();
                let toRedo = draft.message;
                if (state.message.id !== pop.id) changeStep()

                draft.message = pop;
                draft.redoLog = [...draft.redoLog, toRedo];
                draft.updated = (new Date()).getTime()
            })
        }
    }

    function redo() {
        if (state.redoLog.length) {
            dispatch(draft => {
                let pop = draft.redoLog.pop();
                let toUndo = draft.message;
                if (state.message.id !== pop.id) changeStep()

                draft.message = pop;
                draft.undoLog = [...draft.undoLog, toUndo];
                draft.updated = (new Date()).getTime()

            })
        }
    }

    function bringToFront(id) {
        pushToUndo();
        let element = state.message.children.find(item => item.id === id)
        dispatch(draft => {
            draft.message.children = [...draft.message.children.filter(child => child.id !== element.id), element]
        })
    }

    function sendToBack(id) {
        pushToUndo();
        let element = state.message.children.find(item => item.id === id)
        dispatch(draft => {
            draft.message.children = [element, ...draft.message.children.filter(child => child.id !== element.id)]
        })
    }

    function updatePosition(direction, id) {
        let targetIndex = state.message.children.findIndex(element => element.id === id)
        let elementsCopy = [...state.message.children].filter(child => child.id !== id)
        let target = state.message.children.find(item => item.id === id)
        console.log(direction)
        let newIndex = direction === 'up' ? targetIndex + 1 : targetIndex - 1;
        newIndex = newIndex < 0 ? 0 : newIndex

        elementsCopy.splice(newIndex, 0, target)
        setChildren(elementsCopy)
    }

    function removeElement(id) {
        pushToUndo();

        unSelectElement()
        dispatch(draft => {
            draft.message.children = [...draft.message.children.filter(child => child.id !== id)]
        })
    }

    function selectElement(element) {
        dispatch(draft => {
            if (draft.selectedElement && draft.selectedElement.id === element.id) {
                return
            }
            draft.selectedElement = element;
        })
    }

    function unSelectElement() {
        dispatch(draft => {
            draft.selectedElement = null;
        })
    }

    function refresh() {
        dispatch(draft => {
            draft.needUpdate += 1;
        })
    }

    function changeStep() {
        let step = state.currentStep === 'parent' ? 'nextStep' : 'parent'
        dispatch(draft => {
            if (step === draft.currentStep) return
            if (step === 'parent') {
                draft.nextStep = {...draft.message}
                draft.message = {...draft.parent}
            } else {
                draft.parent = {...draft.message};
                draft.message = {...draft.nextStep}
            }
            draft.currentStep = step
        })
    }

    function autoSave() {
        setter('waitingSave', true)
        clearTimeout(proccessTimeout)
        setProccessTimeout(setTimeout(async () => {
            save();

            if (!state.needSaveDraft) {
                setter("needSaveDraft", true);
                apiUpdateEntity("messages", state.messageId, {needSaveDraft: true});
            }
        }, 3000))
    }

    async function save() {
        let messages = [{...state.message}];
        if (state.currentStep === 'parent' && state.nextStep) {
            messages.push({...state.nextStep})
        } else if (state.currentStep === 'nextStep' && state.parent) {
            messages.push({...state.parent})
        }
        let promise = messages.map(async data =>  await apiUpdateEntity("message_contents", data.id, data))
        Promise.all(promise).then(() => {
                setter('waitingSave', false)
                setter('lastSave', new Date())
            }
        )
    }

    function resetEditor() {
        dispatch(draft => {
            draft.messageId = null;
            draft.message = {
                id: 'new',
                name: "New message",
                type: 'notification',
                children: [],
                style: {}
            };
            draft.undoLog = [];
            draft.redoLog = [];
            draft.style = {};
            draft.children = [];
            draft.selectedElement = null;
            draft.versionA = null;
            draft.versionB = null;
            draft.testIsActive = false;
            draft.lng = 'fr'
            draft.currentStep = 'parent'
            draft.fonts = []
        })
    }

    function setter(key, value) {
        dispatch(draft => {
            draft[key] = value;
        })
    }

    return {
        setMessage,
        changeStep,
        setChildren,
        setOptions,
        addElement,
        updateElement,
        removeElement,
        selectElement,
        unSelectElement,
        bringToFront,
        sendToBack,
        updatePosition,
        undo,
        redo,
        save,
        autoSave,
        resetEditor,
        refresh,
        setter,
        duplicateElement
    };
}

const useEditorContext = () => {
    return [useStateContext(), useDispatchContext()]
};

export {useEditorContext, EditorProvider, StateContext, DispatchContext};
