import React, {useState} from "react";
import {Button, Form, Input, message, Modal} from "antd";
import {useApiContext} from "../../providers/ApiProvider";


export default function ModalForgetPassword() {

    const [apiDispatch] = useApiContext();
    const {post} = apiDispatch

    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const api_endpoint = process.env.API_ENTRYPOINT;

    const [form] = Form.useForm();

    async function handleOk() {
        form.submit()
    }

    const onFinish = async (values) => {
        setLoading(true);

        const response = await post(api_endpoint + '/public/forget-password', values);

        if (response.status !== 'success')
            message.error(response.message)
        else {
            message.success('Un email a été envoyé à cette adresse');
            setTimeout(() => setVisible(false), 1000);
        }

        setLoading(false);
    };

    return (
        <>
            <Button type="link" onClick={() => setVisible(true)}>
                Mot de passe oublié
            </Button>

            <Modal
                title='Mot de passe oublié'
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={[
                    <Button key="back" onClick={() => setVisible(false)}>
                        Annuler
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                        Demander nouveau mot de passe
                    </Button>
                ]}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label='Veuillez entrer votre adresse mail'
                        name="email"
                        rules={[
                            {required: true, message: 'Email requis'},
                            {type: 'email', message: 'Email non valide!'}
                        ]}
                    >
                        <Input
                            style={{
                                width: 300
                            }}
                            placeholder="yourname@example.com"
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}