import React from "react";

export const DimensionViewable = {
    name: "dimensionViewable",
    props: {},
    events: {},
    render(moveable, React) {
        const rect = moveable.getRect();
        // Add key (required)
        // Add class prefix moveable-(required)
        return <div key={"dimension-viewer"} className={"moveable-dimension"} style={{
            position: "absolute",
            left: `${rect.width / 2}px`,
            top: `-30px`,
            background: "#4af",
            borderRadius: "2px",
            padding: "2px 4px",
            color: "white",
            fontSize: "13px",
            whiteSpace: "nowrap",
            fontWeight: "bold",
            willChange: "transform",
            transform: "translate(-50%, 0px)",
        }}>
            {Math.round(rect.offsetWidth)} x {Math.round(rect.offsetHeight)}
        </div>
    }
}

export const RotationViewable = {
    name: "rotationViewable",
    props: {},
    events: {},
    render(moveable, React) {
        const rect = moveable.getRect();
        // Add key (required)
        // Add class prefix moveable-(required)
        return <div key={"rotation-viewer"} className={"moveable-rotation-info"} style={{
            position: "absolute",
            left: `${rect.width / 2}px`,
            top: `-30px`,
            background: "#4af",
            borderRadius: "2px",
            padding: "2px 4px",
            color: "white",
            fontSize: "13px",
            whiteSpace: "nowrap",
            fontWeight: "bold",
            willChange: "transform",
            transform: "translate(-50%, 0px)",
        }}>
            {Math.round(rect.rotation)}°
        </div>
    }
}
