import React, {useState} from "react";
import {Button, Card, Space, Switch} from "antd";
import moment from 'moment'
import Icon, {CopyOutlined, EditTwoTone, EyeOutlined,} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {useAccountContext} from "../providers/AccountProvider";
import ModalInputCategory from "./modal/ModalInputCategory";

import {ReactComponent as CursorIcon} from "../assets/icons/cursor_icon.svg";
import {ReactComponent as DeleteIcon} from '../assets/icons/delete_icon.svg'
import {ReactComponent as SettingIcon} from '../assets/icons/setting_icon.svg'
import {ReactComponent as TimeIcon} from '../assets/icons/time_icon.svg'

export default function MessageCard({message, setMessageID, toggleStatus, openModal, showDeleteConfirm}) {

    const [accountState] = useAccountContext();

    const [checkTimeout, setCheckTimeout] = useState(null)
    const [status, setStatus] = useState(message.status)

    const handleCheck = (checked) => {
        clearTimeout(checkTimeout)
        setCheckTimeout(setTimeout(() => {
            let newStatus = checked ? 'active' : 'inactive'
            toggleStatus(message.id, newStatus)
            setStatus(newStatus)
        }, 200))
    }

    return (
        <Card className="card-style" style={{width: 400}}>
            <div className="flex between flexColumn" style={{height: '100%'}}>
                <div>
                    <div className="flex align-baseline between">
                        <div>
                            {status === "active" ? 'Actif' : 'Inactif'} &nbsp;
                            <Switch size="small" defaultChecked={message.status === 'active'} onChange={handleCheck}/>
                        </div>
                        <Space>
                            { message.isTemplate && !message.isRequestHandler ?
                                <ModalInputCategory message={message}/> : null}
                            <Button size="small" icon={<EditTwoTone/>} onClick={() => openModal(message, "rename")}
                                    title="Renommer"/>
                            <Button size="small" icon={<Icon component={DeleteIcon}/>}
                                    onClick={() => showDeleteConfirm(message.id)} danger title="Supprimer"/>
                        </Space>
                    </div>
                    <div className="flex between align-baseline">
                        <h4>{message.name} </h4>

                    </div>
                </div>
                {
                    message.stats ?
                        <div className="mt-2em">
                            <p className="flex between">
                                <span><EyeOutlined/> Nombre de vues </span>
                                <span>{message.stats.views}{message.testIsActive && message.stats.haveTest ? " / " + message.testing.stats.views : null}</span>
                            </p>
                            <p className="flex between">
                                <span><CursorIcon width="1em" height="1em"/> Taux de clic </span>
                                <span>{message.stats.click.toFixed(0)}%{message.testIsActive && message.stats.haveTest ? " / " + message.testing.stats.click.toFixed(0) + "%" : null}</span>
                            </p>
                        </div>
                        : <p>No stats</p>
                }
                <div className="mt-2em">
                    <p className="flex between">
                        <span><TimeIcon width="1em" height="1em"/> Crée le </span>
                        <span>{moment(message.createdAt).format('DD-MM-YYYY')}</span>
                    </p>

                    <p className="flex between">
                        <span><SettingIcon width="1em" height="1em"/> Modifié le </span>
                        <span>{moment(message.updatedAt).format('DD-MM-YYYY')}</span>
                    </p>
                </div>
                <div className="flex between mt-2em">
                    <div>
                        <Link to={`editor/${message.id}`}>
                            <Button type="primary"
                                    size="large"
                                    style={{marginRight: '14px'}}>Modifier</Button>
                        </Link>
                        <Button
                            size="large"
                            onClick={() => setMessageID(message.id)}>Aperçu</Button>
                    </div>
                    <Space>

                        <Button size="large" className="warning-btn"
                                onClick={() => openModal(message, 'duplicate')} icon={<CopyOutlined/>}/>
                    </Space>
                </div>
            </div>
        </Card>
    )
}