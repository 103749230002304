import React, {useState} from 'react'
import background from "../assets/bg-login.jpg";
import logo from "../assets/logo.png";
import {Button, Card, Form, Input, message} from "antd";
import {HomeOutlined, LockOutlined, MailOutlined, UserOutlined} from "@ant-design/icons";
import logoMmc from "../assets/logo-mmc-white.png";
import {ReactComponent as LinkIcon} from "../assets/icons/behaviour_page_icon.svg";
import {useApiContext} from "../providers/ApiProvider";
import {useAuthContext} from "../providers/AuthProvider";
import {Redirect} from "react-router-dom";

const api_endpoint = process.env.API_ENTRYPOINT

export default function SignUp() {

    const [processing, setProcessing] = useState(false);
    const [apiDispatch] = useApiContext();
    const {post} = apiDispatch;
    const [authState, authDispatch] = useAuthContext();
    const {authLogin, authIsLogged} = authDispatch;

    const onFinish = async (values) => {
        setProcessing(true);

        const data = {
            firstname: values.firstname.trim(),
            lastname: values.lastname.trim(),
            accountName: values.accountName.trim(),
            email: values.email.trim(),
            password: values.password,
            url: values.url.trim()
        }

        const response = await post(`${api_endpoint}/public/create-account`, data);

        if (!response.success) {
            message.error(response.message)
        } else {
            message.success('Votre compte a été crée avec succes.')
            await authLogin(values.email.trim(), values.password, (response) => {
                if (response.error)
                    message.error(response.error);
            });
        }

        setProcessing(false)

    }

    if (authIsLogged())
        return <Redirect to="/"/> 

    return (
        <div
            style={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0) 100%), center / cover url(${background})`,
                justifyContent: "space-between",
                alignItems: "center",
                padding: '0 84px',
                height: '100%',
                display: "flex"
            }}
        >
            <div style={{width: '43%'}}>
                <img style={{height: '62px', marginBottom: '40px'}} src={logo} alt="logo hotel push marketing"/>
                <p className="log-description">
                    Personnalisez votre site web selon le profil de chaque visiteur et boostez ainsi ses performances !
                </p>
            </div>

            <Card className="card-style" style={{width: '38%', padding: '1em 2em 0', margin: 0, maxWidth: '550px'}}>
                <div>
                    <h2 style={{textAlign: 'center'}}>Inscription</h2>
                </div>
                <div>
                    <Form
                        name="signup"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        style={{
                            margin: '1em auto',
                            width: "100%",
                            padding: "1em",
                        }}
                    >
                        <Form.Item
                            name="email"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'L\'email est obligatoire!',
                                },
                            ]}
                        >
                            <Input placeholder="Email"
                                   prefix={<MailOutlined />}/>
                        </Form.Item>

                        <Form.Item
                            name="lastname"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Le nom est obligatoire!',
                                },
                            ]}
                        >
                            <Input placeholder="Nom"
                                   prefix={<UserOutlined />}/>
                        </Form.Item>

                        <Form.Item
                            name="firstname"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Le prénom est obligatoire!',
                                },
                            ]}
                        >
                            <Input placeholder="Prénom"
                                   prefix={<UserOutlined />}/>
                        </Form.Item>

                        <Form.Item
                            name="accountName"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Le nom de votre hôtel est requis',
                                },
                            ]}
                        >
                            <Input placeholder="Nom de votre hôtel" prefix={<HomeOutlined />}/>
                        </Form.Item>

                        <Form.Item
                            name="url"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'L\'url est requis',
                                },
                            ]}
                        >
                            <Input placeholder="Url de votre site" prefix={<LinkIcon style={{width: '1em', height: '1em'}} />}/>
                        </Form.Item>

                        <Form.Item
                            name="password"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'entrer un mot de passe!',
                                },
                            ]}
                        >
                            <Input.Password autoComplete="new-password" placeholder="Mot de passe" prefix={<LockOutlined />}/>
                        </Form.Item>

                        <Form.Item
                            name="confirm"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({getFieldValue}) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value)
                                            return Promise.resolve();

                                        return Promise.reject('les deux mots de passe ne sont pas indentiques');
                                    }
                                })
                            ]}
                        >
                            <Input.Password placeholder={"Confirmer le mot de passe"} prefix={<LockOutlined />}/>
                        </Form.Item>
                        <div style={{textAlign: 'center'}}>
                            <Button type="primary" htmlType="submit" loading={processing}>
                                S'inscrire
                            </Button>
                        </div>
                    </Form>
                </div>
            </Card>

            <img src={logoMmc} alt="" className="mmc-logo"/>
        </div>
    );
}