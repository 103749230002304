import React, {useState} from 'react';
import {Button, message} from "antd";
import {useApiContext} from "../providers/ApiProvider";

export default function CheckEmailMarketing({emailMarketing}) {

    const [apiDispatch] = useApiContext();
    const {post} = apiDispatch;

    const [loading, setLoading] = useState(false);

    async function check() {
        setLoading(true);

        const response = await post('https://api.mmcmail.mmcreation.com/mailchimp/ping', {api_key: emailMarketing.apiKey});

        if (response.success)
            message.success("Connexion établie");
        else
            message.error("Connexion impossible, vérifier vos identifiants");

        setLoading(false);
    }

    return (
        emailMarketing?.platform ?
            <Button
                type="primary"
                onClick={check}
                loading={loading}
            >
                Tester la connexion
            </Button> : null
    )
}