import React, {useRef, useState} from 'react'
import {useEditorContext} from "../../../providers/EditorProvider";
import {faArrowDown, faArrowUp, faBringFront, faSendBack} from "@fortawesome/pro-solid-svg-icons";
import FaIcon from "../../FaIcon";
import {Button, Typography} from 'antd';
import {DeleteTwoTone} from "@ant-design/icons";

const {Paragraph} = Typography;

export default function LayersTab() {

    const [editorState, editorDispatch] = useEditorContext()
    const {
        bringToFront,
        removeElement,
        selectElement,
        sendToBack,
        setChildren,
        setter,
        updateElement,
        updatePosition,
        unSelectElement,
    } = editorDispatch

    const [draggedElement, setDraggedElement] = useState(null)
    const [targetElement, setTargetElement] = useState(null)
    const [position, setPosition] = useState(null)

    const dragContainer = useRef()

    function handleDragStart(child) {
        console.log('dragStart')
        selectElement(child)
        setDraggedElement(child)
    }

    function isInContainer(e) {
        let container = dragContainer.current.getClientRects()[0]
        if (e.clientX < container.x || e.clientX > container.x + container.width) return false
        if (e.clientY < container.y || e.clientY > container.y + container.height) return false

        return true
    }

    function handleDragEnd(e) {

        let isInDragZone = isInContainer(e)

        if (!targetElement || !isInDragZone) {
            return
        }

        let elementsCopy = [...editorState.message.children].filter(child => child.id !== draggedElement.id)
        let targetIndex = elementsCopy.findIndex(element => element.id === targetElement.id)
        let newIndex = position === 'top' ? targetIndex + 1 : targetIndex;

        elementsCopy.splice(newIndex, 0, draggedElement)

        setChildren(elementsCopy)
        setPosition(null)
        setTargetElement(null)

    }


    function handleDragLeave(e) {
        let targetEl = e.target
        targetEl.classList.remove('hover-top')
        targetEl.classList.remove('hover-bot')
        targetEl.classList.remove('dragover')

    }

    function handleDragOver(e, target) {
        let targetEl = e.target
        let targetDimensions = targetEl.getClientRects()
        if (target.id !== draggedElement.id) {

            let newPosition = e.clientY < targetDimensions[0].top + targetDimensions[0].height / 2 ? 'top' : 'bot';

            targetEl.classList.remove('hover-top')
            targetEl.classList.remove('hover-bot')
            targetEl.classList.add(`hover-${newPosition}`)

            setPosition(newPosition)
            setTargetElement(target)
        }

    }


    function handleNameChange(child, value) {
        console.log(value)
        updateElement({...child, name: value})
        setter('editMode', false)
    }

    function handleSpaceHover(e) {
        let targetEl = e.target
        targetEl.classList.add(`dragover`)
    }


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '100%'
        }}>
            <div className="layerIcons"
                 style={{position: 'sticky', top: 0, width: '100%', backgroundColor: 'white', padding: '1em 0'}}>
                <Button
                    style={{margin: '0 5px'}}
                    title={'Mettre au premier plan'}
                    icon={<FaIcon icon={faBringFront}/>}
                    onClick={() => bringToFront(editorState.selectedElement.id)}
                    key="goFront"/>

                <Button
                    style={{margin: '0 5px'}}
                    icon={<FaIcon icon={faArrowUp}/>}
                    title={'Envoyer vers le premier plan'}
                    onClick={() => updatePosition('up', editorState.selectedElement.id)}
                    key="goUp"/>

                <Button
                    style={{margin: '0 5px'}}
                    icon={<FaIcon icon={faArrowDown}/>}
                    title={'Envoyer vers l\'arrière plan'}
                    onClick={() => updatePosition('down', editorState.selectedElement.id)}
                    key="goDown"/>

                <Button
                    style={{margin: '0 5px'}}
                    icon={<FaIcon icon={faSendBack}/>}
                    title={'Mettre en arrière plan'}
                    onClick={() => sendToBack(editorState.selectedElement.id)}
                    key="goBack"/>
            </div>

            <div className="layersContainer" ref={dragContainer}>
                {
                    editorState.message.children.map(child => (
                            <div key={child.id}>
                                <div
                                    className={`layerBox ${editorState.selectedElement?.id === child.id ? 'selected' : ''}`}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        selectElement(child)
                                    }}
                                    draggable="true"
                                    onDragStart={() => handleDragStart(child)}
                                    onDragOver={(e) => handleDragOver(e, child)}
                                    onDragLeave={handleDragLeave}
                                    onDragEnd={handleDragEnd}
                                >
                                    <Paragraph editable={{
                                        onChange: (value) => handleNameChange(child, value),
                                    }}
                                               style={{marginBottom: '0'}}
                                               onClick={(e) => {
                                                   e.stopPropagation()
                                                   setter('editMode', true)
                                                   unSelectElement()
                                               }}
                                               className="mr-10">
                                        {child.name}
                                    </Paragraph>
                                    <DeleteTwoTone
                                        title="Supprimer cet élément"
                                        onClick={() => removeElement(child.id)}/>
                                </div>
                                <div className="inter"
                                     onDragOver={(e) => handleSpaceHover(e)}
                                     onDragLeave={handleDragLeave}
                                />
                            </div>
                        )
                    )
                }

            </div>


        </div>
    )
}