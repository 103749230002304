import React, {useEffect, useState} from 'react'
import {PageHeader, Tabs} from "antd";
import Templates from "./Templates";
import TemplateCategories from "./TemplateCategories";
import UserList from "../components/User/UserList";
import AccountList from "../components/Account/AccountList";
import {Redirect, useLocation} from "react-router-dom";
import {useAuthContext} from "../providers/AuthProvider";
import AdminSetting from "../components/AdminSetting";
import Timers from "../components/Timers/Timers";

export default function Admin({}) {

    const [authState] = useAuthContext();
    const {auth} = authState;

    const [activeTab, setActiveTab] = useState('account')
    const params = new URLSearchParams(useLocation().search)

    useEffect(() => {
        console.log('pa', params.get("tab"))
        let tab = params.get("tab") ?? 'account'
        setActiveTab(tab)
    }, [params.get("tab")])


    if (!auth.roles.includes('ROLE_ADMIN')) {
        return <Redirect to={{
            pathname: "/erreur",
            state: {status: '403', subtitle: 'Vous n\'êtes pas autorisé à accéder à cette page'}
        }}/>
    }

    return (
        <>
            <PageHeader
                title={<h1>Admin</h1>}
                style={{borderBottom: '2px solid #2494D1'}}
            />
            <Tabs activeKey={activeTab} onTabClick={(key) => setActiveTab(key)}>
                <Tabs.TabPane tab="Comptes" key="account">
                    <AccountList/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Utilisateurs" key="users">
                    <UserList/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Templates" key="templates">
                    <Templates/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Décomptes" key="timers">
                    <Timers />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Tags" key="tags">
                    <TemplateCategories/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={"Réglages"} key="settings">
                    <AdminSetting/>
                </Tabs.TabPane>
            </Tabs>

        </>
    )
}