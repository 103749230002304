import React from 'react'

export default function IconFinder({element, style, refTarget = null, className, onContextMenu}) {

    let currentFill = element.colors && !element.useCurrent ? '' : 'currentColor'

    return (
        <div
            id={element.id}
            className={className ? `${className} ${element.colors ? '' : 'iconFinder'} ${currentFill}` : `targets iconFinder ${currentFill}`}
            style={style}
            onContextMenu={onContextMenu}
            ref={refTarget}
            dangerouslySetInnerHTML={{__html: element.content}}
        >
        </div>
    )

}